import {
    all,
    call,
    fork,
    put,
    takeEvery,
    takeLatest,
} from 'redux-saga/effects';

import { NOTIFICATION_FETCH_LIST, NOTIFICATION_VIEWED } from '../actions';
import { notificationLoading, notificationSetList } from './actions';
import {
    getNotifications,
    notificationViewed,
} from '../../api/query/notification';
import filterNotifications from "../../components/common/filterNotifications";
import ErrorResponse from "../../components/common/ErrorResponse";
import {appActions} from "../toolkit/app/slice";

function* getNotificationList({ payload }) {
    try {
        yield put(notificationLoading(true));
        const getData = yield call(getNotifications, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(notificationSetList(filterNotifications(getData.data.items)));
        }
        yield put(notificationLoading(false));
    } catch (error) {
        const errorResponse = ErrorResponse(error.response);
        yield put(appActions.setNotification({type: 'error', message: errorResponse}));
        yield put(notificationLoading(false));
    }
}

export function* watchGetList() {
    yield takeEvery(NOTIFICATION_FETCH_LIST, getNotificationList);
}

function* setNotificationViewed({ payload }) {
    try {
        yield call(notificationViewed, payload);
    } catch (error) {
        yield put(appActions.setNotification({type: 'error', message: 'Ошибка при обработке уведомлений'}));
    }
}
export function* watchViewsID() {
    yield takeLatest(NOTIFICATION_VIEWED, setNotificationViewed);
}

export default function* rootSaga() {
    yield all([fork(watchGetList), fork(watchViewsID)]);
}
