// eslint-disable-next-line no-unused-vars
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { MANAGERS_CHECK_PHOTO, MANAGERS_FETCH_PHOTO } from '../actions';

import { changeLoading, fetchPhoto, setPhoto } from './actions';

import { SendGet } from '../../api/handler/sendGet';

export function* watchManagerPhoto() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(MANAGERS_FETCH_PHOTO, loadManagersPhoto);
  // yield takeEvery('*', function* logger(action) {
  //   const state = yield select();
  //
  //   console.log('action', action);
  //   console.log('state after', state);
  // });
}
export function* watchCheckManagerPhoto() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(MANAGERS_CHECK_PHOTO, checkManagersPhoto);
}
function* checkManagersPhoto({ payload }) {
  const managersData = (state) => state.managers;
  const { photoList, loading } = yield select(managersData);
  const arraySavesID = photoList.length
    ? photoList.map((el) => el.user_id)
    : [];
  if (!arraySavesID.length && !loading) {
    yield put(fetchPhoto(payload));
  } else {
    const compareID = [];
    payload.forEach((item) => {
      if (arraySavesID.indexOf(item) <= -1) {
        compareID.push(item);
      }
    });
    if (compareID.length && !loading) {
      yield put(fetchPhoto(payload));
    }
  }
}
const getManagersPhoto = async (managers) => {
  try {
    return await SendGet('managers/photo', { users: managers });
  } catch (error) {
    return error.response;
  }
};

function* loadManagersPhoto({ payload }) {
  try {
    const { managers } = payload;
    yield put(changeLoading(true));
    const getData = yield call(getManagersPhoto, managers.join(','));
    yield put(changeLoading(false));
    if (Number.parseInt(getData.status, 10) === 200) {
      yield put(setPhoto(getData.data));
    }
  } catch (error) {
    yield put(changeLoading(false));
  }
}

export default function* rootSaga() {
  yield all([fork(watchManagerPhoto), fork(watchCheckManagerPhoto)]);

  // yield [
  //   takeEvery(MANAGERS_FETCH_PHOTO, loadManagersPhoto),
  //   takeEvery(MANAGERS_CHECK_PHOTO, checkManagersPhoto),
  // ];
}
