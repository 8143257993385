import React, {useContext, useEffect, useMemo} from 'react';
import { useSelector } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import FormError  from '../../../../components/validate/FormError';
import {Style6Right} from "../../../../constants/ReactSelect";
import Contragent from "./../fields/Contragent";
import Name from "./../fields/Name";
import Status from "./../fields/Status";
import DateStart from "./../fields/DateStart";
import Responsible from "./../fields/Responsible";
import Executor from "./../fields/Executor";
import Priority from "./../fields/Priority";
import {FormGroup} from "reactstrap";
import {Grid} from "@mui/material";
import {ModalCreateTaskContext} from "../index";
import DoubleStatus from "../fields/DoubleStatus";
import Type from "../fields/Type";
import Deadline from "../fields/Deadline";

const Default = (props) => {
    const {showClient, showDeadline = false, showSelectType, excludeTypes, listTemplates, viewDoubleStatus} = props;
    const {data, errors, onChangeList, taskTypes, onClickClient, executorGroupsId, changedData} = useContext(ModalCreateTaskContext);

    const {
        onChangeTaskType,
        onChangeName,
        onChangeTaskTypeTemplate,
        onChangeClient,
        onChangeResponsible,
        onChangeManager,
        onChangePriority,
        onChangeTaskStatus,
        onChangeDateStart,
        onChangeTaskDoubleStatus,
        isDisabledExecutor,
        onChangeDeadline,
    } = onChangeList;

    const { currentUser } = useSelector((state) => state.authUser);

    const readOnlyName = useMemo(() => {
        if (!data.taskType) return false;
        const typeId = data.taskType.id;
        if ([5,7,10].includes(typeId)) {
            return true;
        }
        return false;
    }, [data.taskType]);

    useEffect(() => {
        if (readOnlyName && data.name) {
            onChangeName('');
        }
    }, [readOnlyName, data.name])

    const disabledType = useMemo(() => {
        return (taskTypes.length - excludeTypes.length) < 2;
    }, [taskTypes, excludeTypes]);

    const disabledPriority = useMemo(() => {
        let disabled = false;
        const templateId = data.taskTypeTemplate?.id ?? null;
        switch (templateId) {
            case 48:
            case 49:
            case 50:
            case 51:
            case 52:
            case 53:
                if (data.id && !changedData.taskTypeTemplate) {
                    disabled = true;
                }
                break;
        }

        return disabled;
    }, [data.id, data.taskTypeTemplate, changedData.taskTypeTemplate]);
    return (
        <>
            <Grid item xs={12}>
                <Grid container columnSpacing={2}>
                    {showSelectType && (
                        <Grid item xs={6}>
                            <FormGroup>
                                <Type
                                    value={data.taskType}
                                    setValue={onChangeTaskType}
                                    excludeId={excludeTypes}
                                    options={taskTypes}
                                    isDisabled={disabledType}
                                />
                                <FormError errors={errors} name="taskType" />
                            </FormGroup>
                        </Grid>
                    )}
                    <Grid item xs={6}>
                        <FormGroup>
                            <Name
                                value={data.name}
                                setValue={onChangeName}
                                template={data.taskTypeTemplate}
                                setTemplate={onChangeTaskTypeTemplate}
                                listTemplates={listTemplates}
                                readOnly={readOnlyName}
                            />
                            <FormError errors={errors} name="name"/>
                        </FormGroup>
                    </Grid>
                    {showDeadline && (
                        <Grid item xs={2}>
                            <FormGroup>
                                <Deadline
                                    value={data.deadline}
                                    setValue={onChangeDeadline}
                                />
                                <FormError errors={errors} name="deadline" />
                            </FormGroup>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {showClient && (
                <Grid item xs={12} md={6}>
                    <FormGroup>
                        <Contragent
                            value={data.client}
                            setValue={onChangeClient}
                            onClickClient={onClickClient}
                            // changeClient={changeClient || !data.client || !data.id}
                            changeClient={true} // временное решение, пока разбираемся почему слетает связь между задачей и заказом
                        />
                        <FormError errors={errors} name="client" />
                    </FormGroup>
                </Grid>
            )}
            <Grid item xs={6} md={3}>
                <FormGroup>
                    <Responsible
                        value={data.responsible}
                        setValue={onChangeResponsible}
                        userFirst={[currentUser.id]}
                        groupUsersFirst={[currentUser.id]}
                    />
                    <FormError errors={errors} name="responsible" />
                </FormGroup>
            </Grid>
            <Grid item xs={6} md={3}>
                <FormGroup>
                    <Executor
                        value={data.manager}
                        setValue={onChangeManager}
                        includeGroups={executorGroupsId}
                        userFirst={[currentUser.id]}
                        groupUsersFirst={[currentUser.id]}
                        style={Style6Right()}
                        isDisabled={isDisabledExecutor}
                    />
                    <FormError errors={errors} name="manager" />
                </FormGroup>
            </Grid>
            <Grid item xs={4} md={2}>
                <FormGroup>
                    <Priority
                        currentValue={data.priority}
                        onChange={onChangePriority}
                        disabled={disabledPriority}
                    />
                </FormGroup>
            </Grid>
            <Grid item xs={4} md={2}>
                <FormGroup>
                    <Status
                        value={data.taskStatus}
                        setValue={onChangeTaskStatus}
                    />
                    <FormError errors={errors} name="taskStatus" />
                </FormGroup>
            </Grid>
            {viewDoubleStatus && (
                <Grid item xs={4} md={2}>
                    <FormGroup>
                        <DoubleStatus value={data.taskDoubleStatus} setValue={onChangeTaskDoubleStatus} />
                        <FormError errors={errors} name="taskDoubleStatus" />
                    </FormGroup>
                </Grid>
            )}
            <Grid item xs={4} md={2}>
                <FormGroup>
                    <DateStart
                        value={data.dateStart}
                        setValue={onChangeDateStart}
                        isReadOnly={data.dateFinish}
                    />
                    <FormError errors={errors} name="dateStart" />
                </FormGroup>
            </Grid>
        </>
    );
};
export default Default;
