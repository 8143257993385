import React from 'react'
import {useDispatch, useSelector} from "react-redux";

export const ConfirmContext = React.createContext();

export const ConfirmContextProvider = ({ children }) => {
    const dispatch = useDispatch();
    const state = useSelector(redux => redux.modal.confirm);
    return (
        <ConfirmContext.Provider value={[state, dispatch]}>
            {children}
        </ConfirmContext.Provider>
    );
};