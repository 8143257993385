import { SendGet } from '../handler/sendGet';
import {sendPut} from "../handler/sendPut";
import {sendPost} from "../handler/sendPost";
import {sendDelete} from "../handler/sendDelete";

// eslint-disable-next-line import/prefer-default-export
export const getDeals = (params) => {
  return SendGet(`deals`, params);
};
export const getDeal = ({ id, params }) => {
  return SendGet(`deals/${id}`, params);
};
export const dealChange = (id, params) => {
  return sendPut(`deals/${id}`, params);
};
export const dealLogistics = (params) => {
  return SendGet(`deal-logistics`, params);
};
export const dealFiles = (params) => {
  return SendGet(`deal-files`, params);
};
export const dealLogisticsView = (id, params) => {
  return SendGet(`deal-logistics/${id}`, params);
};
export const dealLogisticsChange = (id, params) => {
  return sendPut(`deal-logistics/${id}`, params);
};
export const dealLogisticsDelete = (id) => {
  return sendDelete(`deal-logistics/${id}`);
};
export const dealLogisticsCreate = (params) => {
  return sendPost(`deal-logistics/create`, params);
};
export const dealPayments = (params) => {
  return SendGet(`deal-payments`, params);
};
export const dealPaymentsChange = (id, params) => {
  return sendPut(`deal-payments/${id}`, params);
};
export const dealCreate = (params) => {
  return sendPost('deals/create', params);
}
export const dealPaymentsCreate = (params) => {
  return sendPost(`deal-payments/create`, params);
};
export const dealPaymentDelete = (id) => {
  return sendDelete(`deal-payments/${id}`);
};
export const dealCountOfType = (params) => {
  return SendGet(`deals/count-of-type`, params);
};
export const dealLogisticsDelLinCreateLtl = (params) => {
  return sendPost(`deal-logistics/del-lin-create-ltl`, params);
};
export const fetchDealLogisticsListTk = (params) => {
  return SendGet(`deal-logistics/list-tk`, params);
};
export const fetchDealLogisticsDirectory = (params) => {
  return SendGet(`deal-logistics/directory`, params);
};
export const dealLogisticsUploadTk = (params) => {
  return sendPost(`deal-logistics/upload-tk`, params);
};
export const dealLogisticsImportFromTk = (params) => {
  return sendPost(`deal-logistics/import-from-tk`, params);
};
export const fetchDealsGeneral = (params) => {
  return SendGet(`/deals/deal-general`, params);
};
export const dealPaymentCreateTasks = (params) => {
  return sendPost(`deal-payments/create-tasks`, params);
};
export const dealSetShipperRating = (params) => {
  return sendPost(`deals/set-shipper-rating`, params);
};