// eslint-disable-next-line import/prefer-default-export
import {getCurrencyValueFromShipper, isAllowRc} from "./isRedPrice";
import {currencySandboxIsDefault, defaultCurrencyRU} from "../../constants/defaultCrm";

export const getFirstPrice = (sp, ruleRc, isAgent) => {
    const result = {price: 0, currency: 1};

    let currencyID = sp?.currency ?? 1;
    let price = Number.parseFloat(sp.price);
    const {count} = sp;
    let currencyData = sp?.currencyData ?? null;

    const shipper = sp?.shipper ?? null; // поставщик
    const shipperCurrency = shipper?.currency ?? null; // курс валюты поставщика

    const currencyValue = getCurrencyValueFromShipper(shipperCurrency, currencyData);
    if (currencyValue) {
        price /= currencyValue;
        currencyID = 1;
        currencyData = defaultCurrencyRU;
    }
    if (!currencySandboxIsDefault) {
        const currencyCourse = Number.parseFloat(currencyData?.course ?? 1);
        price /= currencyCourse;
        currencyID = 1;
    }
    result.price = price;
    result.currency = currencyID;

    if (sp.storage) {
        const allowRc = isAllowRc({spProduct: sp, ruleRC: ruleRc});
        const statusCrm = parseInt(sp.storage.status_crm, 10);
        const status = parseInt(sp.storage.status, 10);
        if (!allowRc){
            if (!status && !statusCrm) {
                result.price = 0;
                result.count = 0;
            } else if (!statusCrm && status) {
                if (isAgent) {
                    result.price = 0;
                    result.count = 0;
                }
            }
            if (!count) {
                if ((statusCrm && status) || (statusCrm && !status && isAgent)) {
                    result.price = 0;
                }
            }
        }
    }

    return result;
}

export const isShowRetailPrice = (sp, ruleRc, isAgent) => {
    const {count} = sp;
    if (sp.storage) {
        const allowRc = isAllowRc({spProduct: sp, ruleRC: ruleRc});
        const statusCrm = parseInt(sp.storage.status_crm, 10);
        if (!allowRc){
            if (!statusCrm && isAgent) {
                return false;
            }
            if (!count) {
                return false;
            }
        }
    }
    return true;
}

export const getPrices = (props) => {
    const result = {
        firstPrice: {
            price: 0,
            currency: 1,
        },
        isShowRetailPrice: false,
    }
    const {product, access} = props;
    if (!product || typeof product === 'undefined' || Object.keys(product).length < 5 || typeof product.storageProduct === 'undefined' || !product.storageProduct) {
        return result;
    }
    const {storageProduct, ruleRC} = product;

    if (product.status === 3) {
        const {currencyData, shipper} = storageProduct;
        const shipperCurrency = shipper?.currency ?? null;
        const getCurrencyShipper = getCurrencyValueFromShipper(shipperCurrency, currencyData);
        result.firstPrice = {
            price: storageProduct.price / getCurrencyShipper,
            currency: 1,
        }
        return result;
    }
    result.firstPrice = getFirstPrice(storageProduct, ruleRC, access.isAgent);
    // result.isShowRetailPrice = isShowRetailPrice(storageProduct, ruleRC, access.isAgent);

    return result;
}

export const getPriceWithDiscount = (price, discount = 0) => {
    const discountNumber = price * discount / 100;
    return price - discountNumber;
}