import { SendGet } from '../handler/sendGet';
import { sendPut } from '../handler/sendPut';
import { sendPost } from '../handler/sendPost';
import {sendDelete} from "../handler/sendDelete";

export const getTargets = (params) => {
  return SendGet(`targets`, params);
};
export const getTargetsMoreData = (params) => {
  return sendPost(`targets/index-more-data`, params);
};
export const getTarget = ({id, params}) => {
  return SendGet(`targets/${id}`, params);
};
export const createTarget = (params) => {
  return sendPost(`targets/create`, params);
};
export const updateTarget = (params) => {
  return sendPost(`targets/update`, params);
};
export const getTargetTasks = (params) => {
  return SendGet(`targets/tasks`, params);
};
export const getTargetFiles = (params) => {
  return SendGet(`targets/files`, params);
};
export const uploadTargetFile = (params) => {
  return sendPost(`targets/upload-file`, params);
};
export const removeTargetFile = (params) => {
  return SendGet(`targets/delete-file`, params);
};
export const deleteTask = (id: number) => {
  return sendDelete(`targets/delete-task/${id}`);
};
export const getTargetLogs = (params) => {
  return SendGet(`targets/logs`, params);
};
export const getTargetTableHandbook = (params) => {
  return sendPost(`targets/table-handbook`, params);
};