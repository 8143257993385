import React from "react";
import {Style6} from "../../../../constants/ReactSelect";
import DirectoryManager from "../../../../components/directory/DirectoryManager";

const Executor = (props) => {
    const {value, setValue, includeGroups = [], userFirst = [], groupUsersFirst = [], style=Style6(), isDisabled = false} = props;
    return (
        <>
            <div className="font-weight-bold">Исполнитель:</div>
            <div className="mt-2">
                <DirectoryManager
                    key={includeGroups}
                    initState={value}
                    onChange={setValue}
                    groups
                    includeGroups={includeGroups}
                    userFirst={userFirst}
                    groupUsersFirst={groupUsersFirst}
                    style={style}
                    isDisabled={isDisabled}
                />
            </div>
        </>
    )
}
export default Executor;