import React from 'react';
import AsyncSelect from 'react-select/async/dist/react-select.esm';
import {fetchTaskDoubleStatus} from '../../api/query/directory';
import {Style} from '../../constants/ReactSelect';
import {DisabledOptionSelect} from '../crm/order/DisabledOptionSelect';

const DirectoryTaskDoubleStatus = ({
    initState = null,
    onChange,
    placeholder = 'Выберите',
    style = Style(),
    disabledOptions = [],
    isClearable = false,
    groupId = 0,
}) => {
    const loadOptions = (inputValue, callback) => {
        fetchTaskDoubleStatus({group_id: groupId, s: inputValue}).then((response) => {
            callback(response.data);
        });
    };

    return (
        <AsyncSelect
            cacheOptions
            defaultOptions
            value={initState}
            onChange={onChange}
            loadOptions={loadOptions}
            noOptionsMessage={() => 'ничего не найдено'}
            loadingMessage={() => 'поиск...'}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => Number.parseInt(option.id, 10)}
            styles={style}
            placeholder={placeholder}
            isOptionDisabled={(option) => DisabledOptionSelect(disabledOptions, option)}
            isClearable={isClearable}
        />
    );
};

export default DirectoryTaskDoubleStatus;
