import React, {useEffect, useMemo, useState} from 'react';
import {Rating} from "@mui/material";
import css from "./ModalShipperScore.module.scss";

const Entity = (props) => {
    const {data, changeData, readOnly = false, actions = [], showAccordion = false} = props;
    const {id, name, rating} = data;
    const [showFields, setShowFields] = useState([]);
    const [statusAccordion, setStatusAccordion] = useState(true)

    const setData = (name, value) => {
        changeData(id, name, value);
    }

    const setDisplayFields = () => {
        if (rating > 0 && rating < 5) {
            setShowFields(actions);
        } else {
            setShowFields([])
            setData('detail', {});
        }
    }

    useEffect(() => {
        setDisplayFields();
    }, [rating]);

    return (
        <div className={css.shipperItem}>
            <div className="row align-items-center">
                {!!(showAccordion && showFields.length) && (
                    <div className="col-auto pr-0">
                        <button
                            type="button"
                            className={`btn-accordion btn btn-outline-primary ${!statusAccordion ? 'closed' : ''}`}
                            onClick={(e) => {
                                e.stopPropagation()
                                setStatusAccordion(!statusAccordion);
                            }}
                        >
                            <i className="simple-icon-arrow-down"/>
                        </button>
                    </div>
                )}
                <div className="col-6"><strong>{name}</strong></div>
                <div className="col">
                    <Rating
                        sx={{
                            '.MuiRating-iconHover, .MuiRating-iconFilled': {
                                color: '#ED7117'
                            }
                        }}
                        value={rating}
                        onChange={(event, newValue) => setData('rating', newValue)}
                        readOnly={readOnly}
                    />
                </div>
            </div>
            {
                statusAccordion && showFields.map((item) => {
                    const Component = item;
                    return <Component key={`${item}_${data.id}`} data={data} setData={setData} readOnly={readOnly}/>
                })
            }
        </div>
    )
};

export default Entity;
