import {
  NOTIFICATION_FETCH_LIST,
  NOTIFICATION_LOADING,
  NOTIFICATION_SET_LIST,
  NOTIFICATION_VIEWED,
} from '../actions';

const INIT_STATE = {
  loading: false,
  list: [],
  reload: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case NOTIFICATION_FETCH_LIST:
      return { ...state, reload: false };

    case NOTIFICATION_LOADING:
      return { ...state, loading: action.payload };

    case NOTIFICATION_SET_LIST:
      return {
        ...state,
        list: action.payload,
      };
    case NOTIFICATION_VIEWED:
      return {
        ...state,
        list: state.list.map(item => action.payload.ids.includes(Number.parseInt(item.id, 10)) ? {...item, status : 1} : item)
      };
    default:
      return { ...state };
  }
};
