// eslint-disable-next-line import/named
import {
  CRM_CLIENT_CARD_COUNT_ENTITY,
  CRM_CLIENT_CHANGE,
  CRM_CLIENT_CHANGE_LOADING,
  CRM_CLIENT_CHANGE_SUCCESS,
  CRM_CLIENT_FETCH_DATA,
  CRM_CLIENT_LOAD_LOADING,
  CRM_CLIENT_MODAL_CREATE_CLIENT_STATUS,
  CRM_CLIENT_MODAL_CREATE_DEALER_STATUS,
  CRM_CLIENT_ORDERS_FETCH,
  CRM_CLIENT_ORDERS_LOADING,
  CRM_CLIENT_ORDERS_SET,
  CRM_CLIENT_RELOAD,
  CRM_CLIENT_REMOVE_CONTACT,
  CRM_CLIENT_REMOVE_CONTACT_SUCCESS,
  CRM_CLIENT_SET_DATA,
  CRM_CLIENT_SET_DATA_FORM,
  CRM_CLIENT_SET_ERRORS, CRM_CLIENT_SET_FILTER_RANGE, CRM_CLIENT_SET_FILTER_VALUE,
  CRM_CLIENTS_CHANGE_LOADING,
  CRM_CLIENTS_FETCH_DATA,
  CRM_CLIENTS_SET_DATA,
  CRM_CONTACT_CHANGE_CLIENT,
  CRM_CONTACT_COPY_TO_CLIENT,
} from '../../actions';

export const fetchListData = (params) => {
  return {
    type: CRM_CLIENTS_FETCH_DATA,
    payload: params,
  };
};
export const setListData = (data) => {
  return {
    type: CRM_CLIENTS_SET_DATA,
    payload: data,
  };
};
export const setIndexChangeLoading = (data) => {
  return {
    type: CRM_CLIENTS_CHANGE_LOADING,
    payload: data,
  };
};
export const fetchOneData = (params) => {
  return {
    type: CRM_CLIENT_FETCH_DATA,
    payload: params,
  };
};
export const setOneData = (data) => {
  return {
    type: CRM_CLIENT_SET_DATA,
    payload: data,
  };
};
export const setOneDataForm = (data) => {
  return {
    type: CRM_CLIENT_SET_DATA_FORM,
    payload: data,
  };
};
export const setOneErrors = (errors) => {
  return {
    type: CRM_CLIENT_SET_ERRORS,
    payload: errors,
  };
};
export const setCardChangeLoading = (data) => {
  return {
    type: CRM_CLIENT_LOAD_LOADING,
    payload: data,
  };
};
export const setCardReload = (reload) => {
  return {
    type: CRM_CLIENT_RELOAD,
    payload: reload,
  };
};
export const clientChange = (params) => {
  return {
    type: CRM_CLIENT_CHANGE,
    payload: params,
  };
};
export const clientChangeSuccess = (params) => {
  return {
    type: CRM_CLIENT_CHANGE_SUCCESS,
    payload: params,
  };
};
export const clientChangeLoading = (loading) => {
  return {
    type: CRM_CLIENT_CHANGE_LOADING,
    payload: loading,
  };
};
export const contactCopyToClient = (data) => {
  return {
    type: CRM_CONTACT_COPY_TO_CLIENT,
    payload: data,
  };
};
export const contactChangeClient = (data) => {
  return {
    type: CRM_CONTACT_CHANGE_CLIENT,
    payload: data,
  };
};
export const clientCardOrdersSet = (data) => {
  return {
    type: CRM_CLIENT_ORDERS_SET,
    payload: data,
  };
};
export const clientCardOrdersFetch = (data) => {
  return {
    type: CRM_CLIENT_ORDERS_FETCH,
    payload: data,
  };
};
export const clientCardOrdersLoading = (data) => {
  return {
    type: CRM_CLIENT_ORDERS_LOADING,
    payload: data,
  };
};
export const removeClientContact = (params) => {
  return {
    type: CRM_CLIENT_REMOVE_CONTACT,
    payload: params,
  };
};
export const removeClientContactSuccess = (params) => {
  return {
    type: CRM_CLIENT_REMOVE_CONTACT_SUCCESS,
    payload: params,
  };
};
export const setClientCountEntity = (params) => {
  return {
    type: CRM_CLIENT_CARD_COUNT_ENTITY,
    payload: params,
  };
};
export const clientModalCreateClient = (data) => {
  return {
    type: CRM_CLIENT_MODAL_CREATE_CLIENT_STATUS,
    payload: data,
  };
};
export const clientModalCreateDealer = (data) => {
  return {
    type: CRM_CLIENT_MODAL_CREATE_DEALER_STATUS,
    payload: data,
  };
};
export const clientSetFilterValue = (data) => {
  return {
    type: CRM_CLIENT_SET_FILTER_VALUE,
    payload: data,
  };
};
export const clientSetFilterRange = (data) => {
  return {
    type: CRM_CLIENT_SET_FILTER_RANGE,
    payload: data,
  };
};

