import React, {useEffect, useState} from "react";
import CityCheckIcon from "../../assets/icons/city-check-icon";
import CityCrossIcon from "../../assets/icons/city-cross-icon";
import css from './CityAdvancedPopover.module.scss'
import {fetchStorage} from "../../api/query/storage";

const queryExpand = [
    'statusData',
    'logistics',
    'address',
    'address.cityData',
    'address.value',
]

const weekConst = {
    1: 'ПН',
    2: 'ВТ',
    3: 'СР',
    4: 'ЧТ',
    5: 'ПТ',
    6: 'СБ',
    7: 'ВС'
}

const CityAdvancedPopover = ({ storageId }) => {

    const [storage, setStorage] = useState(null)

    const dataStorage = {
        cityFull: storage?.address.value ?? 'Не назначен',
        tkCitys: storage?.logistics?.tk_list.map(el => el.name).join(', '),
        days: storage?.shipment_days ?? '',
        shipmentPickup: storage?.shipment_pickup ?? '', //Самовывоз
        thirdParty: storage?.logistics?.third_party_withdrawal ?? '', //Возможность вывода 3-им лицом
        deliveryYandex: storage?.logistics?.delivery_yandex_taxi ?? '', //Возможность яндекса
        shipmentStoking: storage?.logistics?.shipment_stoking ?? '', //Возможность отгружать от имени ООО "Стокинг"
        sameDayShipment: storage?.logistics?.same_day_shipment ?? '', //Возможность отгрузки день в день

        deliveryTerminalStatus: storage?.logistics?.delivery_to_terminal?.status ?? '', //Доставка до терминала в свсвоем городе
        deliveryTerminalTK: storage?.logistics?.delivery_to_terminal?.tk_list.map(el => el.name).join(', ') ?? '',
        deliveryTerminalMinPrice: storage?.logistics?.delivery_to_terminal?.min_price,
        deliveryTerminalWorkTime: storage?.logistics?.delivery_to_terminal?.work_time?.map(el => weekConst[el]).join(', '), // преобразовать и map

        deliveryClientStatus: storage?.logistics?.delivery_to_client?.status ?? '', //Доставка до терминала в свсвоем городе
        deliveryClientMinPrice: storage?.logistics?.delivery_to_client?.min_price,
        deliveryClientWorkTime: storage?.logistics?.delivery_to_client?.work_time?.map(el => weekConst[el]).join(', '),

        statusArchived: storage?.status_arh,
        statusName: storage?.statusData?.name,

        shipmentPaymentStatus: storage?.logistics?.shipment_without_payment.status, // Без оплаты статус
        shipmentPaymentDays: storage?.logistics?.shipment_without_payment.days, // Без оплаты дни
        shipmentPaymentCredit: storage?.logistics?.shipment_without_payment.credit_limit, // Без оплаты кредитный лимит


        reserveStatus: storage?.logistics?.reserve.status, // зависимая закуп цена
        reserveDays: storage?.logistics?.reserve.days, // зависимая закуп цена
        returnsStatus: storage?.logistics?.purchase_returns.status, // зависимая закуп цена
        returnsDays: storage?.logistics?.purchase_returns.count_days, // зависимая закуп цена

        edoStatus: storage?.logistics?.edo.status, // зависимая закуп цена
        edoList: storage?.logistics?.edo?.list?.map(el => el.name) ?? '', //
    }

    const getIcon = (value) => value ? <CityCheckIcon/> : <CityCrossIcon/>

    useEffect(() => {
        if (storageId) {
            const storageFetch = fetchStorage({
                id: storageId,
                params: {
                    expand: queryExpand.join(','),
                }
            })
            storageFetch.then(data => {
                setStorage(data.data)
            })
        }
    }, [])

    return (
        <div className={`d-flex flex-column p-1 ${css.cityPopover}`}>
            {dataStorage.cityFull ?
                <div>Адрес: {dataStorage.cityFull}</div> : ''
            }
            {dataStorage.tkCitys ?
                <div>Транспортные компании, с которыми работает склад: {dataStorage.tkCitys}</div> : ''
            }
            {dataStorage.days ?
                <div>Срок отгрузки (кол-во дней): {dataStorage.days}</div> : ''
            }

            {dataStorage.shipmentPickup !== null ?
                <div>{getIcon(dataStorage.shipmentPickup)} Самовывоз</div> : ''
            }
            {dataStorage.thirdParty ?
                <div>{getIcon(dataStorage.thirdParty)} Возможность вывода 3-им лицом</div> : ''
            }
            {dataStorage.deliveryYandex !== null ?
                <div>{getIcon(dataStorage.deliveryYandex)} Доставка товара через Яндекс такси</div> : ''
            }
            {dataStorage.shipmentStoking !== null ?
                <div>{getIcon(dataStorage.shipmentStoking)} Возможность отгружать от имени ООО “Стокинг” через генеральную доверенность</div> : ''
            }
            {dataStorage.sameDayShipment !== null ?
                <div>{getIcon(dataStorage.sameDayShipment)} Возможность отгрузки день в день</div> : ''
            }

            <hr className={css.cityPopoverLine}/>

            {dataStorage.deliveryTerminalStatus !== null ?
                <div>{getIcon(dataStorage.deliveryTerminalStatus)} Доставка до терминала траснпортной компании в своем городе</div> : ''
            }
            {dataStorage.deliveryTerminalTK ?
                <div>Транспортные компании: {dataStorage.deliveryTerminalTK}</div> : ''
            }
            {dataStorage.deliveryTerminalMinPrice ?
                <div>Сумма минимального заказа: {dataStorage.deliveryTerminalMinPrice}</div> : ''
            }
            {dataStorage.deliveryTerminalWorkTime ?
                <div>График доставки до терминала: {dataStorage.deliveryTerminalWorkTime}</div> : ''
            }

            {(dataStorage.deliveryTerminalStatus || dataStorage.deliveryTerminalTK || dataStorage.deliveryTerminalStatus || dataStorage.deliveryTerminalWorkTime) ? <hr className={css.cityPopoverLine}/> : <></>}

            {dataStorage.deliveryClientStatus !== null ?
                <div>{getIcon(dataStorage.deliveryClientStatus)} Доставка до клиента</div> : ''
            }
            {dataStorage.deliveryClientStatus !== null ? <hr className={css.cityPopoverLine}/> : <></>}

            {dataStorage.deliveryClientMinPrice ?
                <div>Сумма минимального заказа: {dataStorage.deliveryClientMinPrice}</div> : ''
            }
            {dataStorage.deliveryClientWorkTime ?
                <div>График доставки до клиента: {dataStorage.deliveryClientWorkTime}</div> : ''
            }

            {(dataStorage.deliveryClientMinPrice || dataStorage.deliveryClientWorkTime) ? <hr className={css.cityPopoverLine}/> : <></>}

            {dataStorage.statusArchived ?
                <div>Архивный</div> : ''
            }
            {dataStorage.statusName ?
                <div>Статус: {dataStorage.statusName}</div> : ''
            }

            {(dataStorage.statusArchived || dataStorage.statusName) ? <hr className={css.cityPopoverLine}/> : <></>}

            {dataStorage.shipmentPaymentStatus !== null ?
                <div>{getIcon(1)} { dataStorage.shipmentPaymentStatus ? 'Оплата с отсрочкой' : '100 % предоплата' }</div> : ''
            }
            {dataStorage.shipmentPaymentDays ?
                <div>Максимальное кол-во дней отсрочки: {dataStorage.shipmentPaymentDays}</div> : ''
            }
            {dataStorage.shipmentPaymentCredit ?
                <div>Кредитный лимит: {dataStorage.shipmentPaymentCredit}</div> : ''
            }

            {(dataStorage.shipmentPaymentStatus || dataStorage.shipmentPaymentDays || dataStorage.shipmentPaymentCredit) ? <hr className={css.cityPopoverLine}/> : <></>}

            {dataStorage.reserveStatus !== null ?
                <div>{getIcon(dataStorage.reserveStatus)} Возможность резерва</div> : ''
            }
            {dataStorage.reserveDays ?
                <div>Максимальное кол-во дней для резерва: {dataStorage.reserveDays}</div> : ''
            }
            {dataStorage.returnsStatus !== null ?
                <div>{getIcon(dataStorage.returnsStatus)} Возможность возврата товара</div> : ''
            }
            {dataStorage.returnsDays ?
                <div>Максимальное кол-во дней для возврата товара после оплаты: {dataStorage.returnsDays}</div> : ''
            }

            {(dataStorage.reserveStatus || dataStorage.reserveDays || dataStorage.returnsStatus || dataStorage.returnsDays) ? <hr className={css.cityPopoverLine}/> : <></>}

            {dataStorage.edoStatus !== null ?
                <div>{getIcon(dataStorage.edoStatus)} Работа по ЭДО</div> : ''
            }
            {dataStorage.returnsDays ?
                <div>Варианты: {dataStorage.edoList}</div> : ''
            }
        </div>
    )
}

export default CityAdvancedPopover