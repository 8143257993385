import { SendGet } from '../handler/sendGet';
import { sendPost } from '../handler/sendPost';

export const getNotifications = (params) => {
    return SendGet(`notifications`, params);
};
export const getNotificationById = ({ id, params }) => {
    return SendGet(`notifications/${id}`, params);
};
export const notificationViewed = (params) => {
    return sendPost(`notifications/viewed`, params);
};
export const getUserNotifications = (params) => {
    return SendGet(`user-notifications`, params);
};
export const updateUserNotification = (params) => {
    return sendPost(`user-notifications/update`, params);
};
