// eslint-disable-next-line import/no-cycle
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  GET_USER_INFO,
  GET_USER_INFO_LOADING,
  GET_USER_INFO_RELOAD,
  SET_USER_INFO,
  SET_TOKEN,
} from '../actions';

export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history },
});
export const loginUserSuccess = (data = {}) => ({
  type: LOGIN_USER_SUCCESS,
  payload: data,
});
export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message },
});

export const forgotPassword = (forgotUserMail, history) => ({
  type: FORGOT_PASSWORD,
  payload: { forgotUserMail, history },
});
export const forgotPasswordSuccess = (forgotUserMail) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: forgotUserMail,
});
export const forgotPasswordError = (message) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { message },
});

export const resetPassword = ({
  resetPasswordCode,
  password,
  confirmPassword,
  history,
}) => ({
  type: RESET_PASSWORD,
  payload: { resetPasswordCode, password, confirmPassword, history },
});
export const resetPasswordSuccess = (success) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: success,
});
export const resetPasswordError = (message) => ({
  type: RESET_PASSWORD_ERROR,
  payload: { message },
});

export const registerUser = (user, history) => ({
  type: REGISTER_USER,
  payload: { user, history },
});
export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user,
});
export const registerUserError = (message) => ({
  type: REGISTER_USER_ERROR,
  payload: { message },
});

export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history },
});
export const getUserInfo = (params) => ({
  type: GET_USER_INFO,
  payload: params,
});
export const setUserInfo = (data) => ({
  type: SET_USER_INFO,
  payload: data,
});
export const setToken = (data = '') => ({
  type: SET_TOKEN,
  payload: data,
});
export const getUserInfoLoading = (loading) => ({
  type: GET_USER_INFO_LOADING,
  payload: loading,
});
export const getUserInfoReload = (reload) => ({
  type: GET_USER_INFO_RELOAD,
  payload: reload,
});
