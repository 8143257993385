// eslint-disable-next-line no-unused-vars
/* eslint-disable no-use-before-define */
import {
    all,
    call,
    fork,
    put,
    takeEvery,
    takeLatest,
} from 'redux-saga/effects';

import {
    changeLoading,
    fetchProductsLoading,
    foundProducts,
    loadingDocumentFiles,
    loadingDocuments, orderCardCommentsLoading, orderCardCommentsSet, orderCardFeedbackLoading, orderCardFeedbackSet,
    orderClientLoading,
    orderClientSet,
    reloadDocumentFiles,
    reloadDocuments,
    searchingProduct,
    setDocumentFiles,
    setDocuments,
    setErrorFetch,
    setOrderData,
    setOrders,
    setProducts, setReload,
} from './actions';

import { SendGet } from '../../../api/handler/sendGet';
import {
    CRM_ORDER_FETCH_DATA,
    CRM_ORDERS_FETCH_DATA,
    CRM_ORDER_SEARCH_PRODUCT,
    CRM_ORDER_FETCH_PRODUCTS,
    CRM_ORDER_FETCH_DOCUMENTS,
    CRM_ORDER_FILE_REMOVE,
    CRM_ORDER_DOCUMENT_FILES_FETCH,
    CRM_ORDER_DOCUMENT_FILES_REMOVE,
    CRM_ORDER_CLIENT_FETCH, ORDER_CARD_COMMENTS_FETCH, ORDER_CARD_FEEDBACK_FETCH,
} from '../../actions';
import {getProductsV2} from '../../../api/query/product';
import { getDocuments } from '../../../api/query/document';
import { getOrderProducts } from '../../../api/query/orderProduct';
import {updateOrder} from "../../../api/query/order";
import {getDocumentFiles, removeDocumentFile} from "../../../api/query/documentFile";
import {fetchOrganizationOne} from "../../../api/query/organization";
import {getComments} from "../../../api/query/comment";
import ErrorResponse from "../../../components/common/ErrorResponse";
import {getOrderFeedback} from "../../../api/query/orderFeedback";
import {orderActions} from "../../toolkit/order/slice";
import {dealLogistics} from "../../../api/query/deal";
import {appActions} from "../../toolkit/app/slice";

// Запрос данных по карточке заказа
export function* watchOrderData() {
    // eslint-disable-next-line no-use-before-define
    yield takeLatest(CRM_ORDER_FETCH_DATA, loadOrderData);
}
const getOrderData = async ({ id, params = {} }) => {
    // eslint-disable-next-line no-return-await
    return await SendGet(`orders/${id}`, params);
};
function* loadOrderData({ payload }) {
    try {
        yield put(setOrderData({}));
        yield put(changeLoading(true));
        const getData = yield call(getOrderData, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(setOrderData(getData.data));
        }
        yield put(changeLoading(false));
    } catch (error) {
        yield put(changeLoading(false));
        yield put(setErrorFetch(error));
    }
}

// Запрос данных по карточкам
export function* watchOrders() {
    // eslint-disable-next-line no-use-before-define
    yield takeLatest(CRM_ORDERS_FETCH_DATA, loadOrders);
}
const getOrders = async (params) => {
    // eslint-disable-next-line no-return-await
    return await SendGet(`/orders`, params);
};
function* loadOrders({ payload }) {
    try {
        // const { crmOrder } = yield select();
        yield put(changeLoading(true));
        const getData = yield call(getOrders, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(setOrders(getData.data));
        }
        yield put(changeLoading(false));
    } catch (error) {
        yield put(changeLoading(false));
        yield put(setErrorFetch(error));
    }
}

// Поиск продуктов
export function* watchSearchProduct() {
    yield takeEvery(CRM_ORDER_SEARCH_PRODUCT, searchProduct);
}
function* searchProduct({ payload }) {
    try {
        yield put(searchingProduct(true));
        const getData = yield call(getProductsV2, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(foundProducts(getData.data.items));
        }
        yield put(searchingProduct(false));
    } catch (error) {
        yield put(searchingProduct(false));
        // yield put(setErrorFetch(error));
    }
}

// Запрос данных по продуктам карточки
export function* watchOrderProducts() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(CRM_ORDER_FETCH_PRODUCTS, loadOrderProducts);
}
function* loadOrderProducts({ payload }) {
    try {
        yield put(fetchProductsLoading(true));
        const getData = yield call(getOrderProducts, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(setProducts(getData.data.items));
        }
        yield put(fetchProductsLoading(false));
    } catch (error) {
        yield put(fetchProductsLoading(false));
    }
}

// Загрузка документов
export function* watchOrderDocuments() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(CRM_ORDER_FETCH_DOCUMENTS, loadOrderDocuments);
}
function* loadOrderDocuments({ payload }) {
    try {
        yield put(reloadDocuments(false));
        yield put(loadingDocuments(true));
        const getData = yield call(getDocuments, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(setDocuments(getData.data));
        }
        yield put(loadingDocuments(false));
    } catch (error) {
        yield put(loadingDocuments(false));
    }
}
export function* watchRemoveFile() {
    yield takeEvery(CRM_ORDER_FILE_REMOVE, handlerFileRemove);
}
function* handlerFileRemove({ payload }) {
    try {
        const prepareData = payload;
        prepareData.action = 'fileRemove';
        const getData =  yield call(updateOrder, prepareData);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(setReload(true));
        }
    } catch (error) {
        yield put(appActions.setNotification({type: 'error', message: ErrorResponse(error.response)}));
    }
}

// Загрузка файлов документов
export function* watchOrderDocumentFiles() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(CRM_ORDER_DOCUMENT_FILES_FETCH, loadOrderDocumentFiles);
}
function* loadOrderDocumentFiles({ payload }) {
    try {
        yield put(loadingDocumentFiles(true));
        const getData = yield call(getDocumentFiles, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(setDocumentFiles(getData.data));
        }
        yield put(loadingDocumentFiles(false));
    } catch (error) {
        yield put(loadingDocumentFiles(false));
    }
}

// Удаление файла документа
export function* watchRemoveDocumentFile() {
    yield takeEvery(CRM_ORDER_DOCUMENT_FILES_REMOVE, handlerRemoveDocumentFile);
}
function* handlerRemoveDocumentFile({ payload }) {
    try {
        const getData = yield call(removeDocumentFile, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(reloadDocumentFiles(Date.now()));
        }
    } catch (error) {
        yield put(appActions.setNotification({type: 'error', message: ErrorResponse(error.response)}))
    }
}
// Запрос данных по клиенту
export function* watchGetClient() {
    yield takeEvery(CRM_ORDER_CLIENT_FETCH, loadOneClient);
}
function* loadOneClient({ payload }) {
    try {
        const getData = yield call(fetchOrganizationOne, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(orderClientSet(getData.data));
        }
    } catch (error) {
        yield put(orderClientLoading(false));
    }
}

export function* watchOrderComments() {
    yield takeLatest(ORDER_CARD_COMMENTS_FETCH, loadOrderComments);
}
function* loadOrderComments({ payload }) {
    try {
        const getData = yield call(getComments, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(orderCardCommentsSet(getData.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(orderCardCommentsLoading(false));
    }
}

export function* watchOrderFeedback() {
    yield takeLatest(ORDER_CARD_FEEDBACK_FETCH, loadOrderFeedback);
}
function* loadOrderFeedback({ payload }) {
    try {
        const getData = yield call(getOrderFeedback, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(orderCardFeedbackSet(getData.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(orderCardFeedbackLoading(false));
    }
}

function* fetchLogisticsData({payload}) {
    try {
        const getData = yield call(dealLogistics, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(orderActions.setLogisticsData(getData.data.items));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(orderActions.setLogisticsData([]));
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchOrderData),
        fork(watchOrders),
        fork(watchSearchProduct),
        fork(watchOrderProducts),
        fork(watchOrderDocuments),
        fork(watchRemoveFile),
        fork(watchOrderDocumentFiles),
        fork(watchRemoveDocumentFile),
        fork(watchGetClient),
        fork(watchOrderComments),
        fork(watchOrderFeedback),
    ]);
    yield takeEvery(orderActions.fetchLogisticsData.type, fetchLogisticsData);
}
