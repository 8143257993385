import { GetData } from '../index';
import {ResponseException} from '../../constants/defaultValues';
import axiosResponseError from "../../components/applications/AxiosResponseError";

// eslint-disable-next-line import/prefer-default-export
export const SendGet = async (url, params, version = 1) => {
    try {
        return await GetData(url, params, version);
    } catch (exception) {
        axiosResponseError(exception);
        throw new ResponseException(exception);
    }
};
