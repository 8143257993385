import React from 'react';
import Select from "react-select";
import {Style} from '../../constants/ReactSelect';

const DirectoryTaskTypeSync = ({
    initState = null,
    onChange,
    placeholder = 'Выберите тип',
    style = Style(),
    isClearable = true,
    excludeId = [],
    options = [],
    menuPortalTarget = false,
    isDisabled = false,
  }) => {

    const filterOptions = options.filter(x => !excludeId.includes(x.id));

    return (
        <Select
            defaultOptions
            value={initState}
            onChange={onChange}
            options={filterOptions}
            noOptionsMessage={() => 'ничего не найдено'}
            loadingMessage={() => 'поиск...'}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => Number(option.id)}
            styles={style}
            placeholder={placeholder}
            isClearable={isClearable}
            menuPortalTarget={menuPortalTarget}
            isDisabled={isDisabled}
        />
    );
};

export default DirectoryTaskTypeSync;
