import React from 'react';
import css from "../ModalShipperScore.module.scss";
import TextareaAutosize from "react-textarea-autosize";
import {Typography} from "@mui/material";

const Comment = (props) => {
    const {data, setData, readOnly} = props;

    const setValue = (value) => {
        setData('comment', value);
    }

    if (readOnly) {
        return <Typography fontSize={12} mt={1} variant="body2">{data.comment}</Typography>
    }
    return (
        <div className={css.actionRow}>
            <TextareaAutosize
                defaultValue={data.comment}
                onBlur={(event => setValue(event.target.value.trim()))}
                minRows={5}
                maxRows={16}
                className="form-control style-2"
                style={{resize: 'none'}}
                placeholder="Комментарий"
            />
        </div>
    )
};

export default Comment;
