import React from 'react'

const SettingsIcon = ({width = '26', height = '26', color = '#3A3A3A'}) => (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            className="hoverStroke"
            d="M20 25C22.7614 25 25 22.7614 25 20C25 17.2386 22.7614 15 20 15C17.2386 15 15 17.2386 15 20C15 22.7614 17.2386 25 20 25Z"
            stroke={color}
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            className="hoverStroke"
            d="M3.33594 21.4657V18.5324C3.33594 16.7991 4.7526 15.3657 6.5026 15.3657C9.51927 15.3657 10.7526 13.2324 9.23594 10.6157C8.36927 9.11575 8.88594 7.16575 10.4026 6.29908L13.2859 4.64908C14.6026 3.86575 16.3026 4.33241 17.0859 5.64908L17.2693 5.96575C18.7693 8.58241 21.2359 8.58241 22.7526 5.96575L22.9359 5.64908C23.7193 4.33241 25.4193 3.86575 26.7359 4.64908L29.6193 6.29908C31.1359 7.16575 31.6526 9.11575 30.7859 10.6157C29.2693 13.2324 30.5026 15.3657 33.5193 15.3657C35.2526 15.3657 36.6859 16.7824 36.6859 18.5324V21.4657C36.6859 23.1991 35.2693 24.6324 33.5193 24.6324C30.5026 24.6324 29.2693 26.7657 30.7859 29.3824C31.6526 30.8991 31.1359 32.8324 29.6193 33.6991L26.7359 35.3491C25.4193 36.1324 23.7193 35.6658 22.9359 34.3491L22.7526 34.0324C21.2526 31.4157 18.7859 31.4157 17.2693 34.0324L17.0859 34.3491C16.3026 35.6658 14.6026 36.1324 13.2859 35.3491L10.4026 33.6991C8.88594 32.8324 8.36927 30.8824 9.23594 29.3824C10.7526 26.7657 9.51927 24.6324 6.5026 24.6324C4.7526 24.6324 3.33594 23.1991 3.33594 21.4657Z"
            stroke={color}
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default SettingsIcon
