import React from 'react';
import AsyncSelect from 'react-select/async/dist/react-select.esm';
import {fetchTargetStatus, fetchTaskPriority, fetchTaskStatus} from '../../api/query/directory';
import {Style} from '../../constants/ReactSelect';
import {DisabledOptionSelect} from '../crm/order/DisabledOptionSelect';
import {components} from "react-select";
import PhotoManagerNew from "../profile/photoManagerNew";
import Priority from "../common/Priority";

export const valueOption = (props) => {
    const {data} = props;
    return (
        <components.SingleValue {...props}>
            <Priority data={data} size="xs" withoutBorder={true}/>
        </components.SingleValue>
    );
};

const DirectoryTaskPriority = ({
       initState = null,
       onChange,
       placeholder = 'Выберите приоритет',
       style = Style(),
       disabledOptions = [],
       isClearable = false,
       isDisabled = false,
    }) => {
    const loadOptions = (inputValue, callback) => {
        fetchTaskPriority(inputValue).then((response) => {
            callback(response.data);
        });
    };

    return (
        <AsyncSelect
            cacheOptions
            defaultOptions
            value={initState}
            onChange={onChange}
            loadOptions={loadOptions}
            noOptionsMessage={() => 'ничего не найдено'}
            loadingMessage={() => 'поиск...'}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => Number(option.id)}
            styles={style}
            placeholder={placeholder}
            isOptionDisabled={(option) => DisabledOptionSelect(disabledOptions, option)}
            isClearable={isClearable}
            isDisabled={isDisabled}
            components={{
                SingleValue: valueOption,
            }}
        />
    );
};

export default DirectoryTaskPriority;
