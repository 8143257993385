// eslint-disable-next-line import/prefer-default-export
export const extractDataFromDDByFrom = (data) => {
    if (!data) return null;
    switch (data.type) {
        case 'LEGAL':
            return {
                id: 1,
                name: 'Юридическое лицо'
            };
        case 'INDIVIDUAL':
            return {
                id: 4,
                name: 'Индивидуальный предприниматель'
            }
        default:
            return {
                id: 5,
                name: 'Физическое лицо'
            }
    }
}