import React, {useMemo} from "react";
import DirectoryTaskStatus from "../../../../components/directory/DirectoryTaskStatus";
import {Style6} from "../../../../constants/ReactSelect";
import {defaultDisabledStatus} from "../params";
import {useContext} from "react";
import {ModalCreateTaskContext} from "../index";

const Status = ({value, setValue}) => {
    const {parentEntity} = useContext(ModalCreateTaskContext);
    const disabledStatuses = useMemo(() => {
        let values = [...defaultDisabledStatus];
        if (parentEntity?.type_id === 12) {
            values = values.filter(x => x !== 5);
        }
        const currentStatus = value?.id ?? null;
        if (currentStatus){
            if (currentStatus === 6) {
                values = [...values, ...[1]];
            }
        }
        if (![4,5].includes(currentStatus)) {
            values = [...values, ...[6]];
        }


        return values;
    }, [value, parentEntity]);

    return (
        <>
            <div className="font-weight-bold">Статус:</div>
            <div className="mt-2">
                <DirectoryTaskStatus
                    initState={value}
                    onChange={setValue}
                    disabledOptions={disabledStatuses}
                    style={Style6()}
                />
            </div>
        </>
    )
}
export default Status;