// eslint-disable-next-line import/prefer-default-export
export const getHeadText = (message) => {
    const {data} = message;
    const {type_id: typeId} = data;
    let result;
    switch (typeId) {
        case 8:
            result = `Сделка №${data.deal_id}`
            break;
        case 14:
        case 15:
        case 16:
        case 18:
            result = `Склад №${data.storage_id}`
            break;
        case 17:
            result = `Сделка №${data.id}`
            break;
        case 9:
            result = `Заказ №${data.order_id}`
            break;
        case 27:
            result = `Цель №${data.target_id}`
            break;
        case 28:
            result = `Задача цели №${data.target_id}`
            break;
        default:
            result = '';
    }
    return result;
}
