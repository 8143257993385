import React from "react";
import {Style6} from "../../../../constants/ReactSelect";
import DirectoryTaskDoubleStatus from "../../../../components/directory/DirectoryTaskDoubleStatus";

const DoubleStatus = ({value, setValue}) => {
    return (
        <>
            <div className="font-weight-bold">Результат:</div>
            <div className="mt-2">
                <DirectoryTaskDoubleStatus
                    initState={value}
                    onChange={setValue}
                    disabledOptions={[]}
                    style={Style6()}
                    groupId={1}
                />
            </div>
        </>
    )
}
export default DoubleStatus;