import React from 'react'

const UserIcon = ({width = '14', height = '14'}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ opacity: 0.5 }}>
            <path
                d="M1 17C1 14 5 14 7 12C8 11 5 11 5 6C5 2.667 6.333 1 9 1C11.667 1 13 2.667 13 6C13 11 10 11 11 12C13 14 17 14 17 17"
                stroke="#3A3A3A"
                strokeLinecap="round"
            />
        </svg>
    )
}

export default UserIcon