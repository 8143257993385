import {adminRoot} from "../constants/defaultValues";

const GetOrganisationUrl = (id, type) => {
    if(!id || !type) return '#';

    if(type === 2)
        return `${adminRoot}/partners/buyers/${id}`;

    return `${adminRoot}/partners/dealers/${id}`;
};

export default GetOrganisationUrl;