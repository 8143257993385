// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Manager_wrapImage__zvgeh .Manager_wrapImageText__LTPmo {
  margin: 0 0 0 5px; }

.Manager_textElem__rHRan {
  display: flex;
  margin: 0; }
  .Manager_textElem__rHRan.Manager_textElemRank__jyw2v {
    display: flex;
    align-items: center; }
    .Manager_textElem__rHRan.Manager_textElemRank__jyw2v p {
      font-size: 12px !important; }
    .Manager_textElem__rHRan.Manager_textElemRank__jyw2v svg {
      width: 18px !important;
      height: 18px !important; }
  .Manager_textElem__rHRan .Manager_textElemHeadline__Iw3fZ {
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    white-space: nowrap; }
  .Manager_textElem__rHRan .Manager_textElemText__zOc7n {
    margin: 0 0 0 5px;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
`, "",{"version":3,"sources":["webpack://./src/components/crm/task/Manager.module.scss"],"names":[],"mappings":"AAEA;EAEI,iBAAiB,EAAA;;AAIrB;EACE,aAAa;EACb,SAAS,EAAA;EAFX;IAKI,aAAa;IACb,mBAAmB,EAAA;IANvB;MASM,0BAA0B,EAAA;IAThC;MAaM,sBAAsB;MACtB,uBAAuB,EAAA;EAd7B;IAmBI,SAAS;IACT,eAAe;IACf,gBAAgB;IAChB,mBAAmB,EAAA;EAtBvB;IA0BI,iBAAiB;IACjB,eAAe;IACf,uBAAuB;IACvB,gBAAgB;IAChB,mBAAmB,EAAA","sourcesContent":["@import '../../../assets/css/sass/02_variables';\n\n.wrapImage {\n  .wrapImageText {\n    margin: 0 0 0 5px;\n  }\n}\n\n.textElem {\n  display: flex;\n  margin: 0;\n\n  &.textElemRank {\n    display: flex;\n    align-items: center;\n\n    p {\n      font-size: 12px !important;\n    }\n\n    svg {\n      width: 18px !important;\n      height: 18px !important;\n    }\n  }\n\n  .textElemHeadline {\n    margin: 0;\n    font-size: 12px;\n    font-weight: 600;\n    white-space: nowrap;\n  }\n\n  .textElemText {\n    margin: 0 0 0 5px;\n    font-size: 12px;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    white-space: nowrap;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapImage": `Manager_wrapImage__zvgeh`,
	"wrapImageText": `Manager_wrapImageText__LTPmo`,
	"textElem": `Manager_textElem__rHRan`,
	"textElemRank": `Manager_textElemRank__jyw2v`,
	"textElemHeadline": `Manager_textElemHeadline__Iw3fZ`,
	"textElemText": `Manager_textElemText__zOc7n`
};
export default ___CSS_LOADER_EXPORT___;
