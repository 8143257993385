// eslint-disable-next-line import/no-cycle
import {
    COMMENT_CHANGE,
    COMMENT_CREATE,
    COMMENT_CHANGE_SUCCESS,
    COMMENT_CHANGE_LOADING,
    COMMENTS_FETCH, COMMENT_VIEWING, COMMENTS_SET,
} from '../actions';

export const commentChange = (params) => {
    return {
        type: COMMENT_CHANGE,
        payload: params,
    };
};
export const commentCreate = (params) => {
    return {
        type: COMMENT_CREATE,
        payload: params,
    };
};
export const commentChangeSuccess = (params) => {
    return {
        type: COMMENT_CHANGE_SUCCESS,
        payload: params,
    };
};
export const commentChangeLoading = (loading) => {
    return {
        type: COMMENT_CHANGE_LOADING,
        payload: loading,
    };
};
export const commentsFetch = (params) => {
    return {
        type: COMMENTS_FETCH,
        payload: params,
    };
};
export const commentsSet = (params) => {
    return {
        type: COMMENTS_SET,
        payload: params,
    };
};
export const commentsViewing = (params) => {
    return {
        type: COMMENT_VIEWING,
        payload: params,
    };
};

