import { createSlice } from "@reduxjs/toolkit";
import React from "react";
import {fetchMetaDefault} from "../../../../constants/defaultCrm";
import {advancedInfoDefault} from "../../../../views/app/Settings/scenarios/View/params";

const initialState = {
    list: {
        loading: false,
        data: {
            items: [],
            meta: fetchMetaDefault
        },
        reload: false,
    },
    view: {
        data: undefined,
        loading: false,
        tasks: {
            reload: false,
            loading: false,
            data: {
                items: [],
                meta: fetchMetaDefault
            },
        },
        advancedInfo: {
            data: advancedInfoDefault,
            reload: false,
            loading: false,
        }
    },
    modalCreate: {
        status: false,
    }
}

const slice = createSlice({
    name: "scenario",
    initialState,
    reducers: {
        getList: (state, { payload }) => {
            state.list = {
                ...state.list,
                loading: true,
                reload: false,
            };
        },
        setList: (state, { payload }) => {
            state.list = {
                ...state.list,
                loading: false,
                data: {
                    ...state.list.data,
                    items: !payload ? [] : payload.items,
                    // eslint-disable-next-line no-underscore-dangle
                    meta: !payload ? fetchMetaDefault : payload._meta
                },
            };
        },
        setView: (state, { payload }) => {
            state.view = {
                ...state.view,
                loading: false,
                data: payload,
            };
        },
        setViewAdvancedInfo: (state, { payload }) => {
            state.view.advancedInfo = {
                ...state.view.advancedInfo,
                data: payload || advancedInfoDefault,
                loading: false,
            };
        },
        getView: (state, { payload }) => {
            state.view.loading = true;
        },
        getViewAdvancedInfo: (state, { payload }) => {
            state.view.advancedInfo = {
                ...state.view.advancedInfo,
                reload: false,
                loading: true,
            };
        },
        reloadTasks: (state, { payload }) => {
            state.view.tasks.reload = true;
        },
        setTasks: (state, { payload }) => {
            state.view.tasks = {
                ...state.view.tasks,
                loading: false,
                data: payload || {
                    items: [],
                    meta: fetchMetaDefault,
                },
            };
        },
        getTasks: (state, { payload }) => {
            state.view.tasks = {
                ...state.view.tasks,
                loading: true,
                reload: false,
            };
        },
        modalCreateStatus: (state, { payload }) => {
            state.modalCreate.status = payload;
        },
    }
})

export const scenarioReducer = slice.reducer;
export const scenarioActions = slice.actions;