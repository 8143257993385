import {adminRoot} from "../../constants/defaultValues";

// eslint-disable-next-line import/prefer-default-export
export const getOrgLink = (organization, typeId = null) => {
    if (!organization) return '#';

    const types = organization.types ?? [];
    let typesId = types.map(x => x.id);
    if (typeId) {
        typesId = typesId.filter(x => x === typeId);
    }
    if (typesId.includes(2)) {
        return `${adminRoot}/partners/buyers/${organization.id}`;
    }
    if (typesId.includes(1)) {
        return `${adminRoot}/partners/dealers/${organization.id}`;
    }
    return null;
}