/* eslint-disable react/no-array-index-key,no-unused-vars */
/* eslint-disable react/no-danger */
import React, {useEffect, useMemo, useState} from 'react';
import {Badge, Nav, NavItem} from 'reactstrap';
import {NavLink, useLocation} from 'react-router-dom';
import classnames from 'classnames';
import {useDispatch, useSelector} from "react-redux";
// eslint-disable-next-line import/no-extraneous-dependencies
import {Drawer, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import IntlMessages from '../../../helpers/IntlMessages';
import menuItems from '../../../constants/menu';
import {addContainerClassname, changeSelectedMenuHasSubItems, setContainerClassnames} from "../../../redux/menu/actions";
import {usePrevious} from "../../../hooks/usePrevious";
import css from './Sidebar.module.scss'

const Sidebar = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [selectedParentMenu, setSelectedParentMenu] = useState('');
    const [viewingParentMenu, setViewingParentMenu] = useState('');
    const [collapsedMenus, setCollapsedMenus] = useState('');
    const {currentUser} = useSelector((state) => state.authUser);
    const {containerClassnames, menuClickCount} = useSelector((state) => state.menu);
    const prevPathname = usePrevious(location.pathname);
    const { openLevel } = useSelector((state) => state.menu);

    const filteredList = (menuItemsTmp) => {
        if (!currentUser) return [];
        return menuItemsTmp.filter((x) => {
                let access = true;
                const permissions = typeof x.permissions !== 'undefined' ? x.permissions : null;
                if (permissions && !currentUser.permissions.filter(x2 => permissions.includes(x2)).length) {
                    access = false;
                }
                return access;
            }
        );
    };

    const eachMenuElement = (menuList) => {
        menuList.forEach((x) => {
            const {id, to} = x;
            if (location.pathname.search(to) !== -1) {
                setSelectedParentMenu(id)
            }
            const children = typeof x.children !== 'undefined' ? x.children : [];
            if (children.length) {
                eachMenuElement(children);
            }
        });
    };

    const getIsHasSubItem = () => {
        eachMenuElement(menuItems);
        const menuItem = menuItems.find((x) => x.id === selectedParentMenu);
        if (menuItem)
            return !!(menuItem && menuItem.subs && menuItem.subs.length > 0);
        return false;
    };
    const toggle = () => {
        const hasSubItems = getIsHasSubItem();
        dispatch(changeSelectedMenuHasSubItems(hasSubItems));
        const currentClasses = containerClassnames
            ? containerClassnames.split(' ').filter((x) => x !== '')
            : '';
        let clickIndex = -1;

        if (!hasSubItems) {
            if (
                currentClasses.includes('menu-default') &&
                (menuClickCount % 4 === 0 || menuClickCount % 4 === 3)
            ) {
                clickIndex = 1;
            } else if (
                currentClasses.includes('menu-sub-hidden') &&
                (menuClickCount === 2 || menuClickCount === 3)
            ) {
                clickIndex = 0;
            } else if (
                currentClasses.includes('menu-hidden') ||
                currentClasses.includes('menu-mobile')
            ) {
                clickIndex = 0;
            }
        } else if (
            currentClasses.includes('menu-sub-hidden') &&
            menuClickCount === 3
        ) {
            clickIndex = 2;
        } else if (
            currentClasses.includes('menu-hidden') ||
            currentClasses.includes('menu-mobile')
        ) {
            clickIndex = 0;
        }
        if (clickIndex >= 0) {
            dispatch(setContainerClassnames(clickIndex, containerClassnames, hasSubItems));
        }
    };

    const openSubMenu = (e, menuItem) => {
        const selectedParent = menuItem.id;
        const hasSubMenu = menuItem.subs && menuItem.subs.length > 0;
        dispatch(changeSelectedMenuHasSubItems(hasSubMenu));
        if (!hasSubMenu) {
            setViewingParentMenu(selectedParent);
            setSelectedParentMenu(selectedParent);
            toggle();
        } else {
            e.preventDefault();
            const currentClasses = containerClassnames
                ? containerClassnames.split(' ').filter((x) => x !== '')
                : '';

            if (!currentClasses.includes('menu-mobile')) {
                if (
                    currentClasses.includes('menu-sub-hidden') &&
                    (menuClickCount === 2 || menuClickCount === 0)
                ) {
                    dispatch(setContainerClassnames(3, containerClassnames, hasSubMenu));
                } else if (
                    currentClasses.includes('menu-hidden') &&
                    (menuClickCount === 1 || menuClickCount === 3)
                ) {
                    dispatch(setContainerClassnames(2, containerClassnames, hasSubMenu));
                } else if (
                    currentClasses.includes('menu-default') &&
                    !currentClasses.includes('menu-sub-hidden') &&
                    (menuClickCount === 1 || menuClickCount === 3)
                ) {
                    dispatch(setContainerClassnames(0, containerClassnames, hasSubMenu));
                }
            } else {
                dispatch(addContainerClassname('sub-show-temporary', containerClassnames));
            }
            setViewingParentMenu(selectedParent);
        }
    };

    const toggleMenuCollapse = (e, menuKey) => {
        e.preventDefault();

        const currentCM = collapsedMenus;
        if (currentCM.indexOf(menuKey) > -1) {
            setCollapsedMenus(currentCM.filter((x) => x !== menuKey));
        } else {
            currentCM.push(menuKey);
            setCollapsedMenus(currentCM);
        }
        return false;
    };
    const renderMenu = useMemo(() => {
        const filteredMenu = filteredList(menuItems);

        const MenuItem = ({item}) => {
            const itemId = item.id;
            const isActive = selectedParentMenu === itemId || selectedParentMenu.indexOf(`${itemId}/`) > -1;
            return (
                <NavItem
                    key={item.id}
                    className={classnames({
                        active: isActive,
                    })}
                >
                    <NavLink
                        to={item.to}
                        data-flag={item.id}
                    >
                        <i className={item.icon}/>
                        <IntlMessages id={item.label}/>
                    </NavLink>
                </NavItem>
            )
        };

        return (
            <Nav vertical className="list-unstyled">
                {filteredMenu.map((item) => {
                    return <MenuItem key={item.id} item={item} />
                })}
            </Nav>
        )
    }, [currentUser, selectedParentMenu]);

    useEffect(() => {
        if (prevPathname !== location.pathname) {
            eachMenuElement(menuItems);
        }
    }, [location.pathname]);

    const filteredMenu = filteredList(menuItems);
    return (
        <div className={`${css.sidebar  } ${  !openLevel && css.sidebarHide}` }>
            <Drawer
                variant="permanent"
            >
                {filteredMenu.map(el => {
                    const isActive = selectedParentMenu === el.id || selectedParentMenu.indexOf(`${el.id}/`) > -1;
                    const activeClass = isActive ? css.active : ''
                    return <NavLink key={el.to} to={el.to} activeClassName={activeClass} className={css.elemMenu}>
                        <ListItem className={css.elem}>
                            <ListItemButton className={css.elemContainer}>
                                <ListItemIcon className={css.elemSvg}>
                                    {el.icon({width: 32, height: 32})}
                                </ListItemIcon>
                                <ListItemText className={css.elemHeadline} primary={<IntlMessages id={el.label}/>} />
                            </ListItemButton>
                        </ListItem>
                    </NavLink>
                })}
            </Drawer>
        </div>
    );
}
export default Sidebar;
