// eslint-disable-next-line import/prefer-default-export
export const defaultChats = [
    {
        id: 1,
        name: 'Чат сделки',
        class: ''
    },
    {
        id: 2,
        name: 'Поставщик Stoking',
        class: 'provider'
    }
]