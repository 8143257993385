import {
  CRM_TASK_CHANGE,
  CRM_TASK_CHANGE_LOADING,
  CRM_TASK_CHANGE_SUCCESS,
  CRM_TASK_COMMENTS_LOADING,
  CRM_TASK_COMMENTS_RELOAD,
  CRM_TASK_COMMENTS_SET,
  CRM_TASK_CREATE,
  CRM_TASK_FETCH_DATA,
  CRM_TASK_LOADING,
  CRM_TASK_SET_DATA,
  CRM_TASK_SET_ERRORS,
  CRM_TASKS_COUNT_NEW, CRM_TASKS_COUNT_NEW_COMMENT, CRM_TASKS_COUNT_OPENED, CRM_TASKS_COUNT_TO_ENGINEER,
  CRM_TASKS_FETCH_DATA,
  CRM_TASKS_LOADING,
  CRM_TASKS_RELOAD,
  CRM_TASKS_SET_DATA,
} from '../../actions';

export const taskListFetchData = (params) => {
  return {
    type: CRM_TASKS_FETCH_DATA,
    payload: params,
  };
};
export const taskListSetData = (data) => {
  return {
    type: CRM_TASKS_SET_DATA,
    payload: data,
  };
};
export const taskListLoading = (loading) => {
  return {
    type: CRM_TASKS_LOADING,
    payload: loading,
  };
};
export const taskListReload = (reload) => {
  return {
    type: CRM_TASKS_RELOAD,
    payload: reload,
  };
};
export const taskOneFetchData = (params) => {
  return {
    type: CRM_TASK_FETCH_DATA,
    payload: params,
  };
};
export const taskOneSetData = (data) => {
  return {
    type: CRM_TASK_SET_DATA,
    payload: data,
  };
};
export const taskOneSetErrors = (errors) => {
  return {
    type: CRM_TASK_SET_ERRORS,
    payload: errors,
  };
};
export const taskOneLoading = (loading) => {
  return {
    type: CRM_TASK_LOADING,
    payload: loading,
  };
};
export const taskOneChange = (params) => {
  return {
    type: CRM_TASK_CHANGE,
    payload: params,
  };
};
export const taskOneCreate = (params) => {
  return {
    type: CRM_TASK_CREATE,
    payload: params,
  };
};
export const taskOneChangeSuccess = (params) => {
  return {
    type: CRM_TASK_CHANGE_SUCCESS,
    payload: params,
  };
};
export const taskOneChangeLoading = (loading) => {
  return {
    type: CRM_TASK_CHANGE_LOADING,
    payload: loading,
  };
};
export const taskCommentsLoading = (loading) => {
  return {
    type: CRM_TASK_COMMENTS_LOADING,
    payload: loading,
  };
};
export const taskCommentsSet = (data) => {
  return {
    type: CRM_TASK_COMMENTS_SET,
    payload: data,
  };
};
export const taskCommentsReload = (data) => {
  return {
    type: CRM_TASK_COMMENTS_RELOAD,
    payload: data,
  };
};
export const setCountNewTasks = (params) => {
  return {
    type: CRM_TASKS_COUNT_NEW,
    payload: params,
  };
};
export const setCountOpenedTasks = (params) => {
  return {
    type: CRM_TASKS_COUNT_OPENED,
    payload: params,
  };
};
export const setCountTasksToEngineer = (params) => {
  return {
    type: CRM_TASKS_COUNT_TO_ENGINEER,
    payload: params,
  };
};
export const setCountNewComments = (params) => {
  return {
    type: CRM_TASKS_COUNT_NEW_COMMENT,
    payload: params,
  };
};