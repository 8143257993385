/* eslint-disable no-unused-vars,no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import React from "react";
import {defaultQueryList, fetchMetaDefault} from "../../../constants/defaultCrm";

const initialState = {
    list: {
        loading: false,
        data: {
            items: [],
            meta: fetchMetaDefault
        },
        reload: false,
        countItems: {
            data: [],
            loading: false
        }
    },
    card: {
        products: {
            list: {
                isFetching: false,
                data: defaultQueryList,
                reload: false,
            },
            selected: [],
            modalUpdate: {
                id: null,
                isFetching: false,
                data: null,
            },
            modalAddFromFiles: {
                storageId: null,
            },
            modalMassEdit: {
                status: false
            },
            countProducts: {
                reload: false,
            },
            modalAddFromCode: {
                status: false,
                foundProducts: null,
                selectedProducts: [],
            },
        }
    },
    modalUpdate: {
        status: false,
        id: null,
        data: null,
        loading: false,
        errors: []
    }
}

const slice = createSlice({
    name: "storage",
    initialState,
    reducers: {
        setCountItemsData: (state, { payload }) => {
            state.list.countItems = {
                loading: false,
                data: !payload ? [] : payload,
            };
        },
        fetchCountItemsData: (state, { payload }) => {
            state.list.countItems.loading = true;
        },
        setData: (state, { payload }) => {
            state.list = {
                ...state.list,
                data: {
                    ...state.list.data,
                    items: !payload ? [] : payload.items,
                    // eslint-disable-next-line no-underscore-dangle
                    meta: !payload ? fetchMetaDefault : payload._meta
                },
                loading: false,
                reload: false,
            };
        },
        fetchData: (state, { payload }) => {
            state.list = {
                ...state.list,
                loading: true,
                reload: false,
            };
        },
        reloadData: (state, { payload }) => {
            state.list.reload = payload;
        },
        modalUpdateStatus: (state, { payload }) => {
            state.modalUpdate.status = payload;
        },
        modalUpdateId: (state, { payload }) => {
            state.modalUpdate.id = payload;
        },
        modalUpdate: (state, { payload }) => {
            state.modalUpdate = {
                ...state.modalUpdate,
                ...payload
            };
        },
        modalUpdateFetchData: (state, { payload }) => {
            state.modalUpdate = {
                ...state.modalUpdate,
                loading: true,
                errors: [],
            };
        },
        fetchProductsList: (state, {payload}: PayloadAction<any>) => {
            state.card.products.list = {
                ...state.card.products.list,
                isFetching: true,
                reload: false,
            };
        },
        setProductsList: (state, {payload}: PayloadAction) => {
            state.card.products = {
                ...state.card.products,
                list: {
                    ...state.card.products.list,
                    data: payload || defaultQueryList,
                    isFetching: false,
                },
                selected: [],
            }
        },
        updateProductsList: (state, {payload}: PayloadAction<any>) => {
            state.card.products.list.reload = payload;
        },
        setProductsSelected: (state, {payload}: PayloadAction<number | number[]>) => {
            const currentElements = state.card.products.selected;
            if (Array.isArray(payload)) {
                state.card.products.selected = payload;
            } else {
                state.card.products.selected = currentElements.includes(payload)
                    ? state.card.products.selected.filter(x => x !== payload)
                    : [...state.card.products.selected, payload]
            }
        },
        setIdModalUpdateProduct: (state, {payload}: PayloadAction<number | null>) => {
            state.card.products.modalUpdate.id = payload;
        },
        setDataUpdateProduct: (state, {payload}: PayloadAction<any>) => {
            state.card.products.modalUpdate = {
                ...state.card.products.modalUpdate,
                data: payload,
                isFetching: false,
            };
        },
        fetchDataUpdateProduct: (state, {payload}: PayloadAction<any>) => {
            state.card.products.modalUpdate = {
                ...state.card.products.modalUpdate,
                isFetching: true,
            };
        },
        setStatusModalAddProductFromFile: (state, {payload}: PayloadAction<number | null>) => {
            state.card.products.modalAddFromFiles.storageId = payload;
        },
        setIdModalMassEditProduct: (state, {payload}: PayloadAction<boolean>) => {
            state.card.products.modalMassEdit.status = payload;
        },
        setReloadCountProductsStorage: (state, {payload}: PayloadAction<boolean>) => {
            state.card.products.countProducts.reload = payload;
        },
        setStatusModalAddProductFromCode: (state, {payload}: PayloadAction<boolean>) => {
            state.card.products.modalAddFromCode.status = payload;
        },
        setProductsModalAddProductFromCode: (state, {payload}: PayloadAction<any>) => {
            state.card.products.modalAddFromCode.foundProducts = payload;
        },
        setSelectedProductsModalAddProductFromCode: (state, {payload}: PayloadAction<any>) => {
            state.card.products.modalAddFromCode.selectedProducts = payload;
        },
        addProductModalAddProductFromCode: (state, {payload}: PayloadAction<any>) => {
            state.card.products.modalAddFromCode.selectedProducts = [
                ...state.card.products.modalAddFromCode.selectedProducts,
                {
                    count: '',
                    price: '',
                    currency: '',
                    name: payload.name,
                    product: payload,
                }
            ];
        },
        removeProductModalAddProductFromCode: (state, {payload}: PayloadAction<number>) => {
            state.card.products.modalAddFromCode.selectedProducts = state.card.products.modalAddFromCode.selectedProducts.filter(x => x.product.id !== payload);
        },
        setDataProductModalAddProductFromCode: (state, {payload}: PayloadAction<{id: number, name: string, value: any}>) => {
            state.card.products.modalAddFromCode.selectedProducts =
                state.card.products.modalAddFromCode.selectedProducts.map(x => {
                    return x.product.id === payload.id ? {...x, [payload.name]: payload.value} : x
                });
        },
    }
})

export const storageReducer = slice.reducer;
export const storageActions = slice.actions;