// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.photoPerson {
  width: 30px;
  height: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: auto;
  flex-shrink: 0; }
  .photoPerson > img {
    max-width: 100%;
    max-height: 100%; }
  .photoPerson.size-xs {
    width: 20px;
    height: 20px; }
  .photoPerson.size-sm {
    width: 25px;
    height: 25px; }
  .photoPerson.size-md {
    width: 40px;
    height: 40px; }
  .photoPerson.size-lg {
    width: 70px;
    height: 70px; }
    .photoPerson.size-lg > img {
      width: 70px;
      height: 70px; }
`, "",{"version":3,"sources":["webpack://./src/assets/css/sass/custom/photoPerson.scss"],"names":[],"mappings":"AAAA;EACI,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,cAAc;EAEd,cAAc,EAAA;EAVlB;IAaQ,eAAe;IACf,gBAAgB,EAAA;EAdxB;IAkBQ,WAAW;IACX,YAAY,EAAA;EAnBpB;IAuBQ,WAAW;IACX,YAAY,EAAA;EAxBpB;IA4BQ,WAAW;IACX,YAAY,EAAA;EA7BpB;IAiCQ,WAAW;IACX,YAAY,EAAA;IAlCpB;MAqCY,WAAW;MACX,YAAY,EAAA","sourcesContent":[".photoPerson {\n    width: 30px;\n    height: 30px;\n    position: relative;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 50%;\n    overflow: auto;\n    //border: 1px solid #ed7117;\n    flex-shrink: 0;\n\n    > img {\n        max-width: 100%;\n        max-height: 100%;\n    }\n\n    &.size-xs {\n        width: 20px;\n        height: 20px;\n    }\n\n    &.size-sm {\n        width: 25px;\n        height: 25px;\n    }\n\n    &.size-md {\n        width: 40px;\n        height: 40px;\n    }\n\n    &.size-lg {\n        width: 70px;\n        height: 70px;\n\n        > img {\n            width: 70px;\n            height: 70px;\n        }\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
