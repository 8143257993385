import { SendGet } from '../handler/sendGet';

// eslint-disable-next-line import/prefer-default-export
export const getProducts = (params) => {
    return SendGet(`products`, params);
};
export const getProductsV2 = (params) => {
    return SendGet(`products`, params, 2);
};
export const fetchCategories = (params) => {
    return SendGet(`categories`, params);
};
export const fetchCategory = (slug, params) => {
    return SendGet(`categories/slug/${slug}`, params);
};
export const fetchBrands = (props) => {
    const {
        expand = [],
        fields = [],
        filters = [],
        limit = 30,
        page = 1,
        sort = [],
    } = props;

    const params = {
        'selection[filter]': JSON.stringify(filters),
        expand: expand.join(','),
        fields: fields.join(','),
        'per-page': limit,
        page,
        sort: sort.join(','),
    };
    return SendGet(`brands`, params)
        .then((response: any) => {
            if (response.status === 200) {
                return response.data
            }
            return null;
        });
};