import React from 'react'
import css from './UpdateArticle.module.scss'
import {HTMLParser} from "../../../../components/shared/HTMLParser";

const UpdateArticle = ({headInfo = true, headline, imgDetail, description}) => {
    return (
        <div className={css.updateArticle}>
            {headInfo &&
                <>
                    <p className={css.updateArticleHeadline}>{headline}</p>
                </>
            }
            <div className={`${css.updateArticleImg} ${!headInfo ? css.updateArticleImgMargin : ''}`}>
                {imgDetail &&
                    <img src={imgDetail.path} alt={imgDetail.name} className={css.searchResultImg}/>
                }
            </div>
            <div className={`${css.updateArticleDescription} ${css.updateArticleWhiteSpace}`}>
                <HTMLParser html={description}/>
            </div>
        </div>
    )
}

export default UpdateArticle