import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Redirect} from "react-router-dom";
import {userLogin} from "../constants/defaultValues";
import LoadingData from "../views/loadingData";
import {getUserInfo} from "../redux/auth/actions";
import {userOptions} from "./authHelper";
import App from "../views/app";

// eslint-disable-next-line import/prefer-default-export
export function withAccountLayout<WCP>(WrappedComponent: React.ComponentType<WCP>) {
    function Component(props: WCP) {
        console.log('component');
        const dispatch = useDispatch();
        const {roles = [], permissions = []} = props;
        const { currentUser: user, token, error } = useSelector((state) => state.authUser);
        const [currentLoading, setCurrentLoading] = useState(true);
        const uploadingSuccess = useSelector((state) => state.userData.change.success);


        useEffect(() => {
            if (user || !token || error) {
                setCurrentLoading(false);
            }
        }, [user]);

        useEffect(() => {
            if (!user && token) {
                dispatch(getUserInfo(userOptions));
            }
            if (user || !token || error) {
                setCurrentLoading(false);
            }
        }, []);

        useEffect(() => {
            if (uploadingSuccess) {
                dispatch(getUserInfo(userOptions));
            }
        }, [uploadingSuccess]);

        if (user) {
            const {roles: userRoles, permissions: userPermissions} = user;
            let access = true; // доступ
            if (userRoles.includes('pokup1632301072')) {
                window.location.href = '/account';
            }
            if (roles.length && !userRoles.filter(role => roles.includes(role)).length) {
                access = false;
            }
            if (
                permissions.length &&
                !userPermissions.filter(permission => permissions.includes(permission)).length
            ) {
                access = false;
            }

            if (!access) {
                return <Redirect
                    to={{
                        pathname: '/unauthorized',
                        state: { from: props.location },
                    }}
                />
            }
        }
        if (!user && !currentLoading) {
            return <Redirect
                to={{
                    pathname: userLogin,
                    state: { from: props.location },
                }}
            />
        }

        if (currentLoading) {
            return <LoadingData />;
        }

        return (
            <>
                <App component={WrappedComponent} props={props}/>
            </>
        );
    }

    return Component;
}
