import React from 'react';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';

const ModalOffModeDuty = ({isShow, changeShow, onClickFinish}) => {

    return (
        <>
            <Modal
                size="md"
                className="modal-style"
                isOpen={isShow}
                toggle={() => changeShow(!isShow)}
                backdrop="static"
            >
                <ModalHeader className="pb-0" toggle={() => changeShow(!isShow)} />
                <ModalBody className="pt-0">
                    <div className="window-question">
                        <div className="icon">
                            <i className="simple-icon-question" />
                        </div>
                        <div className="text mt-3">Вы действительно хотите завершить дежурство?</div>
                        <div className="d-flex align-items-center justify-content-center mt-3">
                            <button
                                type="button"
                                className="btn-primary btn ml-2 mr-2"
                                onClick={onClickFinish}
                            >Завершить</button>
                            <button
                                type="button"
                                className="btn-outline-primary btn ml-2 mr-2"
                                onClick={() => changeShow(false)}
                            >Отмена</button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};
export default ModalOffModeDuty;
