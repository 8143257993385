// eslint-disable-next-line import/prefer-default-export
export const changeQueryUrl = (location, params) => {
    const locationParams = new URLSearchParams(location.search);
    const paramsKeys = Object.keys(params);
    if (!paramsKeys.length) return locationParams;
    paramsKeys.forEach(x => {
        const firstSymbol = x[0];
        if (firstSymbol === '-') {
            const name = x.substring(1);
            locationParams.delete(name);
        } else {
            locationParams.set(x, params[x]);
        }
    });
    return locationParams;
}