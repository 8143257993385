import React, {useContext} from 'react';
import FormError  from '../../../../components/validate/FormError';
import {FormGroup} from "reactstrap";
import {Grid} from "@mui/material";
import Brands from "../fields/Brands";
import ProductType from "../fields/ProductType";
import PriceSegment from "../fields/PriceSegment";
import {ModalCreateTaskContext} from "../index";
import {usedBrands} from "../../../../constants/defaultValues";

export const validate = (data) => {
    const errors = {};
    if (!data.productType) {
        errors.productType = 'Выберите тип товара';
    }
    if (!data.brand) {
        errors.brand = 'Выберите бренд';
    }
    if (!data.priceSegment) {
        errors.priceSegment = 'Выберите ценовой сегмент';
    }
    return errors;
}

const Template49 = () => {
    const {data, errors, onChangeList} = useContext(ModalCreateTaskContext);
    const {onChangeBrand, onChangeProductType, onChangePriceSegment} = onChangeList;

    const excludeBrandsId = Object.values(usedBrands)
        .filter(x => ![394].includes(x.id))
        .map(x => x.id);

    return (
        <>
            <Grid item xs={2}>
                <FormGroup>
                    <Brands
                        value={data.brand}
                        setValue={onChangeBrand}
                        exclude={excludeBrandsId}
                    />
                    <FormError errors={errors} name="brand" />
                </FormGroup>
            </Grid>
            <Grid item xs={2}>
                <FormGroup>
                    <ProductType
                        value={data.productType}
                        setValue={onChangeProductType}
                    />
                    <FormError errors={errors} name="productType" />
                </FormGroup>
            </Grid>
            <Grid item xs={2}>
                <FormGroup>
                    <PriceSegment
                        value={data.priceSegment}
                        setValue={onChangePriceSegment}
                    />
                    <FormError errors={errors} name="priceSegment" />
                </FormGroup>
            </Grid>
        </>
    );
};
export default Template49;
