import React, {useContext, useEffect} from 'react';
import FormError  from '../../../../components/validate/FormError';
import {FormGroup} from "reactstrap";
import {Grid} from "@mui/material";
import Brands from "../fields/Brands";
import {ModalCreateTaskContext} from "../index";
import {usedBrands} from "../../../../constants/defaultValues";

export const validate = (data) => {
    const errors = {};
    if (data?.client?.segment_income?.id == 3) {
        errors.client = 'Нельзя создать такую задачу для контрагента типа "C"'
    }
    return errors;
}

const Template51 = () => {
    const {data, errors, onChangeList, changedData} = useContext(ModalCreateTaskContext);
    const {onChangeBrand} = onChangeList;

    const isDisabledBrand = true;

    const brandValue = data.brand;

    useEffect(() => {
        if (!brandValue && (!data.id || changedData.taskTypeTemplate)) {
            onChangeBrand(usedBrands.other);
        }
    }, []);

    return (
        <>
            <Grid item xs={2}>
                <FormGroup>
                    <Brands
                        value={brandValue}
                        setValue={onChangeBrand}
                        isDisabled={isDisabledBrand}
                    />
                    <FormError errors={errors} name="brand" />
                </FormGroup>
            </Grid>
        </>
    );
};
export default Template51;
