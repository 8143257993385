import {Scrollbars} from "react-custom-scrollbars";
import React, {useContext} from "react";
import css from "../Comments.module.scss";
import {getChatId} from "../helper";
import {DesktopContext} from "../../../../utils/hoc/withContext";
import ChatToggle from "./ChatToggle/ChatToggle";

const ChatTypes = ({types: chatTypes, activeChat, setActiveChat, countNewMessage, setActiveStyle}) => {
    if (chatTypes.length < 2) return <></>;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const desktop = useContext(DesktopContext)
    const onSelectedChat = (id) => {
        setActiveChat(chatTypes.find(x => getChatId(x) === id) ?? null);
    }

    return (
        <>
            <div className={`${css.chatList} ${!desktop && `${css.chatListMobile} ${css.chatListFullWidth}`}`}>
                <Scrollbars
                    autoHide
                    style={{ height: '100%' }}
                >
                    <ChatToggle
                        setActiveStyle={setActiveStyle}
                        chatTypes={chatTypes}
                        activeChat={activeChat}
                        countNewMessage={countNewMessage}
                        onSelectedChat={onSelectedChat}
                    />
                </Scrollbars>
            </div>
        </>
    )
}
export default ChatTypes