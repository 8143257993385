import {
    defaultDirection,
    defaultLocale,
    defaultColor,
    localeOptions,
    themeColorStorageKey,
    themeRadiusStorageKey,
} from '../constants/defaultValues';

export const mapOrder = (array, order, key) => {
    // eslint-disable-next-line func-names
    array.sort(function (a, b) {
        const A = a[key];
        const B = b[key];
        if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
            return 1;
        }
        return -1;
    });
    return array;
};

export const getDateWithFormat = () => {
    const today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; // January is 0!

    const yyyy = today.getFullYear();
    if (dd < 10) {
        dd = `0${dd}`;
    }
    if (mm < 10) {
        mm = `0${mm}`;
    }
    return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
    const now = new Date();
    return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
    let direction = defaultDirection;

    try {
        if (localStorage.getItem('direction')) {
            const localValue = localStorage.getItem('direction');
            if (localValue === 'rtl' || localValue === 'ltr') {
                direction = localValue;
            }
        }
    } catch (error) {
        console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
        direction = defaultDirection;
    }
    return {
        direction,
        isRtl: direction === 'rtl',
    };
};
export const setDirection = (localValue) => {
    let direction = 'ltr';
    if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
    }
    try {
        localStorage.setItem('direction', direction);
    } catch (error) {
        console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error);
    }
};

export const getCurrentColor = () => {
    let currentColor = defaultColor;
    try {
        if (localStorage.getItem(themeColorStorageKey)) {
            currentColor = localStorage.getItem(themeColorStorageKey);
        }
    } catch (error) {
        console.log('>>>>: src/helpers/Utils.js : getCurrentColor -> error', error);
        currentColor = defaultColor;
    }
    return currentColor;
};

export const setCurrentColor = (color) => {
    try {
        localStorage.setItem(themeColorStorageKey, color);
    } catch (error) {
        console.log('>>>>: src/helpers/Utils.js : setCurrentColor -> error', error);
    }
};

export const getCurrentRadius = () => {
    let currentRadius = 'rounded';
    try {
        if (localStorage.getItem(themeRadiusStorageKey)) {
            currentRadius = localStorage.getItem(themeRadiusStorageKey);
        }
    } catch (error) {
        console.log(
            '>>>>: src/helpers/Utils.js : getCurrentRadius -> error',
            error
        );
        currentRadius = 'rounded';
    }
    return currentRadius;
};
export const setCurrentRadius = (radius) => {
    try {
        localStorage.setItem(themeRadiusStorageKey, radius);
    } catch (error) {
        console.log(
            '>>>>: src/helpers/Utils.js : setCurrentRadius -> error',
            error
        );
    }
};

export const getCurrentLanguage = () => {
    let language = defaultLocale;
    try {
        language =
            localStorage.getItem('currentLanguage') &&
            localeOptions.filter(
                (x) => x.id === localStorage.getItem('currentLanguage')
            ).length > 0
                ? localStorage.getItem('currentLanguage')
                : defaultLocale;
    } catch (error) {
        console.log(
            '>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
            error
        );
        language = defaultLocale;
    }
    return language;
};
export const setCurrentLanguage = (locale) => {
    try {
        localStorage.setItem('currentLanguage', locale);
    } catch (error) {
        console.log(
            '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
            error
        );
    }
};

export const getCurrentUser = () => {
    let user = null;
    try {
        user =
            localStorage.getItem('current_user') != null
                ? JSON.parse(localStorage.getItem('current_user'))
                : null;
    } catch (error) {
        console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
        user = null;
    }
    return user;
};

export const setCurrentUser = (user) => {
    try {
        if (user) {
            localStorage.setItem('current_user', JSON.stringify(user));
        } else {
            localStorage.removeItem('current_user');
        }
    } catch (error) {
        console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
    }
};

export const getToken = () => {
    let token = null;
    try {
        token =
            localStorage.getItem('token') != null
                ? localStorage.getItem('token')
                : null;
    } catch (error) {
        console.log('>>>>: src/helpers/Utils.js  : token -> error', error);
        token = null;
    }
    return token;
};

export const setToken = (token) => {
    try {
        if (token) {
            localStorage.setItem('token', token);
        } else {
            localStorage.removeItem('token');
        }
    } catch (error) {
        console.log('>>>>: src/helpers/Utils.js : setToken -> error', error);
    }
};

export const ucFirst = (str) => {
    if (!str) return str;
    return str[0].toUpperCase() + str.slice(1);
}

export const getSelectCurrency = (id) => {
    let currency = null

    try {
        currency = localStorage.getItem(`currency-${id}`)
    } catch (error) {
        console.log('>>>>: src/helpers/Utils.js : getSelectCurrency -> error', error)
    }

    if (!currency) {
        // eslint-disable-next-line default-case
        switch (id) {
            case 'first':
                currency = 2
                break
            case 'second':
                currency = 3
                break
        }
    }

    return currency
}

export const setSelectCurrency = (id, currency) => {
    try {
        localStorage.setItem(`currency-${id}`, currency);
    } catch (error) {
        console.log('>>>>: src/helpers/Utils.js : setSelectCurrency -> error', error)
    }
}

/**
 * Формирование данных "Где мы сейчас находимся" и отправляем на сервер
 * @param typeId
 * @param id
 * @param status
 * @returns {{data: {entity_type_id, entity_id, status: number}, type: string}}
 */
export const getImLooking = (typeId, id, status = 0) => {
    return {
        type: 'imLooking',
        data: {
            entity_id: id,
            entity_type_id: typeId,
            status
        }
    }
}

export const isReservedElement = (target: any, elements = ['a', 'button', '.MuiCheckbox-root', '.MuiList-root']) => {
    let result = false;
    const tag = target.tagName.toLowerCase();
    if (elements.includes(tag)) result = true;
    if (target.closest('.MuiMenu-root')) result = true;
    // eslint-disable-next-line consistent-return
    elements.forEach(el => {
        if (target.closest(el)) {
            result = true;
            return true;
        }
    });
    return result;
}

export const getSort = (data, defaultValue = null) => {
    if (!data.length && defaultValue) {
        data.push(defaultValue);
    }
    return data.map(x => `${x.desc ? '-' : ''}${x.id}`).join(',');
}

export const getFilterData = (filter) => {
    let newValue = filter.value;
    if (!newValue) return null;

    if (Array.isArray(newValue)) {
        newValue = filter.value.map((x: any) => x?.id ?? x);
    }
    if (typeof filter.value.id !== 'undefined') {
        newValue = filter.value.id;
    }
    if (typeof filter.value.startDate !== 'undefined') {
        newValue = `${filter.value.startDate} - ${filter.value.endDate}`;
    }
    return {
        id: filter.id,
        value: newValue,
    }
};

export const getFilters = (data) => {
    const result: any = [];
    data.forEach((x) => {
        const filter = getFilterData(x);
        if (!filter) return;
        const attribute = `selection[${filter.id}]`;
        result[attribute] = filter.value;
    })
    return result;
}

export const tryRequire = (path) => {
    try {
        return require(`${path}`);
    } catch (err) {
        console.log(err);
        return null;
    }
};