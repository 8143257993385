export const DisabledOptionSelect = (openOptions, option) => {
  const optionID = Number.parseInt(option.id, 10);
  if (!openOptions.length) {
    return false;
  }
  if (openOptions.includes(optionID)) return true;
  return false;
};
export const EnableOptionSelect = (openOptions, option) => {
  const optionID = Number.parseInt(option.id, 10);
  if (!openOptions.length) {
    return true;
  }
  if (openOptions.includes(optionID)) return false;
  return true;
};
