import React, {useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
// eslint-disable-next-line import/no-extraneous-dependencies
import {Chip, Tooltip} from '@mui/material';
import {useHistory} from "react-router-dom";
import {adminRoot} from "../../../constants/defaultValues";
import {encodeDataToUrl} from "../../../components/common/ModuleDataAndUrl";
import css from './nav.module.scss'
import SuperPageNav from "./SuperPageNav";
import {useEffect, useState} from "react";
import {send} from "@giantmachines/redux-websocket";

const PageNavTasks = (props) => {
    const {navItem} = props;
    const { task } = useSelector((state) => state.websocket.event);
    const countTasksToEngineer = useSelector((state) => state.crmTask.countToEngineer); // задачи, которые находятся у кого-то в работе (подбор)
    const countNewTasks = useSelector((state) => state.crmTask.countNew);
    const currentUser = useSelector((state) => state.authUser.currentUser);
    const { connected } = useSelector((state) => state.websocket);
    const dispatch = useDispatch();
    const history = useHistory()
    const linkTasks = `${adminRoot}/crm/tasks`;
    const [countData, setCountData] = useState({
        countTasksMyGroups: 0,
    })

    const changeCountData = (name, value) => {
        setCountData((oldValue) => {
            return  {...oldValue, [name]: value};
        })
    }

    const hrefToTasksMyGroup = useMemo(() => {
        const groupsId = [];
        currentUser.crmGroups.forEach(x => {
            groupsId.push(`G-${x.id}`);
            if (x.parent_id) {
                groupsId.push(`G-${x.parent_id}`);
            }
        })
        const linkTasksParams = {
            status_id: '1|2|3|6',
            manager_id: groupsId.join('|'),
        };

        return `${linkTasks}?${encodeDataToUrl(linkTasksParams)}`;
    }, []);

    const hrefToTasks = useMemo(() => {
        let managerID = [];
        managerID.push(currentUser.id);
        const children = currentUser.babies ?? [];
        const parents = currentUser.parents ?? [];
        if (children.length) {
            managerID = managerID.concat(children.map(x => x.id));
        }
        if (parents.length) {
            managerID = managerID.concat(parents.map(x => x.id));
        }
        const linkTasksParams = {
            status_id: '1|2|3|6',
            created_at: `01.01.2019-${moment().format('DD.MM.YYYY')}`,
            manager_id: managerID.join('|'),
        };

        return `${linkTasks}?${encodeDataToUrl(linkTasksParams)}`;
    }, []);

    const hrefToTasksToEngineer = useMemo(() => {
        let managerID = [];
        managerID.push(currentUser.id);
        const children = currentUser.babies ?? [];
        const parents = currentUser.parents ?? [];
        if (children.length) {
            managerID = managerID.concat(children.map(x => x.id));
        }
        if (parents.length) {
            managerID = managerID.concat(parents.map(x => x.id));
        }
        const linkTasksToEngineerParams = {
            id: countTasksToEngineer.join('|'),
            responsible_id: managerID.join('|'),
        };

        return `${linkTasks}?${encodeDataToUrl(linkTasksToEngineerParams)}`;
    }, [countTasksToEngineer]);

    const goToHref = (e, href) => {
        e.preventDefault()
        return history.push(href)
    }

    useEffect(() => {
        if (connected) {
            dispatch(send({type: 'getCountTasksMyGroups'}));
        }
    }, [connected]);

    useEffect(() => {
        if (task !== null) {
            const action = task?.action;
            if (['countTasksMyGroups'].includes(action)) {
                changeCountData(action, Number(task.data ?? 0));
            }
        }
    }, [task]);

    return (
        <SuperPageNav href={navItem.to}>
            <div className={css.counterText}>
                <span className={css.headline}>{navItem.name}</span>
                <div className={css.counters}>
                    {countTasksToEngineer.length > 0 &&
                        <Tooltip title="Вы ответственный за задачи" placement="top">
                            <Chip
                                clickable={false}
                                onClick={(e) => goToHref(e, hrefToTasksToEngineer)}
                                className={`${css.counter  } ${  css.counterBlue}`}
                                label={countTasksToEngineer.length}/>
                        </Tooltip>
                    }
                    {countNewTasks > 0 &&
                        <Tooltip title="Вы исполнитель задачи" placement="top">
                            <Chip
                                clickable={false}
                                onClick={(e) => goToHref(e, hrefToTasks)}
                                className={`${css.counter  } ${  css.counterOrange}`}
                                label={countNewTasks}/>
                        </Tooltip>
                    }
                    {countData.countTasksMyGroups > 0 &&
                        <Tooltip title="Ваш отдел исполнитель задачи" placement="top">
                            <Chip
                                clickable={false}
                                onClick={(e) => goToHref(e, hrefToTasksMyGroup)}
                                className={`${css.counter  } ${  css.counterRed}`}
                                label={countData.countTasksMyGroups}/>
                        </Tooltip>
                    }
                </div>
            </div>
        </SuperPageNav>
    );
};
export default PageNavTasks;
