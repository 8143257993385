import {PayloadAction} from "@reduxjs/toolkit";
import {call, put, takeEvery} from "redux-saga/effects";
import {articlesApi, getArticle, getArticles} from "./api";
import {articlesActions} from "./slice";

function* fetchArticles({payload}: PayloadAction<IFetchPage>) {
  try {
    const data = yield call(getArticles, payload)
    yield put(articlesActions.setArticles(data.data.items))
    yield put(articlesActions.setPages(data.data._meta))
  } catch (error) {
    new Error("Похоже, что-то пошло не так :(" + ` ${error}`)
    yield put(articlesActions.setArticles([]))
  }
}

function* fetchArticle({payload}: PayloadAction<IFetchBySlug>) {
  try {
    const data = yield call(getArticle, payload)
    yield put(articlesActions.setArticle(data.data))
  } catch (error) {
    new Error("Похоже, что-то пошло не так :(" + ` ${error}`)
    yield put(articlesActions.setArticle(null))
  }
}

function* fetchArticlesUpdate({payload}: PayloadAction<IFetchBySlug>) {
  try {
    const data = yield call(getArticles, payload)
    yield put(articlesActions.setArticlesUpdate(data.data.items))
  } catch (error) {
    new Error("Похоже, что-то пошло не так :(" + ` ${error}`)
    yield put(articlesActions.setArticle(null))
  }
}

export function* articlesSaga() {
  yield takeEvery(articlesActions.getArticles.type, fetchArticles)
  yield takeEvery(articlesActions.getArticle.type, fetchArticle)
  yield takeEvery(articlesActions.getArticlesUpdate.type, fetchArticlesUpdate)
}
