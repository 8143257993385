import {styled} from "@mui/styles";
import {Dialog} from "@mui/material";

// eslint-disable-next-line import/prefer-default-export
export const StyledDialog = styled(Dialog)(() => ({
    zIndex: '1050',
    '& .MuiDialog-container': {
        alignItems: 'flex-start',
        verticalAlign: 'top',
        overflow: 'auto',
    },
    '& .MuiDialog-paper': {
        width: "100%",
        maxHeight: "none",
        overflow: "visible"
    },
    '& .MuiDialogTitle-root': {
        fontSize: "1rem",
        fontWeight: "600",
        padding: "1.5rem 2rem 1rem",
    },
    '& .MuiDialogContent-root': {
        padding: "0 2rem 1rem",
        overflow: 'visible'
    },
    '& .MuiDialogContentText-root': {
        textAlign: "center",
    },
    '& .MuiDialogActions-root': {
        justifyContent: "center",
        padding: "0 2rem 2rem",
    },
}));
