import React, {useContext} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {Button} from '@mui/material'
import {NavLink} from "react-router-dom";
import css from './nav.module.scss'
import {DesktopContext} from "../../../utils/hoc/withContext";

const PageNavItem = (props) => {
    const {menuItem, checkAccess, redirectToFilter, onClick} = props;
    const {name, to, id, icon, component: Component} = menuItem;
    const allowAccess = checkAccess(menuItem);
    const desktop = useContext(DesktopContext)

    if (!allowAccess) return <></>;

    return (
        <>
            {/* eslint-disable-next-line no-nested-ternary */}
            {desktop ?
                <li key={id}>
                    {typeof Component === 'undefined' || !Component ? (
                        <NavLink to={to}>{name}</NavLink>
                    ) : (
                        <Component
                            navItem={menuItem}
                            redirect={redirectToFilter}
                        />
                    )}
                </li>
            :
                <>
                    {typeof Component === 'undefined' || !Component ? (
                        <Button
                            onClick={onClick}
                            to={to}
                            component={NavLink}
                            variant="outlined"
                            className={css.navButton}
                            startIcon={icon({width: 20, height: 24})}
                        >
                            <span className={css.navButtonName}>{name}</span>
                        </Button>
                    ) : (
                        <Button
                            onClick={onClick}
                            to={to}
                            component={NavLink}
                            variant="outlined"
                            className={css.navButton}
                            startIcon={icon({width: 20, height: 24})}
                        >
                            <Component
                                navItem={menuItem}
                                redirect={redirectToFilter}
                            />
                        </Button>
                    )}
                </>
            }
        </>
    )
}
export default PageNavItem;
