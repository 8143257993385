import {storeData} from "../../store";

const baseTask = (state: storeData) => state.task;
const taskCard = (state: storeData) => state.task.card;
const watchers = (state: storeData) => state.task.card.watchers;
const comments = (state: storeData) => state.task.card.comments;
const modalCreate = (state: storeData) => state.task.modalCreate;
const previousTask = (state: storeData) => state.task.previousTask;
const modalNextTask = (state: storeData) => state.task.modalNextTask;

// eslint-disable-next-line import/prefer-default-export
export const taskSelectors = {
    baseTask,
    watchers,
    comments,
    taskCard,
    modalCreate,
    previousTask,
    modalNextTask
};