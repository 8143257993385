export const stringAnalysis = (string) => {
    let convert = false;
    let returnString = string.toString();
    if (returnString.indexOf('|') > -1 && !convert) {
        returnString = returnString.split('|');
        convert = true;
    }
    if (returnString.indexOf('-') > -1 && !convert && returnString.indexOf('G-') === -1) {
        const dateFromString = returnString.split('-');
        const startDate = dateFromString[0] ?? null;
        const endDate = dateFromString[1] ?? null;
        if (startDate && endDate) {
            returnString = {
                startDate: startDate.toString().trim(),
                endDate: endDate.toString().trim(),
            };
            convert = true;
        }
    }
    return returnString;
};
export const encodeDataToUrl = (data) => {
    return Object
        .keys(data)
        .map(value => `${value}=${encodeURIComponent(data[value])}`)
        .join('&');
};
export const decodeUrlToData = (search = '') => {
    const returnArray = [];
    let currentString = decodeURI(search);
    currentString = currentString.toString().trim();
    if (!currentString.length) return returnArray;
    currentString = currentString.slice(1);
    const stringArray = currentString.split('&');
    if (!stringArray.length) return returnArray;
    stringArray.forEach((item) => {
        const splitString = item.split('=');
        const filterId = splitString[0] ?? null;
        const filterValue = splitString[1] ?? null;
        if (filterId && filterValue) {
            returnArray.push({
                id: filterId,
                value: stringAnalysis(filterValue)
            })
        }
    });
    return returnArray;
};