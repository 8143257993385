import React from 'react';
import css from "./Popup.module.scss";
import useOnClickOutside from "../../helpers/useOnClickOutside";

// eslint-disable-next-line react/display-name
const Popup = React.memo((props) => {
    const { children, classes, open, onChange } = props;

    const ref = React.useRef();

    useOnClickOutside(ref, () => setTimeout( ()=> onChange(false), 200));

    const finalClass = classes ? `${css.popupBody} ${classes}` : `${css.popupBody}`;

    const renderDropDownMenu = () => {
        return (
            <div
                className={finalClass}
                ref={ref}>
                {children}
            </div>
        )
    };

    return (
        <>
            {open && renderDropDownMenu()}
        </>
    );
});

export default Popup