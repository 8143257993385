import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userLogin } from '../constants/defaultValues';
import { getUserInfo } from '../redux/auth/actions';
import {roleBuyer, roleProvider} from "../constants/rolesAndPermissions";

const LoadingPage = React.lazy(() =>
    import(/* webpackChunkName: "views-app" */ '../views/loadingData')
);

export const userOptions = {
    expand: 'avatarData, permissions, roles, parents, babies, notification, state, crmGroups'
};

export const userIsCrm = (user) => {
    return !!(user && user.roles.filter(x => ![roleBuyer, roleProvider].includes(x)).length);
}

const ProtectedRoute = ({
    component: Component,
    // eslint-disable-next-line no-unused-vars
    roles = [],
    permissions = [],
    ...rest
}) => {
    const uploadingSuccess = useSelector((state) => state.userData.change.success);
    const { currentUser, token, error } = useSelector((state) => state.authUser);
    const [currentLoading, setCurrentLoading] = useState(true);
    const dispatch = useDispatch();

    const setComponent = (props) => {
        if (currentUser) {
            let access = true; // доступ
            const userCrm = userIsCrm(currentUser);
            if (!userCrm) {
                window.location.href = 'https://stoking.ru/account';
            }
            if (roles.length && !currentUser.roles.filter(role => roles.includes(role)).length) {
                access = false;
            }
            if (
                permissions.length &&
                !currentUser.permissions.filter(permission => permissions.includes(permission)).length
            ) {
                access = false;
            }
            if (access) {
                return <Component {...props} />;
            }
            return (
                <Redirect
                    to={{
                        pathname: '/unauthorized',
                        state: { from: props.location },
                    }}
                />
            );
        }
        if (currentLoading) {
            return <LoadingPage {...props} />;
        }
        return (
            <Redirect
                to={{
                    pathname: userLogin,
                    state: { from: props.location },
                }}
            />
        );
        // return <Component {...props} />;
    };

    useEffect(() => {
        if (!currentUser && token) {
            dispatch(getUserInfo(userOptions));
        }
        if (currentUser || !token || error) {
            setCurrentLoading(false);
        }
    }, []);

    useEffect(() => {
        if (uploadingSuccess) {
            dispatch(getUserInfo(userOptions));
        }
    }, [uploadingSuccess]);

    return <Route {...rest} render={setComponent} />;
};

// eslint-disable-next-line import/prefer-default-export
export { ProtectedRoute };
