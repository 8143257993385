/* eslint-disable import/prefer-default-export */
import menuItems from '../../constants/menu';

export const getParentItem = (location) => {
    let selectedParent = null;
    menuItems.forEach((x) => {
        const {id, to} = x;
        if (location.pathname.search(to) !== -1) {
            selectedParent = id;
        }
    });
    return selectedParent;
};