/* eslint-disable no-unused-vars */
import {currencySandboxIsDefault} from "../../constants/defaultCrm";
import {getCurrencyValueFromShipper, isAllowRc} from "./isRedPrice";
import {getPrices} from "./price";

const sortSPByProduct = (spList) => {
    return spList.sort((a, b) => {
        const aItemKey = a.itemKey;
        const bItemKey = b.itemKey;
        const allowRcA = isAllowRc({spProduct: a.storageProduct, ruleRC: a.ruleRC});
        const allowRcB = isAllowRc({spProduct: b.storageProduct, ruleRC: b.ruleRC});

        const aPrice = Number.parseFloat(a.storageProduct?.price ?? 0);
        const bPrice = Number.parseFloat(b.storageProduct?.price ?? 0);

        // eslint-disable-next-line no-unused-vars
        const aCount = Number.parseFloat(a.storageProduct?.count ?? 0);

        if (allowRcA > allowRcB) return -1;
        if (allowRcA < allowRcB) return 1;

        if (aItemKey > bItemKey) return 1;
        if (aItemKey < bItemKey) return -1;

        if (aPrice > bPrice) return 1;
        if (aPrice < bPrice) return -1;

        if (aCount > 0) return -1;
        if (aCount < 0) return 1;
        return 0;
    });
}

// eslint-disable-next-line import/prefer-default-export
export const prepareSearchProducts = (props) => {
    const {products, currencyArr, isAgent = false} = props;
    let arrayStorage = [];
    let arrayNoStorage = [];
    if (!products.length) return [];

    const prepareSpElement = (sp, item) => {

        const newSp = sp;
        const {firstPrice} = getPrices({
            product: {
                ...item,
                storageProduct: newSp,
            },
            access: {isAgent}
        });

        newSp.price = firstPrice.price;
        newSp.currency = firstPrice.currency;
        newSp.currencyData = currencyArr.find(x => parseInt(x.id, 10) === firstPrice.currency);

        return newSp;

        // if (sp.storage) {
        //     const allowRc = isAllowRc({spProduct: sp, ruleRC: item.ruleRC});
        //     const statusCrm = parseInt(sp.storage.status_crm, 10);
        //     if (allowRc) {
        //         arrayStorage.push({...item, storageProduct: newSp});
        //     } else if (statusCrm){
        //         newSp.price = 0;
        //         newSp.count = 0;
        //         arrayNoStorage.push({...item, storageProduct: newSp});
        //     }
        //     // if (Number.parseInt(sp.storage.status_crm, 10) === 1) {
        //     //     arrayStorage.push({...item, storageProduct: newSp});
        //     // } else {
        //     //     arrayNoStorage.push({...item, storageProduct: newSp});
        //     // }
        // } else {
        //     arrayNoStorage.push({...item, storageProduct: newSp});
        // }
    }

    const resultPositions = [];
    products.forEach((item, key) => {
        let storageProducts = item.storageProducts ?? [];
        if (storageProducts.length) {
            storageProducts = storageProducts.filter(x => ![1, 4, 5].includes(Number(x.status)));
            storageProducts.forEach((sp) => {
                const isOffShipper = sp.shipperModel && !Number(sp.shipperModel.status_crm) && !sp.storage; // это отключенный поставщик
                if (!isOffShipper) {
                    resultPositions.push({...item, storageProduct: prepareSpElement(sp, item), itemKey: key});
                }
            });
        } else {
            arrayNoStorage = [...arrayNoStorage, item];
        }
    });
    arrayStorage = [...arrayStorage, ...sortSPByProduct(resultPositions)]
    // arrayStorage.sort((a, b) => {
    //     const aPrice = Number.parseFloat(a.storageProduct?.price ?? 0);
    //     const bPrice = Number.parseFloat(b.storageProduct?.price ?? 0);
    //     return (bPrice - aPrice);
    // });
    // arrayStorage.sort((a) => {
    //     const aCount = Number.parseFloat(a.storageProduct?.count ?? 0);
    //     return aCount > 0 ? -1 : 1;
    // });
    // arrayNoStorage.sort((a, b) => {
    //     const aPrice = Number.parseFloat(a.storageProduct?.price ?? 0);
    //     const bPrice = Number.parseFloat(b.storageProduct?.price ?? 0);
    //     return (aPrice - bPrice);
    // });

    // newArray.sort((a, b) => {
    //     const storageA = a.storageProduct?.storage ?? null;
    //     const storageB = b.storageProduct?.storage ?? null;
    //
    //     const isStorageA = storageA ? 1 : 0;
    //     const isStorageB = storageB ? 1 : 0;
    //     return (isStorageB - isStorageA);
    // });
    return [...arrayStorage, ...arrayNoStorage];
}