import React, {useEffect, useMemo, useState} from 'react';
import {fetchSuggestClient, fetchTaskTypeTemplate} from '../../../../../api/query/directory';
import ErrorResponse from "../../../../../components/common/ErrorResponse";
import {appActions} from "../../../../../redux/toolkit/app/slice";
import {useDispatch} from "react-redux";
import {highlight} from "../../../../../helpers/product/highlight";
import {Box, Button, CircularProgress, ClickAwayListener, TextField, Tooltip} from "@mui/material";
import css from './NameSuggest.module.scss';
import useDebounce from "../../../../../helpers/hooks/useDebounce";


const Option = (props) => {
    const {searchValue, label} = props;
    const resultStr = highlight({filter: searchValue, str: label})
    return (
        <div>
            {resultStr}
        </div>
    );
};


const NameSuggest = ({
    initState,
    onChange,
    disabled = false,
    placeholder = '',
    params = {},
    getOptionLabel = (option) => `${option.name}`,
    onChangeInputText = () => null,
    template = null,
    listTemplates = [],
    readOnly = false,
}) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState(initState);
    const [openSearch, setOpenSearch] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [focus, setFocus] = useState(false);

    const fetchData = () => {
        let list = listTemplates;
        if (searchValue) {
            list = list.filter(x => x.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1);
        }
        setSearchResults(list);
        // fetchTaskTypeTemplate({taskTypeId: -1, s: searchValue})
        //     .then((response) => {
        //         setSearchResults(response.data);
        //     })
        //     .catch((error) => {
        //         const errorResponse = ErrorResponse(error.response);
        //         dispatch(appActions.setNotification({type: 'error', message: errorResponse}));
        //     });
    };

    const handlerOnFocus = () => {
        if (!openSearch && searchResults.length) {
            setOpenSearch(true);
        }
    };

    useEffect(() => {
        setOpenSearch(!!(focus && searchResults.length));
        setLoading(false);
    }, [searchResults]);

    const handleClickAway = () => {
        setOpenSearch(false);
    }

    useDebounce(() => {
        setLoading(true);
        fetchData();
    }, 0, [searchValue]);

    const onSelectElement = (item) => {
        onChange(item);
        handleClickAway();
    }

    const onChangeInput = (value) => {
        setSearchValue(value);
        onChangeInputText(value);
    }

    const onClickClear = () => {
        onSelectElement(null);
    }

    useEffect(() => {
        setSearchResults(listTemplates);
    }, [listTemplates]);

    useEffect(() => {
        if (focus) {
            handlerOnFocus();
        }
    }, [focus]);

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Box className={css.autoSuggestBox}>
                <div className={css.inputBox}>
                    <TextField
                        placeholder={placeholder}
                        value={template ? template.name : initState}
                        color="primary"
                        size="small"
                        type="text"
                        variant="outlined"
                        className={css.textField}
                        autoComplete="off"
                        onFocus={() => setFocus(true)}
                        onBlur={() => setFocus(false)}
                        onChange={(event) => onChangeInput(event.target.value)}
                        InputProps={{
                            readOnly: !!template || readOnly,
                            className: css.input,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={17} /> : null}
                                </React.Fragment>
                            ),
                        }}
                    />
                    {!!template && (
                        <>
                            <Tooltip title="Выбран шаблон" arrow placement="top">
                                  <span className={css.activeTemplate}>
                                    <i className="simple-icon-check" />
                                </span>
                            </Tooltip>
                            <Tooltip title="Удалить шаблон" arrow placement="top">
                                <Button className={css.buttonClear} onClick={onClickClear} size="small">
                                    <i className="icon-cross" />
                                </Button>
                            </Tooltip>
                        </>
                    )}
                </div>
                <Box className={`${css.searchResultWindow} ${openSearch ? css.open : ''}`}>
                    <div className={css.content}>
                        {
                            searchResults.map((item) => {
                                return (
                                    <div
                                        key={item.id}
                                        className={css.item}
                                        onClick={() => onSelectElement(item)}
                                    >
                                        <Option {...item} searchValue={searchValue} label={getOptionLabel(item)} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </Box>
            </Box>
        </ClickAwayListener>
    )
};

export default NameSuggest;
