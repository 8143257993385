/* eslint-disable no-unused-vars,no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import React from "react";
import {fetchMetaDefault} from "../../../constants/defaultCrm";

const initialState = {
    card: {
        deals: {
            data: {
                items: [],
                meta: fetchMetaDefault,
            },
            general: {
                data: null,
                loading: false
            },
            loading: false,
            reload: false,
        },
        files: {
            data: {
                items: [],
                meta: fetchMetaDefault,
            },
            loading: false,
            reload: false,
        }
    },
    list: {
        brandsFromDeals: {
            data: [],
            loading: false
        },
        data: {
            items: [],
            meta: fetchMetaDefault
        },
        loading: false,
        reload: false,
        filter: {
            selection: {},
        },
        selected: [],
    }
}

const slice = createSlice({
    name: "dealer",
    initialState,
    reducers: {
        setDeals: (state, { payload }) => {
            state.card.deals = {
                ...state.card.deals,
                data: {
                    items: !payload ? [] : payload.items,
                    // eslint-disable-next-line no-underscore-dangle
                    meta: !payload ? fetchMetaDefault : payload._meta
                },
                loading: false,
                reload: false,
            };
        },
        setDealsGeneral: (state, { payload }) => {
            state.card.deals.general = {
                ...state.card.deals.general,
                data: !payload ? null : payload,
                loading: false,
            };
        },
        fetchDeals: (state, { payload }) => {
            state.card.deals = {
                ...state.card.deals,
                loading: true,
                reload: false,
            };
        },
        fetchDealsGeneral: (state, { payload }) => {
            state.card.deals.general = {
                ...state.card.deals.general,
                loading: true,
            };
        },
        reloadDeals: (state, { payload }) => {
            state.card.deals.reload = payload;
        },
        setBrandsFromDeals: (state, { payload }) => {
            state.list.brandsFromDeals = {
                loading: false,
                data: !payload ? [] : payload,
            };
        },
        fetchBrandsFromDeals: (state, { payload }) => {
            state.list.brandsFromDeals.loading = true;
        },
        setListData: (state, { payload }) => {
            state.list = {
                ...state.list,
                data: {
                    ...state.list.data,
                    items: !payload ? [] : payload.items,
                    // eslint-disable-next-line no-underscore-dangle
                    meta: !payload ? fetchMetaDefault : payload._meta
                },
                loading: false,
                reload: false,
            };
        },
        fetchListData: (state, { payload }) => {
            state.list = {
                ...state.list,
                loading: true,
                reload: false,
            };
        },
        getFiles: (state, { payload }) => {
            state.card.files = {
                ...state.card.files,
                loading: true,
                reload: false,
            };
        },
        setFiles: (state, { payload }) => {
            state.card.files = {
                ...state.card.files,
                data: {
                    items: !payload ? [] : payload.items,
                    // eslint-disable-next-line no-underscore-dangle
                    meta: !payload ? fetchMetaDefault : payload._meta
                },
                loading: false,
                reload: false,
            };
        },
        reloadFiles: (state, { payload }) => {
            state.card.files.reload = true;
        },
        setListFilterSelection: (state, { payload }) => {
            state.list.filter = {
                ...state.list.filter,
                selection: payload ?? {},
            };
        },
        setListSelected: (state, { payload }) => {
            state.list = {
                ...state.list,
                selected: payload,
            };
        },
    }
})

export const dealerReducer = slice.reducer;
export const dealerActions = slice.actions;