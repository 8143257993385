import React from 'react';

const UpdateIcon = ({width="32", height="32"}) => (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className='hoverStroke' d="M29.5628 13.2559L28.1911 19.1827C27.0154 24.3012 24.692 26.3713 20.3251 25.946C19.6253 25.8892 18.8695 25.7616 18.0577 25.5631L15.7063 24.996C9.86978 23.5923 8.06424 20.6714 9.43589 14.7447L10.8075 8.80377C11.0875 7.59857 11.4234 6.54934 11.8433 5.68443C13.4809 2.25315 16.2662 1.33153 20.941 2.45166L23.2784 3.00463C29.1429 4.39416 30.9344 7.32917 29.5628 13.2559Z" stroke="#3A3A3A" strokeLinecap="round" strokeLinejoin="round"/>
        <path className='hoverStroke' d="M20 26.0381C19.1464 26.627 18.0725 27.1178 16.7646 27.5525L14.5893 28.2817C9.12346 30.0765 6.246 28.5761 4.46996 23.0092L2.70768 17.4704C0.945407 11.9034 2.40479 8.95873 7.87059 7.16386L10.0459 6.4347C10.6104 6.2524 11.1473 6.09816 11.6567 6C11.2437 6.85537 10.9133 7.89303 10.6379 9.08494L9.28868 14.9603C7.93943 20.8217 9.71548 23.7103 15.4566 25.0986L17.7696 25.6595C18.5682 25.8558 19.3116 25.982 20 26.0381Z" stroke="#3A3A3A" strokeLinecap="round" strokeLinejoin="round"/>
        <path className='hoverStroke' d="M16.8516 11.375L23.3182 13.015" stroke="#3A3A3A" strokeLinecap="round" strokeLinejoin="round"/>
        <path className='hoverStroke' d="M15.5469 16.5312L19.4135 17.5179" stroke="#3A3A3A" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export default UpdateIcon;