import React, {useContext} from 'react'
import {NavLink} from "react-router-dom";
import {DesktopContext} from "../../../utils/hoc/withContext";
import css from "./nav.module.scss";

const SuperPageNav = ({children, href}) => {
    
    const desktop = useContext(DesktopContext)
    return (
        <>
            {desktop ?
                <NavLink to={href} className={css.counterContainer}>
                    {children}
                </NavLink>
                :
                <div className={css.counterContainer}>
                    {children}
                </div>
            }
        </>
    )
}

export default SuperPageNav