import {put, call, takeEvery} from 'redux-saga/effects'

import {statisticsActions} from "./slice";
import ErrorResponse from "../../../components/common/ErrorResponse";
import {
    fetchStatisticsDealers,
    fetchStatisticsDealersProducts,
    fetchStatisticsPublishedProducts,
    fetchStatisticsSupplier,
    fetchStatisticsSuppliersStorageProductCounts
} from "../../../api/query/statistics";
import {
    fetchStatisticsStorageTime,
    fetchStatisticsStorage,
    fetchStatisticsStorageTimeModerator,
    fetchStatisticsTask,
    fetchStatisticsStorageModerator
} from "../../../api/query/statisticsManager";
import {appActions} from "../app/slice";

function* fetchDealersData({payload}) {
    try {
        const getData = yield call(fetchStatisticsDealers, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(statisticsActions.setDataDealers(getData.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(statisticsActions.setDataDealers(null));
    }
}

function* fetchDealersProductsData({payload}) {
    try {
        const getData = yield call(fetchStatisticsDealersProducts, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(statisticsActions.setDataDealersProducts(getData.data));
        }
    } catch (error) {
        console.log(error);
        ErrorResponse(error.response);
        yield put(statisticsActions.setDataDealersProducts(null));
    }
}

function* fetchModeratorsOrderData({payload}) {
    try {
        const getData = yield call(fetchStatisticsTask, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(statisticsActions.setDataManagersTask(getData.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(statisticsActions.setDataManagersTask(null));
    }
}

function* fetchModeratorsStorageData({payload}) {
    try {
        const getData = yield call(fetchStatisticsStorage, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(statisticsActions.setDataManagersStorage(getData.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(statisticsActions.setDataManagersStorage(null));
    }
}

function* fetchModeratorsStorageModeratorData({payload}) {
    try {
        const getData = yield call(fetchStatisticsStorageModerator, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(statisticsActions.setDataManagersStorageModerator(getData.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(statisticsActions.setDataManagersStorageModerator(null));
    }
}

function* fetchModeratorsStorageTimeData({payload}) {
    try {
        const getData = yield call(fetchStatisticsStorageTime, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(statisticsActions.setDataManagersStorageTime(getData.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(statisticsActions.setDataManagersStorageTime(null));
    }
}

function* fetchModeratorsStorageTimeModeratorData({payload}) {
    try {
        const getData = yield call(fetchStatisticsStorageTimeModerator, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(statisticsActions.setDataManagersStorageTimeModerator(getData.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(statisticsActions.setDataManagersStorageTimeModerator(null));
    }
}

function* fetchDataPublishedProducts({payload}) {
    try {
        const getData = yield call(fetchStatisticsPublishedProducts, payload);
        const codeResponse = Number(getData.status);
        if (codeResponse === 200) {
            yield put(statisticsActions.setDataPublishedProducts(getData.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(statisticsActions.setDataPublishedProducts(null));
    }
}

function* fetchSuppliersData({payload}) {
    try {
        const getData = yield call(fetchStatisticsSupplier, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(statisticsActions.setDataSuppliers(getData.data));
        }
    } catch (error) {
        const errorResponse = ErrorResponse(error.response);
        yield put(statisticsActions.setDataSuppliers(null));
        yield put(appActions.setNotification({type: 'error', message: errorResponse}));
    }
}

function* fetchSuppliersProdCounts({payload}) {
    try {
        const getData = yield call(fetchStatisticsSuppliersStorageProductCounts);
        const codeResponse = Number.parseInt(getData.status, 10);

        if (codeResponse === 200) {
            yield put(statisticsActions.setSuppliersStorageProductCounts({data: getData.data}));
        }
    } catch (error) {
        const errorResponse = ErrorResponse(error.response);
        yield put(statisticsActions.setSuppliersStorageProductCounts({data: [], error: true, loading: false}));
        yield put(appActions.setNotification({type: 'error', message: errorResponse}));
    }
}

// eslint-disable-next-line import/prefer-default-export
export function* statisticsSaga() {
    yield takeEvery(statisticsActions.fetchDataDealers.type, fetchDealersData);
    yield takeEvery(statisticsActions.fetchDataDealersProducts.type, fetchDealersProductsData);
    yield takeEvery(statisticsActions.fetchDataManagersTask.type, fetchModeratorsOrderData);
    yield takeEvery(statisticsActions.fetchDataManagersStorage.type, fetchModeratorsStorageData);
    yield takeEvery(statisticsActions.fetchDataManagersStorageModerator.type, fetchModeratorsStorageModeratorData);
    yield takeEvery(statisticsActions.fetchDataManagersStorageTime.type, fetchModeratorsStorageTimeData);
    yield takeEvery(statisticsActions.fetchDataManagersStorageTimeModerator.type, fetchModeratorsStorageTimeModeratorData);
    yield takeEvery(statisticsActions.fetchDataPublishedProducts.type, fetchDataPublishedProducts);
    yield takeEvery(statisticsActions.fetchDataSuppliers.type, fetchSuppliersData);
    yield takeEvery(statisticsActions.fetchSuppliersStorageProductCounts.type, fetchSuppliersProdCounts);
}
