/* eslint-disable no-unused-vars */
import React from "react";
import ErrorBug from "../views/errorBug";

const ErrorFallback = ({error, resetErrorBoundary}) => {
    return (
        <>
            {/* <div role="alert"> */}
            {/*    <p>Something went wrong:</p> */}
            {/*    <pre>{error.message}</pre> */}
            {/*    /!* eslint-disable-next-line react/button-has-type *!/ */}
            {/*    <button onClick={resetErrorBoundary}>Try again</button> */}
            {/* </div> */}
            <ErrorBug />
        </>
    )
}
export default ErrorFallback;