/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line no-unused-vars,import/prefer-default-export
import {numberFormat} from "../../components/finance/number_format";

export const getLongNameDocument = (document) => {
    const longName = [document.doc_id];
    if(document.client){
        longName.push(document.client.name);
    }
    if(document.products){
        let sumDocument = 0;
        document.products.forEach(item => {
            const currency = item.currencyFinish.course ?? 1;
            let price = item.price_finish;
            price /= currency;
            sumDocument += price * item.count;
        });
        if(sumDocument){
            longName.push(`${numberFormat(sumDocument, 2, '.', ' ')} руб.`);
        }
    }
    return `ST${longName.join(' - ')}`;
}