// eslint-disable-next-line import/named

import {
  USER_CARD_FETCH,
  USER_CARD_LOADING,
  USER_CARD_RELOAD, USER_CARD_SET,
  USER_CHANGE_LOADING,
  USER_CHANGE_SUCCESS
} from '../actions';

const INIT_STATE = {
  change: {
    loading: false,
    success: false,
  },
  card: {
    data: {},
    loading: false,
    reload: false,
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_CHANGE_LOADING:
      return {
        ...state,
        change: { ...state.change, loading: action.payload },
      };
    case USER_CHANGE_SUCCESS:
      return {
        ...state,
        change: { ...state.change, success: action.payload },
      };
    case USER_CARD_LOADING:
      return {
        ...state,
        card: { ...state.card, loading: action.payload },
      };
    case USER_CARD_RELOAD:
      return {
        ...state,
        card: { ...state.card, reload: action.payload },
      };
    case USER_CARD_FETCH:
      return {
        ...state,
        card: { ...state.card, reload: false },
      };
    case USER_CARD_SET:
      return {
        ...state,
        card: { ...state.card, data: action.payload },
      };
    default:
      return { ...state };
  }
};
