import React from 'react';
import '../../assets/css/sass/custom/photoPerson.scss';
import UniqID from "../common/UniqID";

const defaultSrc = './assets/img/profiles/profile.svg';

const PhotoContainer = ({size, color, alt, src, className = []}) => {
    return (
        <>
            <div className={`photoPerson size-${size} ${className.join(' ')}`}
                 style={{border: `2px solid ${color}`}}>
                <img
                    src={src}
                    alt={alt}
                    className="img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall"
                />
            </div>
        </>
    );
}
const PhotoManagerNew = (props) => {
    const { size = 'standard', data, className = []} = props;
    if (!data) {
        return <PhotoContainer
            size={size}
            color="#ed7117"
            alt={`manager_${UniqID()}`}
            src={defaultSrc}
            className={className}
        />
    }
    const {state, avatarData} = data;
    const searchPhoto = avatarData?.path ?? defaultSrc;
    return (
        <PhotoContainer
            size={size}
            color={state ? state.color : 'transparent'}
            alt={`manager_${data.id}`}
            src={searchPhoto}
            className={className}
        />
    );
};
export default PhotoManagerNew;
