import React, {useEffect, useState} from 'react'
import {useLocation, useHistory } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import {BottomNavigation, BottomNavigationAction} from "@mui/material";
import {useSelector} from "react-redux";
import css from './Navigation.module.scss'
import ModalElem from "../../../components/ModalElem/ModalElem";
import PageNavItem from "../pageNav/PageNavItem";
import {getParentItem} from "../../../helpers/menu/getParentItem";
import menuItems from "../../../constants/menu";
import IntlMessages from "../../../helpers/IntlMessages";

const Navigation = () => {

    const [open, setOpen] = useState([]);
    const [toggleModal, setToggleModal] = useState(false)
    const history = useHistory();
    const [currentPage, setCurrentPage] = useState('');

    const handleClose = () => {
        setToggleModal(false)
        setOpen([])
    }

    const handleLocation = (href) => {
        history.push(href)
        setOpen([])
    }

    const location = useLocation();

    useEffect(() => {
        handleClose()
    }, [location.pathname])

    const handleOpen = (el) => {
        if (el.children?.length) {
            if (JSON.stringify(el.children) !== JSON.stringify(open)) {
                setOpen(el.children)
                setToggleModal(true)
            } else {
                setToggleModal(v => !v)

            }
        }
        else
            handleLocation(el.to)
    }

    const currentUser = useSelector((state) => state.authUser.currentUser)

    if (!currentUser) return <></>
    const currentItemId = getParentItem(location)

    if (!currentItemId) return <></>
    const currentItem = menuItems.find(x => x.id === currentItemId)
    if (typeof currentItem === 'undefined') return <></>

    const redirectToFilter = (event) => {
        event.stopPropagation();
        event.preventDefault();
        const href = event.target.getAttribute('data-href');
        if (href) {
            history.push(href);
        }
    };

    const checkAccess = (menuItem) => {
        if (!currentUser) return false;
        const {permissions} = menuItem;
        let access = true;
        if (typeof permissions === 'undefined' || !permissions) return access;

        if (!currentUser.permissions.filter(x => permissions.includes(x)).length) {
            access = false;
        }
        return access;
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        menuItems.forEach(nav => {
            if (nav.to === location.pathname) {
                setCurrentPage(nav.to)
            }
        })
    }, [location.pathname])

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        menuItems.forEach(nav => {
            if (nav.to === location.pathname) {
                setCurrentPage(nav.to)
            }
        })

    }, [])

    return (
        <div>
            <BottomNavigation
                showLabels
                className={css.navigation}
                onChange={(event, newValue) => {
                    setCurrentPage(newValue);
                }}
                value={currentPage}
            >
                {menuItems.map(el => {
                    // eslint-disable-next-line no-unused-expressions
                    return <BottomNavigationAction key={el.id} onClick={() => {handleOpen(el) }} className={css.navigationElem} label={<IntlMessages id={el.label}/>} value={el.to} icon={el.icon()} />
                })}
            </BottomNavigation>
            <ModalElem check={toggleModal && !!open.length} onClose={handleClose}>
                <div className={css.pageItems} id="pageNavMobile">
                    {toggleModal && open.map(x => {
                        return <PageNavItem
                            onClick={handleClose}
                            key={x.id}
                            menuItem={x}
                            checkAccess={checkAccess}
                            redirectToFilter={redirectToFilter}
                        />
                    })}
                </div>
            </ModalElem>
        </div>
    )
}

export default Navigation