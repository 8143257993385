import React, {useContext} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import TopNav from '../containers/navs/Topnav';
import Sidebar from '../containers/navs/Sidebar/Sidebar';
import Footer from '../containers/navs/Footer';
import {DesktopContext} from "../utils/hoc/withContext";
import PageNav from "../containers/navs/PageNav";

const AppLayout = ({containerClassnames, children, history}) => {

    const desktop = useContext(DesktopContext)

    return (
        <div id="app-container" className={containerClassnames}>
            <TopNav history={history}/>
            {desktop && <Sidebar/>}
            <main>
                {!desktop && <PageNav />}
                <div className="container-fluid">{children}</div>
                <Footer/>
            </main>
        </div>
    );
};
const mapStateToProps = ({menu}) => {
    const {containerClassnames} = menu;
    return {containerClassnames};
};
const mapActionToProps = {};

export default withRouter(
    connect(mapStateToProps, mapActionToProps)(AppLayout)
);
