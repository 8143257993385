import React from "react";

export const escapeRegExp = (string: string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

export const highlight = (props) => {
    const { filter, str } = props;
    if (!filter || typeof str === 'undefined') return str;
    const regexp = new RegExp(escapeRegExp(filter.trim()), 'ig');
    const matchValue = str.match(regexp);
    if (matchValue) {
        return str.split(regexp).map((s, index, array) => {
            if (index < array.length - 1) {
                const c = matchValue.shift();
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <React.Fragment key={`${s}_${index}`}>{s}<span className='highlight'>{c}</span></React.Fragment>
                )
            }
            return s
        })
    }
    return str;
}
