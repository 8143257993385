// eslint-disable-next-line no-unused-vars
/* eslint-disable no-use-before-define */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {CRM_DOCUMENT_PRODUCTS_GET, CRM_DOCUMENTS_FETCH_DATA} from '../../actions';
import {getDocumentProducts, getDocuments} from '../../../api/query/document';
import {documentListLoading, documentListSetData, documentProductsSet} from './actions';
import ErrorResponse from "../../../components/common/ErrorResponse";
import {appActions} from "../../toolkit/app/slice";

export function* watchDocuments() {
    yield takeEvery(CRM_DOCUMENTS_FETCH_DATA, loadDocuments);
}
function* loadDocuments({ payload }) {
    try {
        yield put(documentListLoading(true));
        const getData = yield call(getDocuments, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(documentListSetData(getData.data));
        }
        yield put(documentListLoading(false));
    } catch (error) {
        const errorResponse = ErrorResponse(error.response);
        yield put(appActions.setNotification({type: 'error', message: errorResponse}));
        yield put(documentListLoading(false));
    }
}

export function* watchProducts() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(CRM_DOCUMENT_PRODUCTS_GET, loadProducts);
}
function* loadProducts({ payload }) {
    try {
        const getData = yield call(getDocumentProducts, payload);
        const codeResponse = Number(getData.status);
        if (codeResponse === 200) {
            yield put(documentProductsSet(getData.data.items));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(documentProductsSet([]));
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchDocuments),
        fork(watchProducts),
    ]);
}
