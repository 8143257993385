import React, {useContext, useEffect, useMemo} from 'react';
import FormError  from '../../../../components/validate/FormError';
import {FormGroup} from "reactstrap";
import {Grid} from "@mui/material";
import Brands from "../fields/Brands";
import {ModalCreateTaskContext} from "../index";
import Analog from "../fields/Analog";
import {usedBrands} from "../../../../constants/defaultValues";
import {getParentType} from "../helper";

export const validate = (data) => {
    const errors = {};
    if (!data.brand) {
        errors.brand = 'Выберите бренд';
    }
    return errors;
}

const Template50 = () => {
    const {data, setData, errors, onChangeList, taskTypes, changedData} = useContext(ModalCreateTaskContext);
    const {onChangeBrand, onChangeAnalog} = onChangeList;

    const excludeBrandsId = Object.values(usedBrands).map(x => x.id);

    const isDisabledAnalog = useMemo(() => {
        let disabled = false;
        if (data.id && !changedData.taskTypeTemplate) {
            disabled = true;
        }
        return disabled;
    }, [data.id, changedData.taskTypeTemplate]);

    const setDepartment = (value) => {
        const typeDepartments = data.taskType?.departments ?? [];
        if (!typeDepartments) return true;
        let managerGroup = null;
        if (value) {
            managerGroup = typeDepartments.find(x => x.id === 6);
        } else {
            managerGroup = typeDepartments.find(x => x.id === 26);
        }
        setData('manager', managerGroup ? {
            ...managerGroup,
            isGroup: true,
            id: `G-${managerGroup.id}`
        } : null)
    }

    const onChangeAnalogHandler = (value) => {
        onChangeAnalog(value);
        setDepartment(value);
    }

    return (
        <>
            <Grid item xs={2}>
                <FormGroup>
                    <Brands
                        value={data.brand}
                        setValue={onChangeBrand}
                        exclude={excludeBrandsId}
                    />
                    <FormError errors={errors} name="brand" />
                </FormGroup>
            </Grid>
            {/* STO1-1983: убираем "аналог" */}
            {/* <Grid item xs={2}>
                <FormGroup>
                    <Analog
                        value={data.analog}
                        setValue={onChangeAnalogHandler}
                        disabled={isDisabledAnalog}
                    />
                    <FormError errors={errors} name="analog" />
                </FormGroup>
            </Grid> */}
        </>
    );
};
export default Template50;
