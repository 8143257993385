import React from 'react';
import moment from 'moment';
import {Tooltip} from "@mui/material";
import PhotoManagerNew from "../profile/photoManagerNew";
import css from './NotificationItem.module.scss'
import {getColor} from "./helpers";
import {isJson} from "../validate/JSON";

const NotificationItem = ({ notification, handleClick, mouseMiddleButton, contactedMe = false }) => {
    const creatorName = `${notification.creator.last_name} ${notification.creator.first_name}`;
    const data = isJson(notification.data) ? JSON.parse(notification.data) : notification.data;

    const onClickIndicator = (e) => {
        e.stopPropagation();
        e.preventDefault();
        handleClick(notification.id, true);
    }

    const message = notification.data.message ?? '';
    let text = message.replace(creatorName, '');
    const commentMessage = notification.data.comment_message ?? '';
    if (commentMessage) {
        text = commentMessage;
    }

    const mouseDownHandler = (event) => {
        if( event.button === 1 ) {
            mouseMiddleButton(notification.id);
        }
    }

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
            className={`${css.notification}`}
            onClick={() => handleClick(notification.id)}
            onMouseDown={mouseDownHandler}
        >
            <PhotoManagerNew
                size="md"
                data={notification?.creator ?? null}
            />
            <div className={css.text}>
                <div className={css.head}>
                    <Tooltip title={creatorName} arrow placement="top">
                        <span className={`${css.creator} overflow-ellipsis`}>
                            {creatorName}:
                        </span>
                    </Tooltip>
                    <span className={css.date}>{moment(notification.date_create).format('DD.MM HH:mm')}</span>
                </div>
                <div className={css.body} style={{color: getColor(data.type_id)}}>
                    <div className={css.commentText}>
                        {text}
                    </div>
                    <div className={css.indicatorContainer}>
                        {contactedMe && (
                            <div className={`${css.circle} ${notification.status ? css.read : ''}`} onClick={onClickIndicator}>
                                <span className={`${css.button} ${css.forMe}`} />
                            </div>
                        )}
                        <div className={`${css.circle} ${notification.status ? css.read : ''}`} onClick={onClickIndicator}>
                            <span className={css.button} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotificationItem;