import React from "react";
import css from "../Comments.module.scss";
import {ViewManager} from "../../task/Manager";

const SearchUserItem = ({item, index, onClickItem}) => {
    const selected = item.selected ?? false;

    return (
        <div role="button"
             data-index={index}
             onKeyDown={() => null}
             tabIndex="0"
             className={`${css.searchResultUserItem} ${css.searchItem} ${selected ? css.selected : ''}`}
             data-id={item.id}
             onClick={() => onClickItem(item.id)}
        >
            <div className="row ml-0 mr-0">
                <div className="col">
                    <ViewManager data={item} field="search_user_comment" showTooltip={false} />
                </div>
            </div>
        </div>
    );
};
export default SearchUserItem