import React from "react";
import {priceSegmentOptions} from "../params";
import SelectSync from "../../../../components/select/SelectSync";

const PriceSegment = (props) => {
    const {setValue, value} = props;
    return (
        <>
            <div className="font-weight-bold">Ценовой сегмент:</div>
            <div className="mt-2">
                <SelectSync
                    initState={value}
                    onChange={setValue}
                    loadOptions={priceSegmentOptions}
                    placeholder="Выберите значение"
                    disabled={false}
                    isClearable={true}
                />
            </div>
        </>
    )
};
export default PriceSegment;