import {sendWelcomeMail as apiSendWelcomeMail} from "../../../../../api/query/user";

export const sendWelcomeMail = async (data) => {
    let result = {
        type: '',
        data: null,
    }
    await apiSendWelcomeMail(data)
        .then(response => {
            result = {
                type: 'success',
                data: response.data,
            }
        }).catch(error => {
            const message = error?.response?.data?.message ?? 'Что-то пошло не так';
            result = {
                type: 'error',
                data: message,
            };
        });
    return result;
}

export const prepareEmails = (emails) => {
    const result = [];
    emails.forEach(x => {
        result.push({
            id: x.id,
            name: x.email
        })
    });
    return result;
}