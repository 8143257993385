/* eslint-disable no-unused-vars,no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import React from "react";
import {defaultQueryList, fetchMetaDefault} from "../../../constants/defaultCrm";

const initialState = {
    list: {
        loading: false,
        data: {
            items: [],
            meta: fetchMetaDefault
        },
        reload: false,
        more: {
            data: [],
            loading: false
        }
    },
    view: {
        data: undefined,
        loading: false,
        tasks: {
            reload: false,
            loading: false,
            data: {
                items: [],
                meta: fetchMetaDefault
            },
        },
        logs: {
            reload: false,
            loading: false,
            data: {
                items: [],
                meta: fetchMetaDefault
            },
        },
    },
    modalCreate: {
        status: false,
    }
}

const slice = createSlice({
    name: "target",
    initialState,
    reducers: {
        reloadTasks: (state, { payload }) => {
            state.view.tasks.reload = true;
        },
        setTasks: (state, { payload }) => {
            state.view.tasks = {
                ...state.view.tasks,
                loading: false,
                data: payload || {
                    items: [],
                    meta: fetchMetaDefault,
                },
            };
        },
        setView: (state, { payload }) => {
            state.view = {
                ...state.view,
                loading: false,
                data: payload,
            };
        },
        getView: (state, { payload }) => {
            state.view.loading = true;
        },
        getTasks: (state, { payload }) => {
            state.view.tasks = {
                ...state.view.tasks,
                loading: true,
                reload: false,
            };
        },
        setCountItemsData: (state, { payload }) => {
            state.list.countItems = {
                loading: false,
                data: !payload ? [] : payload,
            };
        },
        resetCountComments: (state, { payload }) => {
            state.view.tasks.data.items = state.view.tasks.data.items.map(x => x.id === payload.id ? {
                ...x,
                commentsNewCount: 0,
                commentsMyNewCount: 0,
            } : x)
        },
        modalCreateStatus: (state, { payload }) => {
            state.modalCreate.status = payload;
        },
        getList: (state, { payload }) => {
            state.list = {
                ...state.list,
                loading: true,
                reload: false,
            };
        },
        getListMoreData: (state, { payload }) => {
            state.list.more = {
                ...state.list.more,
                loading: true,
            };
        },
        setListMoreData: (state, { payload }) => {
            state.list.more = {
                ...state.list.more,
                loading: false,
                data: payload || [],
            };
        },
        setList: (state, { payload }) => {
            state.list = {
                ...state.list,
                loading: false,
                data: {
                    ...state.list.data,
                    items: !payload ? [] : payload.items,
                    // eslint-disable-next-line no-underscore-dangle
                    meta: !payload ? fetchMetaDefault : payload._meta
                },
            };
        },
        reloadLogs: (state, { payload }) => {
            state.view.logs.reload = true;
        },
        setLogs: (state, { payload }) => {
            state.view.logs = {
                ...state.view.logs,
                loading: false,
                data: {
                    ...state.view.logs,
                    items: !payload ? [] : payload.items,
                    // eslint-disable-next-line no-underscore-dangle
                    meta: !payload ? fetchMetaDefault : payload._meta
                },
            };
        },
        getLogs: (state, { payload }) => {
            state.view.logs = {
                ...state.view.logs,
                loading: true,
                reload: false,
            };
        },
    }
})

export const targetReducer = slice.reducer;
export const targetActions = slice.actions;