import {useHistory, useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import React, {useEffect} from "react";
import {taskActions} from "../../redux/toolkit/task/slice";
import {changeQueryUrl} from "../url";

const OpenTask = (props) => {
    const {name = 'openTask', data} = props;
    const location = useLocation();
    const locationParams = new URLSearchParams(location.search);
    const dispatch = useDispatch();
    const history = useHistory();
    const taskId = locationParams.get(name);

    const openTask = (id) => {
        dispatch(taskActions.setModalCreate({
            status: true,
            data: {
                id,
                changeClient: !id,
                callbackClose: () => {
                    history.replace({
                        search: changeQueryUrl(location, {'-openTask': 0}).toString(),
                    });
                },
                ...data,
            }
        }))
    };

    useEffect(() => {
        if (taskId !== null) {
            openTask(taskId);
        }
    }, [taskId]);

    return <></>;
};
export default OpenTask;