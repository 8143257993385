/* eslint-disable react/no-array-index-key */
import React, {useState} from 'react';
import {ArrowContainer, Popover} from "react-tiny-popover";
import {Tooltip} from "@mui/material";
import ModalOffModeDuty from "../modal/ModalOffModeDuty";
import {watchEnd} from "../../api/query/crm";
import ErrorResponse from "../../components/common/ErrorResponse";

const TopnavModeDuty = () => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [modalEndMode, setModalEndMode] = useState(false);
    const onClickOutside = () => {
        setPopoverOpen(false);
    };

    const onClickFinish = () => {
        watchEnd().then(() => {
            setModalEndMode(false);
        }).catch(error => {
            ErrorResponse(error.response);
        })
    };

    return (
        <>
            <div className="d-inline-block mode-duty">
                <Popover
                    isOpen={popoverOpen}
                    positions={['bottom', 'top']}
                    align="center"
                    onClickOutside={onClickOutside}
                    parentElement={document.getElementById('navbar')}
                    content={({ position, childRect, popoverRect }) => (
                        <ArrowContainer
                            position={position}
                            childRect={childRect}
                            popoverRect={popoverRect}
                            className='popover-arrow-container'
                            arrowClassName='popover-arrow'
                        >
                            <div className="popover-arrow-container__body">
                                <p className="text-center mb-0">Вы находитесь в режиме дежурного</p>
                                <div className="mt-2 d-flex align-items-center justify-content-center">
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary btn-xs"
                                        onClick={() => setModalEndMode(true)}
                                    >Завершить</button>
                                </div>
                            </div>
                        </ArrowContainer>
                    )}
                >
                    <Tooltip title='Режим дежурного' placement='left'>
                        <button
                            type="button"
                            className="btn mode-duty__button mr-2"
                            onClick={() => setPopoverOpen(!popoverOpen)}
                            id="tooltip_mode_duty"
                        >
                            <i className="iconsminds-monitor---phone" />
                        </button>
                    </Tooltip>
                </Popover>
            </div>
            <ModalOffModeDuty isShow={modalEndMode} changeShow={setModalEndMode} onClickFinish={onClickFinish} />
        </>
    );
};

export default TopnavModeDuty;
