import React from "react";
import css from "./Topnav.module.scss";

const TopNavRatesItem = (item) => {
    const toRub = 1 / item.course;
    const id = Number.parseInt(item.id, 10);

    const Icon = () => {
        switch (id) {
            case 3:
                return <span className={css.rateIcon}>$</span>;
            case 2:
                return <span className={css.rateIcon}>€</span>;
            case 4:
                return <span className={css.rateIcon}>₺</span>;
            case 5:
                return <span className={css.rateIcon}>¥</span>;
            case 6:
                return <span className={css.rateIcon}>₸</span>;
            default:
                return <></>;
        }
    };

    return (
        <>
            <div className={css.rate} title={item.shortname}>
                <div className={css.rateName}>
                    <Icon />
                    <span className={css.rateCurrency}>{item.shortname}</span>
                </div>
                <span className={css.rateValue}>
                        {toRub.toFixed(4)} &#8381;
                    </span>
            </div>
        </>
    );
};

export default TopNavRatesItem