import {sendWelcomeMail} from "../../containers/crm/order/card/contact/helpers";
import {appActions} from "../../redux/toolkit/app/slice";

export const sendLogin = async (emailId, contact, setModalSelectEmailStatus, dispatch, reloadOtherData, type_id = 1) => {
    const params = {
        user_id: contact.id,
        type_id: type_id,
        email_id: emailId,
    };
    const {type: resultType, data: resultData} = await sendWelcomeMail(params);
    if (resultType === 'success') {
        setModalSelectEmailStatus(false);
        dispatch(appActions.setNotification({type: 'success', message: 'Письмо отправлено'}));
        reloadOtherData();
        return true;
    }
    dispatch(appActions.setNotification({type: 'error', message: resultData}));
    return true;
}

// eslint-disable-next-line consistent-return,import/prefer-default-export
export const onClickSendLogin = async (countEmails, contact, confirm, setModalSelectEmailStatus, dispatch, reloadOtherData, typeId) => {
    if (countEmails === 1) {
        const isConfirmed = await confirm({
            title: 'Вы действительно хотите отправить пользователю данные для входа?',
            confirmButtonText: `Отправить`,
            closeButtonText: `Отмена`,
        });
        if (!isConfirmed) return false;
        await sendLogin(contact.emails[0].id, contact, setModalSelectEmailStatus, dispatch, reloadOtherData, typeId);
    } else {
        setModalSelectEmailStatus(true);
    }
}