import React from 'react';

const RankGreenIcon = ({width="24", height="24"}) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.64973 23.6286C4.74494 23.6763 4.84622 23.6982 4.94591 23.6982C5.19066 23.6982 5.42538 23.5637 5.54169 23.33C6.16595 22.0775 7.16815 21.025 8.3816 20.3255C8.90907 20.6175 9.4798 20.8477 10.0843 21.0047C10.1399 21.0197 10.1964 21.0265 10.252 21.0265C10.5477 21.0265 10.8172 20.828 10.895 20.5281C10.9876 20.1726 10.7737 19.8094 10.4187 19.7174C7.77606 19.0306 5.93044 16.6419 5.93044 13.9083C5.93044 10.5989 8.62239 7.90646 11.9318 7.90646C15.2416 7.90646 17.9349 10.5987 17.9349 13.9083C17.9349 15.9776 16.8676 17.881 15.1554 18.972C15.1462 18.977 15.1375 18.9834 15.128 18.9887C14.8874 19.1403 14.6345 19.2767 14.3695 19.3943C14.034 19.5433 13.8826 19.9368 14.0322 20.2723C14.1809 20.6075 14.5739 20.7594 14.9099 20.6096C15.1053 20.5228 15.2936 20.4258 15.478 20.3237C16.7805 21.0719 17.8297 22.2171 18.4468 23.5898C18.5579 23.8369 18.8 23.9828 19.054 23.9828C19.1449 23.9828 19.2375 23.9638 19.3261 23.924C19.6605 23.7734 19.8106 23.3799 19.66 23.0449C19.0141 21.6073 17.9679 20.382 16.67 19.5021C18.2878 18.1299 19.2647 16.0947 19.2647 13.9078C19.2647 9.86496 15.9756 6.57617 11.9318 6.57617C7.88947 6.57617 4.60015 9.86496 4.60015 13.9078C4.60015 16.1184 5.59233 18.14 7.1969 19.5003C5.99373 20.3166 5.00024 21.4345 4.35118 22.7363C4.1874 23.065 4.32112 23.4645 4.64973 23.6286Z" fill="#3A3A3A"/>
            <path d="M8.8947 15.8016C9.31088 16.1281 10.1063 16.511 11.0476 16.511C11.6972 16.511 12.4158 16.3288 13.1269 15.819C13.4252 15.6046 13.4937 15.1889 13.2796 14.8906C13.0652 14.5924 12.65 14.5241 12.351 14.7382C11.0228 15.6913 9.84574 14.8529 9.71308 14.7527C9.42402 14.5288 9.00837 14.58 8.78288 14.8674C8.55607 15.1568 8.60565 15.5748 8.8947 15.8016Z" fill="#3A3A3A"/>
            <path d="M9.97021 12.3919C9.97021 11.9607 9.61944 11.6094 9.18823 11.6094C8.75702 11.6094 8.40625 11.9607 8.40625 12.3919C8.40625 12.8234 8.75702 13.1741 9.18823 13.1741C9.6197 13.1741 9.97021 12.8234 9.97021 12.3919Z" fill="#3A3A3A"/>
            <path d="M13.6353 12.3919C13.6353 11.9607 13.2848 11.6094 12.8533 11.6094C12.4216 11.6094 12.0703 11.9607 12.0703 12.3919C12.0703 12.8234 12.4216 13.1741 12.8533 13.1741C13.2848 13.1741 13.6353 12.8234 13.6353 12.3919Z" fill="#3A3A3A"/>
            <path d="M7.86022 4.25815C7.97283 4.50026 8.2131 4.64294 8.46391 4.64294C8.55807 4.64294 8.65327 4.6229 8.74374 4.5807C9.0771 4.42615 9.2211 4.03054 9.06681 3.69745L8.20571 1.84575C8.0509 1.51266 7.65371 1.36839 7.32246 1.52294C6.9891 1.67775 6.8451 2.07309 6.99938 2.40646L7.86022 4.25815Z" fill="#3A3A3A"/>
            <path d="M8.52344 5.58775C8.52344 5.76076 8.59438 5.93351 8.71887 6.05773C8.84282 6.18195 9.01583 6.25289 9.18858 6.25289C9.36133 6.25289 9.5346 6.18168 9.65882 6.05773C9.78225 5.93351 9.85372 5.76076 9.85372 5.58775C9.85372 5.41052 9.78225 5.24199 9.65882 5.11777C9.40986 4.8696 8.96651 4.8696 8.71887 5.11777C8.59438 5.24226 8.52344 5.41052 8.52344 5.58775Z" fill="#3A3A3A"/>
            <path d="M14 5.65611C14 5.82912 14.0709 6.00187 14.1954 6.12609C14.3194 6.25031 14.4924 6.32125 14.6651 6.32125C14.8379 6.32125 15.0112 6.25004 15.1354 6.12609C15.2588 6.00187 15.3303 5.82912 15.3303 5.65611C15.3303 5.47888 15.2588 5.31035 15.1354 5.18613C14.8864 4.93796 14.4431 4.93796 14.1954 5.18613C14.0709 5.31062 14 5.47888 14 5.65611Z" fill="#3A3A3A"/>
            <path d="M11.7027 4.92266C11.5788 5.04661 11.5078 5.21962 11.5078 5.39264C11.5078 5.56565 11.5788 5.73839 11.7027 5.86261C11.8267 5.98683 11.9949 6.05751 12.1727 6.05751C12.3457 6.05751 12.519 5.98657 12.6424 5.86261C12.7669 5.73839 12.8376 5.56565 12.8376 5.39264C12.8376 5.21962 12.7666 5.04661 12.6424 4.92266C12.3945 4.67422 11.9467 4.67422 11.7027 4.92266Z" fill="#3A3A3A"/>
            <path d="M11.7626 2.5274C12.1268 2.48046 12.3839 2.14709 12.3367 1.78287C12.314 1.6038 12.3433 1.48591 12.4245 1.43237C12.5335 1.35984 12.7576 1.35061 12.9401 1.45927C13.0994 1.55316 13.1688 1.70112 13.1519 1.91079C13.1321 2.15606 13.0074 2.26024 12.6287 2.5187C12.2927 2.74788 11.8744 3.03298 11.6655 3.56784C11.5318 3.90991 11.7006 4.29575 12.0427 4.4292C12.122 4.46059 12.2046 4.47509 12.2845 4.47509C12.5506 4.47509 12.8019 4.31448 12.9043 4.05206C12.9605 3.90753 13.1211 3.79281 13.3777 3.61742C13.787 3.33865 14.4055 2.9172 14.4777 2.01733C14.535 1.30314 14.2135 0.666478 13.6182 0.31439C13.0145 -0.0421814 12.2412 -0.039544 11.6929 0.321511C11.1744 0.663049 10.9286 1.25751 11.018 1.95272C11.0645 2.31694 11.4002 2.57382 11.7626 2.5274Z" fill="#3A3A3A"/>
            <path d="M15.5684 5.01302C15.6868 5.10005 15.8245 5.14199 15.9611 5.14199C16.1657 5.14199 16.3675 5.0481 16.4975 4.8706L17.7964 3.10146C18.014 2.80529 17.9502 2.38911 17.6543 2.17179C17.3586 1.955 16.9422 2.01803 16.7251 2.31421L15.426 4.08335C15.2084 4.37979 15.2725 4.7957 15.5684 5.01302Z" fill="#3A3A3A"/>
    </svg>
);

export default RankGreenIcon;