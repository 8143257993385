import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {ErrorBoundary} from "react-error-boundary";
import {storeData} from "./redux/store";
import ErrorFallback from "./components/ErrorFallback";
import {ConfirmContextProvider} from "./components/context/ConfirmContextProvider";
import ModalConfirm from "./containers/modal/ModalConfirm";
import {logError500} from "./helpers/error500";
import WithContext from "./utils/hoc/withContext";
import {GlobalContextProvider} from "./components/context/GlobalContextProvider";

const App = React.lazy(() => import(/* webpackChunkName: "App" */ './App'));

const entryBlock = document.getElementById('root');

const app = (
    <Provider store={storeData}>
        <Suspense fallback={<div className="loading" />}>
            <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => null}
                resetKeys={[]}
                onError={logError500}
            >
                <GlobalContextProvider>
                    <ConfirmContextProvider>
                        <WithContext Component={App}/>
                        <ModalConfirm />
                    </ConfirmContextProvider>
                </GlobalContextProvider>
            </ErrorBoundary>
        </Suspense>
    </Provider>
);
ReactDOM.render(app, entryBlock);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
