import React from "react";
import TimeZone from "../../../../components/common/TimeZone";

const Time = ({client}) => {
    if (!client) return <></>;

    const city = client?.legalAddress?.city ?? null;

    if (!city) return <></>;

    return (
        <>
            <div className="d-flex align-items-center">
                <i className="simple-icon-clock mr-2 text-primary" />
                <TimeZone {...city} />
            </div>
        </>
    )
}
export default Time;