// eslint-disable-next-line import/named
import {
    CRM_ORDER_ADD_EXIST_PRODUCT_ERRORS,
    CRM_ORDER_CLIENT_FETCH,
    CRM_ORDER_CLIENT_LOADING,
    CRM_ORDER_CLIENT_RELOAD,
    CRM_ORDER_CLIENT_SET,
    CRM_ORDER_CLIENT_UPDATE_CONTACT,
    CRM_ORDER_COUNT_NEW,
    CRM_ORDER_COUNT_YOU_RESPONSIBLE,
    CRM_ORDER_DISABLED,
    CRM_ORDER_DOCUMENT_FILES_FETCH,
    CRM_ORDER_DOCUMENT_FILES_LOADING,
    CRM_ORDER_DOCUMENT_FILES_RELOAD,
    CRM_ORDER_DOCUMENT_FILES_REMOVE,
    CRM_ORDER_DOCUMENT_FILES_SET,
    CRM_ORDER_FETCH_DATA,
    CRM_ORDER_FETCH_DOCUMENTS,
    CRM_ORDER_FETCH_PRODUCTS,
    CRM_ORDER_FETCH_PRODUCTS_LOADING,
    CRM_ORDER_FETCH_PRODUCTS_RELOAD,
    CRM_ORDER_FILE_REMOVE,
    CRM_ORDER_FOUND_PRODUCTS,
    CRM_ORDER_LOADING,
    CRM_ORDER_LOADING_DOCUMENTS,
    CRM_ORDER_MODAL_ABOUT_PRODUCT_SHOW,
    CRM_ORDER_MODAL_LOGS_PRODUCT_SHOW,
    CRM_ORDER_MODAL_CANCEL_SHOW,
    CRM_ORDER_MODAL_CREATE_DOCUMENT_SHOW,
    CRM_ORDER_MODAL_EDIT_PRODUCT_SHOW,
    CRM_ORDER_MODAL_MASS_EDIT_PRODUCT_SHOW,
    CRM_ORDER_MODAL_PRODUCT_RELATED_SHOW,
    CRM_ORDER_MODAL_MOVE_DOCUMENT_SHOW,
    CRM_ORDER_MODAL_SEARCH_DOCUMENT_SHOW,
    CRM_ORDER_PRODUCT_SUPPLIER_SET,
    CRM_ORDER_PRODUCTS_ADDED_ID,
    CRM_ORDER_RELOAD,
    CRM_ORDER_RELOAD_DOCUMENTS,
    CRM_ORDER_REMOVE_PRODUCT,
    CRM_ORDER_SEARCH_PRODUCT,
    CRM_ORDER_SEARCHING_PRODUCT,
    CRM_ORDER_SELECTED_DOCUMENT,
    CRM_ORDER_SELECTED_PRODUCTS,
    CRM_ORDER_SELECTED_TASKS,
    CRM_ORDER_SET_DATA,
    CRM_ORDER_SET_DOCUMENTS,
    CRM_ORDER_SET_EDITABLE_PRODUCT,
    CRM_ORDER_SET_ERROR,
    CRM_ORDER_SET_PRODUCTS,
    CRM_ORDERS_FETCH_DATA,
    CRM_ORDERS_RELOAD,
    CRM_ORDERS_SET_DATA,
    CRM_ORDERS_SET_PARAMS,
    ORDER_CARD_COMMENTS_FETCH,
    ORDER_CARD_COMMENTS_LOADING,
    ORDER_CARD_COMMENTS_RELOAD,
    ORDER_CARD_COMMENTS_SET,
    ORDER_CARD_FEEDBACK_FETCH,
    ORDER_CARD_FEEDBACK_LOADING,
    ORDER_CARD_FEEDBACK_RELOAD,
    ORDER_CARD_FEEDBACK_SET,
    CRM_ORDER_MODAL_ADD_PRODUCT_SHOW,
    CRM_ORDER_MODAL_SEARCH_PRODUCT_SHOW,
    CRM_ORDER_MODAL_SORTING_LISTING_SHOW,
    CRM_ORDER_MODAL_FILTER_LISTING_SHOW,
    CRM_ORDER_MODAL_ANALOG_PRODUCT_SHOW,
    CRM_ORDER_MODAL_SIMILARS_PRODUCT_SHOW, CRM_ORDER_MODAL_SCORE_PRODUCT_SHOW,
    CRM_ORDER_MODAL_ACTION_AUTO_PERSENT_SHOW,
} from '../../actions';

// eslint-disable-next-line import/prefer-default-export
export const changeLoading = (status) => {
  return {
    type: CRM_ORDER_LOADING,
    payload: status,
  };
};
export const fetchOrderData = (paramsCall) => {
  return {
    type: CRM_ORDER_FETCH_DATA,
    payload: paramsCall,
  };
};
export const fetchOrders = (params) => {
  return {
    type: CRM_ORDERS_FETCH_DATA,
    payload: params,
  };
};
export const setReloadOrders = (params) => {
  return {
    type: CRM_ORDERS_RELOAD,
    payload: params,
  };
};
export const setOrderData = (orderData) => {
  return {
    type: CRM_ORDER_SET_DATA,
    payload: orderData,
  };
};
export const setOrderDisabled = (value) => {
  return {
    type: CRM_ORDER_DISABLED,
    payload: value,
  };
};
export const setOrders = (orders) => {
  return {
    type: CRM_ORDERS_SET_DATA,
    payload: orders,
  };
};
export const setOrdersParams = (params) => {
  return {
    type: CRM_ORDERS_SET_PARAMS,
    payload: params,
  };
};
export const setErrorFetch = (errors) => {
  return {
    type: CRM_ORDER_SET_ERROR,
    payload: errors,
  };
};
export const setReload = (reload) => {
  return {
    type: CRM_ORDER_RELOAD,
    payload: reload,
  };
};
export const searchingProduct = (searching) => {
  return {
    type: CRM_ORDER_SEARCHING_PRODUCT,
    payload: searching,
  };
};
export const foundProducts = (products) => {
  return {
    type: CRM_ORDER_FOUND_PRODUCTS,
    payload: products,
  };
};
export const searchProduct = (params) => {
  return {
    type: CRM_ORDER_SEARCH_PRODUCT,
    payload: params,
  };
};
export const fetchProducts = (params) => {
  return {
    type: CRM_ORDER_FETCH_PRODUCTS,
    payload: params,
  };
};
export const fetchProductsLoading = (params) => {
  return {
    type: CRM_ORDER_FETCH_PRODUCTS_LOADING,
    payload: params,
  };
};
export const fetchProductsReload = (params) => {
  return {
    type: CRM_ORDER_FETCH_PRODUCTS_RELOAD,
    payload: params,
  };
};
export const setProducts = (params) => {
  return {
    type: CRM_ORDER_SET_PRODUCTS,
    payload: params,
  };
};
export const removeProduct = (params) => {
  return {
    type: CRM_ORDER_REMOVE_PRODUCT,
    payload: params,
  };
};

export const setStatusModalAboutProduct = (show, data) => {
    return {
        type: CRM_ORDER_MODAL_ABOUT_PRODUCT_SHOW,
        payload: {show, data}
  }
}

export const setStatusModalLogsProduct = (show, data) => {
    return {
        type: CRM_ORDER_MODAL_LOGS_PRODUCT_SHOW,
        payload: {show, data}
    }
}
export const setStatusModalAnalogProduct = (show, data) => {
    return {
        type: CRM_ORDER_MODAL_ANALOG_PRODUCT_SHOW,
        payload: {show, data}
    }
}
export const setStatusModalSimilarsProduct = (show, data) => {
    return {
        type: CRM_ORDER_MODAL_SIMILARS_PRODUCT_SHOW,
        payload: {show, data}
    }
}
export const setStatusModalScoreProduct = (show, data) => {
    return {
        type: CRM_ORDER_MODAL_SCORE_PRODUCT_SHOW,
        payload: {show, data}
    }
}
export const setStatusModalActionAutoPercent = (show) => {
    return {
        type: CRM_ORDER_MODAL_ACTION_AUTO_PERSENT_SHOW,
        payload: {show}
    }
}

export const setStatusModalFilterListing = (show, data) => {
    return {
        type: CRM_ORDER_MODAL_FILTER_LISTING_SHOW,
        payload: {show, data}
    }
}

export const setStatusModalSortingListing = (show, data) => {
    return {
        type: CRM_ORDER_MODAL_SORTING_LISTING_SHOW,
        payload: {show, data}
    }
}

export const setStatusModalAddProduct = (show, data, callback) => {
    return {
        type: CRM_ORDER_MODAL_ADD_PRODUCT_SHOW,
        payload: {show, data, callback}
    }
}

export const setStatusModalSearchProduct = (show) => {
    return {
        type: CRM_ORDER_MODAL_SEARCH_PRODUCT_SHOW,
        payload: {show}
    }
}

export const setErrorsAddExistProducts = (params) => {
  return {
    type: CRM_ORDER_ADD_EXIST_PRODUCT_ERRORS,
    payload: params,
  };
};
export const setEditProduct = (params) => {
  return {
    type: CRM_ORDER_SET_EDITABLE_PRODUCT,
    payload: params,
  };
};
export const setSelectProducts = (params) => {
  return {
    type: CRM_ORDER_SELECTED_PRODUCTS,
    payload: params,
  };
};
export const fetchDocuments = (params) => {
  return {
    type: CRM_ORDER_FETCH_DOCUMENTS,
    payload: params,
  };
};
export const setDocuments = (params) => {
  return {
    type: CRM_ORDER_SET_DOCUMENTS,
    payload: params,
  };
};
export const loadingDocuments = (params) => {
  return {
    type: CRM_ORDER_LOADING_DOCUMENTS,
    payload: params,
  };
};
export const reloadDocuments = (params) => {
  return {
    type: CRM_ORDER_RELOAD_DOCUMENTS,
    payload: params,
  };
};
export const selectedDocument = (params) => {
  return {
    type: CRM_ORDER_SELECTED_DOCUMENT,
    payload: params,
  };
};
export const updateClientContact = (params) => {
  return {
    type: CRM_ORDER_CLIENT_UPDATE_CONTACT,
    payload: params,
  };
};
export const orderTasksSelected = (params) => {
  return {
    type: CRM_ORDER_SELECTED_TASKS,
    payload: params,
  };
};
export const orderRemoveFile = (params) => {
  return {
    type: CRM_ORDER_FILE_REMOVE,
    payload: params,
  };
};
export const setJustAddedProductID = (productID) => {
  return {
    type: CRM_ORDER_PRODUCTS_ADDED_ID,
    payload: productID,
  };
};
export const fetchDocumentFiles = (params) => {
  return {
    type: CRM_ORDER_DOCUMENT_FILES_FETCH,
    payload: params,
  };
};
export const setDocumentFiles = (data) => {
  return {
    type: CRM_ORDER_DOCUMENT_FILES_SET,
    payload: data,
  };
};
export const loadingDocumentFiles = (params) => {
  return {
    type: CRM_ORDER_DOCUMENT_FILES_LOADING,
    payload: params,
  };
};
export const reloadDocumentFiles = (params) => {
  return {
    type: CRM_ORDER_DOCUMENT_FILES_RELOAD,
    payload: params,
  };
};
export const setProductTempSupplier = (data) => {
  return {
    type: CRM_ORDER_PRODUCT_SUPPLIER_SET,
    payload: data,
  };
};
export const removeDocumentFile = (params) => {
  return {
    type: CRM_ORDER_DOCUMENT_FILES_REMOVE,
    payload: params,
  };
};
export const setCountNewOrder = (params) => {
  return {
    type: CRM_ORDER_COUNT_NEW,
    payload: params,
  };
};
export const setCountYouResponsibleOrder = (params) => {
  return {
    type: CRM_ORDER_COUNT_YOU_RESPONSIBLE,
    payload: params,
  };
};
export const setStatusModalCancelOrder = (params) => {
  return {
    type: CRM_ORDER_MODAL_CANCEL_SHOW,
    payload: params,
  };
};
export const setStatusModalEditProduct = (params) => {
  return {
    type: CRM_ORDER_MODAL_EDIT_PRODUCT_SHOW,
    payload: params,
  };
};
export const setStatusModalMoveDocument = (params) => {
  return {
    type: CRM_ORDER_MODAL_MOVE_DOCUMENT_SHOW,
    payload: params,
  };
};
export const setStatusModalSearchDocument = (params) => {
  return {
    type: CRM_ORDER_MODAL_SEARCH_DOCUMENT_SHOW,
    payload: params,
  };
};
export const setStatusModalCreateDocument = (params) => {
  return {
    type: CRM_ORDER_MODAL_CREATE_DOCUMENT_SHOW,
    payload: params,
  };
};
export const setStatusModalMassEditProduct = (params) => {
  return {
    type: CRM_ORDER_MODAL_MASS_EDIT_PRODUCT_SHOW,
    payload: params,
  };
};
export const setStatusModalProductRelated = (show, data) => {
  return {
    type: CRM_ORDER_MODAL_PRODUCT_RELATED_SHOW,
    payload: {show, data},
  };
};
export const orderClientFetch = (params) => {
  return {
    type: CRM_ORDER_CLIENT_FETCH,
    payload: params,
  };
};
export const orderClientSet = (params) => {
  return {
    type: CRM_ORDER_CLIENT_SET,
    payload: params,
  };
};
export const orderClientLoading = (params) => {
  return {
    type: CRM_ORDER_CLIENT_LOADING,
    payload: params,
  };
};
export const orderClientReload = (params) => {
  return {
    type: CRM_ORDER_CLIENT_RELOAD,
    payload: params,
  };
};
export const orderCardCommentsSet = (data) => {
  return {
    type: ORDER_CARD_COMMENTS_SET,
    payload: data,
  };
};
export const orderCardCommentsFetch = (data) => {
  return {
    type: ORDER_CARD_COMMENTS_FETCH,
    payload: data,
  };
};
export const orderCardCommentsLoading = (data) => {
  return {
    type: ORDER_CARD_COMMENTS_LOADING,
    payload: data,
  };
};
export const orderCardCommentsReload = (data) => {
  return {
    type: ORDER_CARD_COMMENTS_RELOAD,
    payload: data,
  };
};

export const orderCardFeedbackSet = (data) => {
  return {
    type: ORDER_CARD_FEEDBACK_SET,
    payload: data,
  };
};
export const orderCardFeedbackFetch = (data) => {
  return {
    type: ORDER_CARD_FEEDBACK_FETCH,
    payload: data,
  };
};
export const orderCardFeedbackLoading = (data) => {
  return {
    type: ORDER_CARD_FEEDBACK_LOADING,
    payload: data,
  };
};
export const orderCardFeedbackReload = (data) => {
  return {
    type: ORDER_CARD_FEEDBACK_RELOAD,
    payload: data,
  };
};