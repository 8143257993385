// eslint-disable-next-line no-unused-vars
/* eslint-disable no-use-before-define */
import {all, fork, call, takeEvery, put, } from 'redux-saga/effects';
import {SendGet} from "../../../api/handler/sendGet";
import {STATISTICS_DEAL} from "../../actions";
import {changeLoading, setStatisticsDeal} from "./actions";

export function* watchDeals() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(STATISTICS_DEAL, loadDeals)
}

const getDeals = async (params) => {
    // eslint-disable-next-line no-return-await
    return SendGet('/statistics/deals', params);
}

function* loadDeals({payload}) {
    try {
        yield put(changeLoading(true));
        const getData = yield call(getDeals, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(setStatisticsDeal(getData.data));
        }
        yield put(changeLoading(false));
    } catch (error) {
        yield put(changeLoading(false));
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchDeals)
    ])
}