/* eslint-disable no-unused-vars */
import React, {useState} from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import useConfirm from "../../hooks/useConfirm";


const ModalConfirm = () => {
    const { onConfirm, onCancel, confirmState } = useConfirm();

    const {status, data} = confirmState;
    const {
        title,
        html = '',
        confirmButtonText = 'Да',
        closeButtonText = 'Отмена',
        showConfirmButton = true,
        showCloseButton = true
    } = data;

    return (
        <Modal
            size="md"
            className="modal-style"
            isOpen={status}
            toggle={onCancel}
            backdrop="static"
            centered
        >
            <ModalHeader className="pb-0" toggle={onCancel} />
            <ModalBody className="pt-0">
                <div className="window-question">
                    <div className="icon">
                        <i className="simple-icon-question" />
                    </div>
                    <div className="text mt-3">
                        <div dangerouslySetInnerHTML={{ __html: title }} />
                    </div>
                    {html && (
                        <div className="mt-2">
                            <div dangerouslySetInnerHTML={{ __html: html }} />
                        </div>
                    )}
                    <div className="d-flex align-items-center justify-content-center mt-3">
                        {showConfirmButton && (
                            <button
                                type="button"
                                className="btn-outline-primary btn ml-2 mr-2"
                                onClick={onCancel}
                            >
                                {closeButtonText}
                            </button>
                        )}
                        {showCloseButton && (
                            <button
                                type="button"
                                className="btn-primary btn ml-2 mr-2"
                                onClick={onConfirm}
                            >
                                {confirmButtonText}
                            </button>
                        )}
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
};

export default ModalConfirm;
