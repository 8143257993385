import {MODAL_CONFIRM_DATA, MODAL_CONFIRM_STATUS, MODAL_SELECT_DATE_DATA, MODAL_SELECT_DATE_STATUS} from "../actions";

const INIT_STATE = {
    selectDate: {
        status: false,
        data: null,
    },
    confirm: {
        status: false,
        data: {}
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case MODAL_SELECT_DATE_STATUS:
            return { ...state,
                selectDate: {
                    ...state.selectDate,
                    status: action.payload,
                }
            };
        case MODAL_SELECT_DATE_DATA:
            return { ...state,
                selectDate: {
                    ...state.selectDate,
                    data: action.payload,
                }
            };
        case MODAL_CONFIRM_STATUS:
            return { ...state,
                confirm: {
                    ...state.confirm,
                    status: action.payload,
                }
            };
        case MODAL_CONFIRM_DATA:
            return { ...state,
                confirm: {
                    ...state.confirm,
                    data: action.payload,
                }
            };
        default:
            return { ...state };
    }
};
