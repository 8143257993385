import React from 'react'
import {useDispatch} from "react-redux";

export const GlobalContext = React.createContext();

export const GlobalContextProvider = ({ children }) => {
    const dispatch = useDispatch();
    return (
        <GlobalContext.Provider value={{dispatch}}>
            {children}
        </GlobalContext.Provider>
    );
};