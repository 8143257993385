import React from 'react'
import {Suspense} from "react";
import {Redirect, Switch} from "react-router-dom";
import {ProtectedRoute} from "../../../helpers/authHelper";
import {List} from "./scenarios/List";
import {View} from "./scenarios/View";

const Settings = ({ match }) => {
    return (
        <Suspense fallback={<div className="loading" />}>
            <Switch>
                <Redirect exact from={`${match.url}/`} to={`${match.url}/scenarios`} />
                <ProtectedRoute
                    path={`${match.url}/scenarios/:card`}
                    component={View}
                    permissions={['crms1629778467']}
                />
                <ProtectedRoute
                    path={`${match.url}/scenarios`}
                    component={List}
                    permissions={['crms1629778467']}
                />
                <Redirect to="/error" />
            </Switch>
        </Suspense>
    );
};
export default Settings;
