/* eslint-disable no-unused-vars,no-param-reassign */

import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IBuyerGroupSale} from "./model";
import {fetchMetaDefault} from "../../../constants/defaultCrm";

export const initialState = {
    view: {
        discounts: {
            list: {
                isFetching: false,
                data: [],
            },
            view: {
                isFetching: false,
                data: null,
                brands: {
                    isFetching: false,
                    data: [],
                }
            }
        },
        deals: {
            data: {
                items: [],
                meta: fetchMetaDefault,
            },
            general: {
                data: null,
                loading: false
            },
            loading: false,
            reload: false,
        },
        files: {
            data: {
                items: [],
                meta: fetchMetaDefault,
            },
            loading: false,
            reload: false,
        }
    },
    error: null,
    list: {
        selected: [],
        filter: {
            selection: {},
        }
    }
};

const slice = createSlice({
    name: "buyer",
    initialState,
    reducers: {
        getDiscountList: (state, {payload}: PayloadAction<any>) => {
            state.view.discounts.list.isFetching = true;
        },
        setDiscountList: (state, {payload}: PayloadAction<IBuyerGroupSale[]>) => {
            state.view.discounts.list = {
                data: payload,
                isFetching: false,
            }
        },
        getDiscountView: (state, {payload}: PayloadAction<any>) => {
            state.view.discounts.view.isFetching = true;
        },
        setDiscountView: (state, {payload}: PayloadAction<IBuyerGroupSale | null>) => {
            state.view.discounts.view = {
                ...state.view.discounts.view,
                data: payload,
                isFetching: false,
            }
        },
        getDiscountBrands: (state, {payload}: PayloadAction) => {
            state.view.discounts.view.brands.isFetching = true;
        },
        setDiscountBrands: (state, {payload}: PayloadAction[]) => {
            state.view.discounts.view.brands = {
                data: payload,
                isFetching: false
            }
        },

        fetchDeals: (state, { payload }) => {
            state.view.deals = {
                ...state.view.deals,
                loading: true,
                reload: false,
            };
        },
        setDeals: (state, { payload }) => {
            state.view.deals = {
                ...state.view.deals,
                data: {
                    items: !payload ? [] : payload.items,
                    // eslint-disable-next-line no-underscore-dangle
                    meta: !payload ? fetchMetaDefault : payload._meta
                },
                loading: false,
                reload: false,
            };
        },
        fetchDealsGeneral: (state, { payload }) => {
            state.view.deals.general = {
                ...state.view.deals.general,
                loading: true,
            };
        },
        setDealsGeneral: (state, { payload }) => {
            state.view.deals.general = {
                ...state.view.deals.general,
                data: !payload ? null : payload,
                loading: false,
            };
        },
        getFiles: (state, { payload }) => {
            state.view.files = {
                ...state.view.files,
                loading: true,
                reload: false,
            };
        },
        setFiles: (state, { payload }) => {
            state.view.files = {
                ...state.view.files,
                data: {
                    items: !payload ? [] : payload.items,
                    // eslint-disable-next-line no-underscore-dangle
                    meta: !payload ? fetchMetaDefault : payload._meta
                },
                loading: false,
                reload: false,
            };
        },
        reloadFiles: (state, { payload }) => {
            state.view.files.reload = true;
        },
        setListSelected: (state, { payload }) => {
            state.list = {
                ...state.list,
                selected: payload,
            };
        },
        setListFilterSelection: (state, { payload }) => {
            state.list.filter = {
                ...state.list.filter,
                selection: payload ?? {},
            };
        },
    },
});

export const buyerReducer = slice.reducer;
export const buyerActions = slice.actions;
