import React, {useState} from "react";
import DirectoryTaskPriority from "../../../../components/directory/DirectoryTaskPriority";
import {Style6} from "../../../../constants/ReactSelect";

const Priority = (props) => {
    const {onChange, currentValue, disabled = false} = props;
    return (
        <>
            <div className="font-weight-bold">Приоритет:</div>
            <div className="mt-2">
                 <DirectoryTaskPriority
                    initState={currentValue}
                    onChange={onChange}
                    placeholder="Выберите приоритет"
                    isDisabled={disabled}
                    style={Style6()}
                 />
            </div>
        </>
    )
};
export default Priority;