import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export const initialState = {
    fetching: true,
    articles: [],
    article: null,
    allPages: 0,
    articlesUpdate: [],
}

const slice = createSlice({
    name: "articles",
    initialState: initialState,
    reducers: {
        setArticles: (state, {payload}: PayloadAction<IArticlesElem[] | []>) => {
            state.articles = payload
            state.fetching = false
        },
        setArticle: (state, {payload}: PayloadAction<IArticlesElem | null>) => {
            state.article = payload
            state.fetching = false
        },
        setPages: (state, {payload}: PayloadAction<IArticlesPage>) => {
            state.allPages = payload.pageCount
        },
        getArticles: (state, {payload}: PayloadAction<any>) => {
            state.fetching = true
        },
        getArticle: (state, {payload}: PayloadAction<any>) => {
            state.fetching = true
        },

        setArticlesUpdate: (state, {payload}: PayloadAction<IArticlesElem[] | []>) => {
            state.articlesUpdate = payload
            state.fetching = false
        },
        getArticlesUpdate: (state, {payload}: PayloadAction<any>) => {
            state.fetching = true
        },
    }
})

export const articlesReducer = slice.reducer
export const articlesActions = slice.actions
