import { SendGet } from '../handler/sendGet';
import { sendPost } from '../handler/sendPost';

// eslint-disable-next-line import/prefer-default-export
export const getDocumentFiles = (params) => {
    return SendGet(`document-files`, params);
};
export const createDocumentFile = (params) => {
    return sendPost(`document-files/create`, params);
};
export const removeDocumentFile = (params) => {
    return sendPost(`document-files/remove`, params);
};
export const changeProviderShow = (params) => {
    return sendPost(`document-files/provider-show`, params)
        .then((response: any) => {
            if (response.status === 200) {
                return response.data
            }
            return null;
        });
};