import {
    DEAL_CARD_COMMENTS_FETCH,
    DEAL_CARD_COMMENTS_LOADING,
    DEAL_CARD_COMMENTS_RELOAD,
    DEAL_CARD_COMMENTS_SET,
    DEAL_CARD_DATA_ERROR,
    DEAL_CARD_DATA_FETCH,
    DEAL_CARD_DATA_RELOAD,
    DEAL_CARD_DATA_SET,
    DEAL_CARD_DOCUMENTS_FETCH,
    DEAL_CARD_DOCUMENTS_LOADING,
    DEAL_CARD_DOCUMENTS_RELOAD,
    DEAL_CARD_DOCUMENTS_SELECTED_ADD,
    DEAL_CARD_DOCUMENTS_SELECTED_CLEAR,
    DEAL_CARD_DOCUMENTS_SELECTED_REMOVE,
    DEAL_CARD_DOCUMENTS_SET,
    DEAL_CARD_FILES_FETCH,
    DEAL_CARD_FILES_LOADING,
    DEAL_CARD_FILES_RELOAD,
    DEAL_CARD_FILES_SET,
    DEAL_CARD_LOGISTICS_FETCH,
    DEAL_CARD_LOGISTICS_LOADING,
    DEAL_CARD_LOGISTICS_RELOAD,
    DEAL_CARD_LOGISTICS_SET,
    DEAL_CARD_LOGISTICS_VIEW_COMMENTS_COUNT_SET,
    DEAL_CARD_LOGISTICS_VIEW_COMMENTS_FETCH,
    DEAL_CARD_LOGISTICS_VIEW_COMMENTS_LOADING,
    DEAL_CARD_LOGISTICS_VIEW_COMMENTS_RELOAD,
    DEAL_CARD_LOGISTICS_VIEW_COMMENTS_SET,
    DEAL_CARD_LOGISTICS_VIEW_SET,
    DEAL_CARD_MODAL_LOGISTICS_BREAK_PRODUCT,
    DEAL_CARD_MODAL_LOGISTICS_CREATE,
    DEAL_CARD_PAYMENTS_FETCH,
    DEAL_CARD_PAYMENTS_LOADING, DEAL_CARD_PAYMENTS_MODAL_BREAK_PRODUCT,
    DEAL_CARD_PAYMENTS_MODAL_CONTRACT_CANCEL,
    DEAL_CARD_PAYMENTS_MODAL_CONTRACT_CONFIRM,
    DEAL_CARD_PAYMENTS_MODAL_DELIVERY_CANCEL,
    DEAL_CARD_PAYMENTS_MODAL_DELIVERY_CONFIRM,
    DEAL_CARD_PAYMENTS_MODAL_DOCUMENTS_CANCEL,
    DEAL_CARD_PAYMENTS_MODAL_DOCUMENTS_CONFIRM,
    DEAL_CARD_PAYMENTS_MODAL_RECEIVED_CANCEL,
    DEAL_CARD_PAYMENTS_MODAL_RECEIVED_CONFIRM,
    DEAL_CARD_PAYMENTS_MODAL_SCORE_CANCEL,
    DEAL_CARD_PAYMENTS_MODAL_SCORE_CONFIRM,
    DEAL_CARD_PAYMENTS_MODAL_UPDATE_PAYMENT,
    DEAL_CARD_PAYMENTS_RELOAD,
    DEAL_CARD_PAYMENTS_SET,
    DEAL_CARD_RECIPIENTS_FETCH,
    DEAL_CARD_RECIPIENTS_LOADING,
    DEAL_CARD_RECIPIENTS_RELOAD,
    DEAL_CARD_RECIPIENTS_SET,
    DEAL_FILTERS,
    DEAL_LIST__ACTIVE_TAB,
    DEAL_LIST_FETCH_DATA,
    DEAL_LIST_LOADING,
    DEAL_LIST_RELOAD,
    DEAL_LIST_SET_DATA,
    DEAL_LOAD,
    DEAL_LOAD_FILTERS,
} from '../../actions';

const INIT_STATE = {
    loading: false,
    listActiveTab: '1',
    loadingFilters: false,
    filters: [],
    card: {
        data: null,
        reload: false,
        isError: false,
        logistics: {
            list: {
                loading: false,
                data: [],
                reload: false,
            },
            view: {
                data: null,
                comments: { // комментарий
                    data: {},
                    list: {
                        loading: false,
                        data: {},
                        reload: false,
                    }
                }
            },
            modal: {
                create: {
                    status: false,
                    data: null,
                },
                breakProduct: {
                    status: false,
                    data: null,
                }
            }
        },
        payments: {
            list: {
                loading: false,
                data: [],
                reload: false,
            },
            modal: {
                deliveryConfirm: {
                    status: false,
                    data: null,
                },
                deliveryCancel: {
                    status: false,
                    data: null,
                },
                documentsConfirm: {
                    status: false,
                    data: null,
                },
                documentsCancel: {
                    status: false,
                    data: null,
                },
                receivedConfirm: {
                    status: false,
                    data: null,
                },
                receivedCancel: {
                    status: false,
                    data: null,
                },
                contractConfirm: {
                    status: false,
                    data: null,
                },
                contractCancel: {
                    status: false,
                    data: null,
                },
                scoreConfirm: {
                    status: false,
                    data: null,
                },
                scoreCancel: {
                    status: false,
                    data: null,
                },
                breakProduct: {
                    status: false,
                    data: null,
                },
                updatePayment: {
                    status: false,
                    data: null,
                }
            }
        },
        documents: {
            list: {
                loading: false,
                data: [],
                reload: false,
                selected: [],
            }
        },
        recipients: { // плательщики
            list: {
                loading: false,
                data: [],
                reload: false,
            }
        },
        comments: { // комментарий
            list: {
                loading: false,
                data: {},
                reload: false,
            }
        },
        files: {
            list: {
                loading: false,
                data: [],
                reload: false,
            },
        }
    },
    index: {
        loading: false,
        list: {},
        params: {},
        errors: null,
        reload: false,
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case DEAL_LOAD:
            return { ...state, loading: action.payload };
        case DEAL_LIST__ACTIVE_TAB:
            return { ...state, listActiveTab: action.payload };
        case DEAL_LOAD_FILTERS:
            return { ...state, loadingFilters: action.payload };
        case DEAL_FILTERS:
            return { ...state, filters: action.payload };
        case DEAL_CARD_DATA_FETCH:
            return {
                ...state,
                loading: true,
                card: {
                    ...state.card,
                    reload: false,
                }
            };
        case DEAL_CARD_DATA_SET:
            return {...state,
                card: {
                    ...state.card,
                    data: action.payload,
                    loading: false,
                }
            };
        case DEAL_CARD_DATA_ERROR:
            return {...state,
                card: {
                    ...state.card,
                    isError: action.payload,
                }
            };
        case DEAL_CARD_DATA_RELOAD:
            return {...state,
                card: {
                    ...state.card,
                    reload: action.payload,
                }
            };
        case DEAL_CARD_LOGISTICS_FETCH:
            return {...state,
                card: {
                    ...state.card,
                    logistics: {
                        ...state.card.logistics,
                        list: {
                            ...state.card.logistics.list,
                            loading: true,
                            reload: false,
                        }
                    }
                }
            };
        case DEAL_CARD_LOGISTICS_SET:
            return {...state,
                card: {
                    ...state.card,
                    logistics: {
                        ...state.card.logistics,
                        list: {
                            ...state.card.logistics.list,
                            loading: false,
                            data: action.payload
                        }
                    }
                }
            };
        case DEAL_CARD_LOGISTICS_LOADING:
            return {...state,
                card: {
                    ...state.card,
                    logistics: {
                        ...state.card.logistics,
                        list: {
                            ...state.card.logistics.list,
                            loading: action.payload,
                        }
                    }
                }
            };
        case DEAL_CARD_LOGISTICS_RELOAD:
            return {...state,
                card: {
                    ...state.card,
                    logistics: {
                        ...state.card.logistics,
                        list: {
                            ...state.card.logistics.list,
                            reload: action.payload,
                        }
                    }
                }
            };
        case DEAL_CARD_LOGISTICS_VIEW_SET:
            return {...state,
                card: {
                    ...state.card,
                    logistics: {
                        ...state.card.logistics,
                        view: {
                            ...state.card.logistics.view,
                            data: action.payload,
                        }
                    }
                }
            };
        case DEAL_CARD_PAYMENTS_FETCH:
            return {...state,
                card: {
                    ...state.card,
                    payments: {
                        ...state.card.payments,
                        list: {
                            ...state.card.payments.list,
                            loading: true,
                            reload: false,
                        }
                    }
                }
            };
        case DEAL_CARD_PAYMENTS_SET:
            return {...state,
                card: {
                    ...state.card,
                    payments: {
                        ...state.card.payments,
                        list: {
                            ...state.card.payments.list,
                            loading: false,
                            data: action.payload
                        }
                    }
                }
            };
        case DEAL_CARD_PAYMENTS_LOADING:
            return {...state,
                card: {
                    ...state.card,
                    payments: {
                        ...state.card.payments,
                        list: {
                            ...state.card.payments.list,
                            loading: action.payload,
                        }
                    }
                }
            };
        case DEAL_CARD_PAYMENTS_RELOAD:
            return {...state,
                card: {
                    ...state.card,
                    payments: {
                        ...state.card.payments,
                        list: {
                            ...state.card.payments.list,
                            reload: action.payload,
                        }
                    }
                }
            };
        case DEAL_CARD_DOCUMENTS_FETCH:
            return {...state,
                card: {
                    ...state.card,
                    documents: {
                        ...state.card.documents,
                        list: {
                            ...state.card.documents.list,
                            loading: true,
                            reload: false,
                        }
                    }
                }
            };
        case DEAL_CARD_DOCUMENTS_SET:
            return {...state,
                card: {
                    ...state.card,
                    documents: {
                        ...state.card.documents,
                        list: {
                            ...state.card.documents.list,
                            loading: false,
                            data: action.payload
                        }
                    }
                }
            };
        case DEAL_CARD_DOCUMENTS_LOADING:
            return {...state,
                card: {
                    ...state.card,
                    documents: {
                        ...state.card.documents,
                        list: {
                            ...state.card.documents.list,
                            loading: action.payload,
                        }
                    }
                }
            };
        case DEAL_CARD_DOCUMENTS_RELOAD:
            return {...state,
                card: {
                    ...state.card,
                    documents: {
                        ...state.card.documents,
                        list: {
                            ...state.card.documents.list,
                            reload: action.payload,
                        }
                    }
                }
            };
        case DEAL_CARD_RECIPIENTS_FETCH:
            return {...state,
                card: {
                    ...state.card,
                    recipients: {
                        ...state.card.recipients,
                        list: {
                            ...state.card.recipients.list,
                            loading: true,
                            reload: false,
                        }
                    }
                }
            };
        case DEAL_CARD_RECIPIENTS_SET:
            return {...state,
                card: {
                    ...state.card,
                    recipients: {
                        ...state.card.recipients,
                        list: {
                            ...state.card.recipients.list,
                            loading: false,
                            data: action.payload
                        }
                    }
                }
            };
        case DEAL_CARD_RECIPIENTS_LOADING:
            return {...state,
                card: {
                    ...state.card,
                    recipients: {
                        ...state.card.recipients,
                        list: {
                            ...state.card.recipients.list,
                            loading: action.payload,
                        }
                    }
                }
            };
        case DEAL_CARD_RECIPIENTS_RELOAD:
            return {...state,
                card: {
                    ...state.card,
                    recipients: {
                        ...state.card.recipients,
                        list: {
                            ...state.card.recipients.list,
                            reload: action.payload,
                        }
                    }
                }
            };
        case DEAL_CARD_COMMENTS_FETCH:
            return {...state,
                card: {
                    ...state.card,
                    comments: {
                        ...state.card.comments,
                        list: {
                            ...state.card.comments.list,
                            loading: true,
                            reload: false,
                        }
                    }
                }
            };
        case DEAL_CARD_COMMENTS_SET:
            return {...state,
                card: {
                    ...state.card,
                    comments: {
                        ...state.card.comments,
                        list: {
                            ...state.card.comments.list,
                            loading: false,
                            data: action.payload
                        }
                    }
                }
            };
        case DEAL_CARD_COMMENTS_LOADING:
            return {...state,
                card: {
                    ...state.card,
                    comments: {
                        ...state.card.comments,
                        list: {
                            ...state.card.comments.list,
                            loading: action.payload,
                        }
                    }
                }
            };
        case DEAL_CARD_COMMENTS_RELOAD:
            return {...state,
                card: {
                    ...state.card,
                    comments: {
                        ...state.card.comments,
                        list: {
                            ...state.card.comments.list,
                            reload: action.payload,
                        }
                    }
                }
            };
        case DEAL_CARD_LOGISTICS_VIEW_COMMENTS_FETCH:
            return {...state,
                card: {
                    ...state.card,
                    logistics: {
                        ...state.card.logistics,
                        view: {
                            ...state.card.logistics.view,
                            comments: {
                                ...state.card.logistics.view.comments,
                                loading: true,
                                reload: false,
                            }
                        }
                    }
                }
            };
        case DEAL_CARD_LOGISTICS_VIEW_COMMENTS_SET:
            return {...state,
                card: {
                    ...state.card,
                    logistics: {
                        ...state.card.logistics,
                        view: {
                            ...state.card.logistics.view,
                            comments: {
                                ...state.card.logistics.view.comments,
                                loading: false,
                                data: action.payload
                            }
                        }
                    }
                }
            };
        case DEAL_CARD_LOGISTICS_VIEW_COMMENTS_LOADING:
            return {...state,
                card: {
                    ...state.card,
                    logistics: {
                        ...state.card.logistics,
                        view: {
                            ...state.card.logistics.view,
                            comments: {
                                ...state.card.logistics.view.comments,
                                loading: action.payload,
                            }
                        }
                    }
                }
            };
        case DEAL_CARD_LOGISTICS_VIEW_COMMENTS_RELOAD:
            return {...state,
                card: {
                    ...state.card,
                    logistics: {
                        ...state.card.logistics,
                        view: {
                            ...state.card.logistics.view,
                            comments: {
                                ...state.card.logistics.view.comments,
                                reload: action.payload,
                            }
                        }
                    }
                }
            };
        case DEAL_CARD_DOCUMENTS_SELECTED_ADD:
            return {...state,
                card: {
                    ...state.card,
                    documents: {
                        ...state.card.documents,
                        list: {
                            ...state.card.documents.list,
                            selected: [...state.card.documents.list.selected, action.payload],
                        }
                    }
                }
            };
        case DEAL_CARD_DOCUMENTS_SELECTED_REMOVE:
            return {...state,
                card: {
                    ...state.card,
                    documents: {
                        ...state.card.documents,
                        list: {
                            ...state.card.documents.list,
                            selected: state.card.documents.list.selected.filter(x => x !== action.payload),
                        }
                    }
                }
            };
        case DEAL_CARD_DOCUMENTS_SELECTED_CLEAR:
            return {...state,
                card: {
                    ...state.card,
                    documents: {
                        ...state.card.documents,
                        list: {
                            ...state.card.documents.list,
                            selected: [],
                        }
                    }
                }
            };
        case DEAL_CARD_LOGISTICS_VIEW_COMMENTS_COUNT_SET:
            return {
                ...state,
                card: {
                    ...state.card,
                    logistics: {
                        ...state.card.logistics,
                        list: {
                            ...state.card.logistics.list,
                            data: state.card.logistics.list.data.map(item => Number.parseInt(action.payload.id, 10) === Number.parseInt(item.id, 10) ? {
                                ...item,
                                commentsNewCount: action.payload.count,
                                commentsMyNewCount: action.payload.count,
                            } : item)
                        }
                    }
                }
            };
        case DEAL_LIST_FETCH_DATA:
            return {
                ...state,
                index: { ...state.index, reload: false, loading: true },
            };
        case DEAL_LIST_SET_DATA:
            return {
                ...state,
                index: { ...state.index, list: action.payload, loading: false },
            };
        case DEAL_LIST_LOADING:
            return {
                ...state,
                index: { ...state.index, loading: action.payload },
            };
        case DEAL_LIST_RELOAD:
            return {
                ...state,
                index: { ...state.index, reload: action.payload },
            };
        case DEAL_CARD_PAYMENTS_MODAL_BREAK_PRODUCT:
            return {...state,
                card: {
                    ...state.card,
                    payments: {
                        ...state.card.payments,
                        modal: {
                            ...state.card.payments.modal,
                            breakProduct: {
                                status: action.payload.status ?? false,
                                data: action.payload.data ?? null,
                            },
                        }
                    }
                }
            };
        case DEAL_CARD_PAYMENTS_MODAL_UPDATE_PAYMENT:
            return {...state,
                card: {
                    ...state.card,
                    payments: {
                        ...state.card.payments,
                        modal: {
                            ...state.card.payments.modal,
                            updatePayment: {
                                status: action.payload.status ?? false,
                                data: action.payload.data ?? null,
                            },
                        }
                    }
                }
            };
        case DEAL_CARD_PAYMENTS_MODAL_DELIVERY_CONFIRM:
            return {...state,
                card: {
                    ...state.card,
                    payments: {
                        ...state.card.payments,
                        modal: {
                            ...state.card.payments.modal,
                            deliveryConfirm: {
                                status: action.payload.status ?? false,
                                data: action.payload.data ?? null,
                            },
                        }
                    }
                }
            };
        case DEAL_CARD_PAYMENTS_MODAL_DELIVERY_CANCEL:
            return {...state,
                card: {
                    ...state.card,
                    payments: {
                        ...state.card.payments,
                        modal: {
                            ...state.card.payments.modal,
                            deliveryCancel: {
                                status: action.payload.status ?? false,
                                data: action.payload.data ?? null,
                            },
                        }
                    }
                }
            };
        case DEAL_CARD_PAYMENTS_MODAL_DOCUMENTS_CONFIRM:
            return {...state,
                card: {
                    ...state.card,
                    payments: {
                        ...state.card.payments,
                        modal: {
                            ...state.card.payments.modal,
                            documentsConfirm: {
                                status: action.payload.status ?? false,
                                data: action.payload.data ?? null,
                            },
                        }
                    }
                }
            };
        case DEAL_CARD_PAYMENTS_MODAL_DOCUMENTS_CANCEL:
            return {...state,
                card: {
                    ...state.card,
                    payments: {
                        ...state.card.payments,
                        modal: {
                            ...state.card.payments.modal,
                            documentsCancel: {
                                status: action.payload.status ?? false,
                                data: action.payload.data ?? null,
                            },
                        }
                    }
                }
            };
        case DEAL_CARD_PAYMENTS_MODAL_RECEIVED_CONFIRM:
            return {...state,
                card: {
                    ...state.card,
                    payments: {
                        ...state.card.payments,
                        modal: {
                            ...state.card.payments.modal,
                            receivedConfirm: {
                                status: action.payload.status ?? false,
                                data: action.payload.data ?? null,
                            },
                        }
                    }
                }
            };
        case DEAL_CARD_PAYMENTS_MODAL_RECEIVED_CANCEL:
            return {...state,
                card: {
                    ...state.card,
                    payments: {
                        ...state.card.payments,
                        modal: {
                            ...state.card.payments.modal,
                            receivedCancel: {
                                status: action.payload.status ?? false,
                                data: action.payload.data ?? null,
                            },
                        }
                    }
                }
            };
        case DEAL_CARD_PAYMENTS_MODAL_CONTRACT_CONFIRM:
            return {...state,
                card: {
                    ...state.card,
                    payments: {
                        ...state.card.payments,
                        modal: {
                            ...state.card.payments.modal,
                            contractConfirm: {
                                status: action.payload.status ?? false,
                                data: action.payload.data ?? null,
                            },
                        }
                    }
                }
            };
        case DEAL_CARD_PAYMENTS_MODAL_CONTRACT_CANCEL:
            return {...state,
                card: {
                    ...state.card,
                    payments: {
                        ...state.card.payments,
                        modal: {
                            ...state.card.payments.modal,
                            contractCancel: {
                                status: action.payload.status ?? false,
                                data: action.payload.data ?? null,
                            },
                        }
                    }
                }
            };
        case DEAL_CARD_PAYMENTS_MODAL_SCORE_CONFIRM:
            return {...state,
                card: {
                    ...state.card,
                    payments: {
                        ...state.card.payments,
                        modal: {
                            ...state.card.payments.modal,
                            scoreConfirm: {
                                status: action.payload.status ?? false,
                                data: action.payload.data ?? null,
                            },
                        }
                    }
                }
            };
        case DEAL_CARD_PAYMENTS_MODAL_SCORE_CANCEL:
            return {...state,
                card: {
                    ...state.card,
                    payments: {
                        ...state.card.payments,
                        modal: {
                            ...state.card.payments.modal,
                            scoreCancel: {
                                status: action.payload.status ?? false,
                                data: action.payload.data ?? null,
                            },
                        }
                    }
                }
            };
        case DEAL_CARD_MODAL_LOGISTICS_CREATE:
            return {...state,
                card: {
                    ...state.card,
                    logistics: {
                        ...state.card.logistics,
                        modal: {
                            ...state.card.logistics.modal,
                            create: {
                                status: action.payload.status ?? false,
                                data: action.payload.data ?? null,
                            },
                        }
                    }
                }
            };
        case DEAL_CARD_MODAL_LOGISTICS_BREAK_PRODUCT:
            return {...state,
                card: {
                    ...state.card,
                    logistics: {
                        ...state.card.logistics,
                        modal: {
                            ...state.card.logistics.modal,
                            breakProduct: {
                                status: action.payload.status ?? false,
                                data: action.payload.data ?? null,
                            },
                        }
                    }
                }
            };
        case DEAL_CARD_FILES_FETCH:
            return {...state,
                card: {
                    ...state.card,
                    files: {
                        ...state.card.files,
                        list: {
                            ...state.card.files.list,
                            loading: true,
                            reload: false,
                        }
                    }
                }
            };
        case DEAL_CARD_FILES_SET:
            return {...state,
                card: {
                    ...state.card,
                    files: {
                        ...state.card.files,
                        list: {
                            ...state.card.files.list,
                            loading: false,
                            data: action.payload
                        }
                    }
                }
            };
        case DEAL_CARD_FILES_LOADING:
            return {...state,
                card: {
                    ...state.card,
                    files: {
                        ...state.card.files,
                        list: {
                            ...state.card.files.list,
                            loading: action.payload,
                        }
                    }
                }
            };
        case DEAL_CARD_FILES_RELOAD:
            return {...state,
                card: {
                    ...state.card,
                    files: {
                        ...state.card.files,
                        list: {
                            ...state.card.files.list,
                            reload: action.payload,
                        }
                    }
                }
            };
        default:
            return { ...state };
    }
};
