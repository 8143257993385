// const maxViewedNotification = 10;

const filterNotifications = (list) => {
    const newList = [];
    const newListViewed = [];
    if (!list.length) return newList;
    // let countViewed = 0;
    list.forEach(item => {
        const status = Number.parseInt(item.status, 10);
        if (!status) newList.push(item);
        if (status /* && countViewed < maxViewedNotification */) {
            newListViewed.push(item);
            // eslint-disable-next-line no-plusplus
            // countViewed++;
        }
    });
    return newList.concat(newListViewed);
};
export default filterNotifications;