import { SendGet } from '../handler/sendGet';
import { sendPut } from '../handler/sendPut';
import { sendPost } from '../handler/sendPost';
import {sendDelete} from "../handler/sendDelete";

export const getScenarioTasks = (params) => {
  return SendGet(`scenario-tasks`, params);
};
export const getScenarioTask = ({id, params}) => {
  return SendGet(`scenario-tasks/${id}`, params);
};
export const updateScenarioTask = (params) => {
  return sendPost(`scenario-tasks/update`, params);
};
export const deleteScenarioTask = (params) => {
  return SendGet(`scenario-tasks/delete`, params);
};