import React from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import {Modal} from '@mui/material';
import css from './modal.module.scss'

const ModalElem = ({check = true, children}) => {
    return (
        <Modal
            open={check}
            className={css.modal}
        >
            <div className={css.modalBody}>
                {children}
            </div>
        </Modal>
    )
}

export default ModalElem