// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userRank_rank__r39fq {
  display: flex;
  align-items: center;
  padding: 3px 0; }
  .userRank_rank__r39fq .userRank_rankText__T\\+zI9 {
    margin-left: 5px; }
`, "",{"version":3,"sources":["webpack://./src/helpers/userRank/userRank.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,cAAc,EAAA;EAHhB;IAMI,gBAAgB,EAAA","sourcesContent":[".rank {\n  display: flex;\n  align-items: center;\n  padding: 3px 0;\n\n  .rankText {\n    margin-left: 5px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rank": `userRank_rank__r39fq`,
	"rankText": `userRank_rankText__T+zI9`
};
export default ___CSS_LOADER_EXPORT___;
