// eslint-disable-next-line import/named

import {
    CRM_DOCUMENT_FETCH_DATA,
    CRM_DOCUMENT_LOADING, CRM_DOCUMENT_PRODUCTS_GET, CRM_DOCUMENT_PRODUCTS_SET,
    CRM_DOCUMENT_SET_DATA,
    CRM_DOCUMENT_SET_ERRORS,
    CRM_DOCUMENTS_FETCH_DATA,
    CRM_DOCUMENTS_LOADING,
    CRM_DOCUMENTS_RELOAD,
    CRM_DOCUMENTS_SET_DATA,
} from '../../actions';

const INIT_STATE = {
    index: {
        loading: false,
        list: [],
        params: {},
        errors: null,
        reload: false,
    },
    card: {
        loading: false,
        data: null,
        reload: false,
        errors: null,
    },
    products: {
        list: {
            data: [],
        },
        isFetching: false,
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CRM_DOCUMENTS_FETCH_DATA:
            return {
                ...state,
                index: { ...state.index, reload: false },
            };
        case CRM_DOCUMENTS_SET_DATA:
            return {
                ...state,
                index: { ...state.index, list: action.payload },
            };
        case CRM_DOCUMENTS_LOADING:
            return {
                ...state,
                index: { ...state.index, loading: action.payload },
            };
        case CRM_DOCUMENTS_RELOAD:
            return {
                ...state,
                index: { ...state.index, reload: action.payload },
            };
        case CRM_DOCUMENT_FETCH_DATA:
            return {
                ...state,
                card: { ...state.card, reload: false },
            };
        case CRM_DOCUMENT_SET_DATA:
            return {
                ...state,
                card: { ...state.card, data: action.payload },
            };
        case CRM_DOCUMENT_LOADING:
            return {
                ...state,
                card: { ...state.card, loading: action.payload },
            };
        case CRM_DOCUMENT_SET_ERRORS:
            return {
                ...state,
                card: { ...state.card, errors: action.payload },
            };
        case CRM_DOCUMENT_PRODUCTS_GET:
            return {
                ...state,
                products: {
                    ...state.products,
                    isFetching: true
                },
            };
        case CRM_DOCUMENT_PRODUCTS_SET:
            return {
                ...state,
                products: {
                    ...state.products,
                    list: {
                        data: action.payload
                    },
                    isFetching: false
                },
            };
        default:
            return { ...state };
    }
};
