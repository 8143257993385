import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import ModalNextTask from "../../../../../modal/ModalNextTask";
import {taskActions} from "../../../../../../redux/toolkit/task/slice";
import {setStatusModalCancelOrder} from "../../../../../../redux/crm/order/actions";
import {websocketSetEvent} from "../../../../../../redux/websocket/actions";
import {taskSelectors} from "../../../../../../redux/toolkit/task/selectors";
import {taskListReload} from "../../../../../../redux/crm/task/actions";
import {appActions} from "../../../../../../redux/toolkit/app/slice";

const NextTask = () => {
    const dispatch = useDispatch();
    const previousTask = useSelector(taskSelectors.previousTask);// Для создания новой задачи (для того же заказа и контрагента) при закрытии старой

    const {status: modalStatus} = useSelector(taskSelectors.modalNextTask);
    const taskEvent = useSelector((state) => state.websocket.event.task); // событие срабатывает, когда нет будущих задач.

    const orderId = previousTask?.order?.id ?? null;
    const clientData = previousTask?.organization ?? null;

    const onCloseOrder = () => {
        if (!orderId) {
            dispatch(appActions.setNotification({type: 'error', message: 'Не удалось идентифицировать заказ'}));
            return false;
        }
        dispatch(setStatusModalCancelOrder({show: true, orderId}));
        return true;
    };

    const onCreateTask = () => {
        dispatch(taskActions.setModalCreate({
            status: true,
            data: {
                orderID: orderId,
                clientData,
                callbackAfterSave: () => dispatch(taskListReload(true)),
            }
        }))
    };

    useEffect(() => {
        if (taskEvent) {
            const actionEvent = taskEvent?.action;
            const forOrderId = Number(taskEvent?.order_id);
            const previousOrderId = Number(previousTask?.order?.id);
            if (actionEvent === 'checkNextTask' && forOrderId && previousOrderId === forOrderId) {
                dispatch(taskActions.setStatusModalNextTask(true));
            }
        }
    }, [taskEvent]);

    useEffect(() => {
        if (!modalStatus) {
            dispatch(taskActions.setPreviousTask(null));
        }
    }, [modalStatus]);

    useEffect(() => {
        return () => {
            dispatch(websocketSetEvent({ event: 'task', params: null }));
        }
    }, []);

    return (
        <>
            <ModalNextTask
                isShow={modalStatus}
                changeShow={() => dispatch(taskActions.setStatusModalNextTask(false))}
                onCloseOrder={onCloseOrder}
                onCreateTask={onCreateTask}
            />
        </>
    )
}
export default NextTask