import React from 'react';
import { Row } from 'reactstrap';
import moment from "moment";
import { Colxx } from '../../components/common/CustomBootstrap';

const Footer = () => {
    return (
        <footer className="page-footer">
            <div className="footer-content">
                <div className="container-fluid">
                    <Row>
                        <Colxx xxs="12" sm="6">
                            <p className="mb-0 text-muted">
                                by{' '}
                                <a
                                    href="https://myspaceport.ru"
                                    rel="noreferrer"
                                    target="_blank"
                                    className="btn-link"
                                >
                                    Spaceport
                                </a>{' '}
                                {moment().format('Y')}
                            </p>
                        </Colxx>
                        <Colxx className="col-sm-6 d-none d-sm-block" />
                    </Row>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
