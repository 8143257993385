// eslint-disable-next-line import/named
import {
    CRM_ORDER_ADD_EXIST_PRODUCT_ERRORS,
    CRM_ORDER_CLIENT_FETCH,
    CRM_ORDER_CLIENT_LOADING,
    CRM_ORDER_CLIENT_RELOAD,
    CRM_ORDER_CLIENT_SET,
    CRM_ORDER_CLIENT_UPDATE_CONTACT,
    CRM_ORDER_COUNT_NEW,
    CRM_ORDER_COUNT_YOU_RESPONSIBLE,
    CRM_ORDER_DISABLED,
    CRM_ORDER_DOCUMENT_FILES_FETCH,
    CRM_ORDER_DOCUMENT_FILES_LOADING,
    CRM_ORDER_DOCUMENT_FILES_RELOAD,
    CRM_ORDER_DOCUMENT_FILES_SET,
    CRM_ORDER_FETCH_PRODUCTS,
    CRM_ORDER_FETCH_PRODUCTS_LOADING,
    CRM_ORDER_FETCH_PRODUCTS_RELOAD,
    CRM_ORDER_FOUND_PRODUCTS,
    CRM_ORDER_LOADING,
    CRM_ORDER_LOADING_DOCUMENTS,
    CRM_ORDER_MODAL_ABOUT_PRODUCT_SHOW,
    CRM_ORDER_MODAL_ADD_PRODUCT_SHOW,
    CRM_ORDER_MODAL_ANALOG_PRODUCT_SHOW,
    CRM_ORDER_MODAL_CANCEL_SHOW,
    CRM_ORDER_MODAL_CREATE_DOCUMENT_SHOW,
    CRM_ORDER_MODAL_EDIT_PRODUCT_SHOW,
    CRM_ORDER_MODAL_FILTER_LISTING_SHOW,
    CRM_ORDER_MODAL_LOGS_PRODUCT_SHOW,
    CRM_ORDER_MODAL_MASS_EDIT_PRODUCT_SHOW,
    CRM_ORDER_MODAL_PRODUCT_RELATED_SHOW,
    CRM_ORDER_MODAL_MOVE_DOCUMENT_SHOW, CRM_ORDER_MODAL_SCORE_PRODUCT_SHOW,
    CRM_ORDER_MODAL_ACTION_AUTO_PERSENT_SHOW,
    CRM_ORDER_MODAL_SEARCH_DOCUMENT_SHOW,
    CRM_ORDER_MODAL_SEARCH_PRODUCT_SHOW,
    CRM_ORDER_MODAL_SIMILARS_PRODUCT_SHOW,
    CRM_ORDER_MODAL_SORTING_LISTING_SHOW,
    CRM_ORDER_PRODUCT_SUPPLIER_SET,
    CRM_ORDER_PRODUCTS_ADDED_ID,
    CRM_ORDER_RELOAD,
    CRM_ORDER_RELOAD_DOCUMENTS,
    CRM_ORDER_REMOVE_PRODUCT,
    CRM_ORDER_SEARCHING_PRODUCT,
    CRM_ORDER_SELECTED_DOCUMENT,
    CRM_ORDER_SELECTED_PRODUCTS,
    CRM_ORDER_SELECTED_TASKS,
    CRM_ORDER_SET_DATA,
    CRM_ORDER_SET_DOCUMENTS,
    CRM_ORDER_SET_EDITABLE_PRODUCT,
    CRM_ORDER_SET_ERROR,
    CRM_ORDER_SET_PRODUCTS,
    CRM_ORDERS_RELOAD,
    CRM_ORDERS_SET_DATA,
    CRM_ORDERS_SET_PARAMS,
    ORDER_CARD_COMMENTS_FETCH,
    ORDER_CARD_COMMENTS_LOADING,
    ORDER_CARD_COMMENTS_RELOAD,
    ORDER_CARD_COMMENTS_SET,
    ORDER_CARD_FEEDBACK_FETCH,
    ORDER_CARD_FEEDBACK_LOADING,
    ORDER_CARD_FEEDBACK_RELOAD,
    ORDER_CARD_FEEDBACK_SET,
} from '../../actions';

const INIT_STATE = {
    loading: false,
    orderData: {},
    errors: {},
    isError: false,
    orders: {},
    reloadOrders: false,
    reload: false,
    ordersParams: {},
    client: {
        updateContact: null,
        data: null,
        reload: false,
        loading: false,
    },
    products: {
        productsFound: [],
        searching: false,
        list: [],
        loading: false, // загрузка продуктов
        addExistErrors: [], // ошибки при добавлении продукта из имеющихся
        selectedProducts: [],
        editProduct: null,
        reload: false,
        justAddedID: null, // только что добавленный товар
        tempSupplier: [], // список временных поставщиков
    },
    documents: {
        list: [],
        loading: false,
        reload: false,
        selected: null,
    },
    documentFiles: {
        list: [],
        loading: false,
        reload: false,
    },
    tasks: {
        selected: [],
    },
    countNew: 0, // кол-во новых заказов
    countYouResponsible: 0, // ты ответственный за заказ
    orderDisabled: false, // выполнение действий в карточке
    modalCancel: {
        show: false,
        orderId: null,
    },
    modalEditProduct: {
        show: false,
        data: null,
    },
    modalAboutProduct: {
        show: false,
        data: null,
    },
    modalLogsProduct: {
        show: false,
        data: null,
    },
    modalAnalogProduct: {
        show: false,
        data: null,
    },
    modalSimilarsProduct: {
        show: false,
        data: null,
    },
    modalScoreProduct: {
        show: false,
        data: null
    },
    modalAutoPercent: {
        show: false
    },
    modalAddProduct: {
        show: false,
        data: null,
        callback: null
    },
    modalSearchProduct: {
        show: false
    },
    modalSortingListing: {
        show: false,
        data: null
    },
    modalFilterListing: {
        show: false,
        data: null
    },
    modalMoveDocument: {
        show: false,
        data: null,
    },
    modalSearchDocument: {
        show: false,
        data: null,
    },
    modalCreateDocument: {
        show: false,
        data: null,
    },
    modalMassEditProduct: {
        show: false,
        data: null,
    },
    modalProductRelated: {
        show: false,
        showed: false,
        data: null,
    },
    comments: { // комментарий
        list: {
            loading: false,
            data: {},
            reload: false,
        }
    },
    feedback: {
        list: {
            loading: false,
            data: null,
            reload: false,
        }
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CRM_ORDER_LOADING:
            return { ...state, loading: action.payload };
        case CRM_ORDER_DISABLED:
            return { ...state, orderDisabled: action.payload };
        case CRM_ORDER_SET_DATA:
            return {
                ...state,
                orderData: action.payload,
                reload: false,
                isError: false,
                errors: {},
            };
        case CRM_ORDERS_SET_DATA:
            return {
                ...state,
                orders: action.payload,
                reloadOrders: false,
            };
        case CRM_ORDERS_SET_PARAMS:
            return {
                ...state,
                ordersParams: action.payload,
            };
        case CRM_ORDER_SET_ERROR:
            return { ...state, errors: action.payload, isError: true };
        case CRM_ORDER_RELOAD:
            return { ...state, reload: action.payload };
        case CRM_ORDER_SEARCHING_PRODUCT:
            return {
                ...state,
                products: {
                    ...state.products,
                    searching: action.payload,
                    // productsFound: action.payload ? [] : [...state.products.productsFound],
                },
            };
        case CRM_ORDER_FOUND_PRODUCTS:
            return {
                ...state,
                products: { ...state.products, productsFound: action.payload },
            };
        case CRM_ORDER_SET_PRODUCTS:
            return {
                ...state,
                products: { ...state.products, list: action.payload },
            };
        case CRM_ORDER_FETCH_PRODUCTS_LOADING:
            return {
                ...state,
                products: { ...state.products, loading: action.payload },
            };
        case CRM_ORDER_FETCH_PRODUCTS_RELOAD:
            return {
                ...state,
                products: { ...state.products, reload: action.payload },
            };
        case CRM_ORDER_FETCH_PRODUCTS:
            return {
                ...state,
                products: { ...state.products, reload: false },
            };
        case CRM_ORDER_ADD_EXIST_PRODUCT_ERRORS:
            return {
                ...state,
                products: { ...state.products, addExistErrors: action.payload },
            };
        case CRM_ORDER_SELECTED_PRODUCTS:
            // eslint-disable-next-line no-case-declarations
            const { type } = action.payload;
            // eslint-disable-next-line no-case-declarations
            let { value } = action.payload;
            if (!Array.isArray(value)) {
                value = [value];
            }
            switch (type) {
                case 'add':
                    // eslint-disable-next-line no-case-declarations
                    const notExistArr = value.filter((elem) => {
                        return !state.products.selectedProducts.includes(elem);
                    });
                    return {
                        ...state,
                        products: {
                            ...state.products,
                            selectedProducts: [
                                ...state.products.selectedProducts,
                                ...notExistArr,
                            ],
                        },
                    };
                case 'remove':
                    return {
                        ...state,
                        products: {
                            ...state.products,
                            selectedProducts: state.products.selectedProducts.filter((x) => {
                                return !value.includes(x);
                            }),
                        },
                    };
                case 'clear':
                    return {
                        ...state,
                        products: {
                            ...state.products,
                            selectedProducts: [],
                        },
                    };
                default:
                    return {
                        ...state,
                        products: {
                            ...state.products,
                            selectedProducts: value,
                        },
                    };
            }
        case CRM_ORDER_SET_EDITABLE_PRODUCT:
            return {
                ...state,
                products: { ...state.products, editProduct: action.payload },
            };
        case CRM_ORDER_PRODUCTS_ADDED_ID:
            return {
                ...state,
                products: { ...state.products, justAddedID: action.payload },
            };
        case CRM_ORDER_REMOVE_PRODUCT:
            return {
                ...state,
                products: {
                    ...state.products,
                    list: state.products.list.filter((x) => {
                        return x.id !== action.payload;
                    }),
                },
            };
        case CRM_ORDER_SET_DOCUMENTS:
            return {
                ...state,
                documents: { ...state.documents, list: action.payload },
            };
        case CRM_ORDER_LOADING_DOCUMENTS:
            return {
                ...state,
                documents: { ...state.documents, loading: action.payload },
            };
        case CRM_ORDER_RELOAD_DOCUMENTS:
            return {
                ...state,
                documents: { ...state.documents, reload: action.payload },
            };
        case CRM_ORDER_SELECTED_DOCUMENT:
            return {
                ...state,
                documents: { ...state.documents, selected: action.payload },
            };
        case CRM_ORDER_CLIENT_UPDATE_CONTACT:
            return {
                ...state,
                client: { ...state.client, updateContact: action.payload },
            };
        case CRM_ORDERS_RELOAD:
            return { ...state, reloadOrders: action.payload };
        case CRM_ORDER_SELECTED_TASKS:
            // eslint-disable-next-line no-case-declarations
            const taskSelectedType = action.payload.type;
            // eslint-disable-next-line no-case-declarations
            let taskSelectedValue = action.payload.value;
            if (taskSelectedValue.constructor !== Array) {
                taskSelectedValue = [taskSelectedValue];
            }
            switch (taskSelectedType) {
                case 'add':
                    // eslint-disable-next-line no-case-declarations
                    const notExistArr = taskSelectedValue.filter((elem) => {
                        return !state.tasks.selected.includes(elem);
                    });
                    return {
                        ...state,
                        tasks: {
                            ...state.tasks,
                            selected: [...state.tasks.selected, ...notExistArr],
                        },
                    };
                case 'remove':
                    return {
                        ...state,
                        tasks: {
                            ...state.tasks,
                            selected: state.tasks.selected.filter((x) => {
                                return !taskSelectedValue.includes(x);
                            }),
                        },
                    };
                case 'clear':
                    return {
                        ...state,
                        tasks: {
                            ...state.tasks,
                            selected: [],
                        },
                    };
                default:
                    return { ...state };
            }
        case CRM_ORDER_DOCUMENT_FILES_FETCH:
            return {
                ...state,
                documentFiles: { ...state.documentFiles, reload: false },
            };
        case CRM_ORDER_DOCUMENT_FILES_SET:
            return {
                ...state,
                documentFiles: { ...state.documentFiles, list: action.payload },
            };
        case CRM_ORDER_DOCUMENT_FILES_LOADING:
            return {
                ...state,
                documentFiles: { ...state.documentFiles, loading: action.payload },
            };
        case CRM_ORDER_DOCUMENT_FILES_RELOAD:
            return {
                ...state,
                documentFiles: { ...state.documentFiles, reload: action.payload },
            };
        case CRM_ORDER_PRODUCT_SUPPLIER_SET:
            return {
                ...state,
                products: { ...state.products, tempSupplier: action.payload },
            };
        case CRM_ORDER_COUNT_NEW:
            return { ...state, countNew: action.payload };
        case CRM_ORDER_COUNT_YOU_RESPONSIBLE:
            return { ...state, countYouResponsible: action.payload };
        case CRM_ORDER_MODAL_CANCEL_SHOW: {
            const {show, orderId} = action.payload;
            return {
                ...state,
                modalCancel: { ...state.modalCancel, show: (show ?? false), orderId: (orderId ?? null) },
            };
        }
        case CRM_ORDER_MODAL_EDIT_PRODUCT_SHOW: {
            const {show, data} = action.payload;
            return {
                ...state,
                modalEditProduct: { ...state.modalEditProduct, show: (show ?? false), data: (data ?? null) },
            };
        }
        case CRM_ORDER_MODAL_ABOUT_PRODUCT_SHOW: {
            return {
                ...state,
                modalAboutProduct: { ...state.modalAboutProduct, ...action.payload}
            }
        }
        case CRM_ORDER_MODAL_LOGS_PRODUCT_SHOW: {
            return {
                ...state,
                modalLogsProduct: { ...state.modalLogsProduct, ...action.payload}
            }
        }
        case CRM_ORDER_MODAL_ANALOG_PRODUCT_SHOW: {
            return {
                ...state,
                modalAnalogProduct: { ...state.modalAnalogProduct, ...action.payload}
            }
        }
        case CRM_ORDER_MODAL_SIMILARS_PRODUCT_SHOW: {
            return {
                ...state,
                modalSimilarsProduct: { ...state.modalSimilarsProduct, ...action.payload}
            }
        }
        case CRM_ORDER_MODAL_SCORE_PRODUCT_SHOW: {
            return {
                ...state,
                modalScoreProduct: {...state.modalScoreProduct, ...action.payload}
            }
        }
        case CRM_ORDER_MODAL_ACTION_AUTO_PERSENT_SHOW: {
            return {
                ...state,
                modalAutoPercent: {...state.modalAutoPercent, ...action.payload}
            }
        }
        case CRM_ORDER_MODAL_ADD_PRODUCT_SHOW: {
            return {
                ...state,
                modalAddProduct: { ...state.modalAddProduct, ...action.payload}
            }
        }
        case CRM_ORDER_MODAL_SEARCH_PRODUCT_SHOW: {
            return {
                ...state,
                modalSearchProduct: { ...state.modalSearchProduct, ...action.payload}
            }
        }
        case CRM_ORDER_MODAL_SORTING_LISTING_SHOW: {
            return {
                ...state,
                modalSortingListing: { ...state.modalSortingListing, ...action.payload}
            }
        }
        case CRM_ORDER_MODAL_FILTER_LISTING_SHOW: {
            return {
                ...state,
                modalFilterListing: { ...state.modalFilterListing, ...action.payload}
            }
        }
        case CRM_ORDER_MODAL_MOVE_DOCUMENT_SHOW: {
            const {show, data} = action.payload;
            return {
                ...state,
                modalMoveDocument: { ...state.modalMoveDocument, show: (show ?? false), data: (data ?? null) },
            };
        }
        case CRM_ORDER_MODAL_SEARCH_DOCUMENT_SHOW: {
            const {show, data} = action.payload;
            return {
                ...state,
                modalSearchDocument: { ...state.modalSearchDocument, show: (show ?? false), data: (data ?? null) },
            };
        }
        case CRM_ORDER_MODAL_CREATE_DOCUMENT_SHOW: {
            const {show, data} = action.payload;
            return {
                ...state,
                modalCreateDocument: { ...state.modalCreateDocument, show: (show ?? false), data: (data ?? null) },
            };
        }
        case CRM_ORDER_MODAL_MASS_EDIT_PRODUCT_SHOW: {
            const {show, data} = action.payload;
            return {
                ...state,
                modalMassEditProduct: { ...state.modalMassEditProduct, show: (show ?? false), data: (data ?? null) },
            };
        }
        case CRM_ORDER_MODAL_PRODUCT_RELATED_SHOW: {
            const {show, data} = action.payload;
            return {
                ...state,
                modalProductRelated: { ...state.modalProductRelated, show: (show ?? false), showed: true, data: (data ?? null) },
            };
        }
        case CRM_ORDER_CLIENT_FETCH:
            return {...state,
                client: {
                    ...state.client,
                    loading: true,
                    reload: false,
                }
            };
        case CRM_ORDER_CLIENT_SET:
            return {...state,
                client: {
                    ...state.client,
                    loading: false,
                    data: action.payload,
                }
            };
        case CRM_ORDER_CLIENT_LOADING:
            return {...state,
                client: {
                    ...state.client,
                    loading: action.payload,
                }
            };
        case CRM_ORDER_CLIENT_RELOAD:
            return {...state,
                client: {
                    ...state.client,
                    reload: action.payload,
                }
            };
        case ORDER_CARD_COMMENTS_FETCH:
            return {...state,
                comments: {
                    ...state.comments,
                    list: {
                        ...state.comments.list,
                        loading: true,
                        reload: false,
                    }
                }
            };
        case ORDER_CARD_COMMENTS_SET:
            return {...state,
                comments: {
                    ...state.comments,
                    list: {
                        ...state.comments.list,
                        loading: false,
                        data: action.payload
                    }
                }
            };
        case ORDER_CARD_COMMENTS_LOADING:
            return {...state,
                comments: {
                    ...state.comments,
                    list: {
                        ...state.comments.list,
                        loading: action.payload,
                    }
                }
            };
        case ORDER_CARD_COMMENTS_RELOAD:
            return {...state,
                comments: {
                    ...state.comments,
                    list: {
                        ...state.comments.list,
                        reload: action.payload,
                    }
                }
            };
        case ORDER_CARD_FEEDBACK_FETCH:
            return {...state,
                feedback: {
                    ...state.feedback,
                    list: {
                        ...state.feedback.list,
                        loading: true,
                        reload: false,
                    }
                }
            };
        case ORDER_CARD_FEEDBACK_SET:
            return {...state,
                feedback: {
                    ...state.feedback,
                    list: {
                        ...state.feedback.list,
                        loading: false,
                        data: action.payload
                    }
                }
            };
        case ORDER_CARD_FEEDBACK_LOADING:
            return {...state,
                feedback: {
                    ...state.feedback,
                    list: {
                        ...state.feedback.list,
                        loading: action.payload,
                    }
                }
            };
        case ORDER_CARD_FEEDBACK_RELOAD:
            return {...state,
                feedback: {
                    ...state.feedback,
                    list: {
                        ...state.feedback.list,
                        reload: action.payload,
                    }
                }
            };
        default:
            return { ...state };
    }
};
