// eslint-disable-next-line import/prefer-default-export
// eslint-disable-next-line import/prefer-default-export
export const numberFormat = (number, decimals, decPoint = '.', thousandsSep = ' ') => {
    const newNumber = (`${number  }`).replace(/[^0-9+\-Ee.]/g, '');
    const n = !Number.isFinite(+newNumber) ? 0 : +newNumber;
    const prec = !Number.isFinite(+decimals) ? 0 : Math.abs(decimals);
        // eslint-disable-next-line camelcase
    const sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep;
        // eslint-disable-next-line camelcase
    const dec = (typeof decPoint === 'undefined') ? '.' : decPoint;
    let s = [];

    // eslint-disable-next-line no-shadow
    const toFixedFix = (n, prec) => {
        const k = 10 ** prec;
        return `${  (Math.round(n * k) / k)
            .toFixed(prec)}`;
    };

    s = (prec ? toFixedFix(n, prec) : `${  Math.round(n)}`)
        .split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '')
        .length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1)
            .join('0');
    }
    return s.join(dec);
};