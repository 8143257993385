import {STATISTICS_DEAL_LOADING, STATISTICS_DEAL_DATA} from "../../actions";

const INIT_STATE = {
    loading: false,
    orders: {body: [],general: []},
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case STATISTICS_DEAL_LOADING:
            return {...state, loading: action.payload};
        case STATISTICS_DEAL_DATA:
            return {...state, orders: action.payload};
        default:
            return {...state};
    }
}