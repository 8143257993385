import { SendGet } from '../handler/sendGet';
import {sendPut} from "../handler/sendPut";
import {sendPost} from "../handler/sendPost";

// eslint-disable-next-line import/prefer-default-export
export const getOrderFeedback = (params) => {
  return SendGet(`order-feedbacks`, params);
};
export const orderFeedbackUpdate = ({ id, params }) => {
  return sendPut(`order-feedbacks/${id}`, params);
};
export const orderFeedbackMassOperation = (params) => {
  return sendPost(`order-feedbacks/mass-operation`, params);
};