// eslint-disable-next-line no-unused-vars
/* eslint-disable no-use-before-define */
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { USER_CARD_FETCH, USER_CHANGE } from '../actions';
import {setMeData} from "../../api/query/user";
import { changeUserDataLoading, changeUserDataSuccess, getUserDataLoading, setUserData } from './actions';
import {getMeInfo} from '../../api/query/auth';
import ErrorResponse from "../../components/common/ErrorResponse";
import {appActions} from "../toolkit/app/slice";

export function* watchGet() {
    yield takeLatest(USER_CARD_FETCH, getHandler);
}
function* getHandler({ payload }) {
    try {
        yield put(getUserDataLoading(true));
        const getData = yield call(getMeInfo, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(setUserData(getData.data));
        }
        yield put(getUserDataLoading(false));
    } catch (error) {
        const errorResponse = ErrorResponse(error.response);
        yield put(appActions.setNotification({type: 'error', message: errorResponse}));
        yield put(getUserDataLoading(false));
    }
}
export function* watchChange() {
    yield takeLatest(USER_CHANGE, changeHandler);
}
function* changeHandler({ payload }) {
    try {
        yield put(changeUserDataLoading(true));
        const response = yield call(setMeData, payload);
        const codeResponse = Number.parseInt(response.status, 10);
        if (codeResponse === 200) {
            yield put(changeUserDataSuccess(Date.now()));
        }
        yield put(changeUserDataLoading(false));
    } catch (error) {
        const errorResponse = ErrorResponse(error.response);
        yield put(appActions.setNotification({type: 'error', message: errorResponse}));
        yield put(changeUserDataLoading(false));
    }
}
export default function* rootSaga() {
    yield all([
        fork(watchChange),
        fork(watchGet),
    ]);
}
