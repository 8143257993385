import {SendGet} from "../handler/sendGet";
import {sendPost} from "../handler/sendPost";

export const getAudiences = (params) => {
  return SendGet(`organization-audiences`, params);
};
export const getAudience = ({id, params}) => {
  return SendGet(`organization-audiences/${id}`, params);
};
export const updateAudience = (params) => {
  return sendPost(`organization-audiences/update`, params);
};
export const getOrganizationsList = (params) => {
  return SendGet(`organization-audience-lists`, params);
};
export const addContragent = (params) => {
  return sendPost(`organization-audiences/add-contragent`, params);
};
export const removeContragent = (params) => {
  return sendPost(`organization-audiences/remove-contragent`, params);
};
export const removeAudience = (params) => {
  return sendPost(`organization-audiences/remove`, params);
};
export const download = (params) => {
  return sendPost(`organization-audiences/download`, params);
};