import React from "react";
import DatePicker from "react-datepicker";

const DateStart = ({value, setValue, isReadOnly}) => {
    return (
        <>
            <div className="font-weight-bold">Старт задачи:</div>
            <div className="mt-2">
                <DatePicker
                    locale="ru"
                    selected={value}
                    onChange={setValue}
                    dateFormat="dd.MM.yyyy"
                    className="text-left form-control style-2"
                    closeOnScroll
                    readOnly={isReadOnly}
                />
            </div>
        </>
    )
}
export default DateStart;