import {sendPost} from "../handler/sendPost";
import {SendGet} from "../handler/sendGet";

// eslint-disable-next-line import/prefer-default-export
export const watchEnd = (params) => {
    return sendPost(`crms/watch-end`, params);
};
export const getWatch = (params) => {
    return SendGet(`crms/watch`, params);
};
export const getUsersByGroup = (params) => {
    return SendGet(`crms/users-by-group`, params);
};