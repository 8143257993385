import React from 'react';

const CityCrossIcon = ({width="14", height="14"}) => (
    <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 1L1 13" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M1 1L13 13" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

);

export default CityCrossIcon;