// eslint-disable-next-line import/named
import {
    CRM_ORDER_FEEDBACK_FETCH_DATA, CRM_ORDER_FEEDBACK_LOADING, CRM_ORDER_FEEDBACK_RELOAD, CRM_ORDER_FEEDBACK_SET,
} from '../../actions';

const INIT_STATE = {
    list: {
        loading: false,
        reload: false,
        data: null,
    }
};

export default (state = INIT_STATE, action) => {

    switch (action.type) {
        case CRM_ORDER_FEEDBACK_FETCH_DATA:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: true
                }
            };
        case CRM_ORDER_FEEDBACK_LOADING:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: action.payload
                }
            };
        case CRM_ORDER_FEEDBACK_RELOAD:
            return {
                ...state,
                list: {
                    ...state.list,
                    reload: action.payload
                }
            };
        case CRM_ORDER_FEEDBACK_SET:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    data: action.payload
                }
            };
        default:
            return { ...state };
    }
};
