// eslint-disable-next-line import/prefer-default-export
export const emailIsSubscribeNews = (emailId, subscriptions, subscribeId) => {
    if (!emailId) return 0;
    const subscribe = subscriptions.find(x => x.subscription_id === subscribeId);
    if (typeof subscribe === 'undefined') return 0;
    return !!subscribe.emails?.filter(x => x.id === emailId)?.length;
};

export const prepareContactEmails = (emails, subscriptions, contactId = null) => {
    if (!emails.length) return emails;
    const result = [];
    const subscribe = subscriptions?.find(x => x.subscription_id === 1) ?? null;
    emails.forEach((email) => {
        let mailingCommercial = false;
        let mailingCommercialAllowChange = true;
        if (subscribe) {
            const findSubscribe = subscribe.emails?.filter(se => se.id === email.id);
            if (findSubscribe?.length) {
                mailingCommercial = true;
            }
            if (subscribe.updated_user_id === contactId && !findSubscribe?.length) {
                mailingCommercialAllowChange = false;
            }
        }
        const preEmail = {
            ...email,
            mailing_commercial: mailingCommercial,
            mailing_commercial_allow_change: mailingCommercialAllowChange
        };
        result.push(preEmail);
    });
    return result;
}