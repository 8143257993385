/* eslint-disable */
import { createSlice } from "@reduxjs/toolkit";
import React from "react";
import {defaultComments} from "./model";

const initialState = {
    card: {
        data: null,
        loading: false,
        watchers: {
            loading: false,
            data: [],
        },
        comments: {
            loading: false,
            data: defaultComments,
        },
        changeContragent: false,
        types: [],
    },
    modalCreate: {
        status: false,
        data: null,
    },
    modalNextTask: {
        status: false,
    },
    previousTask: null
};

const slice = createSlice({
    name: "task",
    initialState,
    reducers: {
        setWatchers: (state, { payload }) => {
            state.card.watchers = {
                ...state.card.watchers,
                data: payload,
                loading: false,
            };
        },
        fetchWatchers: (state, { payload }) => {
            state.card.watchers.loading = true;
        },
        setComments: (state, { payload }) => {
            state.card.comments = {
                ...state.card.comments,
                data: payload || defaultComments,
                loading: false,
            };
        },
        fetchComments: (state, { payload }) => {
            state.card.comments.loading = true;
        },
        setChangeContragent: (state, { payload }) => {
            state.card.changeContragent = payload;
        },
        setStatusModalCreate: (state, { payload }) => {
            state.modalCreate.status = payload;
        },
        setDataModalCreate: (state, { payload }) => {
            state.modalCreate.data = payload;
        },
        setModalCreate: (state, { payload }) => {
            state.modalCreate = {
                ...state.modalCreate,
                ...payload
            };
        },
        fetchTask: (state, { payload }) => {
            state.card.loading = true;
        },
        setTask: (state, { payload }) => {
            state.card = {
                ...state.card,
                data: payload,
                loading: false,
            };
            if (payload) {
                state.previousTask = payload;
            }
        },
        fetchTaskTypes: (state, { payload }) => {

        },
        setTaskTypes: (state, { payload }) => {
            state.card.types = payload;
        },
        setStatusModalNextTask: (state, { payload }) => {
            state.modalNextTask.status = payload;
        },
        setPreviousTask: (state, { payload }) => {
            state.previousTask = payload;
        },
    }
});

export const taskReducer = slice.reducer;
export const taskActions = slice.actions;