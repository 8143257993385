import {SendGet} from "../../api/handler/sendGet";

// export class articlesApi {
//     static getArticles(props) {
//         const {
//             expand = [],
//             fields = [],
//             filters = [],
//             limit = 15,
//             page = 1,
//             sort = ['-date_create']
//         } = props;
//         const params = {
//             'selection[filter]': JSON.stringify(filters),
//             expand: expand.join(','),
//             'per-page': limit,
//             page,
//             sort: sort.join(','),
//             fields: fields.join(','),
//         };
//         return axiosInstance
//             .get(`articles`, {params})
//             .then((response) => {
//                 if (response.status === 200) {
//                     return response.data
//                 }
//             })
//     }
//
//     static getArticle(props) {
//         const {
//             expand = [],
//             fields = [],
//             limit = 1,
//             page = 1,
//             slug,
//         } = props;
//         const params = {
//             expand: expand.join(','),
//             'per-page': limit,
//             page,
//             fields: fields.join(','),
//         };
//         return axiosInstance
//             .get(`articles/slug/${slug}`, {params})
//             .then((response) => {
//                 if (response.status === 200) {
//                     return response.data
//                 }
//             })
//     }
// }

export const getArticles = (props) => {
    const {
        expand = [],
        fields = [],
        filters = [],
        limit = 15,
        page = 1,
        sort = ['-date_create']
    } = props;
    const params = {
        'selection[filter]': JSON.stringify(filters),
        expand: expand.join(','),
        'per-page': limit,
        page,
        sort: sort.join(','),
        fields: fields.join(','),
    };
    return SendGet(`articles`, params);
};

export const getArticle = (props) => {
    const {
        expand = [],
        fields = [],
        limit = 1,
        page = 1,
        slug,
    } = props;
    const params = {
        expand: expand.join(','),
        'per-page': limit,
        page,
        fields: fields.join(','),
    };
    return SendGet(`articles/slug/${slug}`, params);
};
