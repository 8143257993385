import React from 'react'
import {Badge, Button} from "@mui/material";
import {defaultChats} from "../../../../../constants/defaultChats";
import css from "../../Comments.module.scss";
import {getChatId} from "../../helper";
import ArrowDown from "../../../../../assets/icons/arrow-down";

const ChatToggle = ({
                        setActiveStyle,
                        chatTypes,
                        activeStyle,
                        activeChat,
                        changeChat = false,
                        setActiveChat = () => {},
                        onSelectedChat = () => {},
                        countNewMessage = []
}) => {

    return (
        <>
            {chatTypes.map((chat) => {
                const chatView = defaultChats.find(x => x.id === chat.view) ?? null;
                if (!chatView) return <></>;

                const chatClass = chatView.class;
                const classes = [css.chat, css[chatClass]];
                const chatName = chat.name ?? chatView.name;
                const activeId = activeChat ? getChatId(activeChat) : null;
                const currentId = getChatId(chat);
                if (activeId === currentId) {
                    classes.push(css.active);

                    setActiveStyle(chatClass)
                }
                // eslint-disable-next-line consistent-return
                if (activeId !== currentId && changeChat) return
                const countNew = countNewMessage[currentId] ?? 0;
                return (
                    <Button key={currentId} className={classes.join(' ')} onClick={() => onSelectedChat(currentId)}>
                        {changeChat &&
                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                            <div className={`${css.messagesArrow} ${activeStyle && css.messagesArrowProvider}`} onClick={() => setActiveChat(null)}>
                                <ArrowDown/>
                            </div>
                        }
                        <span className={css.chatIcon}>St</span>
                        <span className={css.chatName}>{chatName}</span>
                        {!changeChat && <Badge badgeContent={countNew} color="primary" className={`${css.countNew} ${chatClass && css.countNewProvider}`}/>}
                    </Button>
                )
            })}
        </>
    )
}

export default ChatToggle