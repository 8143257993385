import React, {useContext} from 'react';
import moment from 'moment';
import {Button} from "@mui/material";
import CommentsItemFile from '../CommentsItemFile';
import PhotoManagerNew from "../../../profile/photoManagerNew";
import css from "../Comments.module.scss";
import {DesktopContext} from "../../../../utils/hoc/withContext";
import {getNic} from "../helper";

// eslint-disable-next-line camelcase
const CommentsItem = ({ id, message, created, userData, files, sendTo, myMessage }) => {

    const analysisMessage = (mess) => {
        return mess.replace(/(^@\S+\b)/ig, '<a class="nav-link-style-1 nav-to-anchor" data-nic="$&" href="#">$&</a>').replace(/\n/g, '<br/>');
    };

    const substituteHref = (mess) => {
        // http://, https://, ftp://
        const urlPattern = /\b(?:https?|ftp):\/\/[a-z0-9-+&@#\/%?=~_|!:,.;]*[a-z0-9-+&@#\/%=~_|]/gim;
        // www. sans http:// or https://
        const pseudoUrlPattern = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
        // Email addresses
        const emailAddressPattern = /[\w.]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+/gim;

        return mess
            .replace(urlPattern, '<a class="nav-link-style-1" target = "_blank" href="$&">$&</a>')
            .replace(pseudoUrlPattern, '$1<a class="nav-link-style-1" target = "_blank" href="http://$2">$2</a>')
            .replace(emailAddressPattern, '<a class="nav-link-style-1" target = "_blank" href="mailto:$&">$&</a>');
    };

    const onClickMessage = (event) => {
        const {target} = event;
        const classes = Array.from(target.classList);
        if (classes.includes('nav-to-anchor')) {
            event.preventDefault();
            event.stopPropagation();
            const nic = target.getAttribute('data-nic');
            if (typeof nic !== 'undefined' && nic) {
                sendTo(nic.replace('@', ''));
            }
        }
    };

    const onReply = () => {
        const nic = getNic(userData);
        if (nic) {
            sendTo(nic);
        }
    }

    const desktop = useContext(DesktopContext)

    return (
        <>
            <div
                data-id={id}
                className={`${css.commentsContentItem} ${myMessage ? `${css.commentsReverse} ${css.reverse}` : ''}`}
            >
                <PhotoManagerNew data={userData} className={[css.photoPerson]}/>
                <div className={`${css.messageContainer}`}>
                    <div className="row ml-0 mr-0">
                        <div className="col-12 pr-1 pl-1">
                            <div className="d-flex align-items-center justify-content-between position-relative">
                                <div className={`${css.name} font-weight-semibold`}>
                                    {`${userData.first_name} ${userData.last_name}`}
                                </div>
                                <div className="d-flex align-items-center">
                                    {!myMessage && (
                                        <Button className={css.replyTo} onClick={onReply}>Ответить</Button>
                                    )}
                                    {desktop &&
                                        <div className='date text-extra-small'>
                                            {moment(created).format('DD.MM.YYYY HH:mm:ss')}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-1 pr-1 pl-1">
                            <div onKeyPress={() => null} tabIndex={0} role="button" onClick={onClickMessage} className={css.message}>
                                <span dangerouslySetInnerHTML={{__html: substituteHref(analysisMessage(message))}}/>
                            </div>
                        </div>
                        <div className="col-12 pl-1 pr-1">
                            {files.length ? (
                                <div className={`row ${css.filesList}`}>
                                    {files.map((file) => {
                                        return <CommentsItemFile key={file.id} {...file}/>;
                                    })}
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                        {!desktop &&
                            <div className={`${css.commentsDate} ${css.date} text-extra-small`}>
                                {moment(created).format('DD.MM.YYYY HH:mm:ss')}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};
export default CommentsItem;
