// eslint-disable-next-line import/no-cycle

import {
  NOTIFICATION_FETCH_LIST,
  NOTIFICATION_LOADING,
  NOTIFICATION_SET_LIST,
  NOTIFICATION_VIEWED,
} from '../actions';

export const notificationFetchList = (params = {}) => ({
  type: NOTIFICATION_FETCH_LIST,
  payload: params,
});

export const notificationSetList = (items) => ({
  type: NOTIFICATION_SET_LIST,
  payload: items,
});

export const notificationLoading = (loading) => ({
  type: NOTIFICATION_LOADING,
  payload: loading,
});

export const notificationViewed = (params) => ({
  type: NOTIFICATION_VIEWED,
  payload: params,
});
