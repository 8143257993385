import {
  USER_CARD_FETCH,
  USER_CARD_LOADING,
  USER_CARD_RELOAD, USER_CARD_SET,
  USER_CHANGE,
  USER_CHANGE_LOADING,
  USER_CHANGE_SUCCESS
} from '../actions';

// eslint-disable-next-line import/prefer-default-export
export const changeUserData = (params) => {
  return {
    type: USER_CHANGE,
    payload: params,
  };
};
export const changeUserDataLoading = (params) => {
  return {
    type: USER_CHANGE_LOADING,
    payload: params,
  };
};
export const changeUserDataSuccess = (params) => {
  return {
    type: USER_CHANGE_SUCCESS,
    payload: params,
  };
};
export const getUserDataFetch = (params) => {
  return {
    type: USER_CARD_FETCH,
    payload: params,
  };
};
export const setUserData = (params) => {
  return {
    type: USER_CARD_SET,
    payload: params,
  };
};
export const getUserDataLoading = (params) => {
  return {
    type: USER_CARD_LOADING,
    payload: params,
  };
};
export const getUserDataReload = (params) => {
  return {
    type: USER_CARD_RELOAD,
    payload: params,
  };
};
