import React from "react";
import {IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Header = ({onClose, title = '', dealId}) => {

    const substituteHrefTitle = (mess) => {
        return mess
            .replace('${dealLink}', `<a class="nav-link-style-1" target = "_blank" href="/app/crm/deals/${dealId}">сделку</a>`);
    };

    return (
        <div className="row">
            <div className="col-12 col-md-auto">
                <h5 className="m-0 p-0" style={{fontSize: '1.1rem'}}>
                    <span dangerouslySetInnerHTML={{__html: substituteHrefTitle(title)}}/>
                </h5>
            </div>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    color: 'secondary',
                    position: 'absolute',
                    right: '20px',
                    top: '13px'
                }}
            >
                <CloseIcon />
            </IconButton>
        </div>
    )
}
export default Header;