// eslint-disable-next-line no-unused-vars
import React from 'react';

export const Style = (props= {}) => {
    const {
        border = '1px solid hsl(0,0%,80%)',
    } = props;
    return {
        container: (styles) => ({...styles}),
        indicatorSeparator: (styles) => ({...styles, display: 'none'}),
        control: (styles) => ({...styles, minHeight: '37.2px', height: '37.2px', border}),
        placeholder: (styles) => ({
            ...styles,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }),
        clearIndicator: (styles) => ({
            ...styles,
            padding: '0 0 0 5px',
        }),
        input: (styles) => ({...styles}),
        menu: (styles) => ({...styles, width: 'auto', minWidth: '100%', zIndex: '5'}),
        valueContainer: (styles) => ({...styles, padding: '2px 0 2px 8px'}),
        dropdownIndicator: (styles) => ({...styles, padding: '8px 2px 8px 0'}),
    };
};

export const StyleRight = () => {
    return {
        container: (styles) => ({...styles}),
        indicatorSeparator: (styles) => ({...styles, display: 'none'}),
        control: (styles) => ({...styles, minHeight: '37.2px', height: '37.2px'}),
        placeholder: (styles) => ({
            ...styles,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }),
        clearIndicator: (styles) => ({
            ...styles,
            padding: '0 0 0 5px',
        }),
        menu: (styles) => ({...styles, width: 'auto', minWidth: '100%', zIndex: '5', right: '0'}),
        valueContainer: (styles) => ({...styles, padding: '2px 0 2px 8px'}),
        dropdownIndicator: (styles) => ({...styles, padding: '8px 2px 8px 0'}),
    };
};
// eslint-disable-next-line no-unused-vars
export const Style2 = (props = {}) => {
    const {
        bgColor = '#4556AC',
        readOnly = false
    } = props;
    return {
        indicatorSeparator: (styles) => ({...styles, display: 'none'}),
        dropdownIndicator: (styles) => ({
            ...styles,
            padding: '0 8px',
            color: '#ffffff',
            display: readOnly ? 'none' : 'flex',
        }),
        singleValue: (styles) => ({...styles, color: '#ffffff', position: 'relative', top: 0, transform: 'none'}),
        container: (styles) => ({...styles, flex: '1', minWidth: '120px'}),
        input: (styles) => ({...styles, padding: '0', margin: '0 2px'}),
        valueContainer: (styles) => ({...styles, padding: '0 10px'}),
        control: (styles) => ({
            ...styles,
            minHeight: '25px',
            height: '25px',
            background: bgColor,
            fontSize: '12px',
            borderRadius: '30px',
            border: 'none !important',
            color: '#ffffff',
            boxShadow: 'none',
        }),
        placeholder: (styles) => ({...styles, color: '#ffffff'}),
        menu: (styles) => ({
            ...styles,
            width: 'auto',
            minWidth: '100%',
            fontSize: '12px',
        }),
    };
};
export const Style3 = () => {
    return {
        indicatorSeparator: (styles) => ({...styles, display: 'none'}),
        loadingIndicator: (styles) => ({...styles, display: 'none'}),
        menuList: (styles) => ({
            ...styles,
            textAlign: 'left',
            fontSize: '0.65rem',
        }),
        menu: (styles) => ({...styles, minWidth: '100px', width: 'auto'}),
        menuPortal: (styles) => ({...styles, top: `${styles.top - 145}px`}), // 28
        dropdownIndicator: (styles) => ({
            ...styles,
            padding: '0',
            position: 'absolute',
            right: '2px',
            width: '12px',
            // display: 'none',
            // color: '#ffffff',
        }),
        // singleValue: (styles) => ({ ...styles, color: '#ffffff' }),
        container: (styles) => ({...styles, flex: '1', minWidth: '35px'}),
        input: (styles) => ({...styles, padding: '0', margin: '0 2px'}),
        valueContainer: (styles) => ({...styles, padding: '0 5px'}),
        control: (styles) => ({
            ...styles,
            minHeight: '25px',
            height: '25px',
            fontSize: '0.65rem',
            boxShow: 'none',
        }),
        placeholder: (styles) => ({
            ...styles,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }),
    };
};

export const Style3Right = () => {
    return {
        indicatorSeparator: (styles) => ({...styles, display: 'none'}),
        loadingIndicator: (styles) => ({...styles, display: 'none'}),
        menuList: (styles) => ({
            ...styles,
            textAlign: 'left',
            fontSize: '0.65rem',
        }),
        menu: (styles) => ({...styles, minWidth: '100px', width: 'auto', right: '0'}),
        menuPortal: (styles) => ({...styles, top: `${styles.top - 145}px`}), // 28
        dropdownIndicator: (styles) => ({
            ...styles,
            padding: '0',
            position: 'absolute',
            right: '2px',
            width: '12px',
            // display: 'none',
            // color: '#ffffff',
        }),
        // singleValue: (styles) => ({ ...styles, color: '#ffffff' }),
        container: (styles) => ({...styles, flex: '1', minWidth: '35px'}),
        input: (styles) => ({...styles, padding: '0', margin: '0 2px'}),
        valueContainer: (styles) => ({...styles, padding: '0 5px'}),
        control: (styles) => ({
            ...styles,
            minHeight: '25px',
            height: '25px',
            fontSize: '0.65rem',
            boxShow: 'none',
        }),
        placeholder: (styles) => ({
            ...styles,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }),
    };
};

export const Style4 = () => {
    return {
        indicatorSeparator: (styles) => ({...styles, display: 'none'}),
        dropdownIndicator: (styles) => ({
            ...styles,
            padding: '0 8px',
            color: '#3A3A3A',
        }),
        singleValue: (styles) => ({...styles, color: ' #000000', position: 'relative', top: 0, transform: 'none'}),
        container: (styles) => ({...styles, flex: '1', minWidth: '110px'}),
        input: (styles) => ({...styles, padding: '0', margin: '0 2px'}),
        valueContainer: (styles) => ({...styles, padding: '0 10px', justifyContent: 'center'}),
        control: (styles) => ({
            ...styles,
            minHeight: '34px',
            height: '34px',
            background: '#ffffff',
            fontSize: '12px',
            borderRadius: '30px',
            color: '#3A3A3A',
            boxShadow: 'none',
            border: '1px solid #ED7117 !important',
        }),
        placeholder: (styles) => ({...styles, color: '#3A3A3A', opacity: '0.5'}),
        menu: (styles) => ({
            ...styles,
            width: 'auto',
            minWidth: '100%',
            fontSize: '12px',
            textAlign: 'left',
        }),
        option: (styles, state) => ({
            ...styles,
            backgroundColor: state.isSelected ? '#ED7117' : 'transparent',
            ':hover': {
                backgroundColor: '#FCEADC'
            },
        }),
    };
};
export const Style5 = () => {
    return {
        indicatorSeparator: (styles) => ({...styles, display: 'none'}),
        loadingIndicator: (styles) => ({...styles, display: 'none'}),
        menuList: (styles) => ({
            ...styles,
            textAlign: 'left',
            fontSize: '12px',
        }),
        menu: (styles) => ({...styles, minWidth: '100%', width: 'auto'}),
        menuPortal: (styles) => ({...styles, top: `${styles.top - 145}px`}), // 28
        dropdownIndicator: (styles) => ({
            ...styles,
            padding: '0 8px',
            color: '#3A3A3A',
        }),
        // singleValue: (styles) => ({ ...styles, color: '#ffffff' }),
        container: (styles) => ({...styles, flex: '1', minWidth: '35px'}),
        input: (styles) => ({...styles, padding: '0', margin: '0 2px'}),
        valueContainer: (styles) => ({...styles, padding: '0 5px'}),
        control: (styles) => ({
            ...styles,
            minHeight: '26px',
            height: '26px',
            fontSize: '12px',
            boxShow: 'none',
            lineHeight: '1',
        }),
        placeholder: (styles) => ({
            ...styles,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }),
    };
};
export const Style6 = (props = {}) => {
    const {isMulti = false, menuPortal = {}} = props;
    return {
        container: (styles) => ({...styles}),
        indicatorSeparator: (styles) => ({...styles, display: 'none'}),
        control: (styles) => ({...styles, minHeight: '28px', height: isMulti ? 'auto' : '28px', fontSize: '12px'}),
        clearIndicator: (styles) => ({
            ...styles,
            padding: '0 0 0 5px',
        }),
        menuPortal: (styles) => ({...styles, ...menuPortal}), // 28
        menu: (styles) => ({...styles, width: 'auto', minWidth: '100%', zIndex: '5', fontSize: '12px'}),
        valueContainer: (styles) => ({...styles, padding: '2px 0 2px 8px'}),
        dropdownIndicator: (styles) => ({...styles, padding: '0px 2px 0px 0', color: '#3A3A3A'}),
        input: (css) => ({
            ...css,
            /* expand the Input Component div */
            flex: "1 1 auto",
            /* expand the Input Component child div */
            "> div": {
                width: "100%"
            },
            /* expand the Input Component input */
            input: {
                width: "100% !important",
                textAlign: "left"
            },
            padding: '0',
            margin: '0 2px',
            gridTemplateColumns: '0fr'
        }),
        placeholder: (styles) => ({
            ...styles,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            // workaround zone
            pointerEvents: "none",
            userSelect: "none",
            MozUserSelect: "none",
            WebkitUserSelect: "none",
            msUserSelect: "none"
        }),
        singleValue: (styles) => ({
            ...styles,
            width: 'calc(100% - 18px)'
        }),
    };
};

export const Style6Right = (props = {}) => {
    const {isMulti = false} = props;
    return {
        container: (styles) => ({...styles}),
        indicatorSeparator: (styles) => ({...styles, display: 'none'}),
        control: (styles) => ({...styles, minHeight: '28px', height: isMulti ? 'auto' : '28px', fontSize: '12px'}),
        clearIndicator: (styles) => ({
            ...styles,
            padding: '0 0 0 5px',
        }),
        menu: (styles) => ({...styles, width: 'auto', minWidth: '100%', zIndex: '5', fontSize: '12px', right: '0'}),
        valueContainer: (styles) => ({...styles, padding: '2px 0 2px 8px'}),
        dropdownIndicator: (styles) => ({...styles, padding: '0px 2px 0px 0', color: '#3A3A3A'}),
        input: (css) => ({
            ...css,
            /* expand the Input Component div */
            flex: "1 1 auto",
            /* expand the Input Component child div */
            "> div": {
                width: "100%"
            },
            /* expand the Input Component input */
            input: {
                width: "100% !important",
                textAlign: "left"
            },
            padding: '0',
            margin: '0 2px',
            gridTemplateColumns: '0fr'
        }),
        placeholder: (styles) => ({
            ...styles,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            // workaround zone
            pointerEvents: "none",
            userSelect: "none",
            MozUserSelect: "none",
            WebkitUserSelect: "none",
            msUserSelect: "none"
        }),
    };
};

export const Style6NoColorOption = (props = {}) => {
    const {isMulti = false, menuPortal = {}} = props;
    return {
        container: (styles) => ({...styles}),
        indicatorSeparator: (styles) => ({...styles, display: 'none'}),
        control: (styles) => ({...styles, minHeight: '28px', height: isMulti ? 'auto' : '28px', fontSize: '12px'}),
        clearIndicator: (styles) => ({
            ...styles,
            padding: '0 0 0 5px',
        }),
        menuPortal: (styles) => ({...styles, ...menuPortal}), // 28
        menu: (styles) => ({...styles, width: 'auto', minWidth: '100%', zIndex: '5', fontSize: '12px'}),
        valueContainer: (styles) => ({...styles, padding: '2px 0 2px 8px'}),
        dropdownIndicator: (styles) => ({...styles, padding: '0px 2px 0px 0', color: '#3A3A3A'}),
        input: (css) => ({
            ...css,
            /* expand the Input Component div */
            flex: "1 1 auto",
            /* expand the Input Component child div */
            "> div": {
                width: "100%"
            },
            /* expand the Input Component input */
            input: {
                width: "100% !important",
                textAlign: "left"
            },
            padding: '0',
            margin: '0 2px',
            gridTemplateColumns: '0fr'
        }),
        placeholder: (styles) => ({
            ...styles,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            // workaround zone
            pointerEvents: "none",
            userSelect: "none",
            MozUserSelect: "none",
            WebkitUserSelect: "none",
            msUserSelect: "none"
        }),
        option: (styles) => ({
            ...styles,
            backgroundColor: 'white',
            color: '#333333',
            ':hover': {
                backgroundColor: '#FCEADC',
                color: '#333333',
            },
        }),
    };
};
export const Style7 = () => {
    return {
        indicatorSeparator: (styles) => ({...styles, display: 'none'}),
        loadingIndicator: (styles) => ({...styles, display: 'none'}),
        dropdownIndicator: (styles) => ({
            ...styles,
            color: '#898989',
            padding: '0',
            position: 'absolute',
            right: '2px',
            width: '12px',
        }),
        singleValue: (styles) => ({
            ...styles,
            color: '#898989',
            position: 'relative',
            top: 0,
            transform: 'none',
            maxWidth: 'calc(100% - 10px)'
        }),
        container: (styles) => ({...styles, flex: '1', minWidth: '50px'}),
        input: (styles) => ({...styles, padding: '0', margin: '0 2px'}),
        valueContainer: (styles) => ({...styles, padding: '0 5px'}),
        control: (styles) => ({
            ...styles,
            minHeight: '28px',
            height: '28px',
            background: '#E8ECEF',
            fontSize: '12px',
            borderRadius: '3px',
            border: '1px solid #C2C3C3',
            color: '#898989',
            boxShadow: 'none',
        }),
        placeholder: (styles) => ({...styles, color: '#898989'}),
        menu: (styles) => ({
            ...styles,
            width: 'auto',
            minWidth: '100%',
            fontSize: '12px',
        }),
    };
};
export const Style8 = () => {
    return {
        indicatorSeparator: (styles) => ({...styles, display: 'none'}),
        loadingIndicator: (styles) => ({...styles, display: 'none'}),
        menuList: (styles) => ({
            ...styles,
            textAlign: 'left',
            fontSize: '12px',
        }),
        menu: (styles) => ({...styles, minWidth: '100%', width: 'auto'}),
        menuPortal: (styles) => ({...styles, top: `${styles.top - 145}px`}), // 28
        dropdownIndicator: (styles) => ({
            ...styles,
            padding: '0 8px',
            color: '#3A3A3A',
        }),
        // singleValue: (styles) => ({ ...styles, color: '#ffffff' }),
        container: (styles) => ({...styles, flex: '1', minWidth: '35px'}),
        input: (styles) => ({...styles, padding: '0', margin: '0 2px'}),
        valueContainer: (styles) => ({...styles, padding: '0 5px'}),
        control: (styles) => ({
            ...styles,
            minHeight: '26px',
            height: '26px',
            fontSize: '12px',
            boxShow: 'none',
            lineHeight: '1',
            borderColor: 'transparent !important',
            boxShadow: 'none'
        }),
        placeholder: (styles) => ({
            ...styles,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }),
    };
};