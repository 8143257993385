// eslint-disable-next-line import/named

import {
    COMMENT_CHANGE,
    COMMENT_CHANGE_LOADING,
    COMMENT_CHANGE_SUCCESS,
    COMMENT_CREATE, COMMENTS_FETCH, COMMENTS_SET,
} from '../actions';
import {fetchMetaDefault} from "../../constants/defaultCrm";

const INIT_STATE = {
    list: {
        data: {
            items: [],
            meta: fetchMetaDefault,
        },
        isFetching: false,
        reload: false
    },
    change: {
        loading: false,
        errors: [],
        success: false,
    },
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case COMMENT_CREATE:
        case COMMENT_CHANGE:
            return {
                ...state,
                change: { ...state.change, loading: true, success: false },
            };
        case COMMENT_CHANGE_LOADING:
            return {
                ...state,
                change: { ...state.change, loading: action.payload },
            };
        case COMMENT_CHANGE_SUCCESS:
            return {
                ...state,
                change: { ...state.change, errors: [], loading: false, success: true },
            };
        case COMMENTS_FETCH:
            return {
                ...state,
                list: {
                    ...state.list,
                    isFetching: true,
                    reload: false,
                }
            };
        case COMMENTS_SET:
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload || {
                        items: [],
                        meta: fetchMetaDefault,
                    },
                    isFetching: false
                }
            };
        default:
            return { ...state };
    }
};
