import {PutData} from '../index';
import axiosResponseError from "../../components/applications/AxiosResponseError";
import {ResponseException} from "../../constants/defaultValues";

// eslint-disable-next-line import/prefer-default-export
export const sendPut = (url, data, params = {}) => {
    try {
        return PutData(url, data, params);
    } catch (exception) {
        axiosResponseError(exception);
        throw new ResponseException(exception);
    }
};
