import React, {useEffect, useState} from 'react';
import {StyledDialog} from "../../../utils/mui/dialog";
import {Box, DialogActions, DialogContent, DialogTitle, Tab} from "@mui/material";
import Header from "./Header";
import Entity from "./Entity";
import {defaultParams, modalDefaultParams, modalParams} from "./helpers";

const ModalShipperScore = (props) => {
    const {eventId, type, dealId, entityList = [], onSave, disabledSubmit} = props;

    const [ratingData, setRatingData] = useState([]);
    const [statusDisplay, setStatusDisplay] = useState(false);
    const [params, setParams] = useState(modalDefaultParams);

    const onClose = () => {
        setStatusDisplay(false);
    }

    const changeData = (id, name, value) => {
        setRatingData((oldValues) => oldValues.map(x => x.id === id ? {...x, [name]: value} : x))
    }

    const setRatingDataDefault = () => {
        const newData = [];
        entityList.forEach(x => {
            newData.push({
                ...x,
                ...defaultParams,
            });
        });
        setRatingData(newData);
    }

    const setModalData = () => {
        const currentParams = modalParams[type] ?? null;
        if (!currentParams) {
            onClose();
            return false;
        }
        setParams(currentParams);
        setStatusDisplay(true);
    }

    const onSubmit = async () => {
        const result = await onSave({
            data: ratingData,
            status_id: params.statusId,
            dealId,
        });
        setStatusDisplay(!result);
    }

    useEffect(() => {
        setRatingDataDefault();
    }, [entityList]);

    useEffect(() => {
        if (!statusDisplay) {
            setRatingDataDefault();
        }
    }, [statusDisplay])

    useEffect(() => {
        setModalData(type);
    }, [eventId])

    return (
        <>
            <StyledDialog
                open={statusDisplay}
                maxWidth="xs"
                onClose={onClose}
                className="modal-dialog-mui"
            >
                <DialogTitle>
                    <Header
                        title={params.title}
                        dealId={dealId}
                        onClose={onClose}
                    />
                </DialogTitle>
                <DialogContent>
                    {
                        ratingData.map(entity => {
                            return <Entity
                                key={entity.id}
                                data={entity}
                                changeData={changeData}
                                actions={params.actions}
                                showAccordion={ratingData.length > 1}
                            />
                        })
                    }
                </DialogContent>
                <DialogActions>
                    <Box mt={1} width={"100%"} display="flex" alignItems="center" justifyContent="space-around">
                        <button
                            type="button"
                            className="btn-outline-primary btn"
                            onClick={onClose}
                        >Отмена</button>
                        <button
                            type="button"
                            className="btn-primary btn"
                            onClick={onSubmit}
                            disabled={!entityList.length || disabledSubmit || ratingData.some(x => !x.rating)}
                        >
                            Сохранить
                        </button>
                    </Box>
                </DialogActions>
            </StyledDialog>
        </>
    )
};

export default ModalShipperScore;
