// import { Redirect } from 'react-router-dom';
// import React from 'react';
import { setCurrentUser, setToken } from '../../helpers/Utils';

const NoAuthorization = () => {
  setCurrentUser('');
  setToken('');

  return true;
};
export default NoAuthorization;
