// eslint-disable-next-line no-unused-vars
/* eslint-disable no-use-before-define */
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    CRM_TASK_CHANGE,
    CRM_TASK_CREATE,
    CRM_TASK_FETCH_DATA,
    CRM_TASKS_FETCH_DATA,
} from '../../actions';
import {
    taskListLoading,
    taskListReload,
    taskListSetData,
    taskOneChangeLoading,
    taskOneChangeSuccess,
    taskOneLoading,
    taskOneSetData,
} from './actions';
import {
    getTask,
    getTasks,
    taskChange,
    taskCreate,
} from '../../../api/query/task';
import ErrorResponse from "../../../components/common/ErrorResponse";
import {appActions} from "../../toolkit/app/slice";

export function* watchTasks() {
    yield takeLatest(CRM_TASKS_FETCH_DATA, loadTasks);
}
function* loadTasks({ payload }) {
    try {
        yield put(taskListLoading(true));
        const getData = yield call(getTasks, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(taskListSetData(getData.data));
        }
        yield put(taskListLoading(false));
    } catch (error) {
        const errorResponse = ErrorResponse(error.response);
        yield put(appActions.setNotification({type: 'error', message: errorResponse}));
        yield put(taskListLoading(false));
    }
}

export function* watchTask() {
    yield takeEvery(CRM_TASK_FETCH_DATA, loadTask);
}
function* loadTask({ payload }) {
    try {
        yield put(taskOneLoading(true));
        const getData = yield call(getTask, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(taskOneSetData(getData.data));
        }
        yield put(taskOneLoading(false));
    } catch (error) {
        const errorResponse = ErrorResponse(error.response);
        yield put(appActions.setNotification({type: 'error', message: errorResponse}));
        yield put(taskOneLoading(false));
    }
}

export function* watchChange() {
    yield takeEvery(CRM_TASK_CHANGE, changeTask);
}
function* changeTask({ payload }) {
    try {
        const getData = yield call(taskChange, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(appActions.setNotification({type: 'success', message: 'Данные обновлены'}));
        }
        yield put(taskOneChangeSuccess(true));
        yield put(taskListReload(true));
    } catch (error) {
        const errorResponse = ErrorResponse(error.response);
        yield put(appActions.setNotification({type: 'error', message: errorResponse}));
        yield put(taskOneChangeLoading(false));
    }
}
export function* watchCreate() {
    yield takeEvery(CRM_TASK_CREATE, createTask);
}
function* createTask({ payload }) {
    try {
        const getData = yield call(taskCreate, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(appActions.setNotification({type: 'success', message: 'Данные сохранены'}));
        }
        yield put(taskOneChangeSuccess(true));
        yield put(taskListReload(true));
    } catch (error) {
        const errorResponse = ErrorResponse(error.response);
        yield put(appActions.setNotification({type: 'error', message: errorResponse}));
        yield put(taskOneChangeLoading(false));
    }
}
export default function* rootSaga() {
    yield all([
        fork(watchTasks),
        fork(watchTask),
        fork(watchChange),
        fork(watchCreate),
    ]);
}
