import React, {useEffect} from "react"
import {useSelector} from "react-redux";

const Callback = (props) => {
    const {articleId, typesId, reload, reloadCount} = props;
    const commentEvent = useSelector((state) => state.websocket.event.comment);

    const onCallbackEvent = () => {
        const curArticleId = Number(commentEvent?.article_id ?? 0);
        const curTypeId = Number(commentEvent?.type_id ?? 0);
        const use = commentEvent?.use ?? '';
        if (articleId === curArticleId && typesId?.includes(curTypeId) && use !== 'general') {
            reload();
        }
        if (articleId === curArticleId && curTypeId === 7 && use === 'general') {
            reloadCount();
        }
    }

    useEffect(() => {
        onCallbackEvent();
    }, [commentEvent]);

    return <></>;
}
export default Callback;