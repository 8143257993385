// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalUpdateArticles_updateArticleButton__Pb0kB {
  margin: 10px 0 0;
  display: flex;
  justify-content: flex-end; }
`, "",{"version":3,"sources":["webpack://./src/containers/modal/ModalUpdateArticles/ModalUpdateArticles.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,yBAAyB,EAAA","sourcesContent":[".updateArticleButton {\n  margin: 10px 0 0;\n  display: flex;\n  justify-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"updateArticleButton": `ModalUpdateArticles_updateArticleButton__Pb0kB`
};
export default ___CSS_LOADER_EXPORT___;
