/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/prefer-default-export
export const userGrouping = (props) => {
    const {data, userFirst = [], groupDisabled = false, groupUsersFirst = []} = props;

    const getGroups = () => {
        const startListGroups = [];
        let allGroups = [];
        data.forEach(x => {
            const userId = Number.parseInt(x.id, 10);
            const group = x.crmGroups[0];
            const groupID = `G-${group.id}`;
            const searchGroupGeneralArray = allGroups.filter(g => g.id === groupID);
            const searchGroupStartArray = startListGroups.filter(g => g.id === groupID);

            group.nativeId = group.id;
            group.isGroup = true;
            group.id = groupID;
            if (groupDisabled) {
                group.disabled = true;
            }

            if (groupUsersFirst.includes(userId) && !searchGroupStartArray.includes(groupID)) {
                startListGroups.push(group);
            }
            if (!searchGroupGeneralArray.length) {
                allGroups.push(group);
            }
        });
        const startListGroupsId = startListGroups.map(x => x.id);
        allGroups = allGroups.filter(x => !startListGroupsId.includes(x.id));
        allGroups.sort((a, b) => {
            return a.nativeId - b.nativeId;
        });
        if (startListGroups.length) {
            allGroups = [...startListGroups, ...allGroups];
        }
        return allGroups;
    }

    const clearEmpty = (groups) => {
        const result = [];
        groups.forEach((x, key) => {
            const isGroup = x.isGroup ?? false;
            if (isGroup) {
                const nextElement = groups[key+1] ?? null;
                if (nextElement) {
                    const nextGroup = nextElement.isGroup ?? false;
                    if (!nextGroup) {
                        result.push(x);
                    }
                }
            } else {
                result.push(x);
            }
        });
        return result;
    }

    const setGroupsUsers = (groups) => {
        const resultGroups = groups;
        const helpers = [];
        const startList = [];
        data.forEach((x) => {
            const userId = Number.parseInt(x.id, 10);
            const group = x.crmGroups[0];
            const groupID = group.id;
            const searchGroupIndex = resultGroups.findIndex(g => g.id === groupID);
            // const isHelper = Number.parseInt(
            //     typeof x.isHelper !== 'undefined' ? x.isHelper : 0,
            //     10
            // );
            const isHelper = 0;
            if (isHelper) {
                helpers.push(x);
            }
            const searchUserFirst = userFirst.findIndex(x2 => Number.parseInt(x2,10) === userId);
            if (searchUserFirst > -1) {
                resultGroups.splice(0, 0, x);
            } else if (searchGroupIndex > -1) {
                resultGroups.splice(searchGroupIndex+1, 0, x);
            } else {
                resultGroups.push(x);
            }
        });
        if (helpers.length) {
            const searchGroup1 = resultGroups.findIndex((x) => x.id === 'G-1');
            if (searchGroup1 > -1) {
                helpers.forEach((x) => {
                    resultGroups.splice(searchGroup1 + 1, 0, x);
                });
            }
        }
        return clearEmpty(resultGroups);
    };

    const groups = getGroups();
    const groupsUsers = setGroupsUsers(groups);
    return groupsUsers;
}

export const extractCrmGroupsFromUsers = (users) => {
    const result = [];
    if (!users.length) return result;
    users.forEach(user => {
        const {crmGroups} = user;
        if (crmGroups.length) {
            crmGroups.forEach(group => {
                const groupId = group.id;
                if (result.findIndex(x => x.id === `G-${groupId}`) < 0) {
                    result.push({...group, id: `G-${group.id}`});
                }
            });
        }
    });
    return result;
}