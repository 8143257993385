import React, {useEffect, useRef, useState} from 'react'
import ModalUpdateArticles from '../../../containers/modal/ModalUpdateArticles/ModalUpdateArticles'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment/moment'
import {articlesActions} from '../../../redux/articles/slice'

const queryExpand = ['previewImage']

const queryFields = ['slug', 'title', 'description', 'date_publication']

const ModalUpdate = () => {
    const dispatch = useDispatch()

    const articlesUpdate = useSelector(state => state.articles.articlesUpdate)

    const modalUpdate = useRef(null)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        const needTime = moment().tz('Etc/GMT-5').set({hour: 10, minute: 0, second: 0})
        const diffTime = needTime.diff(moment().tz('Etc/GMT-5'))

        if (diffTime < 0) return

        modalUpdate.current = setTimeout(() => {
            handleArticles()
            setOpen(true)
        }, diffTime)

        return () => {
            clearTimeout(modalUpdate.current)
            modalUpdate.current = null
        }
    }, [])

    const handleArticles = (page = 1) => {
        const currentDate = moment().tz('Etc/GMT-5').subtract(1, 'days').format('DD.MM.YYYY')

        dispatch(
            articlesActions.getArticlesUpdate({
                filters: [
                    {
                        id: 'status_crm',
                        value: 1,
                    },
                    {
                        id: 'date_publication',
                        value: currentDate,
                    },
                ],
                expand: queryExpand,
                fields: queryFields,
                page,
            }),
        )
    }

    return (
        <div>
            <ModalUpdateArticles isShow={open} changeShow={setOpen} articles={articlesUpdate} />
        </div>
    )
}

export default ModalUpdate
