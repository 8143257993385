import {deleteStorageFile, uploadStorageFile} from '../api/query/storage'

export const uploadFile = async ({storage_id, files = []}) => {
    let result = {
        type: '',
        data: null,
    }
    const formData = new FormData()
    for (let i = 0; i < files.length; i++) {
        formData.append('files[]', files[i])
    }
    formData.append('storage_id', storage_id.toString())

    await uploadStorageFile(formData)
        .then(response => {
            result = {
                type: 'success',
                data: response,
            }
        })
        .catch(error => {
            const message = error?.response?.data?.message ?? 'Что-то пошло не так'
            result = {
                type: 'error',
                data: message,
            }
        })
    return result
}

export const deleteFile = async data => {
    let result = {
        type: '',
        data: null,
    }
    await deleteStorageFile(data)
        .then(response => {
            result = {
                type: 'success',
                data: response.data,
            }
        })
        .catch(error => {
            const message = error?.response?.data?.message ?? 'Что-то пошло не так'
            result = {
                type: 'error',
                data: message,
            }
        })
    return result
}
