import React from 'react';

const RankExperiencedIcon = ({width="24", height="24"}) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.3125 6.80424C20.3125 7.16741 20.6068 7.462 20.97 7.462H21.6897C22.0526 7.462 22.347 7.16767 22.347 6.80424C22.347 6.44081 22.0526 6.14648 21.6897 6.14648H20.97C20.6068 6.14648 20.3125 6.44081 20.3125 6.80424Z" fill="#3A3A3A"/>
        <path d="M11.7292 3.51523C11.8577 3.64393 12.0267 3.70829 12.195 3.70829C12.3633 3.70829 12.5318 3.64393 12.6602 3.51576C12.9174 3.25914 12.9174 2.8427 12.6608 2.58582L12.0763 2.00138C11.8192 1.74424 11.4027 1.74451 11.1459 2.00112C10.889 2.258 10.889 2.67418 11.1453 2.93132L11.7292 3.51523Z" fill="#3A3A3A"/>
        <path d="M19.6806 3.71411C19.8494 3.71411 20.0166 3.65002 20.1453 3.52185L20.7303 2.93767C20.988 2.68106 20.988 2.26462 20.7314 2.00748C20.4745 1.75007 20.058 1.75033 19.8012 2.00695L19.2162 2.59086C18.9593 2.84774 18.9593 3.26418 19.2154 3.52132C19.3438 3.64976 19.5126 3.71411 19.6806 3.71411Z" fill="#3A3A3A"/>
        <path d="M15.939 2.41688C16.3019 2.41688 16.597 2.12229 16.597 1.75912V0.730024C16.597 0.366859 16.3019 0.0722656 15.939 0.0722656C15.5758 0.0722656 15.2812 0.366595 15.2812 0.730024V1.75912C15.2812 2.12229 15.5758 2.41688 15.939 2.41688Z" fill="#3A3A3A"/>
        <path d="M13.6458 10.2214V12.1807C13.6458 12.5444 13.9407 12.8388 14.3033 12.8388H17.4537C17.8163 12.8388 18.1112 12.5444 18.1112 12.1807V10.2212C19.1529 9.4938 19.7898 8.30355 19.7898 7.01889C19.7898 4.86232 18.0352 3.10742 15.8794 3.10742C13.7231 3.10742 11.9688 4.86206 11.9688 7.01889C11.969 8.32149 12.6291 9.50566 13.6458 10.2214ZM15.8797 4.42294C17.3105 4.42294 18.4746 5.5876 18.4746 7.01889C18.4746 7.96096 17.9611 8.83103 17.1348 9.28966C16.9186 9.40966 16.7993 9.63384 16.7993 9.86487C16.7988 9.87463 16.7959 9.8836 16.7959 9.89336V11.5227H14.9614V10.8193C15.0674 10.8449 15.1745 10.8673 15.2842 10.8842C15.3179 10.8895 15.3517 10.8919 15.3844 10.8919C15.704 10.8919 15.9836 10.659 16.0337 10.3333C16.0883 9.97432 15.842 9.63859 15.483 9.58373C14.2298 9.39252 13.2843 8.28958 13.2843 7.01889C13.2843 5.5876 14.4487 4.42294 15.8797 4.42294Z" fill="#3A3A3A"/>
        <path d="M16.8565 13.9719C16.8565 13.6088 16.5617 13.3145 16.199 13.3145C15.8359 13.3145 15.541 13.6088 15.541 13.9719C15.541 16.0188 14.4861 17.9016 12.7934 18.9806C12.7839 18.9856 12.7755 18.9919 12.766 18.9972C12.5281 19.1467 12.2778 19.282 12.0159 19.3981C11.6841 19.546 11.5343 19.935 11.6817 20.2668C11.7912 20.5116 12.0315 20.6569 12.2836 20.6569C12.3725 20.6569 12.4635 20.6389 12.55 20.6002C12.7433 20.5147 12.9295 20.4182 13.112 20.3177C14.4006 21.058 15.4373 22.1908 16.0482 23.5485C16.1579 23.7922 16.3976 23.9367 16.6487 23.9367C16.7386 23.9367 16.8307 23.918 16.918 23.8784C17.2492 23.7297 17.3969 23.3399 17.2482 23.0091C16.6086 21.5873 15.5737 20.3757 14.2906 19.5051C15.8904 18.1479 16.8565 16.1354 16.8565 13.9719Z" fill="#3A3A3A"/>
        <path d="M2.40315 23.5873C2.49731 23.6347 2.59753 23.6566 2.69643 23.6566C2.93801 23.6566 3.17063 23.5232 3.28535 23.2927C3.90408 22.0526 4.89546 21.0114 6.09441 20.3204C6.61687 20.6092 7.181 20.8368 7.77889 20.9924C7.83401 21.0071 7.88992 21.014 7.94478 21.014C8.237 21.014 8.50417 20.8175 8.58118 20.5208C8.67217 20.1695 8.46118 19.8108 8.10962 19.7191C5.49599 19.0399 3.67067 16.6774 3.67067 13.9733C3.67067 10.7006 6.33309 8.03791 9.59445 8.03765L10.8274 8.06033C10.8314 8.06059 10.8356 8.06059 10.8398 8.06059C11.1974 8.06059 11.491 7.77417 11.4973 7.41523C11.5036 7.0518 11.2146 6.75193 10.8517 6.74534L9.60658 6.72266C5.60808 6.72266 2.35489 9.97532 2.35489 13.9736C2.35489 16.1599 3.33599 18.1591 4.92289 19.5049C3.7337 20.3117 2.75076 21.4175 2.10856 22.7053C1.9461 23.03 2.07823 23.4245 2.40315 23.5873Z" fill="#3A3A3A"/>
        <path d="M7.93917 14.6048C8.35086 14.9277 9.13759 15.3064 10.0686 15.3064C10.7116 15.3064 11.4223 15.126 12.1257 14.6217C12.4206 14.4097 12.4884 13.9993 12.2766 13.7039C12.0645 13.4085 11.6542 13.3415 11.359 13.5531C10.0464 14.4943 8.8815 13.6686 8.74911 13.5676C8.46322 13.3466 8.05152 13.3964 7.8284 13.681C7.60344 13.9669 7.65328 14.3796 7.93917 14.6048Z" fill="#3A3A3A"/>
        <path d="M9.00801 11.2303C9.00801 10.8038 8.66173 10.457 8.23553 10.457C7.80854 10.457 7.46094 10.8038 7.46094 11.2303C7.46094 11.657 7.80854 12.0038 8.23553 12.0038C8.66173 12.0038 9.00801 11.657 9.00801 11.2303Z" fill="#3A3A3A"/>
        <path d="M11.8595 10.457C11.433 10.457 11.0859 10.8038 11.0859 11.2303C11.0859 11.657 11.4328 12.0038 11.8595 12.0038C12.2857 12.0038 12.6325 11.657 12.6325 11.2303C12.6322 10.8038 12.2857 10.457 11.8595 10.457Z" fill="#3A3A3A"/>
    </svg>
);

export default RankExperiencedIcon;