import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  // LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  GET_USER_INFO,
  SET_USER_INFO,
  GET_USER_INFO_RELOAD,
  GET_USER_INFO_LOADING,
  SET_TOKEN,
} from '../actions';
import { getToken, setToken } from '../../helpers/Utils';

const INIT_STATE = {
  currentUser: null,
  token: getToken(),
  forgotUserMail: '',
  newPassword: '',
  resetSuccess: false,
  registerSuccess: false,
  resetPasswordCode: '',
  loading: false,
  reload: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true, error: '' };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        currentUser: null,
        error: action.payload.message,
      };
    case FORGOT_PASSWORD:
      return { ...state, loading: true, error: '' };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotUserMail: action.payload,
        error: '',
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        forgotUserMail: '',
        error: action.payload.message,
      };
    case RESET_PASSWORD:
      return { ...state, loading: true, error: '' };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        newPassword: '',
        resetSuccess: action.payload,
        resetPasswordCode: '',
        error: '',
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        newPassword: '',
        resetPasswordCode: '',
        error: action.payload.message,
      };
    case REGISTER_USER:
      return { ...state, loading: true, error: '' };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        registerSuccess: action.payload,
        error: '',
      };
    case REGISTER_USER_ERROR:
      return {
        ...state,
        loading: false,
        currentUser: null,
        error: action.payload.message,
      };
    // case LOGOUT_USER:
    //   return { ...state, currentUser: null, error: '' };
    case GET_USER_INFO:
      return { ...state, loading: true, error: '', reload: false };
    case GET_USER_INFO_RELOAD:
      return { ...state, reload: action.payload };
    case GET_USER_INFO_LOADING:
      return { ...state, loading: action.payload };
    case SET_USER_INFO:
      return { ...state, loading: false, currentUser: action.payload };
    case SET_TOKEN:
      setToken(action.payload);
      return { ...state, token: action.payload };
    default:
      return { ...state };
  }
};
