/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line no-unused-vars,import/prefer-default-export

export const getQueryCompany = (typeCompany) => {
    let query = {};
    switch (typeCompany){
        case 1:
            query = {'selection[status]': 1, 'selection[type]': 1};
            break;
        case 2:
            query = {'selection[status]': 1, 'selection[viaContragentStatus]': 1};
            break;
        case 3:
            break;
        case 4:
            query = {'selection[status]': 1, 'selection[type]': 2};
            break;
        default:
    }
    return query;
}