import {adminRoot} from "../../../constants/defaultValues";

// eslint-disable-next-line import/prefer-default-export
export const getLinkClientForTask = (data) => {
    let urlTopic = '#';
    const {type_id: typeId} = data;
    const client = data.client ?? null;
    if (!client) return urlTopic;

    const clientId = client.id;
    const clientTypeId = client.type_id;

    let taskId = null;
    switch (typeId) {
        case 3:
        case 5:
            taskId = data.id;
            break;
        case 4:
            taskId = data.task_id;
            break;
        default:
            taskId = null;
    }
    if (!taskId) return urlTopic;
    if (clientTypeId.includes(2)) {
        urlTopic = `${adminRoot}/partners/buyers/${clientId}?openTask=${taskId}`;
    } else {
        urlTopic = `${adminRoot}/partners/dealers/${clientId}?openTask=${taskId}`;
    }
    return urlTopic;
}