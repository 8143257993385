/* eslint-disable react/destructuring-assignment */
import React from 'react';
import {Tooltip} from "@mui/material";
import css from "./Comments.module.scss";

const CommentsItemFile = (file) => {
    const extension = file.path.substr(file.path.lastIndexOf('.') + 1);
    return (
        <>
            <div className="col-auto mt-2">
                <Tooltip title={file.name} placement='top'>
                    <a
                        href={file.path}
                        rel="noreferrer"
                        className={css.filesListItem}
                        target="_blank"
                        download={file.name}
                        id={`tooltip_download_file_${file.id}`}
                    >
                        <div className={css.fileIcon}>
                            <div className={css.extension}>{extension}</div>
                        </div>
                        <div className={`${css.fileName} mt-1 overflow-ellipsis`}>
                            {file.name}
                        </div>
                    </a>
                </Tooltip>
            </div>
        </>
    );
};
export default CommentsItemFile;
