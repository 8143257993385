import { SendGet } from '../handler/sendGet';
import {sendPut} from "../handler/sendPut";
import {sendPost} from "../handler/sendPost";
import {sendDelete} from "../handler/sendDelete";

// eslint-disable-next-line import/prefer-default-export
export const fetchStorages = (params) => {
  return SendGet(`storages`, params);
};
export const fetchStorage = ({ id, params }) => {
  return SendGet(`storages/${id}`, params);
};
export const updateStorage = (params) => {
  return sendPost(`storages/update`, params);
};
export const changeStorage = (id, params) => {
  return sendPut(`storages/${id}`, params);
};
export const fetchStorageDirectory = (params) => {
  return SendGet(`storages/directory`, params);
};
export const storageRemove = (id) => {
  return sendDelete(`storages/${id}`);
};
export const countProducts = (params: any) => {
  return SendGet(`storages/count-products`, params);
};
export const removeProduct = (id: number) => {
  return sendDelete(`storages/remove-product/${id}`);
};
export const removeProducts = (id: number[]) => {
  return sendPost(`storages/remove-products`, {id});
};
export const productsList = (params) => {
  return SendGet(`storages/products`, params);
};
export const product = (params) => {
  const {id} = params;
  return SendGet(`storages/product/${id}`, params);
};
export const uploadStorageFile = (params) => {
  return sendPost(`storages/upload-files`, params);
};
export const deleteStorageFile = (params) => {
  return sendPost(`storages/delete-file`, params);
};
export const fetchStorageUsers = (params) => {
  return SendGet(`storage-users`, params);
};
export const attachStorageUsers = (params) => {
  return sendPost(`storage-users/attach-user`, params);
};
export const detatchStorageUsers = (params) => {
  return sendPost(`storage-users/detach-user`, params);
};
export const findStorageUsers = (params) => {
  return SendGet(`storage-users/find-user`, params);
};