// eslint-disable-next-line import/prefer-default-export
export const isRedPrice = (columnStorage) => {
    const storage = columnStorage?.storage ?? null;
    const priceChangePercent = Number.parseInt(columnStorage?.price_change_percent ?? 0, 10);
    const priceChangePercentStatus = Number.parseInt(columnStorage?.price_change_status ?? 0, 10);

    return storage && !priceChangePercent && priceChangePercentStatus;
}

export const isAllowRc = (props) => {
    const {spProduct, ruleRC} = props;
    if (!spProduct || !ruleRC) return false;
    const {storage} = spProduct;
    if (!storage) return false;
    const storageProductGroupSale = spProduct?.group ?? null;
    const isNotOrdering = parseInt(storage.status_crm ?? 0, 10); // имитация заказной
    const allowRC = parseInt(spProduct.allowRC, 10);
    return isNotOrdering && (parseInt(ruleRC.type_id, 10) === 2 || storageProductGroupSale) && allowRC;
}

export const getCurrencyValueFromShipper = (currencyShipperData, currencyDataInput = {id: 1, course: 1}) => {
    const currencyId = currencyDataInput.id;
    const currencyCourse = currencyDataInput.course;

    if (!currencyShipperData) return currencyDataInput.course;

    const currencyData = currencyShipperData?.find(x => x.currency_id === currencyId) ?? null;
    if (!currencyData) return currencyDataInput.course;
    const courseToNormal = 1 / currencyCourse;
    const typeId = currencyData.type_id;
    let courseValue = 0;
    switch (typeId) {
        case 1:
            courseValue = courseToNormal + (courseToNormal * currencyData.type_value / 100);
            break;
        case 2:
            courseValue = courseToNormal + currencyData.type_value;
            break;
        default:
            courseValue = Number.parseFloat(currencyData.value);
    }
    return 1 / courseValue;
}