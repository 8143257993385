import {useDispatch, useSelector} from "react-redux";
import React, {useMemo, useState} from "react";
import ModalCustomQuery from "../../../../modal/ModalCustomQuery";
import {setStatusModalCancelOrder} from "../../../../../redux/crm/order/actions";
import DirectoryOrderDoubleStatusAsync from "../../../../../components/directory/DirectoryOrderDoubleStatusAsync";
import {Style4} from "../../../../../constants/ReactSelect";
import {updateOrder} from "../../../../../api/query/order";
import {taskActions} from "../../../../../redux/toolkit/task/slice";
import ErrorResponse from "../../../../../components/common/ErrorResponse";
import {appActions} from "../../../../../redux/toolkit/app/slice";

const CancelOrder = () => {
    const dispatch = useDispatch();
    const modalCloseOrderShow = useSelector((state) => state.crmOrder.modalCancel.show);
    const [status, setStatus] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const orderId = useSelector((state) => state.crmOrder.modalCancel.orderId);

    const changeShow = (status) => {
        dispatch(setStatusModalCancelOrder({show: status}));
    }

    const isDisabledSubmit = useMemo(() => {
        return !status || submitting || !orderId
    }, [status, submitting, orderId]);

    const onSubmit = () => {
        const prepareData = {
            id: orderId,
            status_id: 8,
            double_status_id: status?.id ?? null,
            action: 'updateOrder',
        };
        setSubmitting(true);
        updateOrder(prepareData)
            .then(() => {
                setSubmitting(false);
                changeShow(false);
                dispatch(taskActions.setStatusModalNextTask(false));
            })
            .catch((error) => {
                setSubmitting(false);
                const errorResponse = ErrorResponse(error.response);
                dispatch(appActions.setNotification({type: 'error', message: errorResponse}));
            });
    };

    const content = useMemo(() => {
        return (
            <div className="ml-auto mr-auto" style={{width: '240px'}}>
                <DirectoryOrderDoubleStatusAsync
                    initState={status}
                    onChange={setStatus}
                    placeholder="Не выбрано"
                    style={Style4()}
                    statusId={8}
                />
            </div>
        )
    }, [status])

    return (
        <ModalCustomQuery
            title="Укажите причину отмены заказа"
            content={content}
            isShow={modalCloseOrderShow}
            changeShow={changeShow}
            onSubmit={{
                text: "Сохранить",
                callback: onSubmit,
                submitting: submitting,
                disabled: isDisabledSubmit,
            }}
        />
    )
}
export default CancelOrder;