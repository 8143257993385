export const queryScenarioExpand = [
    'creator',
    'user.avatarData',
    'user.crmGroups',
    'type',
    'event',
    'params'
];
export const queryScenarioField = [
    '*',
];

export const defaultParams = {
    params: {
        date_start: null,
        date_finish: null,
        audience: [],
    }
}

export const advancedInfoDefault = {
    audience_size: null,
    tasks_size: null,
    date_finish: null,
    tasks: null,
}