import {put, call, takeLatest} from 'redux-saga/effects';
import {PayloadAction} from "@reduxjs/toolkit";
import type {IBuyerGroupSale} from "./model";
import {buyerActions} from "./slice";
import {fetchDiscount, fetchDiscountList} from "../../../api/query/discount";
import {fetchBrands} from "../../../api/query/product";
import {fetchDealsGeneral, getDeals} from "../../../api/query/deal";
import {getOrgFiles} from "../../../api/query/orgFile";
import ErrorResponse from "../../../components/common/ErrorResponse";

function* getDiscountList({payload}: PayloadAction<any>) {
    try {
        const data: IBuyerGroupSale[] = yield call(fetchDiscountList, payload);
        yield put(buyerActions.setDiscountList(data));
    } catch (error) {
        yield put(buyerActions.setDiscountList([]));
    }
}

function* getDiscountView({payload}: PayloadAction<any>) {
    try {
        const data: IBuyerGroupSale = yield call(fetchDiscount, payload);
        yield put(buyerActions.setDiscountView(data));
    } catch (error) {
        yield put(buyerActions.setDiscountView(null));
        yield put(buyerActions.setError(error.response.data.message));
    }
}

function* getDiscountBrands({payload}: PayloadAction) {
    try {
        const data = yield call(fetchBrands, payload);
        yield put(buyerActions.setDiscountBrands(data.items));
    } catch (error) {
        yield put(buyerActions.setDiscountBrands([]));
    }
}

function* fetchBuyerDeals({payload}) {
    try {
        const getData = yield call(getDeals, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(buyerActions.setDeals(getData.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(buyerActions.setDeals(null));
    }
}

function* fetchBuyerDealsGeneral({payload}) {
    try {
        const getData = yield call(fetchDealsGeneral, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(buyerActions.setDealsGeneral(getData.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(buyerActions.setDealsGeneral(null));
    }
}

function* fetchBuyerFiles({payload}) {
    try {
        const getData = yield call(getOrgFiles, payload);
        const codeResponse = Number.parseInt(getData.status, 10);

        if (codeResponse === 200) {
            yield put(buyerActions.setFiles(getData.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(buyerActions.setFiles(null));
    }
}

// eslint-disable-next-line import/prefer-default-export
export function* buyerSaga() {
    yield takeLatest(buyerActions.getDiscountList.type, getDiscountList);
    yield takeLatest(buyerActions.getDiscountView.type, getDiscountView);
    yield takeLatest(buyerActions.getDiscountBrands.type, getDiscountBrands);
    yield takeLatest(buyerActions.fetchDeals.type, fetchBuyerDeals);
    yield takeLatest(buyerActions.fetchDealsGeneral.type, fetchBuyerDealsGeneral);
    yield takeLatest(buyerActions.getFiles.type, fetchBuyerFiles);
}
