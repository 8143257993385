export const documentsDefault = () => {
    return {
        disableGroups: [3],
        disableElements: [],
        doc_id: null,
        name: '',
        date: new Date(),
        date_create: new Date(),
        type: null,
        status: null,
        statusPayment: null,
        statusFailure: null,
        responsible: null,
        client: null,
        currency: {
            id: 1,
            name: 'Рубль',
            shortname: 'RUB',
            course: 1,
        },
        score_time: '1 рабочего дня',
        payment: '100% предоплата',
        guarantee: '1 год',
        availability: 'Оборудование находится на складе и готово к отгрузке',
        delivery: '',
        time_transportation: '',
        serial_number: '',
        time_production: '',
        contract: '',
        delivery_mode: 'ТК "Деловые линии"',
        delivery_payer: 'За счет покупателя',
        delivery_address: 'Терминал ТК "Деловые Линии" в городе покупателя',
        free_field: '',
        order_id: null,
    };
};

export const documentsRelProduct = [1, 2, 3, 4, 7, 8, 9, 10];

export const dfExpand = ['supplier', 'type', 'media', 'media.creator.avatarData', 'documentType'];