// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalChangeClient_clients__RRYEV {
  padding: 0 0 0 5px; }
  .ModalChangeClient_clients__RRYEV:first-child {
    padding: 5px 0 0 5px; }

.ModalChangeClient_clientsSelect__x1BfM {
  background-color: #FCEADC; }

.ModalChangeClient_clientsElem__\\+-Y3D {
  display: flex;
  align-items: center;
  justify-content: start;
  padding-bottom: 10px;
  padding-right: 5px; }

.ModalChangeClient_clientsElemText__UK06- {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }
`, "",{"version":3,"sources":["webpack://./src/containers/crm/order/card/modalSearchClient/ModalChangeClient.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB,EAAA;EADpB;IAII,oBAAoB,EAAA;;AAIxB;EACE,yBAAyB,EAAA;;AAG3B;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,oBAAoB;EACpB,kBAAkB,EAAA;;AAGpB;EACE,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB,EAAA","sourcesContent":[".clients {\n  padding: 0 0 0 5px;\n\n  &:first-child {\n    padding: 5px 0 0 5px;\n  }\n}\n\n.clientsSelect {\n  background-color: #FCEADC;\n}\n\n.clientsElem {\n  display: flex;\n  align-items: center;\n  justify-content: start;\n  padding-bottom: 10px;\n  padding-right: 5px;\n}\n\n.clientsElemText {\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clients": `ModalChangeClient_clients__RRYEV`,
	"clientsSelect": `ModalChangeClient_clientsSelect__x1BfM`,
	"clientsElem": `ModalChangeClient_clientsElem__+-Y3D`,
	"clientsElemText": `ModalChangeClient_clientsElemText__UK06-`
};
export default ___CSS_LOADER_EXPORT___;
