// eslint-disable-next-line import/prefer-default-export
export const defaultContactPosition = {id: 8, name: 'Не определено'};
export const defaultOrganizationCategory = {id: 2, name: 'Не определено'};
export const defaultClientCountry = {id: 11, code: 'RU', name: 'Россия'};
export const defaultCurrencyRU = {id: 1, shortname: 'RUB', name: 'Рубль', course: 1};
export const ndsValue = 20;
export const minWidthColumnTableResize = 50;
export const amountRound = 2; // округление сумм
export const currencySandboxIsDefault = false; // false - в рубли, true - по умолчанию
export const defaultImageProduct = '//stoking.ru/assets/img/common/empty-photo.png';
export const websocketUrl = 'wss://ws.stoking.ru';

export const fetchMetaDefault = {
    pageCount: 0,
    perPage: 0,
    currentPage: 0,
    totalCount: 0,
}

export const defaultQueryList = {
    items: [],
    _meta: fetchMetaDefault
}
export const logisticsStatuses = [
    {
        id: 2,
        name: "В обработке",
        completed: false,
        date: null,
        parent_id: null,
        color: '#002FA9'
    },
    {
        id: 3,
        name: "Забор груза от адресата",
        completed: false,
        date: null,
        parent_id: 2,
        color: '#198707'
    },
    {
        id: 4,
        name: "Ожидает сдачи на терминале",
        completed: false,
        date: null,
        parent_id: 2,
        color: '#A9006F'
    },
    {
        id: 6,
        name: "Груз принят к перевозе",
        completed: false,
        date: null,
        parent_id: null,
        color: '#02A2AC'
    },
    {
        id: 7,
        name: "Груз принят к перевозке. Платное хранение",
        completed: false,
        date: null,
        parent_id: 6,
        color: '#000000'
    },
    {
        id: 8,
        name: "Груз в пути",
        completed: false,
        date: null,
        parent_id: 6,
        color: '#8A3B0E'
    },
    {
        id: 9,
        name: "Груз прибыл на терминал",
        completed: false,
        date: null,
        parent_id: null,
        color: '#9D9D9D'
    },
    {
        id: 10,
        name: "Груз прибыл на терминал. Платное хранение",
        completed: false,
        date: null,
        parent_id: 9,
        color: '#FFA944'
    },
    {
        id: 11,
        name: "Груз прибыл в аэропорт",
        completed: false,
        date: null,
        parent_id: null,
        color: '#caab18'
    },
    {
        id: 12,
        name: "Груз прибыл в аэропорт. Платное хранение",
        completed: false,
        date: null,
        parent_id: 11,
        color: '#7e1ca1'
    },
    {
        id: 13,
        name: "Доставка груза до адресата",
        completed: false,
        date: null,
        parent_id: null,
        color: '#02ca91'
    },
    {
        id: 14,
        name: "Заказ завершен",
        completed: false,
        date: null,
        parent_id: 13,
        color: '#ba7063'
    },
];