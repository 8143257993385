import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import useConfirm from "../../../../hooks/useConfirm";
import {taskSelectors} from "../../../../redux/toolkit/task/selectors";
import {deleteWatcherQuery} from "../api";
import {taskActions} from "../../../../redux/toolkit/task/slice";
import Watcher from "./Watcher";

const Watchers = ({id, accessDeleteWatcher}) => {
    const {confirm} = useConfirm();
    const dispatch = useDispatch();

    const {data} = useSelector(taskSelectors.watchers);

    const getWatchers = () => {
        const params = {id: data.id};
        dispatch(taskActions.fetchWatchers(params));
    }

    const onDeleteWatcher = async (watcherId) => {
        const isConfirmed = await confirm({
            title: 'Вы действительно хотите удалить этого наблюдателя?',
            confirmButtonText: `Удалить`,
            closeButtonText: `Отмена`,
        });
        if (isConfirmed) {
            const prepareParams = {
                id,
                user_id: watcherId,
            };
            const result = await deleteWatcherQuery(prepareParams);
            if (result) {
                getWatchers();
            }
        }
    };

    useEffect(() => {
        getWatchers();
        return () => {
            dispatch(taskActions.setWatchers([]));
        };
    }, []);

    if (!data.length) return <></>;

    return (
        <>
            <div className="line-height-0 opacity-50">
                <small>Наблюдатели:</small>
            </div>
            <div className="mt-2">
                <div className="watchers-list">
                    {data.map(watcher => {
                        return <Watcher
                            key={watcher.id}
                            watcher={watcher}
                            onDeleteWatcher={onDeleteWatcher}
                            accessDeleteWatcher={accessDeleteWatcher}
                        />
                    })}
                </div>
            </div>
        </>
    )
}
export default Watchers;