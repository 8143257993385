import MissedDeadline from "./fields/MissedDeadline";
import LongAnswers from "./fields/LongAnswers";
import Comment from "./fields/Comment";

export const defaultParams = {
    rating: 0,
    comment: '',
    detail: {},
}

export const modalDefaultParams = {
    title: '',
    statusId: 0,
    actions: [],
}

export const modalParams = {
    logistic: {
        title: 'Оцените ${dealLink} c точки зрения логиста',
        statusId: 5,
        actions: [MissedDeadline, LongAnswers, Comment]
    },
    bookkeeping: {
        title: 'Оцените ${dealLink} c точки зрения бухгалтера',
        statusId: 6,
        actions: [LongAnswers, Comment]
    },
    supplier: {
        title: 'Оцените ${dealLink} c точки зрения снабженца',
        statusId: 1,
        actions: [MissedDeadline, LongAnswers, Comment]
    }
}