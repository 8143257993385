import {Tooltip} from "@mui/material";
import React, {useContext, useMemo, useState} from "react";
import {getOrgLink} from "../../../../helpers/client/client";
import ModalChangeClient from "../../../crm/order/card/modalSearchClient/ModalChangeClient";
import {ModalCreateTaskContext} from "../index";

const Contragent = ({value, setValue, onClickClient, changeClient}) => {

    const {data} = useContext(ModalCreateTaskContext);
    const [modalChangeClient, setModelChangeClient] = useState(false); // модальное окно выбора клиента

    const onClickClientHandler = (event) => {
        event.stopPropagation();
        event.preventDefault();
        onClickClient();
    };

    const clientNameRender = useMemo(() => {
        if (!value) return <></>;
        const link = getOrgLink(value);
        if (!link) return <></>;
        return <a onClick={onClickClientHandler} href={link} className="nav-link-style-1">{value.name}</a>;
    }, [value]);

    const paramsSearch = useMemo(() => {
        let params = {};
        if (data.taskTypeTemplate?.id === 51) {
            params = {
                ...params,
                'selection[filter]': JSON.stringify([{id: 'status_id', value: [2,3,4]}])
            }
        }
        return params;
    }, [data]);

    return (
        <>
            <div className="font-weight-bold">Контрагент:</div>
            <div className="mt-2">
                <div className="clientControl style-2">
                    <div className="ml-0 mr-0 row w-100">
                        <div className="col-auto d-flex align-items-center color-dim pl-2 pr-2">
                            <span className="text-muted mr-1">ID:</span>{' '}
                            {value ? value.id : ''}
                        </div>
                        <div className="col pl-0 pr-0 overflow-ellipsis">
                            {clientNameRender}
                        </div>
                        {changeClient && (
                            <div className="col-auto text-right d-flex align-items-center color-dim pl-2 pr-2">
                                <Tooltip title='Изменить контрагента' placement='top'>
                                    <button
                                        className="btn"
                                        type="button"
                                        id="tooltip_change_client"
                                        onClick={() => setModelChangeClient(true)}
                                    >
                                        <i className="simple-icon-magnifier"> </i>
                                    </button>
                                </Tooltip>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <ModalChangeClient
                isShow={modalChangeClient}
                changeShow={setModelChangeClient}
                changeClient={setValue}
                orgType={[1,2]}
                otherParams={paramsSearch}
            />
        </>
    )
}
export default Contragent;