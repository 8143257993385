import {SendGet} from "../handler/sendGet";

export const fetchDiscountList = (params) => {
    return SendGet(`sales`, params)
        .then((response: any) => {
            if (response.status === 200) {
                return response.data
            }
            return [];
        });
};

export const fetchDiscount = (params) => {
    const {id} = params;
    return SendGet(`sales/${id}`, params)
        .then((response: any) => {
            if (response.status === 200) {
                return response.data
            }
            return [];
        });
};