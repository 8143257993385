import {deleteTaskWatcher, taskChange, taskCreate} from "../../../api/query/task";
import ErrorResponse from "../../../components/common/ErrorResponse";

export const deleteWatcherQuery = async (params) => {
    let result = false;
    await deleteTaskWatcher(params)
        .then(() => {
            result = true;
        })
        .catch((error) => {
            ErrorResponse(error.response);
        });
    return result;
}

export const changeTask = async (params) => {
    let result = null;
    await taskChange(params)
        .then((response) => {
            result = response;
        })
        .catch((error) => {
            ErrorResponse(error.response);
        });
    return result;
}

export const createTask = async (params) => {
    let result = null;
    await taskCreate(params)
        .then((response) => {
            result = response;
        })
        .catch((error) => {
            ErrorResponse(error.response);
        });
    return result;
}