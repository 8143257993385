import React from 'react'
import {Button, Modal, ModalBody, ModalHeader} from 'reactstrap'
import css from './ModalUpdateArticles.module.scss'
import UpdateArticle from './UpdateArticle/UpdateArticle'
import moment from 'moment/moment'

const ModalUpdateArticles = ({isShow, changeShow, articles}) => {
    const currentDate = moment().tz('Etc/GMT-5').format('DD.MM.YY')
    if (!articles.length) return <></>

    return (
        <>
            <Modal
                size="md"
                className="modal-style"
                isOpen={isShow}
                toggle={() => changeShow(!isShow)}
                backdrop="static">
                <ModalHeader className="pb-0" toggle={() => changeShow(false)}>
                    <span>{`Обновление CRM от ${currentDate}`}</span>
                </ModalHeader>
                <ModalBody className="pt-0">
                    <div>
                        {articles.map((el, index) => {
                            return (
                                <UpdateArticle
                                    key={el.title}
                                    headline={el.title}
                                    slug={el.slug}
                                    imgDetail={el.previewImage}
                                    description={el.description}
                                    date={el.date_publication}
                                    idArticle={`article_${index}`}
                                    classes="articlesElem"
                                />
                            )
                        })}
                    </div>
                    <div className={css.updateArticleButton}>
                        <Button
                            color="primary"
                            href={'/app/updates'}
                            onClick={() => window?.scrollTo && window.scrollTo(0, 0)}>
                            Ко всем обновлениям
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}
export default ModalUpdateArticles
