import React, {useRef, useState} from 'react'
import css from './ResizableBox.module.scss'

const MIN_HEIGHT = 200
const MAX_HEIGHT = 1000

const ResizableBox = props => {
    const {children} = props
    const [initialPos, setInitialPos] = useState(null)
    const [initialSize, setInitialSize] = useState(null)
    const resizableRef = useRef(null)

    const deselectAll = () => {
        if (!window || !document) return
        const selection = window.getSelection ? window.getSelection() : document.selection
        if (selection) selection.empty()
    }

    const initResize = e => {
        if (!resizableRef.current) return
        deselectAll()
        const clientY = e.clientY || e.touches?.[0]?.clientY
        setInitialPos(clientY)
        setInitialSize(resizableRef.current.offsetHeight)
    }

    const endResize = e => {
        if (!resizableRef.current) return
        setInitialPos(null)
        setInitialSize(null)
    }

    const updateSize = e => {
        if (!resizableRef.current) return
        const clientY = e.clientY || e.touches?.[0]?.clientY
        const newHeight = parseInt(initialSize) + parseInt(clientY - initialPos)
        if (newHeight < MIN_HEIGHT || newHeight > MAX_HEIGHT) return
        resizableRef.current.style.height = `${newHeight}px`
    }

    return (
        <div className={css.resizable} ref={resizableRef}>
            {children}
            <div
                className={`${css.draggable} ${initialSize ? css.keep : ''}`}
                onMouseDown={initResize}
                onMouseMove={updateSize}
                onMouseUp={endResize}
                onMouseLeave={endResize}
                onTouchStart={initResize}
                onTouchMove={updateSize}
                onTouchEnd={endResize}
                onTouchCancel={endResize}
                draggable={false}
            />
        </div>
    )
}
export default ResizableBox
