// eslint-disable-next-line import/no-cycle
import {CHANGE_LOADER, CHANGE_LOCALE, CHANGE_MODE_WATCH, CHANGE_MODE_WATCH_FETCH} from '../actions';
import { setCurrentLanguage } from '../../helpers/Utils';

// eslint-disable-next-line import/prefer-default-export
export const changeLocale = (locale) => {
  setCurrentLanguage(locale);
  return {
    type: CHANGE_LOCALE,
    payload: locale,
  };
};
export const changeLoader = (data) => {
  return {
    type: CHANGE_LOADER,
    payload: data,
  };
};
export const changeModeWatch = (data) => {
  return {
    type: CHANGE_MODE_WATCH,
    payload: data,
  };
};
export const fetchModeWatch = (data = {}) => {
  return {
    type: CHANGE_MODE_WATCH_FETCH,
    payload: data,
  };
};
