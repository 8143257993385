import {put, call, takeEvery} from 'redux-saga/effects'
import {scenarioActions} from "./slice";
import {PayloadAction} from "@reduxjs/toolkit";
import {getScenario, getScenarios} from "../../../../api/query/scenario";
import {appActions} from "../../app/slice";
import ErrorResponse from "../../../../components/common/ErrorResponse";
import {getScenarioTasks} from "../../../../api/query/scenarioTask";

function* fetchViewData({payload}: PayloadAction<any>) {
    try {
        const data = yield call(getScenario, payload);
        const codeResponse = Number(data.status);
        if (codeResponse === 200) {
            yield put(scenarioActions.setView(data.data));
        }
    } catch (error) {
        yield put(scenarioActions.setView(null));
        yield put(appActions.setNotification({type: 'error', message: ErrorResponse(error.response)}));
    }
}

function* fetchListData({payload}: PayloadAction<any>) {
    try {
        const data = yield call(getScenarios, payload);
        const codeResponse = Number(data.status);
        if (codeResponse === 200) {
            yield put(scenarioActions.setList(data.data));
        }
    } catch (error) {
        yield put(scenarioActions.setList(null));
    }
}

function* fetchViewTasks({payload}: PayloadAction<any>) {
    try {
        const data = yield call(getScenarioTasks, payload);
        const codeResponse = Number(data.status);
        if (codeResponse === 200) {
            yield put(scenarioActions.setTasks(data.data));
        }
    } catch (error) {
        yield put(scenarioActions.setTasks(null));
        yield put(appActions.setNotification({type: 'error', message:  ErrorResponse(error.response)}));
    }
}

// eslint-disable-next-line import/prefer-default-export
export function* scenarioSaga() {
    yield takeEvery(scenarioActions.getList.type, fetchListData);
    yield takeEvery(scenarioActions.getView.type, fetchViewData);
    yield takeEvery(scenarioActions.getTasks.type, fetchViewTasks);
}
