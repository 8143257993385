import { SendGet } from '../handler/sendGet';
import { sendPut } from '../handler/sendPut';
import { sendPost } from '../handler/sendPost';

export const getComments = (params) => {
    return SendGet(`comments`, params);
};
export const getComment = ({ id, params }) => {
    return SendGet(`comments/${id}`, params);
};
export const commentChange = ({ id, params }) => {
    return sendPut(`comments/${id}`, params);
};
export const commentCreate = (params) => {
    return sendPost(`comments/create`, params);
};
export const commentViewing = (params) => {
    return sendPost(`comments/viewing`, params);
};
export const getCountComments = (params) => {
    return SendGet(`comments/count`, params);
};
export const getContactedMe = (params) => {
    return SendGet(`comments/contacted-me`, params);
};
