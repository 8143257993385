import { SendGet } from '../handler/sendGet';
import {sendPost} from "../handler/sendPost";

// eslint-disable-next-line import/prefer-default-export
export const getOrgFiles = (params) => {
    return SendGet(`organization-files`, params);
};
export const deleteOrgFiles = (params) => {
    return sendPost(`organization-files/remove`, params);
};
export const createOrgFiles = (params) => {
    return sendPost(`organization-files/create`, params);
};
