import React, {useMemo, useState} from 'react';
import ModalShipperScore from "../../../modal/ModalShipperScore";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getPreparedRatingForSave, setShipperRating} from "./helpers";
import {appActions} from "../../../../redux/toolkit/app/slice";
import {dealCardDataReload} from "../../../../redux/crm/deal/actions";
import UniqID from "../../../../components/common/UniqID";

const ContainerModalShipperScore = () => {
    const dispatch = useDispatch();
    const [params, setParams] = useState({
        eventId: null,
        type: '',
        loading: false,
        shippers: [],
        dealId: null,
    })
    const taskEvent = useSelector((state) => state.websocket.event.task);

    const setParam = (name, value) => {
        setParams((oldValues) => {
            return {
                ...oldValues,
                [name]: value
            }
        })
    }

    const onSave = async (ratingData) => {
        const preparedData = {
            deal_id: ratingData.dealId,
            status_id: ratingData.status_id,
            rating: getPreparedRatingForSave(ratingData.data)
        }
        setParam('loading', true);
        const {type: resultType, data: resultData} = await setShipperRating(preparedData);
        setParam('loading', false);
        if (resultType === 'success') {
            dispatch(appActions.setNotification({type: 'success', message: 'Ваша оценка учтена'}));
            setParam('statusDisplay', false);
            dispatch(dealCardDataReload(true));
            return true;
        }
        dispatch(appActions.setNotification({type: 'error', message: resultData}));
        return false;
    }

    const onEvent = (event) => {
        const typeId = event.type_id;
        const dealId = Number(event.deal_id);
        if (!dealId) return false;
        const shippers = event.shippers ?? [];
        if (!shippers.length) return false;

        setParams({
            type: typeId,
            shippers,
            dealId,
            eventId: UniqID(),
        })
    }

    useEffect(() => {
        if (taskEvent) {
            const actionEvent = taskEvent?.action;
            if (actionEvent === 'onClose') {
                onEvent(taskEvent);
            }
        }
    }, [taskEvent]);

    return (
        <ModalShipperScore
            eventId={params.eventId}
            type={params.type}
            entityList={params.shippers}
            dealId={params.dealId}
            onSave={onSave}
            disabledSubmit={params.loading}
        />
    )
};
export default ContainerModalShipperScore;
