import {fixBug} from "../api/query/app";

const logError500 = (error: Error, info) => {
    fixBug({
        platform: 'crm',
        message: {
            error: {
                stack: error.stack,
                message: error.message
            },
            info
        },
        location: window.location.href
    });
};
// eslint-disable-next-line import/prefer-default-export
export {logError500};
