/* eslint-disable camelcase */
interface IsCompleteStatus  {
    status_id: number,
    currentStatusId: number
}

interface StatusTimeline {
    timeline: any,
    status_id: number
}
export const getStatusTimeline = (props: StatusTimeline): any => {
    const {timeline, status_id} = props;
    const getData = timeline?.find((x) => x.status_id === status_id);
    return getData ?? null
}

export const isActiveStatus = (props: IsCompleteStatus) => {
    const {currentStatusId, status_id} = props;
    return currentStatusId === status_id;
};

export const isCompleteStatus = (props: IsCompleteStatus) => {
    const {currentStatusId, status_id} = props;
    return currentStatusId > status_id || currentStatusId === 14;
};

export const extractUrl = (url: string, number_shipment: string) => {
    if (!url || !number_shipment) return '';
    // eslint-disable-next-line no-template-curly-in-string
    return url.replace('${number}', number_shipment.trim());
}
