import {Scrollbars} from "react-custom-scrollbars";
import React, {forwardRef} from "react";
import css from "../Comments.module.scss";
import SearchUserItem from "./SearchUser";

// eslint-disable-next-line react/display-name
const SearchUser = forwardRef((props, ref)  => {
    const {usersFound, onSelectedUser} = props;
    return (
        <div ref={ref} className={css.searchResultUser}>
            <Scrollbars
                autoHeight
                autoHeightMin={0}
                autoHeightMax={120}
            >
                {
                    usersFound.map((item, key) => {
                        return (
                            <SearchUserItem
                                key={`searchUser_${item.id}`}
                                item={item}
                                index={key}
                                onClickItem={onSelectedUser}
                            />
                        );
                    })
                }
            </Scrollbars>
        </div>
    )
});
export default SearchUser;