// eslint-disable-next-line no-unused-vars
/* eslint-disable no-use-before-define */
import {all, takeLatest, fork, put, call, takeEvery} from 'redux-saga/effects';
import {
    DEAL_CARD_COMMENTS_FETCH,
    DEAL_CARD_DATA_FETCH, DEAL_CARD_DOCUMENTS_FETCH, DEAL_CARD_FILES_FETCH,
    DEAL_CARD_LOGISTICS_FETCH, DEAL_CARD_LOGISTICS_VIEW_COMMENTS_FETCH,
    DEAL_CARD_PAYMENTS_FETCH,
    DEAL_CARD_RECIPIENTS_FETCH, DEAL_LIST_FETCH_DATA
} from "../../actions";
import {dealFiles, dealLogistics, dealPayments, getDeal, getDeals} from "../../../api/query/deal";
import {
    changeLoading,
    dealCardCommentsLoading,
    dealCardCommentsSet, dealCardDataError,
    dealCardDataSet,
    dealCardDocumentsLoading,
    dealCardDocumentsSet, dealCardFilesLoading, dealCardFilesSet,
    dealCardLogisticsLoading,
    dealCardLogisticsSet,
    dealCardLogisticsViewCommentsLoading,
    dealCardLogisticsViewCommentsSet,
    dealCardPaymentsLoading,
    dealCardPaymentsSet,
    dealCardRecipientsLoading,
    dealCardRecipientsSet, dealListLoading, dealListSetData
} from "./actions";
import ErrorResponse from "../../../components/common/ErrorResponse";
import {fetchList} from "../../../api/query/organization";
import {getDocuments} from "../../../api/query/document";
import {getComments} from "../../../api/query/comment";

export function* watchDeal() {
    yield takeLatest(DEAL_CARD_DATA_FETCH, loadDealDetails);
}
function* loadDealDetails({ payload }) {
    try {
        const getData = yield call(getDeal, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(dealCardDataSet(getData.data));
        }
        yield put(changeLoading(false));
    } catch (error) {
        const codeResponse = Number.parseInt(error.response.status, 10);
        if (codeResponse === 404) {
            yield put(dealCardDataError(Date.now()));
        }
        ErrorResponse(error.response);
        yield put(changeLoading(false));
    }
}

export function* watchDealLogistics() {
    yield takeLatest(DEAL_CARD_LOGISTICS_FETCH, loadDealLogistics);
}
function* loadDealLogistics({ payload }) {
    try {
        const getData = yield call(dealLogistics, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(dealCardLogisticsSet(getData.data.items));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(dealCardLogisticsLoading(false));
    }
}

export function* watchDealPayments() {
    yield takeLatest(DEAL_CARD_PAYMENTS_FETCH, loadDealPayments);
}
function* loadDealPayments({ payload }) {
    try {
        const getData = yield call(dealPayments, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(dealCardPaymentsSet(getData.data.items));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(dealCardPaymentsLoading(false));
    }
}

export function* watchDealRecipients() {
    yield takeLatest(DEAL_CARD_RECIPIENTS_FETCH, loadDealRecipients);
}
function* loadDealRecipients({ payload }) {
    try {
        const getData = yield call(fetchList, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(dealCardRecipientsSet(getData.data.items));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(dealCardRecipientsLoading(false));
    }
}

export function* watchDealDocuments() {
    yield takeLatest(DEAL_CARD_DOCUMENTS_FETCH, loadDealDocuments);
}
function* loadDealDocuments({ payload }) {
    try {
        const getData = yield call(getDocuments, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(dealCardDocumentsSet(getData.data.items));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(dealCardDocumentsLoading(false));
    }
}

export function* watchDealComments() {
    yield takeLatest(DEAL_CARD_COMMENTS_FETCH, loadDealComments);
}
function* loadDealComments({ payload }) {
    try {
        const getData = yield call(getComments, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(dealCardCommentsSet(getData.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(dealCardCommentsLoading(false));
    }
}

export function* watchDealLogisticsComments() {
    yield takeLatest(DEAL_CARD_LOGISTICS_VIEW_COMMENTS_FETCH, loadDealLogisticsComments);
}
function* loadDealLogisticsComments({ payload }) {
    try {
        const getData = yield call(getComments, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(dealCardLogisticsViewCommentsSet(getData.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(dealCardLogisticsViewCommentsLoading(false));
    }
}

export function* watchDealList() {
    yield takeEvery(DEAL_LIST_FETCH_DATA, loadDealList);
}
function* loadDealList({ payload }) {
    try {
        const getData = yield call(getDeals, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(dealListSetData(getData.data));
        }
        yield put(dealListLoading(false));
    } catch (error) {
        ErrorResponse(error.response);
        yield put(dealListLoading(false));
    }
}

export function* watchDealFiles() {
    yield takeLatest(DEAL_CARD_FILES_FETCH, loadDealFiles);
}
function* loadDealFiles({ payload }) {
    try {
        const getData = yield call(dealFiles, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(dealCardFilesSet(getData.data.items));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(dealCardFilesLoading(false));
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchDeal),
        fork(watchDealLogistics),
        fork(watchDealPayments),
        fork(watchDealRecipients),
        fork(watchDealDocuments),
        fork(watchDealComments),
        fork(watchDealLogisticsComments),
        fork(watchDealList),
        fork(watchDealFiles)
    ]);
}
