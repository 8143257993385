import React from "react";
import {Style6} from "../../../../constants/ReactSelect";
import DirectoryTaskTypeSync from "../../../../components/directory/DirectoryTaskTypeSync";

const Type = ({value, setValue, excludeId = [], options = [], isDisabled = false}) => {

    return (
        <>
            <div className="font-weight-bold">Тип:</div>
            <div className="mt-2">
                <DirectoryTaskTypeSync
                    initState={value}
                    onChange={setValue}
                    style={Style6()}
                    excludeId={excludeId}
                    options={options}
                    isClearable={true}
                    isDisabled={isDisabled}
                />
            </div>
        </>
    )
}
export default Type;