import React from 'react'
import {Box} from "@mui/material";

const TabPanel = (props) => {
    const {children, value, index, className, ...other} = props;

    return (
        <div
            role="tabpanel"
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            className={(className || "") + (value !== index ? " d-none" : "")}
            {...other}
        >
            <Box sx={{padding: "24px"}}>{children}</Box>
        </div>
    );
}

export default TabPanel