import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import todoApp from './todo/reducer';
import chatApp from './chat/reducer';
import surveyListApp from './surveyList/reducer';
import surveyDetailApp from './surveyDetail/reducer';
import crmDeal from './crm/deal/reducer';
import managers from './managers/reducer';
import crmOrder from './crm/order/reducer';
import crmClient from './crm/client/reducer';
import crmTask from './crm/task/reducer';
import notification from './notification/reducer';
import comment from './comment/reducer';
import crmDocument from './crm/document/reducer';
import websocket from './websocket/reducer';
import userData from './user/reducer';
import finance from './finance/reducer';
import statisticsDeal from './statistics/deal/reducer';
import orderFeedback from './crm/orderFeedback/reducer'
import modal from './modal/reducer'
import {orderReducer} from "./toolkit/order/slice";
import {logisticsReducer} from "./toolkit/logistics/slice";
import {storageReducer} from "./toolkit/storage/slice";
import {statisticsReducer} from "./toolkit/statistics/slice";
import {dealerReducer} from "./toolkit/dealer/slice";
import {buyerReducer} from "./toolkit/buyer/slice";
import {taskReducer} from "./toolkit/task/slice";
import {appReducer} from "./toolkit/app/slice";
import {targetReducer} from "./toolkit/target/slice";
import {processReducer} from "./toolkit/process/slice";
import {articlesReducer} from "./articles/slice";
import {usersReducer} from "./toolkit/users/slice";
import {scenarioReducer} from "./toolkit/settings/scenario/slice";
import {audienceReducer} from "./toolkit/audience/slice";

const reducers = combineReducers({
  menu,
  modal,
  settings,
  authUser,
  todoApp,
  chatApp,
  surveyListApp,
  surveyDetailApp,
  crmDeal,
  managers,
  crmOrder,
  crmClient,
  crmTask,
  notification,
  comment,
  crmDocument,
  websocket,
  userData,
  finance,
  statisticsDeal,
  orderFeedback,
  order: orderReducer,
  logistics: logisticsReducer,
  storage: storageReducer,
  statistics: statisticsReducer,
  dealer: dealerReducer,
  buyer: buyerReducer,
  task: taskReducer,
  app: appReducer,
  target: targetReducer,
  process: processReducer,
  articles: articlesReducer,
  users: usersReducer,
  scenario: scenarioReducer,
  audience: audienceReducer,
});

export default reducers;
