import {dealSetShipperRating} from "../../../../api/query/deal";

export const setShipperRating = async (data) => {
    let result = {
        type: '',
        data: null,
    }
    await dealSetShipperRating(data)
        .then(response => {
            result = {
                type: 'success',
                data: response.data,
            }
        }).catch(error => {
            const message = error?.response?.data?.message ?? 'Что-то пошло не так';
            result = {
                type: 'error',
                data: message,
            };
        });
    return result;
}

export const getPreparedRatingForSave = (data) => {
    const result = [];
    data.forEach(item => {
        result.push({
            shipper_id: item.id,
            rating: item.rating,
            comment: item.comment,
            detail: item.detail,
        })
    })
    return result;
}