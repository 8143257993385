import { SendGet } from '../handler/sendGet';

// eslint-disable-next-line import/prefer-default-export
export const fetchStatisticsDealers = (params) => {
  return SendGet(`statistics/dealers`, params);
};

export const fetchStatisticsDealersProducts = (params) => {
  return SendGet(`statistics/dealers-products`, params);
};

export const fetchStatisticsPublishedProducts = (params) => {
  return SendGet(`statistics/publish-products`, params);
};

export const fetchStatisticsPublishedProductsChartData = (params) => {
  return SendGet(`statistics/publish-products-count-by-date`, params);
};

export const fetchStatisticsSupplier = (params) => {
  return SendGet(`statistics/suppliers`, params);
};
export const fetchStatisticsSuppliersStorageProductCounts = () => {
  return SendGet(`statistics/suppliers-storage-product-counts`, []);
};