import { SendGet } from '../handler/sendGet';
import {sendPost} from "../handler/sendPost";

// eslint-disable-next-line import/prefer-default-export
export const getOrderProducts = (params) => {
  return SendGet(`order-products`, params);
};
export const getDiscount = (params: any) => {
  return sendPost(`order-products/sales`, params);
};
export const downloadProduct = (params) => {
  return SendGet(`order-products/product-file`, params);
};