import React from "react";
import Switch from "rc-switch/es";
import 'rc-switch/assets/index.css';
import {Tooltip} from "@mui/material";

const OnlyProfile = (props) => {
    const {setValue, value, isDisabled = false} = props;
    return (
        <>
            <div className="font-weight-bold">
                Профильные
                <Tooltip title="Подбирать только профильные позиции" placement="top">
                    <span className="ml-1 position-relative" style={{top: '2px'}}>
                         <i className="simple-icon-question text-primary" />
                    </span>
                </Tooltip>
            </div>
            <div className="mt-2">
                <Switch
                    className="custom-switch custom-switch-primary custom-switch-medium"
                    checked={value}
                    onChange={setValue}
                    disabled={isDisabled}
                />
            </div>
        </>
    )
};
export default OnlyProfile;