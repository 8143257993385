// eslint-disable-next-line import/prefer-default-export
import {isJson} from "../../components/validate/JSON";

export const extractBrands = (storages = [], sortArr = []) => {
    const brandsUniq = (items) => {
        const itemsUniq = [];
        if (!items.length) return itemsUniq;
        items.forEach(item => {
            const {id} = item;
            const findElement = itemsUniq.find(x => x.id === id);
            if (typeof findElement === 'undefined') {
                itemsUniq.push(item);
            }
        });
        return itemsUniq;
    };
    let brandsAll = [];
    if (typeof storages === 'undefined') return brandsAll;
    if (!storages.length) return brandsAll;
    storages.forEach(storage => {
       const {brands} = storage;
       if (typeof brands !== 'undefined') {
           brandsAll = [...brandsAll, ...brands];
       }
    });
    brandsAll = brandsUniq(brandsAll);
    if (sortArr) {
        brandsAll.sort((a, b) => {
            return sortArr.includes(b.id) - sortArr.includes(a.id);
        });
    }
    return brandsAll;
}
export const extractBrandsFromQuery = (queryBrands = []) => {
    let filter = queryBrands?.['selection[filter]'] ?? null;
    if (!filter) return [];
    if (isJson(filter)) {
        filter = JSON.parse(filter);
    }
    const searchBrandId = filter.find(x => x.id === 'brand_id') ?? null;
    if (!searchBrandId) return [];
    return searchBrandId.value;
}