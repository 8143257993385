import React from 'react';
import {Box, DialogContent} from "@mui/material";
import {useDispatch} from "react-redux";
import {StyledDialog} from "../../utils/mui/dialog";
import {appActions} from "../../redux/toolkit/app/slice";

const ModalNextTask = ({isShow, onCloseOrder, onCreateTask}) => {
    const dispatch = useDispatch();

    const onClickClose = () => {
        dispatch(appActions.setNotification({type: 'error', message: 'В глаз ткни себе!'}));
    };

    return (
        <>
            <StyledDialog
                open={isShow}
                maxWidth="xs"
                className="modal-dialog-mui"
                onClose={onClickClose}
                backdrop="static"
            >
                <DialogContent>
                    <Box pt={3} pb={1}>
                        <div className="window-question">
                            <div className="icon">
                                <i className="simple-icon-question" />
                            </div>
                            <div className="text mt-3">У заказа нет будущих задач, создать?</div>
                            <div className="d-flex align-items-center justify-content-center mt-3">
                                <button
                                    type="button"
                                    className="btn-outline-primary btn ml-2 mr-2"
                                    onClick={onCloseOrder}
                                >Отменить заказ</button>
                                <button
                                    type="button"
                                    className="btn-primary btn ml-2 mr-2"
                                    onClick={onCreateTask}
                                >Создать задачу</button>
                            </div>
                        </div>
                    </Box>
                </DialogContent>
            </StyledDialog>
        </>
    );
};
export default ModalNextTask;
