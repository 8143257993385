import {put, call, takeEvery} from 'redux-saga/effects'

import {logisticsActions} from "./slice";
import {dealLogistics} from "../../../api/query/deal";
import ErrorResponse from "../../../components/common/ErrorResponse";

function* fetchLogisticsData({payload}) {
    try {
        const getData = yield call(dealLogistics, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(logisticsActions.setData(getData.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(logisticsActions.setData(null));
    }
}

// eslint-disable-next-line import/prefer-default-export
export function* logisticsSaga() {
    yield takeEvery(logisticsActions.fetchData.type, fetchLogisticsData);
}
