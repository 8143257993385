import { SendGet } from '../handler/sendGet';
import { sendPut } from '../handler/sendPut';
import { sendPost } from '../handler/sendPost';

export const getTasks = (params) => {
  return SendGet(`order-tasks`, params);
};
export const getTask = ({ id, params }) => {
  return SendGet(`order-tasks/${id}`, params);
};
export const taskChange = ({ id, params }) => {
  return sendPut(`order-tasks/${id}`, params);
};
export const taskCreate = (params) => {
  return sendPost(`order-tasks/create`, params);
};
export const tasksMassOperation = (params) => {
  return sendPost(`order-tasks/mass-operation`, params);
};
export const getTaskWatchers = (params) => {
  return SendGet(`order-tasks/watchers`, params);
};
export const deleteTaskWatcher = (params) => {
  return sendPost(`order-tasks/watcher-delete`, params);
};
export const deleteTask = (params) => {
  return sendPost(`order-tasks/delete`, params);
};
export const getTaskTableHandbook = (params) => {
  return sendPost(`order-tasks/table-handbook`, params);
};