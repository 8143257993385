import React from "react";

const LogoutIcon = ({width="20", height="18"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
            <g opacity="0.5" clipPath="url(#clip0)">
                <path
                    d="M12.0839 17.5H8.75062C7.60228 17.5 6.66728 16.565 6.66728 15.4166V13.75C6.66728 13.52 6.85395 13.3333 7.08395 13.3333C7.31395 13.3333 7.50062 13.52 7.50062 13.75V15.4166C7.50062 16.1058 8.06145 16.6666 8.75062 16.6666H12.0839C12.3139 16.6666 12.5006 16.8533 12.5006 17.0833C12.5006 17.3133 12.3139 17.5 12.0839 17.5Z"
                    fill="#3A3A3A"
                />
                <path
                    d="M8.74999 9.16669H0.416659C0.186659 9.16669 -7.62939e-06 8.98002 -7.62939e-06 8.75002C-7.62939e-06 8.52002 0.186659 8.33335 0.416659 8.33335H8.74999C8.97999 8.33335 9.16666 8.52002 9.16666 8.75002C9.16666 8.98002 8.97999 9.16669 8.74999 9.16669Z"
                    fill="#3A3A3A"
                />
                <path
                    d="M13.3339 20C12.4148 20 11.6673 19.2525 11.6673 18.3333V3.33333C11.6673 2.61666 12.1273 1.98249 12.8114 1.75416L17.8114 0.0874924C17.9698 0.0308257 18.1489 -7.62939e-06 18.3339 -7.62939e-06C19.2531 -7.62939e-06 20.0006 0.747492 20.0006 1.66666V16.6667C20.0006 17.3825 19.5414 18.0175 18.8573 18.245L13.8573 19.9117C13.6964 19.9692 13.5173 20 13.3339 20V20ZM18.3339 0.833326C18.2473 0.833326 18.1606 0.848326 18.0839 0.874992L13.0748 2.54499C12.7306 2.65916 12.5006 2.97583 12.5006 3.33333V18.3333C12.5006 18.7925 12.8748 19.1667 13.3339 19.1667C13.4206 19.1667 13.5073 19.1517 13.5856 19.1242L18.5939 17.455C18.9364 17.34 19.1673 17.0233 19.1673 16.6667V1.66666C19.1673 1.20749 18.7931 0.833326 18.3339 0.833326V0.833326Z"
                    fill="#3A3A3A"
                />
                <path
                    d="M7.08395 4.16666C6.85395 4.16666 6.66728 3.97999 6.66728 3.74999V2.08333C6.66728 0.934992 7.60228 -7.62939e-06 8.75062 -7.62939e-06H18.3339C18.5639 -7.62939e-06 18.7506 0.186659 18.7506 0.416659C18.7506 0.646659 18.5639 0.833326 18.3339 0.833326H8.75062C8.06145 0.833326 7.50062 1.39416 7.50062 2.08333V3.74999C7.50062 3.97999 7.31395 4.16666 7.08395 4.16666Z"
                    fill="#3A3A3A"
                />
                <path
                    d="M5.41717 12.5C5.31051 12.5 5.20384 12.4592 5.12217 12.3783C4.95967 12.2158 4.95967 11.9517 5.12217 11.7892L8.16133 8.75L5.12217 5.71167C4.95967 5.54917 4.95967 5.285 5.12217 5.1225C5.28467 4.96 5.54884 4.96 5.71134 5.1225L9.04467 8.45583C9.20716 8.61833 9.20716 8.8825 9.04467 9.045L5.71134 12.3783C5.63051 12.4592 5.52384 12.5 5.41717 12.5V12.5Z"
                    fill="#3A3A3A"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default LogoutIcon