import {put, call, takeEvery, takeLatest} from 'redux-saga/effects'

import {dealerActions} from "./slice";
import ErrorResponse from "../../../components/common/ErrorResponse";
import {fetchDealsGeneral, getDeals} from "../../../api/query/deal";
import {fetchList, fetchOrgDirectory} from "../../../api/query/organization";
import {getOrgFiles} from "../../../api/query/orgFile";

function* fetchDealerDeals({payload}) {
    try {
        const getData = yield call(getDeals, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(dealerActions.setDeals(getData.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(dealerActions.setDeals(null));
    }
}

function* fetchDealerDealsGeneral({payload}) {
    try {
        const getData = yield call(fetchDealsGeneral, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(dealerActions.setDealsGeneral(getData.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(dealerActions.setDealsGeneral(null));
    }
}

function* fetchBrandsFromDeals({payload}) {
    try {
        const getData = yield call(fetchOrgDirectory, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(dealerActions.setBrandsFromDeals(getData.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(dealerActions.setBrandsFromDeals([]));
    }
}

function* fetchListDealers({payload}) {
    try {
        const getData = yield call(fetchList, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(dealerActions.setListData(getData.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(dealerActions.setListData(null));
    }
}

function* fetchDealerFiles({payload}) {
    try {
        const getData = yield call(getOrgFiles, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(dealerActions.setFiles(getData.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(dealerActions.setFiles(null));
    }
}

// eslint-disable-next-line import/prefer-default-export
export function* dealerSaga() {
    yield takeEvery(dealerActions.fetchDeals.type, fetchDealerDeals);
    yield takeEvery(dealerActions.fetchDealsGeneral.type, fetchDealerDealsGeneral);
    yield takeEvery(dealerActions.fetchBrandsFromDeals.type, fetchBrandsFromDeals);
    yield takeEvery(dealerActions.fetchListData.type, fetchListDealers);
    yield takeLatest(dealerActions.getFiles.type, fetchDealerFiles);
}
