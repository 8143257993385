import { SendGet } from '../handler/sendGet';
import { sendPost } from '../handler/sendPost';

// eslint-disable-next-line import/prefer-default-export
export const getDocuments = (params) => {
    return SendGet(`documents`, params);
};
export const createDocument = (params) => {
    return sendPost(`documents/create`, params);
};
export const downloadDocument = (params) => {
    return SendGet(`documents/download`, params);
};
export const getDocumentProducts = (params) => {
    return SendGet(`document-products`, params);
};
