// eslint-disable-next-line import/no-cycle
import {
  MANAGERS_LIST,
  MANAGERS_PHOTO,
  MANAGERS_FETCH_PHOTO,
  MANAGERS_PHOTO_LOAD,
  MANAGERS_CHECK_PHOTO,
} from '../actions';
// import { SendGet } from '../../api/handler/sendGet';

// eslint-disable-next-line import/prefer-default-export
export const changeLoading = (status) => {
  return {
    type: MANAGERS_PHOTO_LOAD,
    payload: status,
  };
};
export const fetchPhoto = (managers) => {
  return {
    type: MANAGERS_FETCH_PHOTO,
    payload: { managers },
  };
};
export const checkPhotoManagers = (managers) => {
  return {
    type: MANAGERS_CHECK_PHOTO,
    payload: managers,
  };
};
export const setPhoto = (payload) => {
  return {
    type: MANAGERS_PHOTO,
    payload,
  };
};
// export const fetchPhoto2 = (managers = []) => {
//   if (!managers.length) return false;
//
//   return (dispatch) => {
//     dispatch(changeLoading(true));
//     SendGet('managers/photo', { users: managers })
//       .then((response) => {
//         console.log(response.data);
//         dispatch({ type: MANAGERS_PHOTO, payload: response.data });
//         dispatch(changeLoading(false));
//       })
//       .catch(() => {
//         dispatch(changeLoading(false));
//       });
//   };
// };
export const setManagerList = (array) => {
  return {
    type: MANAGERS_LIST,
    payload: array,
  };
};
