export const getStartWord = (curString, positionStart) => {
    let start = positionStart;
    if (curString[positionStart] === " ") {
        start -=1;
    }
    while (start > 0 && curString[start] !== " ")
        start -=1;
    return start;
};
export const getEndWord = (curString, positionStart) => {
    const strLength = curString.length;
    let end = positionStart;
    while (end < strLength && curString[end] !== " ")
        end +=1;
    return end;
};

export const getWord = (curString, positionStart) => {
    const start = getStartWord(curString, positionStart);
    const end = getEndWord(curString, positionStart);
    return curString.substring(start,end);
};
export const stripTags = (originalString) => {
    return originalString?.replace(/(<([^>]+)>)/gi, "") ?? '';
};
export const escapeHtmlDecode = (text) => {
    return text
        ?.replace(/&amp;/g, "&")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, "\"")
        .replace(/&nbsp;/g, " ")
        .replace(/&#039;/g, "'");
};