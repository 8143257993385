import {Scrollbars} from "react-custom-scrollbars";
import React, {useContext} from "react";
import {useSelector} from "react-redux";
import CommentsItem from "./CommentsItem/CommentsItem";
import css from "./Comments.module.scss";
import {DesktopContext} from "../../../utils/hoc/withContext";
import ChatToggle from "./ChatTypes/ChatToggle/ChatToggle";

const Messages = (props) => {
    const {comments, scrollBar, sendToUser, types: chatTypes, activeChat, setActiveChat, changeChat, setActiveStyle, activeStyle, executionQuery} = props;
    const currentUserId = Number(useSelector((state) => state.authUser.currentUser.id));

    const desktop = useContext(DesktopContext)

    return (
        <>
            <div className={`${css.chatList} ${css.chatListMobile}`}>
                {(!desktop && changeChat) && <ChatToggle
                    setActiveStyle={setActiveStyle}
                    chatTypes={chatTypes}
                    activeChat={activeChat}
                    activeStyle={activeStyle}
                    changeChat={changeChat}
                    setActiveChat={setActiveChat}
                />}
            </div>
            <Scrollbars
                // autoHeight
                // autoHeightMin={200}
                // autoHeightMax={200}
                ref={scrollBar}
                loader={executionQuery}
            >
                {comments.map((item) => {
                    return <CommentsItem
                        key={item.id}
                        {...item}
                        sendTo={sendToUser}
                        myMessage={currentUserId === item.user_id}
                    />;
                })}
            </Scrollbars>
        </>
    )
}
export default Messages;