/* eslint-disable no-unused-vars,no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import React from "react";

const initialState = {
    card: {
        logistics: {
            list: {
                loading: false,
                data: [],
                reload: false,
            },
            view: {
                data: null,
                comments: {
                    list: {
                        loading: false,
                        data: {},
                        reload: false,
                    }
                }
            },
            modal: {
                create: {
                    status: false,
                    data: null,
                },
                breakProduct: {
                    status: false,
                    data: null,
                }
            }
        }
    }
}

const slice = createSlice({
    name: "order",
    initialState,
    reducers: {
        setLogisticsData: (state, { payload }) => {
            state.card.logistics.list = {
                ...state.card.logistics.list,
                data: payload,
                loading: false,
            };
        },
        fetchLogisticsData: (state, { payload }) => {
            state.card.logistics.list = {
                ...state.card.logistics.list,
                loading: true,
                reload: false,
            };
        },
    }
})

export const orderReducer = slice.reducer;
export const orderActions = slice.actions;