import { all, call, put, takeLatest } from 'redux-saga/effects';
import {COMMENT_CHANGE, COMMENT_CREATE, COMMENT_VIEWING, COMMENTS_FETCH} from '../actions';
import {
    commentChange,
    commentCreate, commentViewing,
    getComments,
} from '../../api/query/comment';
import {commentChangeLoading, commentChangeSuccess, commentsSet} from './actions';
import ErrorResponse from "../../components/common/ErrorResponse";
import {appActions} from "../toolkit/app/slice";

function* loadComments({ payload }) {
    try {
        const getData = yield call(getComments, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(commentsSet(getData.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
    }
}

function* changeComment({ payload }) {
    try {
        yield call(commentChange, payload);
        yield put(commentChangeSuccess(true));
    } catch (error) {
        const errorResponse = ErrorResponse(error.response);
        yield put(appActions.setNotification({type: 'error', message: errorResponse}));
        yield put(commentChangeLoading(false));
    }
}

function* createComment({ payload }) {
    try {
        yield call(commentCreate, payload);
        yield put(commentChangeSuccess(true));
    } catch (error) {
        const errorResponse = ErrorResponse(error.response);
        yield put(appActions.setNotification({type: 'error', message: errorResponse}));
        yield put(commentChangeLoading(false));
    }
}

function* viewingComments({ payload }) {
    try {
        yield call(commentViewing, payload);
    } catch (error) {
        console.log(`viewingComments`);
    }
}

export default function* commentSaga() {
    yield all([
        takeLatest(COMMENT_CREATE, createComment),
        takeLatest(COMMENT_CHANGE, changeComment),
        takeLatest(COMMENTS_FETCH, loadComments),
        takeLatest(COMMENT_VIEWING, viewingComments),
    ]);
}
