import React from 'react'
import {Style6} from "../../../../constants/ReactSelect";
import DirectoryProductType from "../../../../components/directory/DirectoryProductType";

const ProductType = ({value, setValue}) => {
    return (
        <>
            <div className="font-weight-bold">Тип продукта:</div>
            <div className="mt-2">
                <DirectoryProductType
                    initState={value}
                    onChange={setValue}
                    style={Style6()}
                    isClearable={true}
                    defaultOptions
                />
            </div>
        </>
    )
}

export default ProductType