// eslint-disable-next-line no-unused-vars
/* eslint-disable no-use-before-define */
import {
    all,
    call,
    fork,
    put,
    takeEvery,
} from 'redux-saga/effects';

import {
  setLoadingOrderFeedback, setOrderFeedback,
} from './actions';

import {
    CRM_ORDER_FEEDBACK_FETCH_DATA,
} from '../../actions';
import {getOrders} from "../../../api/query/order";

export function* watchOrderFeedbacks() {
    yield takeEvery(CRM_ORDER_FEEDBACK_FETCH_DATA, loadOrderFeedback);
}
function* loadOrderFeedback({ payload }) {
    try {
        const getData = yield call(getOrders, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(setOrderFeedback(getData.data));
        }
    } catch (error) {
        yield put(setLoadingOrderFeedback(false));
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchOrderFeedbacks),
    ]);
}
