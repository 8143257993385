import { SendGet } from '../handler/sendGet';
import { sendPost } from '../handler/sendPost';
import { sendPut } from '../handler/sendPut';

// eslint-disable-next-line import/prefer-default-export
export const fetchList = (params) => {
  if (!params["per-page"]) params = {...params, "per-page": 10};
  return SendGet(`organizations`, params);
};
export const fetchOrganizationOne = ({ id, params }) => {
  return SendGet(`organizations/${id}`, params);
};
export const fetchContactsClient = (params) => {
  return SendGet(`organizations/contacts`, params);
};
export const createContact = (params) => {
  return sendPost(`organizations/create-contact`, params);
};
export const removeContact = (params) => {
  return sendPost(`organizations/remove-contact`, params);
};
export const clientUpdate = ({ id, params }) => {
  return sendPut(`organizations/${id}`, params);
};
export const changeContactClient1 = (params) => {
  return sendPost(`organizations/change-contact-client`, params);
};
export const requestContactCopyToClient = (params) => {
  return sendPost(`organizations/contact-copy-to`, params);
};
export const createClient = (params) => {
  return sendPost(`organizations/create`, params);
};
export const fetchOrgDirectory = (params) => {
  return SendGet(`organizations/directory`, params);
};
export const fetchFullInfoDD = (params) => {
  return SendGet(`organizations/full-info-dd`, params);
};
export const fetchOtherData = ({ id, params }) => {
  return SendGet(`organizations/other-data/${id}`, params);
};
export const fetchCategoryByOkved = (params) => {
  return SendGet(`organizations/category-by-okved`, params);
};
export const fetchIdByConditions = (params) => {
  return SendGet(`organizations/id-by-conditions`, params);
};
export const fetchNumberOfPurchases = (params) => {
  return SendGet(`organizations/number-of-purchases/${params.id}`, params);
};