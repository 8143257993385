import React from 'react';
import {Tab, Tabs} from '@mui/material';
import css from './TabsCustom.module.scss';

const Label = ({el}) => (
    <div className={css.tabElemWrapIndex}>
        {el.label}
        {el.child ?? <span className={css.tabElemIndex}>{el.count}</span>}
    </div>
);

const TabsCustom = ({tabs, value, variant, handleChange, show = true}) => {
    return (
        <Tabs
            value={value}
            className={css.tabContainer}
            variant={variant}
            onChange={(e, el) => handleChange(el)}
        >
            {tabs.map((el) =>
                el.type === 'switch' ? (
                    <div className={css.tabSwitch} key={el.value}>
                        <Label el={el}/>
                    </div>
                ) : (
                    <Tab
                        key={el.value}
                        className={`${css.tabElem} ${
                            value === el.value && css.tabElemActive
                        }`}
                        label={el.count || el.child ? show && <Label el={el}/> : el.label}
                        value={el.value}
                    />
                )
            )}
        </Tabs>
    );
};

export default TabsCustom;
