// eslint-disable-next-line import/prefer-default-export
import {commentCreate, getComments, getCountComments} from "../../../api/query/comment";

// eslint-disable-next-line import/prefer-default-export
export const addComment = async (data) => {
    let result = {
        type: '',
        result: null,
    }
    await commentCreate(data)
        .then(response => {
            result = {
                type: 'success',
                result: response,
            }
        }).catch(error => {
            const message = error?.response?.data?.message ?? 'Что-то пошло не так';
            result = {
                type: 'error',
                data: message,
            };
        });
    return result;
}
export const loadCountComments = async (data) => {
    let result = {
        type: '',
        data: null,
    }
    await getCountComments(data)
        .then(response => {
            result = {
                type: 'success',
                data: response.data,
            }
        }).catch(error => {
            const message = error?.response?.data?.message ?? 'Что-то пошло не так';
            result = {
                type: 'error',
                data: message,
            };
        });
    return result;
}

export const loadComments = async (data) => {
    let result = {
        type: '',
        data: null,
    }
    await getComments(data)
        .then(response => {
            result = {
                type: 'success',
                data: response.data,
            }
        }).catch(error => {
            const message = error?.response?.data?.message ?? 'Что-то пошло не так';
            result = {
                type: 'error',
                data: message,
            };
        });
    return result;
}

export const getNic = (user) => {
    const emailData = user.emails.find(x => Number(x.is_main) === 1) ?? null;
    if (!emailData) return '';
    return emailData.email.split('@')[0] ?? ''
}

export const getChatId = (chat) => {
    if (!chat) return
    const entityId = chat.entityId ?? null;
    // eslint-disable-next-line consistent-return
    return (`${chat.writeId}${entityId ? `_${entityId}` : ''}`).toString();
}