import React from 'react'
import css from './userRank.module.scss'
import RankGreenIcon from "../../assets/icons/rank-green-icon";
import RankExperiencedIcon from "../../assets/icons/rank-experienced-icon";
import RankDinosaurIcon from "../../assets/icons/rank-dinosaur-icon";

const UserRank = ({id}) => {
    const getIconRank = (id) => {
        let icon = null

        if (id == 1) icon = <RankGreenIcon/>
        else if (id == 2) icon = <RankExperiencedIcon/>
        else if (id == 3) icon = <RankDinosaurIcon/>

        return (icon)
    }

    const getNameRank = (id) => {
        let name = ''

        if (id == 1) name = 'Зеленый'
        else if (id == 2) name = 'Бывалый'
        else if (id == 3) name = 'Динозавр'

        return name
    }

    return (
        <div className={css.rank}>
            {getIconRank(id)}
            <p className={`${css.rankText} mb-0`}>{getNameRank(id)}</p>
        </div>
    )
}

export default UserRank