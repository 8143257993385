import React  from 'react';
import AsyncSelect from 'react-select/async/dist/react-select.esm';
import { Style2 } from '../../constants/ReactSelect';
import {fetchOrderDoubleStatus} from "../../api/query/directory";

const DirectoryOrderDoubleStatusAsync = ({
    initState = null,
    onChange,
    placeholder = 'Выберите',
    style = Style2(),
    isClearable = false,
    menuPortalTarget = false,
    statusId = 0,
}) => {
    const loadOptions = (inputValue, callback) => {
        fetchOrderDoubleStatus({ s: inputValue, status_id: statusId }).then(
            (response) => {
                callback(response.data);
            }
        );
    };

    return (
        <AsyncSelect
            menuPortalTarget={menuPortalTarget}
            cacheOptions
            defaultOptions
            value={initState}
            onChange={onChange}
            loadOptions={loadOptions}
            noOptionsMessage={() => 'ничего не найдено'}
            loadingMessage={() => 'поиск...'}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => Number.parseInt(option.id, 10)}
            styles={style}
            placeholder={placeholder}
            isClearable={isClearable}
        />
    );
};

export default DirectoryOrderDoubleStatusAsync;
