import {SendGet} from "../handler/sendGet";

export const fetchStatisticsTask = (params) => {
    return SendGet(`statistics/moderator-tasks`, params);
};

export const fetchStatisticsStorage = (params) => {
    return SendGet(`statistics/moderator-storages`, params);
};
export const fetchStatisticsStorageModerator = (params) => {
    return SendGet(`statistics/moderator-storages-expand`, params);
};

export const fetchStatisticsStorageTime = (params) => {
    return SendGet(`statistics/moderator-storage-time`, params);
};

export const fetchStatisticsStorageTimeModerator = (params) => {
    return SendGet(`statistics/moderator-storage-time-expand`, params);
};