import moment from "moment";

// eslint-disable-next-line import/prefer-default-export
export const getTimeRemaining = (endtime) => {
    const t = Date.parse(endtime) - moment().toDate();
    const seconds = Math.floor((t / 1000) % 60);
    const minutes = Math.floor((t / 1000 / 60) % 60);
    const hours = Math.floor((t / (1000 * 60 * 60)) % 24);
    const days = Math.floor(t / (1000 * 60 * 60 * 24));
    return {
        'total': t,
        'days': days,
        'hours': hours,
        'minutes': minutes,
        'seconds': seconds
    };
}

export const getFullDateTimeFormat = (date) => {
    return moment(date).format('DD.MM.YYYY  HH:mm')
}
export const getFullDateFormat = (date) => {
    return moment(date).format('DD.MM.YYYY')
}