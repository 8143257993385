import { MANAGERS_LIST, MANAGERS_PHOTO, MANAGERS_PHOTO_LOAD } from '../actions';

const INIT_STATE = {
  loading: false,
  photoList: [],
  managerList: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MANAGERS_PHOTO_LOAD:
      return { ...state, loading: action.payload };
    case MANAGERS_PHOTO:
      return { ...state, photoList: action.payload };
    case MANAGERS_LIST:
      return { ...state, managerList: action.payload };
    default:
      return { ...state };
  }
};
