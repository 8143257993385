export const taskPlaceholderDefault = "Описание задачи";

export const defaultClientIdForContent = 44714;// для Тип=Контент
export const dealerTypeID = 1; // Тип клиента = поставщик
export const taskTypeIDForDealer = 6; // Тип задачи для поставщика

export const taskStateDefault = {
    id: null,
    name: '',
    dateStart: new Date(),
    dateFinish: null,
    dateCreate: null,
    taskStatus: { id: 1, name: 'Новая' },
    taskDoubleStatus: null,
    priority: {id: 1, name: 'Не важный'},
    taskType: null,
    taskTypeTemplate: null,
    departmentId: null,
    currentTypeIdHasTemplate: false,
    badgeFire: false,
    manager: null,
    responsible: null,
    description: '',
    placeholder: taskPlaceholderDefault,
    client: null,
    order: null,
    comment: '',
    brand: null,
    parent_id: null,
    sort: 1,
    parent_entity: null,
    productType: null,
    priceSegment: null,
    analog: false,
    onlyProfile: false,
    deadline: null,
}

export const taskCommentParams = {
    type_id: 4,
    article_id: null,
    expand: 'userData, userData.avatarData, userData.state, files',
    sort: 'id',
}
export const defaultDisabledStatus = [5];

export const managerExpand = [
    'avatarData',
    'state',
];

export const organizationExpand = [
    'types',
    'legalAddress.city',
    'orderData',
    'segment_income'
];

export const organizationFields = [
    'legalAddress.city.id',
    'legalAddress.city.name',
    'legalAddress.city.timezone',
    'id',
    'name',
    'types.id',
    'types.name',
    'orderData.status_id',
];

export const taskParamsExpand = [
    'commentsCount',
    'commentsNewCount',
    'type',
    'type.departments',
    'type.typeClass',
    'type.templates',
    'template',
    'responsible',
    'responsibleGroup',
    'responsible.avatarData',
    'responsible.state',
    'manager',
    'managerGroup',
    'manager.avatarData',
    'manager.state',
    'status',
    'doubleStatus',
    'commentsMyNewCount',
    'order',
    'organization',
    'organization.types',
    'organization.legalAddress.city',
    'organization.orderData',
    'organization.segment_income',
    'brand',
    'allFields',
    'priorityModel',
    'process'
];
export const taskParamsFields = [
    '*',
    'order.id',
    'manager.id',
    'manager.last_name',
    'manager.first_name',
    'manager.avatarData.*',
    'manager.state.*',
    'responsible.id',
    'responsible.last_name',
    'responsible.first_name',
    'responsible.avatarData.*',
    'responsible.state.*',
    'organization.legalAddress.city.id',
    'organization.legalAddress.city.name',
    'organization.legalAddress.city.timezone',
    'organization.id',
    'organization.name',
    'organization.types.id',
    'organization.types.name',
    'organization.orderData.status_id',
    'brand.id',
    'brand.name',
    'process.id'
];

export const priceSegmentOptions = [
    {
        id: 1,
        name: 'Низкий'
    },
    {
        id: 2,
        name: 'Средний'
    },
    {
        id: 3,
        name: 'Высокий'
    }
]

export const otherParamsDefault = {
    numberOfPurchasesByOrg: null,
}