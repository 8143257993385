import {put, call, takeEvery} from 'redux-saga/effects'

import {PayloadAction} from "@reduxjs/toolkit";
import {storageActions} from "./slice";
import ErrorResponse from "../../../components/common/ErrorResponse";
import {fetchStorage, fetchStorageDirectory, fetchStorages, product, productsList} from "../../../api/query/storage";

function* fetchStoragesData({payload}) {
    try {
        const getData = yield call(fetchStorages, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(storageActions.setData(getData.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(storageActions.setData(null));
    }
}

function* fetchCountItems({payload}) {
    try {
        const getData = yield call(fetchStorageDirectory, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(storageActions.setCountItemsData(getData.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(storageActions.setCountItemsData([]));
    }
}

function* modalUpdateStorageData({payload}) {
    try {
        const getData = yield call(fetchStorage, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(storageActions.modalUpdate({
                loading: false,
                data: getData.data,
            }));
        }
    } catch (error) {
        yield put(storageActions.modalUpdate({
            loading: false,
            data: null,
            errors: error.response,
        }));
    }
}

function* fetchProductsList({payload}: PayloadAction<any>) {
    try {
        const data = yield call(productsList, payload);
        const codeResponse = Number(data.status);
        if (codeResponse === 200) {
            yield put(storageActions.setProductsList(data.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(storageActions.setProductsList(null));
    }
}

function* fetchProduct({payload}: PayloadAction<any>) {
    try {
        const data = yield call(product, payload);
        const codeResponse = Number(data.status);
        if (codeResponse === 200) {
            yield put(storageActions.setDataUpdateProduct(data.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(storageActions.setDataUpdateProduct(null));
    }
}

// eslint-disable-next-line import/prefer-default-export
export function* storageSaga() {
    yield takeEvery(storageActions.fetchData.type, fetchStoragesData);
    yield takeEvery(storageActions.fetchCountItemsData.type, fetchCountItems);
    yield takeEvery(storageActions.modalUpdateFetchData.type, modalUpdateStorageData);
    yield takeEvery(storageActions.fetchProductsList.type, fetchProductsList);
    yield takeEvery(storageActions.fetchDataUpdateProduct.type, fetchProduct);
}
