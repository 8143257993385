import { SendGet } from '../handler/sendGet';
import { sendPost } from '../handler/sendPost';

export const getScenarios = (params) => {
  return SendGet(`scenarios`, params);
};
export const getScenario = ({id, params}) => {
  return SendGet(`scenarios/${id}`, params);
};
export const updateScenario = (params) => {
  return sendPost(`scenarios/update`, params);
};
export const deleteScenario = (params) => {
  return sendPost(`scenarios/delete`, params);
};
export const getDirectory = (params) => {
  return SendGet(`scenarios/directory`, params);
};
export const fetchAdvancedInfo = (params) => {
  return SendGet(`scenarios/advanced-info-about-scenario`, params);
};