/* eslint-disable react/no-array-index-key */
import React, {useCallback, useState, useMemo, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IconButton, Badge, Tooltip} from "@mui/material";
import {Scrollbars} from "react-custom-scrollbars";
import {useHistory} from "react-router-dom";
import {
    notificationViewed,
} from '../../redux/notification/actions';
import NotificationItem from "./NotificationItem";
import css from './NotificationItem.module.scss'
import Popup from "../Popup/Popup";
import {notificationParams} from "./params";
import {getUrlTopic, isReading, loadCommentsForMe, loadList, sortFirstContactedMe, sortFirstNew} from "./helpers";
import {appActions} from "../../redux/toolkit/app/slice";
import {getHeadText} from "../common/Notification/helper";
import {siteUrl} from "../../constants/defaultValues";

const NotificationItemContainer = (props) => {
    const {icon, typeSlug} = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false)
    const maxViewedNotification = 10;
    const openPopup = useCallback(() => setOpenModal(!openModal), [openModal]);
    const [loadingList, setLoadingList] = useState(false);
    const [listItems, setListItems] = useState([]);
    const listNew = useMemo(() => listItems.filter(x => !Number(x.status)), [listItems]);
    const notificationEvent = useSelector(
        (state) => state.websocket.event.notification
    );
    const { currentUser } = useSelector((state) => state.authUser);
    const countNew = listNew.length;
    const [listForMe, setListForMe] = useState([]);

    const listForMeId = listForMe.map(x => x.id);

    const checkContactedMe = (notification) => {
        const entityId = notification.data?.id ?? null;
        if (!entityId) return false;
        return listForMeId.includes(entityId);
    }

    const onClickContactedMe = () => {
        const commentsId = listForMeId;
        setListItems((oldValue) => {
            return sortFirstContactedMe(oldValue, commentsId);
        });
        openPopup();
    }

    const onClickNew = () => {
        setListItems((oldValue) => {
            return sortFirstNew(oldValue);
        });
        openPopup();
    }

    const countMessageForMe = useMemo(() => {
        if (!listForMe.length) return 0;
        const data = {};
        listForMe.forEach(x => {
            const exist = listItems.some(y => y.data?.id === x.id && !y.status);
            if (!exist) return true;
            data[`${x.article_id}_${x.type_id}`] = x;
        });
        return Object.keys(data).length;
    }, [listItems, listForMe]);

    // eslint-disable-next-line consistent-return
    const onClickMouseMiddleButton = (id) => {
        const notification = listItems.find(x => x.id === id) ?? null;
        if (!notification) return false;

        const urlTopic = getUrlTopic(notification);
        if (urlTopic) {
            window.open(`${siteUrl}${urlTopic}`, '_blank');
        }
    };

    // eslint-disable-next-line consistent-return
    const onClickNotification = (id, onIndicator = false) => {
        const notification = listItems.find(x => x.id === id) ?? null;
        if (!notification) return false;

        if (!onIndicator) {
            const urlTopic = getUrlTopic(notification);
            if (urlTopic) {
                history.push(urlTopic);
            }
            setOpenModal(false);
        }
        let newStatus = 1;
        let notRead = 0;
        if (isReading(id, listItems) && onIndicator) {
            notRead = 1;
            newStatus = 0;
        }
        setListItems((currentItems) => {
            return currentItems.map(x => x.id === id ? {...x, status: newStatus} : x);
        })
        dispatch(notificationViewed({ids: [id], not_read: notRead}));
    };

    // eslint-disable-next-line consistent-return
    const onClickAllRead = (e) => {
        e.preventDefault();
        const getListNew = listItems.filter(x => !Number(x.status));
        if (!getListNew.length) return false;
        const ids = getListNew.map(x => x.id);

        dispatch(notificationViewed({ids}));
        setListItems((currentItems) => {
            return currentItems.map(x => ids.includes(x.id) ? {...x, status: 1} : x)
        })
        setOpenModal(false);
    };

    // eslint-disable-next-line consistent-return
    const sendToPopup = (messages = []) => {
        if (!messages.length) return false;
        messages.forEach(x => {
            const {message} = x.data;
            const type = typeSlug === 'crm_comments' ? 'stm' : 'notification';
            const link = getUrlTopic(x);
            dispatch(appActions.setNotification({
                type,
                message,
                headText: getHeadText(x),
                link,
            }));
        })
    }

    const getCommentsForMe = async (notificationList) => {
        const commentsId = notificationList.map(x => x.data?.id ?? null).filter(x => x);
        const params = {
            fields: ['id', 'type_id', 'article_id'].join(','),
            'selection[comment_id]': commentsId,
        };
        const {type: resultType, data: resultData} = await loadCommentsForMe(params);
        if (resultType === 'success') {
            const resultItems = resultData.data.items;
            setListForMe(resultItems);
            return true;
        }
        setListForMe([]);
        return true;
    }

    const getList = async (sendPopup = false, selectedId: number[] = []) => {
        if (!currentUser) return false;
        const params = {
            ...notificationParams,
            'selection[type_slug]': typeSlug,
        };
        setLoadingList(true);
        const {type: resultType, data: resultData} = await loadList(params);
        setLoadingList(false);
        if (resultType === 'success') {
            const resultItems = resultData.data.items;
            setListItems(resultItems);
            if (sendPopup) {
                sendToPopup(resultItems.filter((x) => selectedId.includes(x.id)));
            }
            if (typeSlug === 'crm_comments') {
                getCommentsForMe(resultItems);
            }
            return true;
        }
        setListItems([]);
        dispatch(appActions.setNotification({type: 'error', message: resultData}));
        return true;
    }

    useEffect(() => {
        if (Array.isArray(notificationEvent) && notificationEvent.length) {
            getList(true, notificationEvent);
        }
    }, [notificationEvent]);


    useEffect(() => {
        getList();
    }, [currentUser]);

    if (loadingList) {
        return <></>;
    }

    return (
        <div className={css.notificationContainer}>
            <IconButton onClick={openPopup} className={css.notificationButton}>
                {icon}
            </IconButton>
            <Tooltip title='Непрочитанные'>
                <div className={`${css.wrapBadge} ${css.wrapBadgeTop}`}>
                    <Badge
                        className={`${css.notificationCount} ${css.general} cursor-pointer`}
                        color="primary"
                        badgeContent={countNew}
                        onClick={onClickNew}
                    />
                </div>
            </Tooltip>
            {countMessageForMe > 0 && (
                <Tooltip title='Меня позвали'>
                    <div className={css.wrapBadge}>
                        <Badge
                            className={`${css.notificationCount} ${css.forMe} cursor-pointer`}
                            color="info"
                            badgeContent={countMessageForMe}
                            onClick={onClickContactedMe}
                        />
                    </div>
                </Tooltip>
            )}
            <Popup
                open={openModal}
                onChange={openPopup}
                classes={[css.notificationModal]}
            >
                <div className={css.messages}>
                    <Scrollbars
                        autoHeight={false}
                        hideTracksWhenNotNeeded
                    >
                        {
                            listItems.slice(0, maxViewedNotification + countNew).map((notification, index) => {
                                return (
                                    <NotificationItem
                                        key={index}
                                        handleClick={onClickNotification}
                                        mouseMiddleButton={onClickMouseMiddleButton}
                                        notification={notification}
                                        isLast={listItems.length === index+1}
                                        contactedMe={checkContactedMe(notification)}
                                    />
                                );
                            })
                        }
                    </Scrollbars>
                </div>
                {!!countNew && (
                    <a onClick={onClickAllRead} className={css.allRead} href="#1">Отметить все прочитанными</a>
                )}
            </Popup>
        </div>
    );
};

export default NotificationItemContainer;