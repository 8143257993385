import {sendPost} from "../handler/sendPost";

// eslint-disable-next-line import/prefer-default-export
export const setMeData = (params) => {
    return sendPost(`user/me-change`, params);
};
export const requestCheckExist = (params) => {
    return sendPost(`user/check-exist`, params);
};
export const sendWelcomeMail = (params) => {
    return sendPost(`user/send-welcome-mail`, params);
};

export const subscribe = (params) => {
    return sendPost(`user/subscribe`, params);
}
