import React, {useContext, useEffect} from 'react';;
import {ModalCreateTaskContext} from "../index";
import {useSelector} from "react-redux";

export const validate = (data) => {
    const errors = {};
    return errors;
}

const Template53 = () => {
    const {data, errors, onChangeList} = useContext(ModalCreateTaskContext);
    const {onChangeManager} = onChangeList;
    const { currentUser } = useSelector((state) => state.authUser);

    const setExecutor = () => {
        if (data.id) return false;
        onChangeManager(currentUser);
    }

    useEffect(() => {
        const oldValueManager = data.manager;
        setExecutor();
        return () => {
            onChangeManager(oldValueManager);
        }
    }, []);

    return (
        <></>
    );
};
export default Template53;
