import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {taskSelectors} from "../../../redux/toolkit/task/selectors";
import {taskActions} from "../../../redux/toolkit/task/slice";
import ModalCreateTask from "../../../containers/modal/ModalCreateTask";
import NextTask from "../../../containers/crm/order/card/tasks/NextTask";
import CancelOrder from "../../../containers/crm/order/card/CancelOrder";
import ContainerModalShipperScore from "../../../containers/crm/deal/card/ContainerModalShipperScore";

const ModalUpdateTask = () => {
    const dispatch = useDispatch();
    const {status} = useSelector(taskSelectors.modalCreate);

    const changeShow = (value) => {
        dispatch(taskActions.setStatusModalCreate(value));
    };

    return (
        <>
            {status && (
                <ModalCreateTask
                    isShow={status}
                    changeShow={changeShow}
                />
            )}
            <NextTask />
            <CancelOrder />
            <ContainerModalShipperScore />
        </>
    )
}
export default React.memo(ModalUpdateTask);