import { useSelector } from 'react-redux';

const CheckAccess = (type = 2, name) => {
    const { permissions, roles } = useSelector(
        (state) => state.authUser.currentUser
    );
    let access = false;
    switch (type) {
        case 1:
            access = roles.includes(name);
            break;
        case 2:
            access = permissions.includes(name);
            break;
        default:
            access = false;
    }
    return access;
};
export default CheckAccess;
