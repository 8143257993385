import React from 'react';
import AsyncSelect from 'react-select/async/dist/react-select.esm';
import {components} from "react-select";
import { fetchProductType } from '../../api/query/directory';
import { Style } from '../../constants/ReactSelect';

export const formatOptionLabel = (data) => {
    const iconUrl = data && data.media ? data.media.path : '';

    return (
        <div className="d-flex align-items-center">
            {iconUrl ? (
                <img src={iconUrl} width={20} alt={data.name}  className="mr-2"/>
            ) : ''}
            {data.name}
        </div>
    );
};
export const Option = (props) => {
    const { data } = props;
    const iconUrl = data && data.media ? data.media.path : '';
    return (
        <components.Option {...props}>
            <div className="d-flex align-items-center">
                {iconUrl ? (
                    <img src={iconUrl} width={20} alt={data.name}  className="mr-2"/>
                ) : ''}
                {data.name}
            </div>
        </components.Option>
    );
};

// eslint-disable-next-line no-unused-vars
const DirectoryProductType = ({
    initState = null,
    onChange,
    style = Style(),
    menuPortalTarget = false,
    placeholder = 'Выберите тип',
    expand = '',
    menuPlacement = "auto",
    componentsList = null,
    formatImageLabel = false,
    isDisabled = false,
    isClearable = false,
}) => {
    const loadOptions = (inputValue, callback) => {
        fetchProductType({ s: inputValue, expand }).then((response) => {
            callback(response.data);
        });
    };

    return (
        <AsyncSelect
            menuPortalTarget={menuPortalTarget}
            menuPlacement={menuPlacement}
            cacheOptions
            defaultOptions
            value={initState}
            onChange={onChange}
            loadOptions={loadOptions}
            noOptionsMessage={() => 'ничего не найдено'}
            loadingMessage={() => 'поиск...'}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => Number.parseInt(option.id, 10)}
            formatOptionLabel={formatImageLabel ? (data) => formatOptionLabel(data) : ''}
            styles={style}
            placeholder={placeholder}
            components={componentsList}
            isDisabled = {isDisabled}
            isClearable={isClearable}
        />
    );
};

export default DirectoryProductType;
