// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ResizableBox_resizable__LPLFa {
  height: 200px;
  position: relative; }
  @media (max-width: 959px) {
    .ResizableBox_resizable__LPLFa {
      height: 400px; } }

.ResizableBox_draggable__UlzaU {
  width: 100%;
  height: 10px;
  position: absolute;
  bottom: -5px;
  cursor: row-resize;
  z-index: 10;
  -webkit-user-select: none;
          user-select: none;
  touch-action: none; }
  @media (max-width: 959px) {
    .ResizableBox_draggable__UlzaU {
      height: 30px;
      bottom: -10px; }
      .ResizableBox_draggable__UlzaU:not(.ResizableBox_keep__Sf13A)::before {
        content: '';
        position: absolute;
        width: 30px;
        height: 4px;
        border-radius: 2px;
        left: 50%;
        top: 50%;
        background-color: #ccc;
        border: 1px solid #fff;
        transform: translate(-50%, -50%); } }

.ResizableBox_draggable__UlzaU.ResizableBox_keep__Sf13A {
  height: 500px !important;
  bottom: -200px; }
`, "",{"version":3,"sources":["webpack://./src/components/crm/comments/ResizableBox.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,kBAAkB,EAAA;EAElB;IAJF;MAKI,aAAa,EAAA,EAEhB;;AAED;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,yBAAiB;UAAjB,iBAAiB;EACjB,kBAAkB,EAAA;EAElB;IAVF;MAWI,YAAY;MACZ,aAAa,EAAA;MAZjB;QAeM,WAAW;QACX,kBAAkB;QAClB,WAAW;QACX,WAAW;QACX,kBAAkB;QAClB,SAAS;QACT,QAAQ;QACR,sBAAsB;QACtB,sBAAsB;QACtB,gCAAgC,EAAA,EACjC;;AAKL;EACE,wBAAwB;EACxB,cAAc,EAAA","sourcesContent":["@import '../../../assets/css/sass/_02_variables.scss';\n\n.resizable {\n  height: 200px;\n  position: relative;\n\n  @media ($tabletsMax) {\n    height: 400px;\n  }\n}\n\n.draggable {\n  width: 100%;\n  height: 10px;\n  position: absolute;\n  bottom: -5px;\n  cursor: row-resize;\n  z-index: 10;\n  user-select: none;\n  touch-action: none;\n\n  @media ($tabletsMax) {\n    height: 30px;\n    bottom: -10px;\n\n    &:not(.keep)::before {\n      content: '';\n      position: absolute;\n      width: 30px;\n      height: 4px;\n      border-radius: 2px;\n      left: 50%;\n      top: 50%;\n      background-color: #ccc;\n      border: 1px solid #fff;\n      transform: translate(-50%, -50%)\n    }\n  }\n\n}\n\n.draggable.keep {\n  height: 500px !important;\n  bottom: -200px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resizable": `ResizableBox_resizable__LPLFa`,
	"draggable": `ResizableBox_draggable__UlzaU`,
	"keep": `ResizableBox_keep__Sf13A`
};
export default ___CSS_LOADER_EXPORT___;
