import React from "react";
import {getParentEntity} from "../../../../components/crm/task/helpers";
import {useHistory} from "react-router-dom";

const ParentLink = ({data, onClose}) => {
    const history = useHistory();
    const entity = getParentEntity(data);

    if (!entity) return null;

    const onClickLink = (event) => {
        event.stopPropagation();
        event.preventDefault();

        onClose();
        setTimeout(() => {
            history.push(entity.link);
            window.scrollTo( 0, 0 );
        }, 500);
    }

    return (
        <div className="col-6 col-sm-auto">
            <>
                <span className="font-weight-semibold">{entity.name}: </span>
                <a
                    rel="noopener noreferrer"
                    className="nav-link-style-1"
                    href={entity.link}
                    onClick={onClickLink}
                >
                    {entity.id}
                </a>
            </>
        </div>
    )
}
export default ParentLink;