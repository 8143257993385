import {put, call, takeEvery} from 'redux-saga/effects'
import {audienceActions} from "./slice";
import {PayloadAction} from "@reduxjs/toolkit";
import {appActions} from "../app/slice";
import ErrorResponse from "../../../components/common/ErrorResponse";
import {getAudience, getAudiences, getOrganizationsList} from "../../../api/query/organizationAudience";

function* fetchViewData({payload}: PayloadAction<any>) {
    try {
        const data = yield call(getAudience, payload);
        const codeResponse = Number(data.status);
        if (codeResponse === 200) {
            yield put(audienceActions.setView(data.data));
        }
    } catch (error) {
        yield put(audienceActions.setView(null));
        yield put(appActions.setNotification({type: 'error', message: ErrorResponse(error.response)}));
    }
}

function* fetchListData({payload}: PayloadAction<any>) {
    try {
        const data = yield call(getAudiences, payload);
        const codeResponse = Number(data.status);
        if (codeResponse === 200) {
            yield put(audienceActions.setList(data.data));
        }
    } catch (error) {
        yield put(audienceActions.setList(null));
        yield put(appActions.setNotification({type: 'error', message: ErrorResponse(error.response)}));
    }
}

function* fetchListOrganizations({payload}: PayloadAction<any>) {
    try {
        const data = yield call(getOrganizationsList, payload);
        const codeResponse = Number(data.status);
        if (codeResponse === 200) {
            yield put(audienceActions.setListOrganizations(data.data));
        }
    } catch (error) {
        yield put(audienceActions.setListOrganizations(null));
        yield put(appActions.setNotification({type: 'error', message: ErrorResponse(error.response)}));
    }
}

// eslint-disable-next-line import/prefer-default-export
export function* audienceSaga() {
    yield takeEvery(audienceActions.getList.type, fetchListData);
    yield takeEvery(audienceActions.getView.type, fetchViewData);
    yield takeEvery(audienceActions.getListOrganizations.type, fetchListOrganizations);
}
