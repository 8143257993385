import React from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import {useMediaQuery} from "@mui/material";
// eslint-disable-next-line import/no-extraneous-dependencies
import {ThemeProvider} from "@mui/material/styles";
import theme from "../../theme";

export const DesktopContext = React.createContext(true);

const WithContext = ({Component, ...props}) => {
    const desktop = useMediaQuery(theme.breakpoints.up("md"));
    return (
        <ThemeProvider theme={theme}>
            <DesktopContext.Provider value={desktop}>
                <Component {...props}/>
            </DesktopContext.Provider>
        </ThemeProvider>
    );
}
export default WithContext;