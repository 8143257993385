import {put, call, takeEvery} from 'redux-saga/effects'
import {targetActions} from "./slice";
import {PayloadAction} from "@reduxjs/toolkit";
import ErrorResponse from "../../../components/common/ErrorResponse";
import {getTarget, getTargetLogs, getTargets, getTargetsMoreData, getTargetTasks} from "../../../api/query/target";
import {appActions} from "../app/slice";

function* fetchViewData({payload}: PayloadAction<any>) {
    try {
        const data = yield call(getTarget, payload);
        const codeResponse = Number(data.status);
        if (codeResponse === 200) {
            yield put(targetActions.setView(data.data));
        }
    } catch (error) {
        yield put(targetActions.setView(null));
        yield put(appActions.setNotification({type: 'error', message: ErrorResponse(error.response)}));
    }
}
function* fetchViewTasks({payload}: PayloadAction<any>) {
    try {
        const data = yield call(getTargetTasks, payload);
        const codeResponse = Number(data.status);
        if (codeResponse === 200) {
            yield put(targetActions.setTasks(data.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(targetActions.setTasks(null));
    }
}
function* fetchListData({payload}: PayloadAction<any>) {
    try {
        const data = yield call(getTargets, payload);
        const codeResponse = Number(data.status);
        if (codeResponse === 200) {
            yield put(targetActions.setList(data.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(targetActions.setList(null));
    }
}
function* fetchListMoreData({payload}: PayloadAction<any>) {
    try {
        const data = yield call(getTargetsMoreData, payload);
        const codeResponse = Number(data.status);
        if (codeResponse === 200) {
            yield put(targetActions.setListMoreData(data.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(targetActions.setListMoreData(null));
    }
}
function* fetchViewLogs({payload}: PayloadAction<any>) {
    try {
        const data = yield call(getTargetLogs, payload);
        const codeResponse = Number(data.status);
        if (codeResponse === 200) {
            yield put(targetActions.setLogs(data.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(targetActions.setLogs(null));
    }
}

// eslint-disable-next-line import/prefer-default-export
export function* targetSaga() {
    yield takeEvery(targetActions.getView.type, fetchViewData);
    yield takeEvery(targetActions.getTasks.type, fetchViewTasks);
    yield takeEvery(targetActions.getLogs.type, fetchViewLogs);
    yield takeEvery(targetActions.getList.type, fetchListData);
    yield takeEvery(targetActions.getListMoreData.type, fetchListMoreData);
}
