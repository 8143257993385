// eslint-disable-next-line no-unused-vars
/* eslint-disable no-use-before-define */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
    clientCardOrdersLoading,
    clientCardOrdersSet,
    clientChangeLoading,
    clientChangeSuccess, removeClientContactSuccess,
    setCardChangeLoading,
    setIndexChangeLoading,
    setListData,
    setOneData,
    setOneErrors,
} from './actions';

import {
    CRM_CLIENT_CHANGE,
    CRM_CLIENT_FETCH_DATA, CRM_CLIENT_ORDERS_FETCH, CRM_CLIENT_REMOVE_CONTACT,
    CRM_CLIENTS_FETCH_DATA,
} from '../../actions';
import {
    clientUpdate,
    fetchList,
    fetchOrganizationOne, removeContact,
} from '../../../api/query/organization';
import { getOrders } from '../../../api/query/order';
import {appActions} from "../../toolkit/app/slice";
import ErrorResponse from "../../../components/common/ErrorResponse";

// Запрос данных по клиентам
export function* watchClients() {
    yield takeEvery(CRM_CLIENTS_FETCH_DATA, loadClients);
}
function* loadClients({ payload }) {
    try {
        yield put(setIndexChangeLoading(true));
        const getData = yield call(fetchList, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(setListData(getData.data));
        }
        yield put(setIndexChangeLoading(false));
    } catch (error) {
        yield put(setIndexChangeLoading(false));
    }
}

// Запрос данных по клиенту
export function* watchOneClient() {
    yield takeEvery(CRM_CLIENT_FETCH_DATA, loadOneClient);
}
function* loadOneClient({ payload }) {
    try {
        yield put(setCardChangeLoading(true));
        const getData = yield call(fetchOrganizationOne, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(setOneData(getData.data));
        }
        yield put(setCardChangeLoading(false));
    } catch (error) {
        yield put(setOneErrors(error.response.data));
        yield put(setCardChangeLoading(false));
    }
}

// Изменение ответственного
export function* watchChange() {
    yield takeEvery(CRM_CLIENT_CHANGE, changeClient);
}
function* changeClient({ payload }) {
    try {
        yield put(clientChangeLoading(true));
        const getData = yield call(clientUpdate, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(appActions.setNotification({type: 'success', message: 'Данные обновлены'}))
            yield put(clientChangeSuccess(Date.now()));
        }
    } catch (error) {
        const errorResponse = ErrorResponse(error.response);
        yield put(appActions.setNotification({type: 'error', message: errorResponse}));
        yield put(clientChangeLoading(false));
    }
}
// Запрос данных по заказам
export function* watchOrders() {
    yield takeEvery(CRM_CLIENT_ORDERS_FETCH, loadOrders);
}
function* loadOrders({ payload }) {
    try {
        yield put(clientCardOrdersLoading(true));
        const getData = yield call(getOrders, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(clientCardOrdersSet(getData.data));
        }
        yield put(clientCardOrdersLoading(false));
    } catch (error) {
        yield put(clientCardOrdersLoading(false));
    }
}

export function* watchRemoveContact() {
    yield takeEvery(CRM_CLIENT_REMOVE_CONTACT, handlerRemoveContact);
}
function* handlerRemoveContact({ payload }) {
    try {
        const getData = yield call(removeContact, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(removeClientContactSuccess(Date.now()));
        }
    } catch (error) {
        const errorResponse = ErrorResponse(error.response);
        yield put(appActions.setNotification({type: 'error', message: errorResponse}));
    }
}
export default function* rootSaga() {
    yield all([
        fork(watchClients),
        fork(watchOneClient),
        fork(watchChange),
        fork(watchOrders),
        fork(watchRemoveContact),
    ]);
}
