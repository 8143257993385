// import {SweetAlertCustomClass} from "sweetalert2";
//
// const SwalStyle: SweetAlertCustomClass = {
//     container: 'ModalElem-style',
//     popup: 'ModalElem-body p-3',
//     title: 'px-0',
//     closeButton: 'close text-primary',
//     icon: '',
//     image: '',
//     input: '',
//     inputLabel: '',
//     validationMessage: '',
//     actions: 'justify-content-end',
//     confirmButton: 'btn btn-primary order-1',
//     denyButton: 'btn btn-outline-primary',
//     cancelButton: 'btn btn-outline-primary',
//     loader: '',
//     footer: ''
// };
//
// export default SwalStyle;