const UniqID = (inPrefix, moreEntropy) => {
    let uniqIDSeed = '';
    let prefix = inPrefix;
    if (typeof inPrefix === 'undefined') {
        prefix = '';
    }

    let retId;
    const formatSeed = function (inSeed, reqWidth) {
        let seed = inSeed;
        seed = parseInt(seed, 10).toString(16);
        if (reqWidth < seed.length) {
            return seed.slice(seed.length - reqWidth);
        }
        if (reqWidth > seed.length) {
            return Array(1 + (reqWidth - seed.length)).join('0') + seed;
        }
        return seed;
    };

    if (!uniqIDSeed) {
        uniqIDSeed = Math.floor(Math.random() * 0x75bcd15);
    }
    // eslint-disable-next-line no-plusplus
    uniqIDSeed++;

    retId = prefix;
    retId += formatSeed(parseInt(new Date().getTime() / 1000, 10), 8);
    retId += formatSeed(uniqIDSeed, 5);
    if (moreEntropy) {
        retId += (Math.random() * 10).toFixed(8).toString();
    }

    return retId;
};
export default UniqID;
