import ErrorResponse from "../../../components/common/ErrorResponse";
import {updateOrder} from "../../../api/query/order";

// eslint-disable-next-line import/prefer-default-export
export const update = async (prepareData) => {
    let result = [];
    await updateOrder(prepareData)
        .then((response) => {
            result = response;
        })
        .catch((error) => {
            ErrorResponse(error.response);
        });
    return result;
}