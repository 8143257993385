/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */

import {MODAL_CONFIRM_DATA, MODAL_CONFIRM_STATUS, MODAL_SELECT_DATE_DATA, MODAL_SELECT_DATE_STATUS} from "../actions";

export const modalSelectDateSetStatus = (payload) => {
  return {
    type: MODAL_SELECT_DATE_STATUS,
    payload,
  };
};
export const modalSelectDateSetData = (payload) => {
  return {
    type: MODAL_SELECT_DATE_DATA,
    payload,
  };
};
export const modalConfirmSetStatus = (payload) => {
  return {
    type: MODAL_CONFIRM_STATUS,
    payload,
  };
};
export const modalConfirmSetData = (payload) => {
  return {
    type: MODAL_CONFIRM_DATA,
    payload,
  };
};