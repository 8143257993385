import {Tooltip, TooltipProps} from "@mui/material";
import {styled} from "@mui/styles";
import React from "react";
import {adminRoot} from "../../../constants/defaultValues";

const ToBeStyledTooltip = ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ tooltip: className }} />
);

export const StyledTooltip = styled(ToBeStyledTooltip)(({ theme }) => ({
    backgroundColor: '#FFFFFF',
    color: '#3A3A3A',
    //maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    padding: '10px 8px 8px 8px',
    boxShadow: '0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04)',
}));

export const getParentEntity = (data) => {
    let entity = null;
    if (data.order) {
        const id = data.order.id ?? data.order;
        entity = {
            type_id: 3,
            id: id,
            link: `${adminRoot}/crm/orders/${id}`,
            name: 'Заказ',
        }
    }
    if (data.target) {
        entity = {
            type_id: 11,
            id: data.target.id,
            link: `${adminRoot}/crm/targets/${data.target.id}`,
            name: 'Цель',
        }
    }
    if (data.process) {
        entity = {
            type_id: 12,
            id: data.process.id,
            link: `${adminRoot}/crm/process/${data.process.id}`,
            name: 'Процесс',
        }
    }
    return entity;
}