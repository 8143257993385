import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reduxWebsocket from '@giantmachines/redux-websocket';
import {createBrowserHistory} from "history";
import reducers from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();
const reduxWebsocketMiddleware = reduxWebsocket({
  reconnectOnClose: true,
  reconnectOnError: true,
  reconnectInterval: 20000,
});

const middlewares = [sagaMiddleware, reduxWebsocketMiddleware];
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhancers = compose;

const configureStore = (initialState = {}) => {
  const store = createStore(
      reducers,
      initialState,
      composeEnhancers(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(sagas);

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      // eslint-disable-next-line global-require
      const nextRootReducer = require('./reducers');
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};

export const storeData = configureStore();
export const history = createBrowserHistory();
