import {isJson} from "../validate/JSON";

const ErrorResponse = (response) => {
    const {status} = response;
    const responseData = response?.data ?? null;
    let messages = [];
    switch (status) {
        case 403:
            messages.push('Вам не разрешено выполнять это действие');
            break;
        case 500:
            messages.push('Внутренняя ошибка сервера. Что-то пошло не так');
            break;
        default:
            if (isJson(responseData)) {
                messages.push(JSON.parse(responseData)?.message);
            } else {
                messages = responseData.message;
            }
    }
    if (!messages.length) {
        messages.push('Неизвестная ошибка при выполнении операции');
    }
    return Array.isArray(messages) ? messages.join('; ') : messages;
};
export default ErrorResponse;