import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    users: [],
    isFetching: false,
}

const slice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        addUser: (state, {payload}) => {
            state.users = [...state.users, payload]
            state.isFetching = false
        },
        getUsers: state => {
            state.isFetching = true
        },
        skip: state => {
            state.isFetching = false
        },
    },
})

export const usersReducer = slice.reducer
export const usersActions = slice.actions
