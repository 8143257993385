import React from "react";
import {useDispatch} from "react-redux";
import Comments from "../../../../components/crm/comments/Comments";
import {setCountNewComments} from "../../../../redux/crm/task/actions";

const CommentsContainer = ({id}) => {
    const dispatch = useDispatch();
    const onCallbackReloadComments = () => {
        dispatch(setCountNewComments({id, count: 0}));
    };

    return (
        <div className="mt-0">
            <Comments
                chatTypes={[
                    {
                        readId: [4],
                        writeId: 4,
                        view: 1,
                    }
                ]}
                articleId={id}
                callbackReload={onCallbackReloadComments}
            />
        </div>
    )
}
export default CommentsContainer;