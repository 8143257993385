// eslint-disable-next-line no-unused-vars
/* eslint-disable no-use-before-define */
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {CHANGE_MODE_WATCH_FETCH, changeModeWatch} from "../actions";
import {getWatch} from "../../api/query/crm";
import ErrorResponse from "../../components/common/ErrorResponse";

export function* watchModeWatch() {
    yield takeLatest(CHANGE_MODE_WATCH_FETCH, modeWatchHandler);
}
function* modeWatchHandler({ payload }) {
    try {
        const getData = yield call(getWatch, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(changeModeWatch(getData.data));
        }
    } catch (error) {
        ErrorResponse(error.responsible);
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchModeWatch),
    ]);
}
