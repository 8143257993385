import React, {useContext} from "react";
import {IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Time from "./fields/Time";
import DateCreate from "./fields/DateCreate";
import DateFinish from "./fields/DateFinish";
import {DesktopContext} from "../../../utils/hoc/withContext";
import ParentLink from "./header/ParentLink";

const styleAttribute = {
    fontSize: '12px',
    lineHeight: 1,
    flex: 1,
    position: 'relative',
    top: '1px'
}
const Header = ({data, onClose}) => {
    const desktop = useContext(DesktopContext);

    return (
        <div className="row">
            <div className="col-12 col-md-auto">
                <h5 className="m-0 p-0" style={{fontSize: '1.1rem'}}>
                    {data.id ? 'Редактирование задачи' : 'Создание задачи'}
                </h5>
            </div>
            <div className={`col-12 col-md d-flex align-items-center ${!desktop ? 'mt-2' : ''}`}>
                <div style={styleAttribute}>
                    <div className="row align-items-center">
                        <div className="col-6 col-sm-auto" hidden={!data.client?.legalAddress?.city?.timezone ?? null}>
                            <Time client={data.client} />
                        </div>
                        <ParentLink
                            data={data}
                            onClose={onClose}
                        />
                        <div className='col-6 col-sm-auto mt-lg-0 mt-2' hidden={!data.dateCreate}>
                            <DateCreate date={data.dateCreate}/>
                        </div>
                        <div className='col-6 col-sm-auto mt-lg-0 mt-2' hidden={!data.dateFinish}>
                            <DateFinish date={data.dateFinish}/>
                        </div>
                    </div>
                </div>
            </div>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    color: 'secondary',
                    position: 'absolute',
                    right: '20px',
                    top: '13px'
                }}
            >
                <CloseIcon />
            </IconButton>
        </div>
    )
}
export default Header;