import {createTheme} from "@mui/material/styles";

const theme = createTheme({
    typography: {
        "fontFamily": `"Nunito", "Helvetica", "Arial", sans-serif`,
        "fontSize": 14,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500
    },
    palette: {
        primary: {
            main: '#ED7117',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#3A3A3A',
            contrastText: '#FFFFFF'
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1200,
            xl: 1536,
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'initial',
                    whiteSpace: 'nowrap'
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'initial'
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paperAnchorBottom: {
                    borderTopLeftRadius: "1rem",
                    borderTopRightRadius: "1rem",
                    padding: "1rem"
                }
            }
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    height: "1px",
                    margin: "0 10px",
                    width: "calc(100% - 20px)",
                },
                thumb: {
                    width: "20px",
                    height: "20px",
                },
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    padding: "0.5rem",
                }
            }
        }
    }
});

export default theme;
