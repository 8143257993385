import {getDiscount} from "../../api/query/orderProduct";
import ErrorResponse from "../../components/common/ErrorResponse";
import {fetchCategories, getProducts} from "../../api/query/product";
import {deleteScenarioTask} from "../../api/query/scenarioTask";

// eslint-disable-next-line import/prefer-default-export
export const getDiscountForProducts = async (prepareData) => {
    let result = [];
    await getDiscount(prepareData)
        .then((response) => {
            result = response.data
        })
        .catch((error) => {
            ErrorResponse(error.response);
        });
    return result;
}

export const getSales = async (productsId, clientId, orderId = null) => {
    const ids = [ ...new Set(productsId)];
    if (!ids.length) return [];
    const prepareData = {
        id: ids,
        client_id: clientId,
        order_id: orderId,
    }
    const discounts = await getDiscountForProducts(prepareData);
    return discounts;
}

export const getProductsFromCatalog = async (prepareData) => {
    let result = [];

    await getProducts(prepareData)
        .then((response) => {
            result = response?.data?.items ?? [];
        })
        .catch((error) => {
            ErrorResponse(error.response);
        });

    return result;
}

export const getCategoriesQuery = async (prepareData) => {
    let result = {
        type: '',
        data: null,
    }
    await fetchCategories(prepareData)
        .then(response => {
            result = {
                type: 'success',
                data: response.data,
            }
        }).catch(error => {
            const message = error?.response?.data?.message ?? 'Что-то пошло не так';
            result = {
                type: 'error',
                data: message,
            };
        });
    return result;
}