import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from "react-redux";
// eslint-disable-next-line import/no-extraneous-dependencies
import {Tooltip, Chip} from "@mui/material";
import {useHistory} from "react-router-dom";
import {adminRoot} from "../../../constants/defaultValues";
import {encodeDataToUrl} from "../../../components/common/ModuleDataAndUrl";
import {getUsersByGroup} from "../../../api/query/crm";
import css from './nav.module.scss'
import SuperPageNav from "./SuperPageNav";

const PageNavOrders = (props) => {
    const {navItem} = props;
    const currentUser = useSelector((state) => state.authUser.currentUser);
    const {countNew: countNewOrder, countYouResponsible} = useSelector((state) => state.crmOrder);
    const linkOrders = `${adminRoot}/crm/orders`;
    const [usersByGroup, setUsersByGroup] = useState([]);
    const crmGroupsId = currentUser.crmGroups.filter(x => x.parent_id === 1).map(x => x.id);
    const history = useHistory()

    const fetchUsersByGroup = () => {
        (async () => {
            let responseRequest = [];
            await getUsersByGroup({id: crmGroupsId})
                // @ts-ignore
                .then((response) => {
                    responseRequest = response.data;
                }).catch(() => {
                    responseRequest = [];
                });
            setUsersByGroup(responseRequest);
        })();
    };

    const hrefToOrders = useMemo( () => {
        let managersID = [currentUser.id];
        if (usersByGroup.length) {
            managersID = [...managersID, ...usersByGroup.map(x => x.id)];
        }
        managersID = [...managersID, ...crmGroupsId.map(x => `G-${x}`)];
        managersID = [...new Set(managersID)];//удалим дубли
        const linkOrdersParams = {
            status_id: '1',
            manager_id: managersID.join('|'),
        };
        return `${linkOrders}?${encodeDataToUrl(linkOrdersParams)}`;
    }, [currentUser, usersByGroup]);

    const hrefToYouResponsible = useMemo(() => {
        const managersID = [currentUser.id];
        const linkOrdersParams = {
            status_id: '2|3|4|5|6',
            manager_id: managersID.join('|'),
        };
        return `${linkOrders}?${encodeDataToUrl(linkOrdersParams)}`;
    }, [currentUser]);

    useEffect(() => {
        fetchUsersByGroup();
    }, []);

    const goToHref = (e, href) => {
        e.preventDefault()
        return history.push(href)
    }

    return (
        <SuperPageNav href={navItem.to}>
            <div className={css.counterText}>
                <span className={css.headline}>{navItem.name}</span>
                <div className={css.counters}>
                    {countYouResponsible > 0 &&
                        <Tooltip title="Вы ответственный за заказ" placement="top">
                            <Chip
                                clickable={false}
                                onClick={(e) => goToHref(e, hrefToYouResponsible)}
                                className={`${css.counter  } ${  css.counterBlue}`}
                                label={countYouResponsible} />
                        </Tooltip>
                    }
                    {countNewOrder > 0 &&
                        <Tooltip title="Новый непринятый заказ" placement="top">
                            <Chip
                                clickable={false}
                                onClick={(e) => goToHref(e, hrefToOrders)}
                                className={`${css.counter  } ${  css.counterOrange}`}
                                label={countNewOrder} />
                        </Tooltip>
                    }
                </div>
            </div>
        </SuperPageNav>
    )
};
export default PageNavOrders;
