/* eslint-disable */
import React from 'react';
import AsyncSelect from 'react-select/async/dist/react-select.esm';
import { components } from 'react-select';
import { fetchManagers } from '../../api/query/directory';
import { Style } from '../../constants/ReactSelect';
import PhotoManagerNew from "../profile/photoManagerNew";
import {userGrouping} from "../../helpers/users/userGrouping";
import useClickPreventionOnDoubleClick from "../useClickPreventionOnDoubleClick";

export const managerGroup = (data, userFirst = [], groupDisabled = false, groupUsersFirst = []) => {
    const props = {
        data,
        userFirst,
        groupDisabled,
        groupUsersFirst
    }
    if (!data.length) return [];
    return userGrouping(props);
};
export const CustomGroupHeading = (props) => {
    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div className="group-heading-wrapper">
            <components.GroupHeading {...props} />
        </div>
    );
};
export const OptionV2 = (props) => {
    const { data, isSelected, isMulti } = props;
    const groupLabel = (option) => {

        const getOptions = () => {
            return props.options.filter(x => {
                const crmGroups = x.crmGroups ?? null;
                if (!crmGroups) return false;
                return x.crmGroups.some(xg => xg.id === option.id);
            });
        }
        const onDoubleClick = (e) => {
            if (!isMulti) return false;
            const options = getOptions();
            const currentValues = props.getValue();
            const selected = currentValues.some(x => x.id === option.id);
            let newValues = currentValues;
            if (selected) {
                const optionsId = options.map(x => x.id);
                newValues = newValues.filter(x => !optionsId.includes(x.id) && x.id !== option.id);
            } else {
                newValues = [...newValues, ...options, option];
            }
            props.setValue(newValues);
        }
        const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(() => null, onDoubleClick);

        return (
            <div onDoubleClick={handleDoubleClick} className={`group-element ${isMulti ? 'isMulti custom-checkbox custom-control custom-checkbox-filter-select' : ''}`}>
                {isMulti && (
                    <input
                        type="checkbox"
                        checked={isSelected}
                        className="custom-control-input"
                        onChange={() => null}
                    />
                )}
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                <label className={`${isMulti ? 'custom-control-label' : 'pb-0 mb-0'}`}>
                    <div
                        className={`d-flex align-items-center group-element-name ${
                            isSelected ? 'active' : ''
                        }`}
                    >
                        <span className="ml-2">{option.name}</span>
                    </div>
                </label>
            </div>
        );
    };
    const valueLabel = (option) => {
        return (
            <div
                className={`option-element ${isMulti ? 'isMulti custom-checkbox custom-control custom-checkbox-filter-select' : ''}`}
            >
                {isMulti && (
                    <input
                        type="checkbox"
                        checked={isSelected}
                        className="custom-control-input"
                        onChange={() => null}
                    />
                )}
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                <label className={`${isMulti ? 'custom-control-label' : 'pb-0 mb-0'}`}>
                    <div className="d-flex align-items-center">
                        <PhotoManagerNew
                            size="sm"
                            data={option}
                        />
                        <span className="ml-2">
                            {option.last_name} {option.first_name}
                        </span>
                    </div>
                </label>
            </div>
        );
    };

    const isGroup = typeof data.isGroup !== 'undefined' && data.isGroup;
    return (
        <components.Option {...props}>
            {isGroup ? groupLabel(data) : valueLabel(data)}
        </components.Option>
    );
};
export const formatOptionLabel = (data, initState, groups, labelSimple = false) => {
    const groupLabel = (option) => {
        const classes = ['d-flex', 'align-items-center'];
        if (!labelSimple) {
            classes.push('group-element');
            if (initState && initState.id === option.id) {
                classes.push('active');
            }
        }
        return (
            <div className={classes.join(' ')}>
                <span className={!labelSimple && 'ml-2'}>{option.name}</span>
            </div>
        );
    };
    const valueLabel = (option) => {
        const classes = ['d-flex', 'align-items-center'];
        if (!labelSimple) {
            classes.push('option-element');
            if (!groups) {
                classes.push('pl-2');
            }
        }
        return (
            <div className={classes.join(' ')}>
                {!labelSimple && (
                    <PhotoManagerNew size="sm" data={option} />
                )}
                <span className={!labelSimple && 'ml-2'}>
                  {option.last_name} {option.first_name}
                </span>
            </div>
        );
    };

    const isGroup = typeof data.isGroup !== 'undefined' && data.isGroup;
    return isGroup ? groupLabel(data) : valueLabel(data);
};

export const valueOption = (props) => {
    const { data } = props;
    const stringID = data.id.toString();
    const isGroup = stringID.indexOf('G-') > -1;
    return (
        <components.SingleValue {...props}>
            <div className="d-flex align-items-center">
                {!isGroup ? (
                    <PhotoManagerNew size="xs" data={data} />
                ) : (
                    <i className="icomoon icon-tree" style={{ color: '#ed7117' }} />
                )}
                <span className="ml-2">
            {typeof data.name !== 'undefined'
                ? data.name
                : `${data.last_name} ${data.first_name}`}
          </span>
            </div>
        </components.SingleValue>
    );
};

const DirectoryManager = ({
    initState = null,
    onChange,
    placeholder = 'Выберите менеджера',
    style = Style(),
    groups = false,
    isClearable = false,
    userFirst = [],
    groupUsersFirst = [],
    groupDisabled = false,
    isDisabled = false,
    includeGroups = [],
    isMulti = false,
    components = {},
    allowSelectAll = false,
    hideSelectedOptions = true,
    labelSimple = false
}) => {

    const loadOptions = (inputValue, callback) => {
        fetchManagers({ s: inputValue, group_id: includeGroups.join(','), expand: 'avatarData, crmGroups' }).then(
            (response) => {
                callback(groups ? managerGroup(response.data, userFirst, groupDisabled, groupUsersFirst) : response.data);
            }
        );
    };

    return (
        <AsyncSelect
            cacheOptions
            defaultOptions
            value={initState}
            onChange={onChange}
            loadOptions={loadOptions}
            noOptionsMessage={() => 'ничего не найдено'}
            loadingMessage={() => 'поиск...'}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            styles={style}
            placeholder={placeholder}
            formatOptionLabel={(data) => formatOptionLabel(data, initState, groups, labelSimple)}
            components={{
                Option: OptionV2,
                SingleValue: valueOption,
                GroupHeading: CustomGroupHeading,
                ...components,
            }}
            className={`custom-react-select ${groups ? 'isGroups' : ''}`}
            isClearable={isClearable}
            isOptionDisabled={(option) => option.disabled}
            isDisabled={isDisabled}
            isMulti={isMulti}
            closeMenuOnSelect={!isMulti}
            allowSelectAll={allowSelectAll}
            hideSelectedOptions={hideSelectedOptions}
        />
    );
};
export default DirectoryManager;
