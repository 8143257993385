/* eslint-disable no-unused-vars */
import { useContext } from 'react';
import {ConfirmContext} from "../components/context/ConfirmContextProvider";
import {modalConfirmSetData, modalConfirmSetStatus} from "../redux/modal/actions";

let resolveCallback;
function useConfirm() {
    const [confirmState, dispatch] = useContext(ConfirmContext);

    const closeConfirm = () => {
        dispatch(modalConfirmSetStatus(false));
    };

    const onConfirm = () => {
        closeConfirm();
        resolveCallback(true);
    };

    const onCancel = () => {
        closeConfirm();
        resolveCallback(false);
    };

    const confirm = (data) => {
        dispatch(modalConfirmSetData(data));
        dispatch(modalConfirmSetStatus(true));
        return new Promise((res, rej) => {
            resolveCallback = res;
        });
    };

    return { confirm, onConfirm, onCancel, confirmState };
}

export default useConfirm;