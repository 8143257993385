import { SendGet } from '../handler/sendGet';
import { sendPut } from '../handler/sendPut';
import { sendPost } from '../handler/sendPost';
import {sendDelete} from "../handler/sendDelete";

export const getProcesses = (params) => {
  return SendGet(`processes`, params);
};
export const getProcess = ({id, params}) => {
  return SendGet(`processes/${id}`, params);
};
export const getProcessTasks = (params) => {
  return SendGet(`process/tasks`, params);
};
export const getProcessFiles = (params) => {
  return SendGet(`process/files`, params);
};
export const createProcess = (params) => {
  return sendPost(`process/create`, params);
};
export const updateProcess = (params) => {
  return sendPost(`process/update`, params);
};
export const updateLead = (params) => {
  return sendPost(`process/update-lead`, params);
};
export const removeLeadFile = (params) => {
  return sendPost(`process/remove-lead-file`, params);
};
export const getProcessTableHandbook = (params) => {
  return sendPost(`process/table-handbook`, params);
};
export const removeContact = (params) => {
  return sendPost(`process/remove-contact`, params);
};
export const addContact = (params) => {
  return sendPost(`process/add-contact`, params);
};