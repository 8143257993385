import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Chip, Tooltip} from '@mui/material';
import {useHistory} from "react-router-dom";
import {adminRoot} from "../../../constants/defaultValues";
import {encodeDataToUrl} from "../../../components/common/ModuleDataAndUrl";
import css from './nav.module.scss'
import SuperPageNav from "./SuperPageNav";
import {send} from "@giantmachines/redux-websocket";

const PageNavTargets = (props) => {
    const {navItem} = props;
    const currentUser = useSelector((state) => state.authUser.currentUser);
    const { target } = useSelector((state) => state.websocket.event);
    const { connected } = useSelector((state) => state.websocket);
    const history = useHistory();
    const dispatch = useDispatch();
    const linkTargets = `${adminRoot}/crm/targets`;
    const [countData, setCountData] = useState({
        countTargetTaskManager: 0,
        countTargetTaskResponsible: 0,
        countTargets: 0,
    })

    const changeCountData = (name, value) => {
        setCountData((oldValue) => {
            return  {...oldValue, [name]: value};
        })
    }

    const hrefToMyTargets = useMemo(() => {
        const params = {
            status_id: '1|2',
            user_id: currentUser.id,
        };

        return `${linkTargets}?${encodeDataToUrl(params)}`;
    }, []);

    const hrefToMyTasksResponsible = useMemo(() => {
        const params = {
            task_status_id: '1|2',
            responsible_id: currentUser.id,
        };

        return `${linkTargets}?${encodeDataToUrl(params)}`;
    }, []);

    const hrefToMyTasksManager = useMemo(() => {
        const params = {
            task_status_id: '1|2',
            manager_id: currentUser.id,
        };

        return `${linkTargets}?${encodeDataToUrl(params)}`;
    }, []);

    const goToHref = (e, href) => {
        e.preventDefault()
        return history.push(href)
    }

    useEffect(() => {
        if (connected) {
            dispatch(send({type: 'getCountMyTarget'}));
            dispatch(send({type: 'getCountMyTargetTask'}));
        }
    }, [connected]);

    useEffect(() => {
        if (target !== null) {
            const action = target?.action;
            if (['countTargetTaskManager', 'countTargetTaskResponsible', 'countTargets'].includes(action)) {
                changeCountData(action, Number(target.data ?? 0));
            }
        }
    }, [target]);

    return (
        <SuperPageNav href={navItem.to}>
            <div className={css.counterText}>
                <span className={css.headline}>{navItem.name}</span>
                <div className={css.counters}>
                    {countData.countTargets > 0 &&
                        <Tooltip title="Вы создатель цели" placement="top">
                            <Chip
                                clickable={false}
                                onClick={(e) => goToHref(e, hrefToMyTargets)}
                                className={`${css.counter  } ${  css.counterOrange}`}
                                label={countData.countTargets}/>
                        </Tooltip>
                    }
                    {countData.countTargetTaskResponsible > 0 &&
                        <Tooltip title="Вы ответственный за цель" placement="top">
                            <Chip
                                clickable={false}
                                onClick={(e) => goToHref(e, hrefToMyTasksResponsible)}
                                className={`${css.counter  } ${  css.counterBlue}`}
                                label={countData.countTargetTaskResponsible}/>
                        </Tooltip>
                    }
                    {countData.countTargetTaskManager > 0 &&
                        <Tooltip title="Вы исполнитель цели" placement="top">
                            <Chip
                                clickable={false}
                                onClick={(e) => goToHref(e, hrefToMyTasksManager)}
                                className={`${css.counter  } ${  css.counterRed}`}
                                label={countData.countTargetTaskManager}/>
                        </Tooltip>
                    }
                </div>
            </div>
        </SuperPageNav>
    );
};
export default PageNavTargets;
