/* eslint-disable react/no-array-index-key */
import React from 'react';
import NotificationItemContainer from "../../components/NotificationItem/NotificationItemContainer";
import NotificationIcon from "../../assets/icons/notification-icon";

const typeSlug = 'crm_other';

const TopnavNotifications = () => {
    return (
        <NotificationItemContainer
            typeSlug={typeSlug}
            icon={<NotificationIcon/>}
        />
    );
};

export default TopnavNotifications;
