import { sendPost } from '../handler/sendPost';
import { SendGet } from '../handler/sendGet';

// eslint-disable-next-line import/prefer-default-export
export const uploadLeadFile = (params) => {
  return sendPost(`orders/upload-file`, params);
};
export const updateOrder = (params) => {
  return sendPost(`orders/update`, params);
};
export const createOrder = (params) => {
  return sendPost(`orders/create`, params);
};
export const orderByID = ({ id, params }) => {
  return SendGet(`orders/${id}`, params);
};
export const addExistProduct = (params) => {
  return sendPost(`orders/add-product`, params);
};
export const getOrders = (params) => {
  return SendGet(`orders`, params);
};
export const getCountProductSearch = (params) => {
  return SendGet(`orders/count-product-search`, params);
};
export const removeContact = (params) => {
  return sendPost(`orders/remove-contact`, params);
};
export const addContact = (params) => {
  return sendPost(`orders/add-contact`, params);
};
export const orderGetShipperRating = (params) => {
  return SendGet(`orders/shipper-rating`, params);
};
export const fetchDiscounts = (params) => {
  return SendGet(`orders/discounts`, params);
};