/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import React, {useContext, useState} from 'react';
import {useSelector} from "react-redux";
import {Select, MenuItem} from '@mui/material';
import css from './Topnav.module.scss'
import TopNavRatesItem from "./TopNavRatesItem";
import {DesktopContext} from "../../utils/hoc/withContext";
import DropdownIcon from "../../assets/icons/dropdown-icon";
import {getSelectCurrency, setSelectCurrency} from "../../helpers/Utils";

const TopnavRates = () => {

    // eslint-disable-next-line camelcase
    const { common__listCurrency } = useSelector((state) => state.websocket.event);
    const desktop = useContext(DesktopContext)

    const [rate, setRate] = useState(getSelectCurrency('first'));
    const [rateSecond, setRateSecond] = useState(getSelectCurrency('second'));

    const handleChangeSecond = (event) => {
        setRateSecond(event.target.value)
        setSelectCurrency('second', event.target.value)
    }

    const handleChange = (event) => {
        setRate(event.target.value)
        setSelectCurrency('first', event.target.value)
    }

    return (
        <>
            {common__listCurrency.length > 0 && (
                <div className={css.wrapRates}>
                    <Select
                        className={css.rates}
                        onChange={handleChange}
                        value={rate}
                        IconComponent={({ className }) => {
                            // eslint-disable-next-line no-param-reassign
                            className = className.replace("MuiSelect-iconOpen", "")
                            return <DropdownIcon className={className} />
                        }}
                        MenuProps={{
                            disableScrollLock: true,
                        }}
                    >
                        {common__listCurrency.map(item => {
                            return <MenuItem className={css.ratesSelect} key={item.id} value={item.id}>
                                <TopNavRatesItem {...item} />
                            </MenuItem>
                        })}
                    </Select>
                    {desktop &&
                        <Select
                            className={css.rates}
                            onChange={handleChangeSecond}
                            value={rateSecond}
                            IconComponent={({ className }) => {
                                // eslint-disable-next-line no-param-reassign
                                className = className.replace("MuiSelect-iconOpen", "")
                                return <DropdownIcon className={className} />
                            }}
                        >
                            {common__listCurrency.map(item => {
                                return <MenuItem className={css.ratesSelect} key={item.id} value={item.id}>
                                    <TopNavRatesItem {...item} />
                                </MenuItem>
                            })}
                        </Select>
                    }
                </div>
            )}
        </>
    )

};
export default TopnavRates;