import React from "react";
import css from "./Priority.module.scss";

const Priority = ({data, size = '', withoutBorder = false}) => {
    const {id, name} = data;

    return (
        <div className={`${css.priority} ${css[`status-${id}`]} ${css[`size-${size}`]} ${withoutBorder ? css.withoutBorder : ''}`}>
            <i className={`icon-fire`} />
            <span className={css.name}>{name}</span>
        </div>
    )
}
export default Priority;