// eslint-disable-next-line no-unused-vars
/* eslint-disable no-use-before-define */
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { FINANCE_CURRENCY_GET } from "../actions";
import ErrorResponse from "../../components/common/ErrorResponse";
import {fetchCurrency} from "../../api/query/directory";
import {financeCurrencySet} from "./actions";

export function* watchCurrency() {
    yield takeLatest(FINANCE_CURRENCY_GET, currencyHandler);
}
function* currencyHandler({ payload }) {
    try {
        const getData = yield call(fetchCurrency, payload);
        const codeResponse = Number.parseInt(getData.status, 10);
        if (codeResponse === 200) {
            yield put(financeCurrencySet(getData.data));
        }
    } catch (error) {
        ErrorResponse(error.responsible);
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchCurrency),
    ]);
}
