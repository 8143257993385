import React from "react";
import {Style6} from "../../../../constants/ReactSelect";
import DirectoryManager from "../../../../components/directory/DirectoryManager";

const Responsible = ({value, setValue, userFirst = [], groupUsersFirst = []}) => {
    return (
        <>
            <div className="font-weight-bold">Ответственный:</div>
            <div className="mt-2">
                <DirectoryManager
                    initState={value}
                    onChange={setValue}
                    groups
                    userFirst={userFirst}
                    groupUsersFirst={groupUsersFirst}
                    style={Style6()}
                />
            </div>
        </>
    )
}
export default Responsible;