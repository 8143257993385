import moment from "moment";
import {priceSegmentOptions, taskPlaceholderDefault} from "./params";
import {fetchNumberOfPurchases, fetchOrganizationOne} from "../../../api/query/organization";
import {fetchManagers} from "../../../api/query/directory";
import {validate as validate48} from "./content/Template48";
import {validate as validate37} from "./content/Template37";
import {validate as validate49} from "./content/Template49";
import {validate as validate50} from "./content/Template50";
import {validate as validate51} from "./content/Template51";
import {validate as validate52} from "./content/Template52";

export const getManagerOrGroup = (managerID, type = 'manager') => {
    if (!managerID) return managerID;
    let id = null;
    switch (type) {
        case 'manager':
            if(managerID.toString().indexOf('G') < 0) {
                id = managerID;
            }
            break;
        case 'group':
            if(managerID.toString().indexOf('G') > -1) {
                id = managerID.replace('G-', '');
            }
            break;
        default:
    }
    return id;
};


export const prepareTaskData = (newData) => {
    let curManager = newData.manager;
    let curResponsible = newData.responsible;
    if (!curManager && newData.manager_group_id) {
        const newManagerGroup = newData.managerGroup;
        curManager = {
            ...newManagerGroup,
            id: `G-${newManagerGroup.id}`
        }
    }
    if (!curResponsible && newData.responsible_group_id) {
        const newRespGroup = newData.responsibleGroup;
        curResponsible = {
            ...newRespGroup,
            id: `G-${newRespGroup.id}`
        };
    }

    const fields = newData.allFields ?? {};
    const priceSegmentId = fields?.priceSegment ?? null;
    const result = {
        ...newData,
        taskStatus: newData.status,
        taskDoubleStatus: newData.doubleStatus,
        priority: newData.priorityModel,
        taskType: newData.type,
        taskTypeTemplate: newData.template,
        manager: curManager,
        responsible: curResponsible,
        placeholder: newData.placeholder || taskPlaceholderDefault,
        dateStart: moment(newData.created_at).toDate(),
        dateFinish: newData.closed_at,
        dateCreate: newData.date_create,
        badgeFire: Boolean(newData.badge_fire),
        client: newData.organization,
        order: newData.order ? newData.order.id : null,
        ...fields,
        priceSegment: priceSegmentId ? (priceSegmentOptions.find(x => x.id === priceSegmentId) ?? null) : null,
        deadline: newData.deadline ? moment(newData.deadline).toDate() : null,
    };

    return result;
    // setDepartmentId(updateTask.type?.departments.length ? updateTask.type?.departments[0].id : null);

    // setCurrentTypeIdHasTemplate(typesDefaultUsesTemplates.includes(updateTask.type_id) ? updateTask.type_id : false);
}

export const extractParams = (modalData) => {
    const taskId = modalData?.id ?? null;
    const typeDefault = modalData?.typeDefault ?? null;
    const orderID = modalData?.orderID ?? null;
    const clientData = modalData?.clientData ?? null;
    const changeClient = modalData?.changeClient ?? false;
    const parent_id = modalData?.parent_id ?? null;
    const parentEntity = modalData?.parentEntity ?? null;

    const emptyFunc = () => {
        return {result: true}
    };
    const callbackAfterSave = modalData?.callbackAfterSave ?? emptyFunc;
    const callbackClose = modalData?.callbackClose ?? emptyFunc;
    const callbackBeforeSave = modalData?.callbackBeforeSave ?? emptyFunc;

    return {taskId, typeDefault, orderID, clientData, changeClient, callbackAfterSave, callbackClose, parent_id, parentEntity, callbackBeforeSave}
}

export const prepareDataForSubmit = (data) => {
    const deadlineMoment = moment(data.deadline);
    let prepareData = {
        organization_id: data.client?.id ?? null,
        order_id: data.order,
        name: data.taskTypeTemplate ? data.taskTypeTemplate.name : data.name,
        status_id: data.taskStatus.id,
        priority: data.priority?.id ?? null,
        type_id: data.taskType.id,
        template_id: data.taskTypeTemplate ? data.taskTypeTemplate.id : null, // Вид шаблона-задачи
        manager_id: getManagerOrGroup(data.manager.id, 'manager'),
        manager_group_id: getManagerOrGroup(data.manager.id, 'group'),
        badge_fire: data.badgeFire ? 1 : 0,
        responsible_id: getManagerOrGroup(data.responsible.id, 'manager'),
        responsible_group_id: getManagerOrGroup(data.responsible.id, 'group'),
        created_at: moment(data.dateStart).format('YYYY:MM:DD HH:mm:ss'),
        description: data.description,
        placeholder: data.placeholder,
        comment: data.comment,
        double_status_id: data.taskDoubleStatus ? data.taskDoubleStatus.id : null,
        brand_id: data.brand?.id || null,
        parent_id: data.parent_id,
        parent_entity: data.parent_entity,
        fields: {
            product_type_id: data.productType?.id ?? null,
            price_segment: data.priceSegment?.id ?? null,
            analog: data.analog ? 1 : 0,
            only_profile: data.onlyProfile ? 1 : 0,
        },
        deadline: deadlineMoment.isValid() ? deadlineMoment.format('YYYY:MM:DD HH:mm:ss') : null
    };
    return prepareData;
}

export const getType = (type, types) => {
    const result = null;
    if (!type) return result;
    return types.find(x => x.id === type.id) ?? null;
}

// eslint-disable-next-line no-unused-vars
export const getTemplates = (type) => {
    // const templates = [];
    // if (!type) return templates;
    // const findType = types.find(x => x.id === type.id) ?? null;
    // if (!findType) return templates;
    // return findType.templates;
}

export const getTypeFromManagerGroup = (group, types) => {
    const groupId = group.nativeId;
    const filterTypes = types.filter(x => {
        const depId = x.departments.map(d => d.id);
        return depId.includes(groupId);
    });
    return filterTypes[0] ?? null;
}

export const getTypeFromManagerGroups = (manager, types) => {
    const groups = manager?.crmGroups?.filter(x => x.isGroup) ?? [];
    if (!groups.length) return null;
    const groupsId = groups.map(x => Number(x.id.replace('G-', '')));
    const filterTypes = types.filter(x => {
        const depId = x.departments.map(d => d.id);
        return depId.filter(y => groupsId.includes(y)).length;
    });
    return filterTypes[0] ?? null;
}

export const getMainUserIdFromGroup = (group) => {
    if (!group) return null;
    const mainUsers = group.mainUsers.filter(x => x.is_main);
    return mainUsers[0]?.user_id ?? null;
}

export const getUserGroups = (type) => {
    const groupsId = [];
    if (!type) return groupsId;
    const groups = type.departments ?? [];
    return groups.map(x => x.id);
}

export const loadOrganizationData = async (data) => {
    let result = {
        type: '',
        data: null,
    }
    await fetchOrganizationOne(data)
        .then(response => {
            result = {
                type: 'success',
                data: response.data,
            }
        }).catch(error => {
            const message = error?.response?.data?.message ?? 'Что-то пошло не так';
            result = {
                type: 'error',
                data: message,
            };
        });
    return result;
}

export const loadManagerData = async (data) => {
    let result = {
        type: '',
        data: null,
    }
    await fetchManagers(data)
        .then(response => {
            result = {
                type: 'success',
                data: response.data,
            }
        }).catch(error => {
            const message = error?.response?.data?.message ?? 'Что-то пошло не так';
            result = {
                type: 'error',
                data: message,
            };
        });
    return result;
}

export const fieldsFromTemplate = (template) => {
    switch (template) {
        case 48:
            return ['brand', 'productType', 'priceSegment'];
        case 49:
            return ['brand', 'productType', 'priceSegment'];
        case 50:
            return ['brand', 'analog', 'priceSegment'];
    }
}

export const validateForm = (data, {showClient}) => {
    let currentErrors = {};
    const typeTemplates = data.taskType?.templates ?? [];
    if (!moment(data.dateStart).isValid()) currentErrors.dateStart = 'Выберите дату начала';
    // if (!data.taskTypeTemplate && typeTemplates.length) currentErrors.taskTypeTemplate = 'Выберите шаблон';
    if (!data.taskStatus) currentErrors.taskStatus = 'Выберите статус';
    if (!data.taskType) currentErrors.taskType = 'Выберите тип задачи';
    if (!data.manager) currentErrors.manager = 'Выберите менеджера';
    if (!data.responsible) currentErrors.responsible = 'Выберите ответственного';
    if (!data.client && showClient) currentErrors.client = 'Выберите контрагента';
    if (data.viewDoubleStatus && !data.taskDoubleStatus) {
        currentErrors.taskDoubleStatus = 'Выберите статус подбора';
    }
    const taskTypeId = data.taskType?.id ?? null;
    if ([5,7,10].includes(taskTypeId) && !data.taskTypeTemplate) {
        currentErrors.name = 'Выберите шаблон задачи';
    }
    if (data.taskTypeTemplate) {
        let validateFromTemplate = null;
        switch (data.taskTypeTemplate.id) {
            case 37:
            case 38:
            case 39:
                validateFromTemplate = validate37(data);
                break;
            case 48:
                validateFromTemplate = validate48(data);
                break;
            case 49:
                validateFromTemplate = validate49(data);
                break;
            case 50:
                validateFromTemplate = validate50(data);
                break;
            case 51:
                validateFromTemplate = validate51(data);
                break;
            case 52:
                validateFromTemplate = validate52(data);
                break;
        }
        if (validateFromTemplate !== null) {
            currentErrors = {...currentErrors, ...validateFromTemplate};
        }
    }
    return currentErrors;
};

export const getParentType = (taskTypes, template) => {
    return taskTypes.find(x => {
        const {templates} = x;
        return templates.some(y => y.id === template.id);
    }) ?? null;
}

export const numberOfPurchasesByOrg = async (data) => {
    let result = {
        type: '',
        data: null,
    }
    await fetchNumberOfPurchases(data)
        .then(response => {
            result = {
                type: 'success',
                data: response.data,
            }
        }).catch(error => {
            const message = error?.response?.data?.message ?? 'Что-то пошло не так';
            result = {
                type: 'error',
                data: message,
            };
        });
    return result;
}