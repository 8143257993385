import React from 'react';
import {Box, DialogContent} from "@mui/material";
import {StyledDialog} from "../../utils/mui/dialog";

const ModalCustomQuery = (props) => {
    const {isShow, changeShow, title, content, onSubmit, onCancel} = props;
    const {text: submitText, callback: submitCallback, submitting, disabled} = onSubmit;
    const cancelText = onCancel?.text;
    const cancelCallback = onCancel?.callback;

    const onClickClose = () => {
        changeShow(false);
    };

    return (
        <>
            <StyledDialog
                open={isShow}
                maxWidth="xs"
                className="modal-dialog-mui"
                onClose={onClickClose}
                backdrop="static"
            >
                <DialogContent>
                    <Box pt={3} pb={1}>
                        <div className="window-question">
                            <div className="text mt-3">{title}</div>
                            <div className="mt-3">
                                {content}
                            </div>
                            <div className="d-flex align-items-center justify-content-center mt-4">
                                <button
                                    type="button"
                                    className="btn-primary btn ml-2 mr-2"
                                    disabled={disabled}
                                    onClick={submitCallback}
                                >
                                    {submitting ? 'Выполнение...' : submitText}
                                </button>
                                {!!cancelText && (
                                    <button
                                        type="button"
                                        className="btn-danger btn ml-2 mr-2"
                                        onClick={cancelCallback}
                                    >
                                        {cancelText}
                                    </button>
                                )}
                            </div>
                        </div>
                    </Box>
                </DialogContent>
            </StyledDialog>
        </>
    );
};
export default ModalCustomQuery;
