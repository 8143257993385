import {put, call, takeEvery} from 'redux-saga/effects'
import {processActions} from "./slice";
import {PayloadAction} from "@reduxjs/toolkit";
import ErrorResponse from "../../../components/common/ErrorResponse";
import {appActions} from "../app/slice";
import {getProcess, getProcesses, getProcessTasks} from "../../../api/query/process";

function* fetchViewData({payload}: PayloadAction<any>) {
    try {
        const data = yield call(getProcess, payload);
        const codeResponse = Number(data.status);
        if (codeResponse === 200) {
            yield put(processActions.setView(data.data));
        }
    } catch (error) {
        yield put(processActions.setView(null));
        yield put(appActions.setNotification({type: 'error', message: ErrorResponse(error.response)}));
    }
}
function* fetchListData({payload}: PayloadAction<any>) {
    try {
        const data = yield call(getProcesses, payload);
        const codeResponse = Number(data.status);
        if (codeResponse === 200) {
            yield put(processActions.setList(data.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(processActions.setList(null));
    }
}
function* fetchViewTasks({payload}: PayloadAction<any>) {
    try {
        const data = yield call(getProcessTasks, payload);
        const codeResponse = Number(data.status);
        if (codeResponse === 200) {
            yield put(processActions.setTasks(data.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(processActions.setTasks(null));
    }
}

// eslint-disable-next-line import/prefer-default-export
export function* processSaga() {
    yield takeEvery(processActions.getList.type, fetchListData);
    yield takeEvery(processActions.getView.type, fetchViewData);
    yield takeEvery(processActions.getTasks.type, fetchViewTasks);
}
