import React, { Suspense } from 'react';
import { Row } from 'reactstrap';
import AppLayout from '../layout/AppLayout';
import { Colxx } from '../components/common/CustomBootstrap';

const LoadingData = () => {
    return (
        <>
            <AppLayout>
                <div className="dashboard-wrapper">
                    <Suspense fallback={<div className="loading" />}>
                        <Row>
                            <Colxx xxs="12" className="mb-4">
                                {' '}
                            </Colxx>
                        </Row>
                    </Suspense>
                </div>
            </AppLayout>
        </>
    );
};

export default LoadingData;
