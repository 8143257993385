import {storeData} from "../../store";

const getUsers = (state: storeData) => state.users.users;
const getUser = (state: storeData) => state.users.users[0];
const isFetching = (state: storeData) => state.users.isFetching;

// eslint-disable-next-line import/prefer-default-export
export const usersSelectors = {
    getUser,
    getUsers,
    isFetching
};