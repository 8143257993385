import moment from 'moment';
import 'moment-timezone';

const TimeZone = (location) => {
  return location.timezone && typeof location.timezone !== 'undefined'
    ? moment().tz(location.timezone).format('HH:mm')
    : '';
};

export default TimeZone;
