import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    notification: null,
}

const slice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setNotification: (state, {payload}: PayloadAction) => {
            // eslint-disable-next-line no-param-reassign
            state.notification = payload || null;
        },
    }
})

export const appReducer = slice.reducer;
export const appActions = slice.actions;