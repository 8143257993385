import {put, call, takeEvery} from 'redux-saga/effects'

import {taskActions} from "./slice";
import ErrorResponse from "../../../components/common/ErrorResponse";
import {getTask, getTaskWatchers} from "../../../api/query/task";
import {getComments} from "../../../api/query/comment";
import {fetchTaskType} from "../../../api/query/directory";

function* fetchWatchers({payload}) {
    try {
        const getData = yield call(getTaskWatchers, payload);
        const codeResponse = Number(getData.status);
        if (codeResponse === 200) {
            yield put(taskActions.setWatchers(getData.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(taskActions.setWatchers([]));
    }
}

function* fetchComments({payload}) {
    try {
        const getData = yield call(getComments, payload);
        const codeResponse = Number(getData.status);
        if (codeResponse === 200) {
            yield put(taskActions.setComments(getData.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(taskActions.setComments(null));
    }
}

function* fetchTask({payload}) {
    try {
        const getData = yield call(getTask, payload);
        const codeResponse = Number(getData.status);
        if (codeResponse === 200) {
            yield put(taskActions.setTask(getData.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(taskActions.setTask(null));
    }
}

function* fetchTaskTypes({payload}) {
    try {
        const getData = yield call(fetchTaskType, payload);
        const codeResponse = Number(getData.status);
        if (codeResponse === 200) {
            yield put(taskActions.setTaskTypes(getData.data));
        }
    } catch (error) {
        ErrorResponse(error.response);
        yield put(taskActions.setTaskTypes([]));
    }
}

// eslint-disable-next-line import/prefer-default-export
export function* taskSaga() {
    yield takeEvery(taskActions.fetchWatchers.type, fetchWatchers);
    yield takeEvery(taskActions.fetchComments.type, fetchComments);
    yield takeEvery(taskActions.fetchTask.type, fetchTask);
    yield takeEvery(taskActions.fetchTaskTypes.type, fetchTaskTypes);
}
