import React from 'react';
import Switch from "rc-switch/es";
import css from "../ModalShipperScore.module.scss";

const fieldName = 'missedDeadline';

const MissedDeadline = (props) => {
    const {data, setData, readOnly} = props;

    const setValue = (value) => {
        setData('detail', {
            ...data.detail,
            [fieldName]: value
        });
    }

    return (
        <div className={css.actionRow}>
            <div className="row align-items-center">
                <div className="col-6">Срыв сроков</div>
                <div className="col-6">
                    <Switch
                        className={`custom-switch custom-switch-primary ${readOnly ? 'custom-switch-small' : 'custom-switch-medium'}`}
                        checked={!!Number(data.detail[fieldName])}
                        onChange={(value) => setValue(value ? 1 : 0)}
                        disabled={readOnly}
                    />
                </div>
            </div>
        </div>
    )
};

export default MissedDeadline;
