// eslint-disable-next-line import/named
import {
  CRM_ORDER_FEEDBACK_FETCH_DATA, CRM_ORDER_FEEDBACK_LOADING, CRM_ORDER_FEEDBACK_RELOAD, CRM_ORDER_FEEDBACK_SET,
} from '../../actions';

export const fetchOrderFeedback = (params) => {
  return {
    type: CRM_ORDER_FEEDBACK_FETCH_DATA,
    payload: params,
  };
};
export const setReloadOrderFeedback = (params) => {
  return {
    type: CRM_ORDER_FEEDBACK_RELOAD,
    payload: params,
  };
};
export const setOrderFeedback = (data) => {
  return {
    type: CRM_ORDER_FEEDBACK_SET,
    payload: data,
  };
};
export const setLoadingOrderFeedback = (data) => {
  return {
    type: CRM_ORDER_FEEDBACK_LOADING,
    payload: data,
  };
};