import React, {useContext} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useSelector} from "react-redux";
import menuItems from '../../constants/menu';
import {getParentItem} from "../../helpers/menu/getParentItem";
import PageNavItem from "./pageNav/PageNavItem";
import {DesktopContext} from "../../utils/hoc/withContext";

const PageNav = () => {
    const history = useHistory();
    const currentUser = useSelector((state) => state.authUser.currentUser);
    const location = useLocation();

    if (!currentUser) return <></>;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const desktop = useContext(DesktopContext)

    const currentItemId = getParentItem(location);
    if (!currentItemId) return <></>;
    const currentItem = menuItems.find(x => x.id === currentItemId);
    if (typeof currentItem === 'undefined') return <></>;
    const childrenMenu = currentItem.children ?? [];
    if (!childrenMenu.length) return <></>;

    const redirectToFilter = (event) => {
        event.stopPropagation();
        event.preventDefault();
        const href = event.target.getAttribute('data-href');
        if (href) {
            history.push(href);
        }
    };

    const checkAccess = (menuItem) => {
        if (!currentUser) return false;
        const {permissions} = menuItem;
        let access = true;
        if (typeof permissions === 'undefined' || !permissions) return access;

        if (!currentUser.permissions.filter(x => permissions.includes(x)).length) {
            access = false;
        }
        return access;
    };

    return (
        <>
            {desktop ?
                <div id="pageNav">
                    <ul>
                        {childrenMenu.map(x => {
                            return <PageNavItem
                                key={x.id}
                                menuItem={x}
                                checkAccess={checkAccess}
                                redirectToFilter={redirectToFilter}
                            />
                        })}
                    </ul>
                </div>
                :
                <></>
            }
        </>
    )
};
export default PageNav;
