// eslint-disable-next-line import/named
import {
    STATISTICS_DEAL_LOADING,
    STATISTICS_DEAL,
    STATISTICS_DEAL_DATA
} from "../../actions";

// eslint-disable-next-line import/prefer-default-export
export const fetchDeals = (params) => {
    return {
        type: STATISTICS_DEAL,
        payload: params
    }
};
export const setStatisticsDeal = (orders) => {
    return {
        type: STATISTICS_DEAL_DATA,
        payload: orders,
    };
};
export const changeLoading = (status) => {
    return {
        type: STATISTICS_DEAL_LOADING,
        payload: status,
    };
};