// eslint-disable-next-line import/no-cycle
import {
  FINANCE_CURRENCY_GET,
  FINANCE_CURRENCY_SET,
} from '../actions';

export const financeCurrencySet = (data) => {
  return {
    type: FINANCE_CURRENCY_SET,
    payload: data,
  };
};
export const financeCurrencyGet = (data = {}) => {
  return {
    type: FINANCE_CURRENCY_GET,
    payload: data,
  };
};
