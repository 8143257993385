import React from 'react';
import AsyncSelect from 'react-select/async/dist/react-select.esm';
import {fetchProductBrand} from '../../api/query/directory';
import {Style} from '../../constants/ReactSelect';

const DirectoryProductBrand = ({
        initState = null,
        onChange,
        style = Style(),
        menuPortalTarget = false,
        placeholder = 'Выберите бренд',
        isMulti = false,
        defaultOptions = false,
        fetchSort = 'id',
        isClearable = false,
        exclude = [],
        isDisabled = false
    }) => {
    const loadOptions = (inputValue, callback) => {
        fetchProductBrand({
            'selection[name]': inputValue,
            sort: fetchSort,
        }).then((response) => {
            callback(response.data.items.filter(x => !exclude.includes(x.id)));
        });
    };

    return (
        <AsyncSelect
            isClearable={isClearable}
            menuPortalTarget={menuPortalTarget}
            cacheOptions
            defaultOptions={defaultOptions}
            value={initState}
            onChange={onChange}
            loadOptions={loadOptions}
            noOptionsMessage={() => 'ничего не найдено'}
            loadingMessage={() => 'поиск...'}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            styles={style}
            placeholder={placeholder}
            isMulti={isMulti}
            isDisabled={isDisabled}
        />
    );
};

export default DirectoryProductBrand;
