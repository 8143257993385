import { createSlice } from "@reduxjs/toolkit";
import React from "react";
import {fetchMetaDefault} from "../../../constants/defaultCrm";

const initialState = {
    list: {
        loading: false,
        data: {
            items: [],
            meta: fetchMetaDefault
        },
        reload: false,
    },
    view: {
        data: undefined,
        loading: false,
        organizations: {
            loading: false,
            data: {
                items: [],
                meta: fetchMetaDefault
            },
            reload: false,
            selected: [],
            filter: {
                selection: {},
            }
        }
    },
    modalCreate: {
        status: false,
    }
}

const slice = createSlice({
    name: "audience",
    initialState,
    reducers: {
        getList: (state, { payload }) => {
            state.list = {
                ...state.list,
                loading: true,
                reload: false,
            };
        },
        setList: (state, { payload }) => {
            state.list = {
                ...state.list,
                loading: false,
                data: {
                    ...state.list.data,
                    items: !payload ? [] : payload.items,
                    // eslint-disable-next-line no-underscore-dangle
                    meta: !payload ? fetchMetaDefault : payload._meta
                },
            };
        },
        setView: (state, { payload }) => {
            state.view = {
                ...state.view,
                loading: false,
                data: payload,
            };
        },
        getView: (state, { payload }) => {
            state.view.loading = true;
        },
        modalCreateStatus: (state, { payload }) => {
            state.modalCreate.status = payload;
        },
        getListOrganizations: (state, { payload }) => {
            state.view.organizations = {
                ...state.view.organizations,
                loading: true,
                reload: false,
            };
        },
        setListOrganizations: (state, { payload }) => {
            state.view.organizations = {
                ...state.view.organizations,
                loading: false,
                data: {
                    ...state.list.data,
                    items: !payload ? [] : payload.items,
                    // eslint-disable-next-line no-underscore-dangle
                    meta: !payload ? fetchMetaDefault : payload._meta
                },
            };
        },
        setListOrganizationsReload: (state, { payload }) => {
            state.view.organizations = {
                ...state.view.organizations,
                reload: payload,
            };
        },
        setListOrganizationsSelected: (state, { payload }) => {
            state.view.organizations = {
                ...state.view.organizations,
                selected: payload,
            };
        },
        setListOrganizationsFilterSelection: (state, { payload }) => {
            state.view.organizations.filter = {
                ...state.view.organizations.filter,
                selection: payload ?? {},
            };
        },
    }
})

export const audienceReducer = slice.reducer;
export const audienceActions = slice.actions;