import React, {useContext} from 'react';
import FormError  from '../../../../components/validate/FormError';
import {FormGroup} from "reactstrap";
import {Grid} from "@mui/material";
import Brands from "../fields/Brands";
import {ModalCreateTaskContext} from "../index";

export const validate = (data) => {
    const errors = {};
    return errors;
}
const Template37 = () => {
    const {data, errors, onChangeList} = useContext(ModalCreateTaskContext);
    const {onChangeBrand} = onChangeList;

    return (
        <>
            <Grid item xs={2}>
                <FormGroup>
                    <Brands
                        value={data.brand}
                        setValue={onChangeBrand}
                    />
                    <FormError errors={errors} name="brand" />
                </FormGroup>
            </Grid>
        </>
    );
};
export default Template37;
