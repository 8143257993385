import React, { useEffect } from 'react';
import { Row, Card, CardTitle } from 'reactstrap';
import { Colxx } from '../components/common/CustomBootstrap';
import { adminRoot } from '../constants/defaultValues';

const ErrorBug = () => {
    useEffect(() => {
        document.body.classList.add('background');
        document.body.classList.add('no-footer');

        return () => {
            document.body.classList.remove('background');
            document.body.classList.remove('no-footer');
        };
    }, []);

    return (
        <>
            <div className="fixed-background" />
            <main>
                <div className="container">
                    <Row className="h-100">
                        <Colxx xxs="12" md="10" className="mx-auto my-auto">
                            <Card className="auth-card">
                                <div className="position-relative image-side ">
                                    <p className="text-white h2">
                                        MAGIC IS IN THE DETAILS
                                    </p>
                                    <p className="white mb-0">
                                        Да, это произошло!
                                    </p>
                                </div>
                                <div className="form-side">
                                    <a href={adminRoot} className="white">
                                        <span className="logo-single" />
                                    </a>
                                    <CardTitle className="mb-2">
                                        Упс... Что-то пошло не так
                                    </CardTitle>
                                    <p className="mb-5 text-muted text-small mb-0">Чем раньше вы сообщите об этой ошибке, тем быстрее она будет устранена.</p>
                                    <a
                                        href={adminRoot}
                                        className="btn btn-primary btn-shadow btn-lg"
                                    >
                                        Вернуться на главную
                                    </a>
                                </div>
                            </Card>
                        </Colxx>
                    </Row>
                </div>
            </main>
        </>
    );
};

export default ErrorBug;
