export const nearestInteger = ({value, multiple = 1}) => {
    let newValue = value;
    if (value <= multiple) return multiple;

    const mod = newValue % multiple;
    if (mod === 0) return newValue;
    let nearest = value - mod;
    if (mod > multiple / 2) {
        nearest += multiple;
    }
    return nearest;
}

export const accountingRounding = (num, decimalPlaces = 2) => {
    // var d = decimalPlaces || 0,
    //     m = Math.pow(10, d),
    //     n = +(d ? num * m : num).toFixed(8),
    //     i = Math.floor(n), f = n - i,
    //     e = 1e-8,
    //     r = (f > 0.5 - e && f < 0.5 + e) ?
    //         ((i % 2 === 0) ? i : i + 1) : Math.round(n);
    // return d ? r / m : r;

    let value = Math.abs(num * 100 / 100).toString();
    value = value.replace(/(\d+)(\.|,)(\d+)/g, function(o, a, b, c){
        return a + b + c.slice(0, decimalPlaces);
    });
    return Number(value);
}

export const prepareCostForInput = (newValue, decimalPlaces = 2) => {
    let currentValue = newValue;
    if (!currentValue) {
        return currentValue
    }
    return accountingRounding(Number(currentValue), decimalPlaces);
}