import {getNotifications} from "../../../api/query/notification";
import {adminRoot} from "../../../constants/defaultValues";
import {colorDeal, colorOrder, colorStorage, colorTask} from "../../../constants/entityColor";
import {isJson} from "../../validate/JSON";
import {getLinkClientForTask} from "./task";
import {getContactedMe} from "../../../api/query/comment";

export const loadList = async (data: any) => {
    let result = {
        type: '',
        data: null,
    }
    await getNotifications(data)
        .then(response => {
            result = {
                type: 'success',
                data: response,
            }
        }).catch(error => {
            const message = error?.response?.data?.message ?? 'Что-то пошло не так';
            result = {
                type: 'error',
                data: message,
            };
        });
    return result;
}

export const loadCommentsForMe = async (data: any) => {
    let result = {
        type: '',
        data: null,
    }
    await getContactedMe(data)
        .then(response => {
            result = {
                type: 'success',
                data: response,
            }
        }).catch(error => {
            const message = error?.response?.data?.message ?? 'Что-то пошло не так';
            result = {
                type: 'error',
                data: message,
            };
        });
    return result;
}

export const getLink = (data) => {
    const typeId = data.data?.type_id ?? 9;
    let link = '/account';
    // eslint-disable-next-line default-case
    switch (typeId) {
        case 8:
        {
            const dealId = data.data?.deal_id ?? null;
            if (dealId) {
                link = `${adminRoot}/deals/${dealId}#messenger`;
            }
            break;
        }
        case 17:
        case 16:
        case 15:
        case 14:
        {
            const dealId = data.data?.id ?? null;
            if (dealId) {
                link = `${adminRoot}/deals/${dealId}`;
            }
            break;
        }
        case 18:
        {
            const storageId = data.data?.storage_id ?? null;
            if (storageId) {
                link = `${adminRoot}/storages/${storageId}`;
            }
            break;
        }
        case 7:
            // eslint-disable-next-line no-case-declarations
            const orderId = data.data?.order_id ?? null;
            if (orderId) {
                link = `${adminRoot}/orders/${orderId}#messenger`;
            }
            break;
    }
    return link;
}

export const getColor = (typeId) => {
    // eslint-disable-next-line default-case
    switch (typeId) {
        case 1:
        case 11:
        case 7:
            return colorOrder;
        case 3:
        case 4:
        case 5:
            return colorTask;
        case 14:
        case 17:
        case 8:
        case 15:
            return colorDeal;
        case 18:
            return colorStorage;
    }
    return '';
}

export const isReading = (id, listItems) => {
    const item = listItems.find(x => x.id === id) ?? null;
    return item?.status;
}

export const getUrlTopic = (notification) => {
    const data = isJson(notification.data) ? JSON.parse(notification.data) : notification.data;
    let urlTopic;
    switch (data.type_id) {
        case 1: // заказ
            urlTopic = `${adminRoot}/crm/orders/${data.id}`;
            break;
        case 2:
            urlTopic = '#';
            break;
        case 3:
        {
            urlTopic = '#';
            const orderId = data.order_id ?? null;
            if (orderId) {
                urlTopic = `${adminRoot}/crm/orders/${orderId}?openTask=${data.id}`;
            } else if (data.client ?? null) {
                urlTopic = getLinkClientForTask(data);
            }
            break;
        }
        case 4:
            urlTopic = '#';
            const orderId = data.order_id;
            const targetId = data.target_id;
            const taskId = data.task_id;
            if (orderId && taskId) {
                urlTopic = `${adminRoot}/crm/orders/${orderId}?openTask=${taskId}`;
            } else if (targetId && taskId) {
                urlTopic = `${adminRoot}/crm/targets/${targetId}?openTask=${taskId}`;
            } else if (data.client ?? null) {
                urlTopic = getLinkClientForTask(data);
            }
            break;
        case 5:
        {
            urlTopic = '#';
            const orderId = data.order_id ?? null;
            if (orderId) {
                urlTopic = `${adminRoot}/crm/orders/${orderId}?openTask=${data.id}`;
            } else if (data.client ?? null) {
                urlTopic = getLinkClientForTask(data);
            }
            break;
        }
        case 7:
            urlTopic = data.order_id ? `${adminRoot}/crm/orders/${data.order_id}#order-messenger` : '#';
            break;
        case 8:
            urlTopic = data.deal_id ? `${adminRoot}/crm/deals/${data.deal_id}#deal-messenger` : '#';
            break;
        case 11:
            urlTopic = data.order_id ? `${adminRoot}/crm/orders/${data.order_id}#order-feedback` : '#';
            break;
        case 12:
            urlTopic = data.order_id ? `${adminRoot}/crm/orders/${data.order_id}#order-products` : '#';
            break;
        case 18:
            urlTopic = data.storage_id ? `${adminRoot}/crm/storages/${data.storage_id}#messenger` : '#';
            break;
        case 27:
            urlTopic = data.target_id ? `${adminRoot}/crm/targets/${data.target_id}#messenger` : '#';
            break;
        case 28:
            urlTopic = data.target_id ? `${adminRoot}/crm/targets/${data.target_id}#tasks` : '#';
            break;
        default:
            urlTopic = '#';
    }

    return urlTopic;
}

export const sortFirstContactedMe = (list, commentsId) => {
    return list.sort((a, b) => {
        const entityIdA = a.data?.id ?? null;
        const entityIdB = b.data?.id ?? null;
        if (commentsId.includes(entityIdB)) {
            return 1;
        }
        if (commentsId.includes(entityIdA)) {
            return -1;
        }
        if (entityIdB > entityIdA) {
            return 1;
        }
        if (entityIdB < entityIdA) {
            return -1;
        }
        return 0;
    });
}

export const sortFirstNew = (list) => {
    return list.sort((a, b) => {
        const entityIdA = a.data?.id ?? null;
        const entityIdB = b.data?.id ?? null;
        const statusA = a.status;
        const statusB = b.status;

        if (statusB < statusA) {
            return 1;
        }
        if (statusB > statusA) {
            return -1;
        }

        if (entityIdB > entityIdA) {
            return 1;
        }
        if (entityIdB < entityIdA) {
            return -1;
        }
        return 0;
    });
}