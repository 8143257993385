import React from "react";
import NameSuggest from "./NameSuggest";

const Name = ({value, setValue, template, setTemplate, listTemplates, readOnly}) => {
    return (
        <>
            <div className="font-weight-bold">Название:</div>
            <div className="mt-2">
                <NameSuggest
                    initState={value}
                    onChangeInputText={setValue}
                    onChange={(value) => {
                        setTemplate(value);
                        if (value) {
                            setValue(value.name);
                        }
                    }}
                    template={template}
                    listTemplates={listTemplates}
                    readOnly={readOnly}
                />
            </div>
        </>
    )
}
export default Name;