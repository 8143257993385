// eslint-disable-next-line import/no-cycle
import {
  DEAL_CARD_COMMENTS_FETCH,
  DEAL_CARD_COMMENTS_LOADING,
  DEAL_CARD_COMMENTS_RELOAD,
  DEAL_CARD_COMMENTS_SET,
  DEAL_CARD_DATA_ERROR,
  DEAL_CARD_DATA_FETCH,
  DEAL_CARD_DATA_RELOAD,
  DEAL_CARD_DATA_SET,
  DEAL_CARD_DOCUMENTS_FETCH,
  DEAL_CARD_DOCUMENTS_LOADING,
  DEAL_CARD_DOCUMENTS_RELOAD,
  DEAL_CARD_DOCUMENTS_SELECTED_ADD,
  DEAL_CARD_DOCUMENTS_SELECTED_CLEAR,
  DEAL_CARD_DOCUMENTS_SELECTED_REMOVE,
  DEAL_CARD_DOCUMENTS_SET, DEAL_CARD_FILES_FETCH, DEAL_CARD_FILES_LOADING, DEAL_CARD_FILES_RELOAD, DEAL_CARD_FILES_SET,
  DEAL_CARD_LOGISTICS_FETCH,
  DEAL_CARD_LOGISTICS_LOADING,
  DEAL_CARD_LOGISTICS_RELOAD,
  DEAL_CARD_LOGISTICS_SET,
  DEAL_CARD_LOGISTICS_VIEW_COMMENTS_COUNT_SET,
  DEAL_CARD_LOGISTICS_VIEW_COMMENTS_FETCH,
  DEAL_CARD_LOGISTICS_VIEW_COMMENTS_LOADING,
  DEAL_CARD_LOGISTICS_VIEW_COMMENTS_RELOAD,
  DEAL_CARD_LOGISTICS_VIEW_COMMENTS_SET,
  DEAL_CARD_LOGISTICS_VIEW_SET, DEAL_CARD_MODAL_LOGISTICS_BREAK_PRODUCT,
  DEAL_CARD_MODAL_LOGISTICS_CREATE,
  DEAL_CARD_PAYMENTS_FETCH,
  DEAL_CARD_PAYMENTS_LOADING, DEAL_CARD_PAYMENTS_MODAL_BREAK_PRODUCT,
  DEAL_CARD_PAYMENTS_MODAL_CONTRACT_CANCEL,
  DEAL_CARD_PAYMENTS_MODAL_CONTRACT_CONFIRM,
  DEAL_CARD_PAYMENTS_MODAL_DELIVERY_CANCEL,
  DEAL_CARD_PAYMENTS_MODAL_DELIVERY_CONFIRM,
  DEAL_CARD_PAYMENTS_MODAL_DOCUMENTS_CANCEL,
  DEAL_CARD_PAYMENTS_MODAL_DOCUMENTS_CONFIRM,
  DEAL_CARD_PAYMENTS_MODAL_RECEIVED_CANCEL,
  DEAL_CARD_PAYMENTS_MODAL_RECEIVED_CONFIRM,
  DEAL_CARD_PAYMENTS_MODAL_SCORE_CANCEL,
  DEAL_CARD_PAYMENTS_MODAL_SCORE_CONFIRM, DEAL_CARD_PAYMENTS_MODAL_UPDATE_PAYMENT,
  DEAL_CARD_PAYMENTS_RELOAD,
  DEAL_CARD_PAYMENTS_SET,
  DEAL_CARD_RECIPIENTS_FETCH,
  DEAL_CARD_RECIPIENTS_LOADING,
  DEAL_CARD_RECIPIENTS_RELOAD,
  DEAL_CARD_RECIPIENTS_SET,
  DEAL_FILTERS,
  DEAL_LIST__ACTIVE_TAB,
  DEAL_LIST_FETCH_DATA,
  DEAL_LIST_LOADING,
  DEAL_LIST_RELOAD,
  DEAL_LIST_SET_DATA,
  DEAL_LOAD,
  DEAL_LOAD_FILTERS
} from '../../actions';

// eslint-disable-next-line import/prefer-default-export
export const changeLoading = (status) => {
  return {
    type: DEAL_LOAD,
    payload: status,
  };
};
export const setActiveTabList = (payload) => {
  return {
    type: DEAL_LIST__ACTIVE_TAB,
    payload,
  };
};
export const changeLoadFilters = (payload) => {
  return {
    type: DEAL_LOAD_FILTERS,
    payload,
  };
};
export const setFilters = (payload) => {
  return {
    type: DEAL_FILTERS,
    payload,
  };
};
export const dealCardDataFetch = (data) => {
  return {
    type: DEAL_CARD_DATA_FETCH,
    payload: data,
  };
};
export const dealCardDataSet = (data) => {
  return {
    type: DEAL_CARD_DATA_SET,
    payload: data,
  };
};
export const dealCardDataError = (data) => {
  return {
    type: DEAL_CARD_DATA_ERROR,
    payload: data,
  };
};
export const dealCardDataReload = (data) => {
  return {
    type: DEAL_CARD_DATA_RELOAD,
    payload: data,
  };
};
export const dealCardLogisticsSet = (data) => {
  return {
    type: DEAL_CARD_LOGISTICS_SET,
    payload: data,
  };
};
export const dealCardLogisticsFetch = (data) => {
  return {
    type: DEAL_CARD_LOGISTICS_FETCH,
    payload: data,
  };
};
export const dealCardLogisticsLoading = (data) => {
  return {
    type: DEAL_CARD_LOGISTICS_LOADING,
    payload: data,
  };
};
export const dealCardLogisticsReload = (data) => {
  return {
    type: DEAL_CARD_LOGISTICS_RELOAD,
    payload: data,
  };
};
export const dealCardLogisticsViewSet = (data) => {
  return {
    type: DEAL_CARD_LOGISTICS_VIEW_SET,
    payload: data,
  };
};
export const dealCardPaymentsSet = (data) => {
  return {
    type: DEAL_CARD_PAYMENTS_SET,
    payload: data,
  };
};
export const dealCardPaymentsFetch = (data) => {
  return {
    type: DEAL_CARD_PAYMENTS_FETCH,
    payload: data,
  };
};
export const dealCardPaymentsLoading = (data) => {
  return {
    type: DEAL_CARD_PAYMENTS_LOADING,
    payload: data,
  };
};
export const dealCardPaymentsReload = (data) => {
  return {
    type: DEAL_CARD_PAYMENTS_RELOAD,
    payload: data,
  };
};

export const dealCardDocumentsSet = (data) => {
  return {
    type: DEAL_CARD_DOCUMENTS_SET,
    payload: data,
  };
};
export const dealCardDocumentsFetch = (data) => {
  return {
    type: DEAL_CARD_DOCUMENTS_FETCH,
    payload: data,
  };
};
export const dealCardDocumentsLoading = (data) => {
  return {
    type: DEAL_CARD_DOCUMENTS_LOADING,
    payload: data,
  };
};
export const dealCardDocumentsReload = (data) => {
  return {
    type: DEAL_CARD_DOCUMENTS_RELOAD,
    payload: data,
  };
};

export const dealCardRecipientsSet = (data) => {
  return {
    type: DEAL_CARD_RECIPIENTS_SET,
    payload: data,
  };
};
export const dealCardRecipientsFetch = (data) => {
  return {
    type: DEAL_CARD_RECIPIENTS_FETCH,
    payload: data,
  };
};
export const dealCardRecipientsLoading = (data) => {
  return {
    type: DEAL_CARD_RECIPIENTS_LOADING,
    payload: data,
  };
};
export const dealCardRecipientsReload = (data) => {
  return {
    type: DEAL_CARD_RECIPIENTS_RELOAD,
    payload: data,
  };
};

export const dealCardCommentsSet = (data) => {
  return {
    type: DEAL_CARD_COMMENTS_SET,
    payload: data,
  };
};
export const dealCardCommentsFetch = (data) => {
  return {
    type: DEAL_CARD_COMMENTS_FETCH,
    payload: data,
  };
};
export const dealCardCommentsLoading = (data) => {
  return {
    type: DEAL_CARD_COMMENTS_LOADING,
    payload: data,
  };
};
export const dealCardCommentsReload = (data) => {
  return {
    type: DEAL_CARD_COMMENTS_RELOAD,
    payload: data,
  };
};

export const dealCardLogisticsViewCommentsSet = (data) => {
  return {
    type: DEAL_CARD_LOGISTICS_VIEW_COMMENTS_SET,
    payload: data,
  };
};
export const dealCardLogisticsViewCommentsFetch = (data) => {
  return {
    type: DEAL_CARD_LOGISTICS_VIEW_COMMENTS_FETCH,
    payload: data,
  };
};
export const dealCardLogisticsViewCommentsLoading = (data) => {
  return {
    type: DEAL_CARD_LOGISTICS_VIEW_COMMENTS_LOADING,
    payload: data,
  };
};
export const dealCardLogisticsViewCommentsReload = (data) => {
  return {
    type: DEAL_CARD_LOGISTICS_VIEW_COMMENTS_RELOAD,
    payload: data,
  };
};
export const dealCardDocumentsSelectedAdd = (data) => {
  return {
    type: DEAL_CARD_DOCUMENTS_SELECTED_ADD,
    payload: data,
  };
};
export const dealCardDocumentsSelectedRemove = (data) => {
  return {
    type: DEAL_CARD_DOCUMENTS_SELECTED_REMOVE,
    payload: data,
  };
};
export const dealCardDocumentsSelectedClear = (data) => {
  return {
    type: DEAL_CARD_DOCUMENTS_SELECTED_CLEAR,
    payload: data,
  };
};
export const dealCardLogisticsViewCommentsCountSet = (params) => {
  return {
    type: DEAL_CARD_LOGISTICS_VIEW_COMMENTS_COUNT_SET,
    payload: params,
  };
};
export const dealListFetchData = (params) => {
  return {
    type: DEAL_LIST_FETCH_DATA,
    payload: params,
  };
};
export const dealListSetData = (data) => {
  return {
    type: DEAL_LIST_SET_DATA,
    payload: data,
  };
};
export const dealListLoading = (loading) => {
  return {
    type: DEAL_LIST_LOADING,
    payload: loading,
  };
};
export const dealListReload = (reload) => {
  return {
    type: DEAL_LIST_RELOAD,
    payload: reload,
  };
};
export const dealCardPaymentsModalDeliveryConfirm = (data) => {
  return {
    type: DEAL_CARD_PAYMENTS_MODAL_DELIVERY_CONFIRM,
    payload: data,
  };
};
export const dealCardPaymentsModalDeliveryCancel = (data) => {
  return {
    type: DEAL_CARD_PAYMENTS_MODAL_DELIVERY_CANCEL,
    payload: data,
  };
};
export const dealCardModalLogisticsCreate = (data) => {
  return {
    type: DEAL_CARD_MODAL_LOGISTICS_CREATE,
    payload: data,
  };
};
export const dealCardModalLogisticsBreakProduct = (data) => {
  return {
    type: DEAL_CARD_MODAL_LOGISTICS_BREAK_PRODUCT,
    payload: data,
  };
};
export const dealCardPaymentsModalBreakProduct = (data) => {
  return {
    type: DEAL_CARD_PAYMENTS_MODAL_BREAK_PRODUCT,
    payload: data,
  };
};
export const dealCardPaymentsModalUpdatePayment = (data) => {
  return {
    type: DEAL_CARD_PAYMENTS_MODAL_UPDATE_PAYMENT,
    payload: data,
  };
};
export const dealCardPaymentsModalDocumentsConfirm = (data) => {
  return {
    type: DEAL_CARD_PAYMENTS_MODAL_DOCUMENTS_CONFIRM,
    payload: data,
  };
};
export const dealCardPaymentsModalDocumentsCancel = (data) => {
  return {
    type: DEAL_CARD_PAYMENTS_MODAL_DOCUMENTS_CANCEL,
    payload: data,
  };
};
export const dealCardPaymentsModalReceivedConfirm = (data) => {
  return {
    type: DEAL_CARD_PAYMENTS_MODAL_RECEIVED_CONFIRM,
    payload: data,
  };
};
export const dealCardPaymentsModalReceivedCancel = (data) => {
  return {
    type: DEAL_CARD_PAYMENTS_MODAL_RECEIVED_CANCEL,
    payload: data,
  };
};
export const dealCardPaymentsModalContractConfirm = (data) => {
  return {
    type: DEAL_CARD_PAYMENTS_MODAL_CONTRACT_CONFIRM,
    payload: data,
  };
};
export const dealCardPaymentsModalContractCancel = (data) => {
  return {
    type: DEAL_CARD_PAYMENTS_MODAL_CONTRACT_CANCEL,
    payload: data,
  };
};
export const dealCardPaymentsModalScoreConfirm = (data) => {
  return {
    type: DEAL_CARD_PAYMENTS_MODAL_SCORE_CONFIRM,
    payload: data,
  };
};
export const dealCardPaymentsModalScoreCancel = (data) => {
  return {
    type: DEAL_CARD_PAYMENTS_MODAL_SCORE_CANCEL,
    payload: data,
  };
};
export const dealCardFilesSet = (data) => {
  return {
    type: DEAL_CARD_FILES_SET,
    payload: data,
  };
};
export const dealCardFilesFetch = (data) => {
  return {
    type: DEAL_CARD_FILES_FETCH,
    payload: data,
  };
};
export const dealCardFilesLoading = (data) => {
  return {
    type: DEAL_CARD_FILES_LOADING,
    payload: data,
  };
};
export const dealCardFilesReload = (data) => {
  return {
    type: DEAL_CARD_FILES_RELOAD,
    payload: data,
  };
};