// eslint-disable-next-line import/named
import {
    CRM_TASKS_SET_DATA,
    CRM_TASKS_LOADING,
    CRM_TASK_SET_DATA,
    CRM_TASK_LOADING,
    CRM_TASK_SET_ERRORS,
    CRM_TASKS_RELOAD,
    CRM_TASK_CHANGE_LOADING,
    CRM_TASK_CHANGE_SUCCESS,
    CRM_TASK_CHANGE,
    CRM_TASK_CREATE,
    CRM_TASKS_FETCH_DATA,
    CRM_TASK_COMMENTS_SET,
    CRM_TASK_COMMENTS_LOADING,
    CRM_TASK_COMMENTS_RELOAD,
    CRM_TASKS_COUNT_NEW, CRM_TASKS_COUNT_NEW_COMMENT, CRM_TASKS_COUNT_TO_ENGINEER, CRM_TASKS_COUNT_OPENED,
} from '../../actions';
import {defaultQueryList} from "../../../constants/defaultCrm";

const INIT_STATE = {
    index: {
        loading: false,
        list: defaultQueryList,
        params: {},
        errors: null,
        reload: false,
    },
    card: {
        loading: false,
        data: null,
        reload: false,
        errors: null,
    },
    change: {
        loading: false,
        errors: [],
        success: null,
    },
    comments: {
        commentsList: [],
        commentsLoading: false,
        commentsReload: false,
    },
    countNew: 0, // кол-во новых задач
    countOpened: 0, // кол-во открытых задач
    countToEngineer: [], // список ID задач в работе у инженеров
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CRM_TASKS_FETCH_DATA:
            return {
                ...state,
                index: { ...state.index, reload: false },
                change: {...state.change, success: null }
            };
        case CRM_TASKS_SET_DATA:
            return {
                ...state,
                index: { ...state.index, list: action.payload || defaultQueryList },
            };
        case CRM_TASKS_LOADING:
            return {
                ...state,
                index: { ...state.index, loading: action.payload },
            };
        case CRM_TASKS_RELOAD:
            return {
                ...state,
                index: { ...state.index, reload: action.payload },
            };
        case CRM_TASK_SET_DATA:
            return {
                ...state,
                card: { ...state.card, data: action.payload, reload: false },
            };
        case CRM_TASK_LOADING:
            return {
                ...state,
                card: { ...state.card, loading: action.payload },
            };
        case CRM_TASK_SET_ERRORS:
            return {
                ...state,
                card: { ...state.card, errors: action.payload },
            };
        case CRM_TASK_CHANGE:
        case CRM_TASK_CREATE:
            return {
                ...state,
                change: { ...state.change, loading: true, success: false },
            };
        case CRM_TASK_CHANGE_LOADING:
            return {
                ...state,
                change: { ...state.change, loading: action.payload },
            };
        case CRM_TASK_CHANGE_SUCCESS:
            return {
                ...state,
                change: { ...state.change, errors: [], loading: false, success: true },
            };

        case CRM_TASK_COMMENTS_SET:
            return {
                ...state,
                comments: { ...state.comments, commentsList: action.payload },
            };
        case CRM_TASK_COMMENTS_LOADING:
            return {
                ...state,
                comments: {
                    ...state.comments,
                    commentsLoading: action.payload,
                    commentsReload: false,
                },
            };
        case CRM_TASK_COMMENTS_RELOAD:
            return {
                ...state,
                comments: { ...state.comments, commentsReload: action.payload },
            };
        case CRM_TASKS_COUNT_NEW:
            return { ...state, countNew: action.payload };
        case CRM_TASKS_COUNT_OPENED:
            return { ...state, countOpened: action.payload };
        case CRM_TASKS_COUNT_TO_ENGINEER:
            return { ...state, countToEngineer: action.payload };
        case CRM_TASKS_COUNT_NEW_COMMENT:
            return {
                ...state,
                index: {
                    ...state.index,
                    list: {...state.index.list,
                        items: state.index.list.items.map(item => Number(action.payload.id) === Number(item.id) ? {
                            ...item,
                            commentsNewCount: action.payload.count,
                            commentsMyNewCount: action.payload.count,
                        } : item)
                    }
                }
            };
        default:
            return { ...state };
    }
};
