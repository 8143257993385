import React from 'react'
import {Style6} from "../../../../constants/ReactSelect";
import DirectoryProductBrand from "../../../../components/directory/DirectoryProductBrand";

const Brands = ({value, setValue, exclude = [], isDisabled = false}) => {
    return (
        <>
            <div className="font-weight-bold">Бренд:</div>
            <div className="mt-2">
                <DirectoryProductBrand
                    initState={value}
                    onChange={setValue}
                    style={Style6()}
                    isClearable
                    defaultOptions
                    exclude={exclude}
                    isDisabled={isDisabled}
                />
            </div>
        </>
    )
}

export default Brands