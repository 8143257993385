import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";

const Deadline = ({value, setValue, isReadOnly}) => {

    const params = {
        readOnly: false,
        minDate: moment().toDate(),
        dateFormat: "dd.MM.yyyy HH:mm",
        showTimeSelect: true,
        timeIntervals: 60,
        timeCaption: "Время:",
        timeFormat: "HH:mm",
        isClearable: true,
    };

    return (
        <>
            <div className="font-weight-bold">Дедлайн:</div>
            <div className="mt-2">
                <DatePicker
                    locale="ru"
                    selected={value}
                    onChange={setValue}
                    dateFormat="dd.MM.yyyy"
                    className="text-left form-control style-2"
                    closeOnScroll
                    readOnly={isReadOnly}
                    {
                        ...params
                    }
                />
            </div>
        </>
    )
}
export default Deadline;