/* eslint-disable no-unused-vars,no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import React from "react";
import {fetchMetaDefault} from "../../../constants/defaultCrm";

const initialState = {
    list: {
        loading: false,
        data: {
            items: [],
            meta: fetchMetaDefault
        },
        reload: false,
    },
    modalCreate: {
        tkParams: {},
        runImport: false, // выполнить импорт данных из ТК
    }
}

const slice = createSlice({
    name: "logistics",
    initialState,
    reducers: {
        setModalImportFromTk: (state, { payload }) => {
            state.modalCreate.runImport = payload;
        },
        setModalCreateTkParams: (state, { payload }) => {
            const type = payload.type ?? null;
            const data = payload;
            if (type) {
                delete data.type;
            }
            state.modalCreate = {
                ...state.modalCreate,
                tkParams: type === 'change' ? {
                    ...state.modalCreate.tkParams,
                    ...data,
                } : data,
            };
        },
        setData: (state, { payload }) => {
            state.list = {
                ...state.list,
                data: {
                    ...state.list.data,
                    items: !payload ? [] : payload.items,
                    // eslint-disable-next-line no-underscore-dangle
                    meta: !payload ? fetchMetaDefault : payload._meta
                },
                loading: false,
                reload: false,
            };
        },
        fetchData: (state, { payload }) => {
            state.list = {
                ...state.list,
                loading: true,
                reload: false,
            };
        },
        reloadData: (state, { payload }) => {
            state.list = {
                ...state.list,
                reload: payload,
            };
        },
    }
})

export const logisticsReducer = slice.reducer;
export const logisticsActions = slice.actions;