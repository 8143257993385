// eslint-disable-next-line import/no-cycle
import { REDUX_WEBSOCKET_SET_EVENT } from '../actions';

// eslint-disable-next-line import/prefer-default-export
export const websocketSetEvent = (data) => {
  return {
    type: REDUX_WEBSOCKET_SET_EVENT,
    payload: data,
  };
};
