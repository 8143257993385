/* eslint-disable */
import React, {useContext, useEffect, useMemo, useState, useRef, Suspense} from 'react';
import { useSelector } from 'react-redux';
import {useHistory} from "react-router-dom";
import { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import css from './Content.module.scss'
import 'react-datepicker/dist/react-datepicker.css';
import ContentEditable from "react-contenteditable";
import FormError, { ClearError } from '../../../components/validate/FormError';
import CheckAccess from "../../../components/auth/CheckAccess";
import {getOrgLink} from "../../../helpers/client/client";
import CommentsContainer from "./comments";
import Watchers from "./watchers";
import {
    getMainUserIdFromGroup, getParentType,
    getType, getTypeFromManagerGroup, getTypeFromManagerGroups,
    getUserGroups,
    loadManagerData,
    loadOrganizationData, numberOfPurchasesByOrg,
    validateForm
} from "./helper";
import {taskSelectors} from "../../../redux/toolkit/task/selectors";
import {
    dealerTypeID,
    defaultClientIdForContent,
    managerExpand,
    organizationExpand,
    organizationFields, otherParamsDefault,
    taskTypeIDForDealer
} from "./params";
import {FormGroup} from "reactstrap";
import {DesktopContext} from "../../../utils/hoc/withContext";
import Default from "./content/Default";
import {Grid} from "@mui/material";
import {ModalCreateTaskContext} from "./index";
import Template48 from "./content/Template48";
import Template49 from "./content/Template49";
import Template50 from "./content/Template50";
import Template51 from "./content/Template51";
import Template52 from "./content/Template52";
import Template37 from "./content/Template37";
import Template53 from "./content/Template53";
import moment from "moment";
import {defaultPermissions} from "../../../constants/defaultValues";

registerLocale('ru', ru);

const Content = (props) => {
    const {data, setDataHandler, disabledSubmit, changeClient, onClose, submitForm, parentEntity, modalRef, firstData} = props;
    const history = useHistory();
    const { currentUser } = useSelector((state) => state.authUser);
    const {types: taskTypes} = useSelector(taskSelectors.taskCard);
    const [executorGroupsId, setExecutorGroupsId] = useState([]);
    const desktop = useContext(DesktopContext);
    const [otherParams, setOtherParams] = useState(otherParamsDefault)

    // Изменился шаблон
    const changedTemplate = useMemo(() => {
        return data.taskTypeTemplate?.id !== firstData.taskTypeTemplate?.id;
    }, [firstData.taskTypeTemplate, data.taskTypeTemplate]);

    const itIsTargetTask = parentEntity?.type_id === 11;

    const [errors, setErrors] = useState({});

    const isAdmin = CheckAccess(1, 'admin');
    const isControlSnab = CheckAccess(2, 'kontr1652444507');
    const allowTaskSupplier = CheckAccess(2, defaultPermissions.create_task_provider);

    const accessDeleteWatcher = isAdmin || (currentUser && Number(currentUser?.id ?? 0) === Number(data.manager?.id ?? 0));

    const onClickDescription = (event) => {
        const {target} = event;
        const localName = target?.localName ?? '';
        if (target && localName === 'a'){
            window.open(target.href);
        }
    };

    const showSelectType = useMemo(() => {
        const taskTypeId = Number(data.taskType?.id ?? 0);
        if ([11].includes(taskTypeId)) {
            return false;
        }
        return true;
    }, [data.taskType]);

    /**
     * Отображать выборку контрагента
     * @type {unknown}
     */
    const showClient = useMemo(() => {
        const taskTypeId = Number(data.taskType?.id ?? 0);
        if ([8, 11].includes(taskTypeId)) {
            return false;
        }
        return true;
    }, [data.taskType]);

    const showDeadline = useMemo(() => {
        const taskTypeId = Number(data.taskType?.id ?? 0);
        if ([11].includes(taskTypeId)) {
            return true;
        }
        return false;
    }, [data.taskType]);

    const excludeTypes = useMemo(() => {
        let result = [];
        const parentEntityType = parentEntity?.type_id ?? 0;
        if (!allowTaskSupplier) {
            result.push(6);
        }
        if (parentEntityType === 11) {
            result = [...result, ...taskTypes.filter(x => x.id !== 11).map(x => x.id)];
        } else {
            result = [...result, ...taskTypes.filter(x => x.id === 11).map(x => x.id)];
        }
        return result;
    }, [allowTaskSupplier, parentEntity, taskTypes]);

    const getNumberOfPurchasesByOrg = async () => {
        let result = null;
        const clientId = data.client?.id ?? null;
        if (clientId) {
            const {type: resultType, data: resultData} = await numberOfPurchasesByOrg({id: clientId});
            if (resultType === 'success') {
                result = resultData;
            }
        }
        setOtherParams((values) => {
            return {
                ...values,
                numberOfPurchasesByOrg: result,
            }
        });
    }

    const listTemplates = useMemo( () => {
        let templates = [];
        const selectedTypeId = data.taskType?.id ?? null;
        taskTypes.forEach(x => {
            if (excludeTypes.includes(x.id)) return true;
            if (selectedTypeId && x.id !== selectedTypeId) return true;
            templates = [...templates, ...x.templates]
        });
        if (+data.client?.segment_income?.id === 3) {
            templates = templates.filter(x => x.id !== 51);
        }
        if (!data.id && !isAdmin && !currentUser.crmGroups.some(x => x.id === 20)) {
            templates = templates.filter(x => x.id !== 36);
        }
        return templates;
    }, [excludeTypes, taskTypes, data.taskType, data.client, data.id, otherParams])

    const setData = (name, value) => {
        setDataHandler(name, value);
        ClearError({ errors, name: name, changeErrors: setErrors });
    };

    const onChangeDateStart = (value) => {
        const fieldName = 'dateStart';
        setData(fieldName, value);
    };

    const onChangeDeadline = (value) => {
        const fieldName = 'deadline';
        setData(fieldName, value);
    };

    const onChangeDescription = (event) => {
        const fieldName = 'description';
        setData(fieldName, event.target.value);
    };
    const onChangeTaskStatus = (value) => {
        const fieldName = 'taskStatus';
        setData(fieldName, value);
    };
    const onChangeTaskDoubleStatus = (value) => {
        const fieldName = 'taskDoubleStatus';
        setData(fieldName, value);
    };
    const onChangePriority = (value) => {
        const fieldName = 'priority';
        setData(fieldName, value);
    };
    const onChangeClient = (value) => {
        const fieldName = 'client';
        const clientIsDealer = !!getType({id: dealerTypeID}, value.types);

        const taskTypeId = data.taskType?.id ?? null;
        if (clientIsDealer && taskTypeId !== taskTypeIDForDealer && value?.id !== defaultClientIdForContent) {
            onChangeTaskType(getType({id: taskTypeIDForDealer}, taskTypes));
        }
        setData(fieldName, value);
    };

    const setNewOrganization = async (id) => {
        const data = {
            id,
            params: {
                expand: organizationExpand.join(','),
                fields: organizationFields.join(',')
            }
        }
        const {type: resultType, data: resultData} = await loadOrganizationData(data);
        if (resultType === 'success' && resultData) {
            onChangeClient(resultData);
            return true;
        }
        return true;
    }

    const setNewManager = async (id) => {
        const data = {
            manager_id: id,
            expand: managerExpand.join(','),
        }
        const {type: resultType, data: resultData} = await loadManagerData(data);
        if (resultType === 'success') {
            const manager = resultData[0] ?? null;
            if (manager) {
                setData('manager', manager);
                return true;
            }
        }
        return true;
    }

    const setManagerFromType = newTypeData => {
        const departments = newTypeData.departments ?? []
        const typeData = getTypeFromManagerGroups(data.manager, taskTypes)
        const mainUserId = getMainUserIdFromGroup(departments[0] ?? null)

        const shouldSetMain = mainUserId && typeData?.id !== newTypeData.id
        if (shouldSetMain) return setNewManager(mainUserId)

        const shouldSetSelf = currentUser.id && newTypeData.id === 12  // Тип: "Простой"
        if (shouldSetSelf) return setNewManager(currentUser.id)

        setData('manager', null)
        return true
    }

    const setTemplatesAndOther = (newType, withTemplate = true) => {
        const newTypeData = getType(newType, taskTypes);
        const templates = newTypeData?.templates ?? [];
        if (withTemplate) {
            onChangeTaskTypeTemplate(templates.length === 1 ? templates[0] : null);
        }
        if (!newTypeData) {
            setExecutorGroupsId([]);
            return true;
        }
        setExecutorGroupsId(getUserGroups(newTypeData));
        if (newTypeData.id === 8) {
            setNewOrganization(defaultClientIdForContent);
        }
        if (withTemplate) {
            setManagerFromType(newTypeData);
        }
        return true;
    }

    const setRelationFromTemplate = (newValue) => {
        if (!newValue) return false;
        const parentType = getParentType(taskTypes, newValue);
        if (!parentType) return false;
        const departments = parentType.departments;
        if (departments.length) {
            let firstDep = departments[0];
            if ([52, 50].includes(newValue.id)) {
                const findDep = departments.find(x => x.id === 26) ?? null;
                if (findDep) {
                    firstDep = findDep;
                }
            }
            if ([56].includes(newValue.id)) {
                firstDep = null;
            }
            if (firstDep) {
                setData('manager', {
                    ...firstDep,
                    isGroup: true,
                    id: `G-${firstDep.id}`
                })
            }
        }
        setTaskType(parentType);
        setTemplatesAndOther(parentType, false);
    }

    const setTaskType = (value) => {
        const fieldName = 'taskType';
        setData(fieldName, value);
    }

    const onChangeTaskType = (value) => {
        setTaskType(value);
        setTemplatesAndOther(value);
    };

    const onChangeBrand = (value) => {
        const fieldName = 'brand';
        setData(fieldName, value);
    };

    const onChangeProductType = (value) => {
        const fieldName = 'productType';
        setData(fieldName, value);
    };

    const onChangeName = (value) => {
        const fieldName = 'name';
        setData(fieldName, value);
    };

    const onChangeTaskTypeTemplate = (value) => {
        const fieldName = 'taskTypeTemplate';
        setData(fieldName, value);
        if (value) {
            onChangeName(value.name);
        }
        setRelationFromTemplate(value);
    };

    const onChangePriceSegment = (value) => {
        const fieldName = 'priceSegment';
        setData(fieldName, value);
    };

    const onChangeManager = (newManager) => {
        setData('manager', newManager);
        ClearError({ errors, name: 'manager', changeErrors: setErrors });

        if (itIsTargetTask) return false;
        if (!newManager) return false;
        const isGroup = newManager.isGroup ?? false;
        if (isGroup) {
            const typeData = getTypeFromManagerGroup(newManager, taskTypes);
            const departments = data.taskType?.departments ?? [];
            if (!departments.length && !typeData) return true;
            setTaskType(typeData);
            return true;
        }
        const typeData = getTypeFromManagerGroups(newManager, taskTypes);
        if (parentEntity?.type_id === 12) return true;
        if (typeData && data.taskType?.id !== typeData.id) {
            setTaskType(typeData);
        }
    };
    const onChangeResponsible = (value) => {
        const fieldName = 'responsible';
        setData(fieldName, value);
    };

    const onChangeAnalog = (value) => {
        const fieldName = 'analog';
        setData(fieldName, value);
    };

    const onChangeOnlyProfile = (value) => {
        const fieldName = 'onlyProfile';
        setData(fieldName, value);
    };

    const viewDoubleStatus = useMemo(() => {
        const taskTypeId = Number(data.taskType?.id ?? 0);
        const taskStatusId = Number(data.taskStatus?.id ?? 0);
        return taskTypeId === 5 && taskStatusId === 4 && isControlSnab;
    }, [data.taskType, data.taskStatus]);

    const onClickClient = () => {
        const link = getOrgLink(data.client);
        onClose();
        setTimeout(() => {
            history.push(link);
        }, 500);
    };

    const onSubmitForm = (event) => {
        event.preventDefault();
        const errorsData = validateForm(data, {showClient});
        setErrors(errorsData);
        if (Object.keys(errorsData).length > 0) return false;
        submitForm();
    };

    const callbackLoadData = () => {
        setExecutorGroupsId(getUserGroups(data.taskType));
    }

    const isDisabledExecutor = useMemo(() => {
        const template = data.taskTypeTemplate;
        if (!template) return false;
        if (template.id === 56) return false;
        if (template.id === 53) return true;
        const taskType = data.taskType;
        const userGroupsId = currentUser.crmGroups.map(x => x.id);
        if (!taskType) return false;
        const typeClassId = taskType.typeClass[0]?.id ?? null;
        if (!typeClassId) return false;
        const departmentsId = taskType.departments.map(x => x.id);
        if ([2,3,6].includes(typeClassId)) {
            if (![6,3,26].filter(x => userGroupsId.includes(x)).length) {
                return true;
            }
        }
        return false;
    }, [data.taskType, data.taskTypeTemplate]);

    useEffect(() => {
        setDataHandler('placeholder', data.taskTypeTemplate?.description ?? '');
    }, [data.taskTypeTemplate]);

    const templateFields = useMemo(() => {
        const templateId = data.taskTypeTemplate?.id ?? null;
        let fieldsComponent = null;
        switch (templateId) {
            case 37:
            case 38:
            case 39:
                fieldsComponent = Template37;
                break;
            case 48:
                fieldsComponent = Template48;
                break;
            case 49:
                fieldsComponent = Template49;
                break;
            case 50:
                fieldsComponent = Template50;
                break;
            case 51:
                fieldsComponent = Template51;
                break;
            case 52:
                fieldsComponent = Template52;
                break;
            case 53:
                fieldsComponent = Template53;
                break;
        }
        if (fieldsComponent === null) return <></>;
        const FieldsComponent = fieldsComponent;
        return <FieldsComponent />;
    }, [data.taskTypeTemplate]);

    useEffect(() => {
        return () => {
            setOtherParams(otherParamsDefault);
            setErrors({});
        }
    }, [])


    const btnRef = useRef()

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                if (event.target.classList.value.includes('MuiDialog-container MuiDialog-scrollPaper')) {
                    btnRef.current.click()
                }
            }
        };

        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [modalRef.current]);

    useEffect(() => {
        if (data.id) {
            callbackLoadData();
        }
    }, [data.id]);

    useEffect(() => {
        getNumberOfPurchasesByOrg();
    }, [data.client]);

    // useEffect(() => {
    //     setRelationFromTemplate(data.taskTypeTemplate);
    // }, []);

    return (
        <>
            <ModalCreateTaskContext.Provider value={{
                data,
                setData,
                taskTypes,
                onChangeList: {
                    onChangeAnalog,
                    onChangeOnlyProfile,
                    onChangeName,
                    onChangeTaskType,
                    onChangeTaskTypeTemplate,
                    onChangeBrand,
                    onChangeProductType,
                    onChangePriceSegment,
                    onChangeClient,
                    onChangeResponsible,
                    onChangeManager,
                    onChangePriority,
                    onChangeTaskStatus,
                    onChangeDateStart,
                    onChangeTaskDoubleStatus,
                    isDisabledExecutor,
                    onChangeDeadline
                },
                changedData: {
                    taskTypeTemplate: changedTemplate,
                },
                onClickClient,
                executorGroupsId,
                errors,
                parentEntity
            }}>
                <form onSubmit={onSubmitForm} className="tooltip-center-bottom">
                    <Grid container columnSpacing={2}>
                        <Default
                            showClient={showClient}
                            showDeadline={showDeadline}
                            excludeTypes={excludeTypes}
                            listTemplates={listTemplates}
                            viewDoubleStatus={viewDoubleStatus}
                            showSelectType={showSelectType}
                        />
                        {templateFields}
                    </Grid>
                    <div className="row">
                        <div className="col-12">
                            <FormGroup>
                                <div className="mt-0">
                                    <ContentEditable
                                        className={`form-control contenteditable ${css.contentEdit}`}
                                        style={{minHeight: '76px'}} //, width: 'auto'
                                        tagName="div"
                                        html={data.description}
                                        onChange={onChangeDescription}
                                        placeholder={data.placeholder}
                                        onClick={onClickDescription}
                                    />
                                </div>
                                <FormError errors={errors} name="description" />
                            </FormGroup>
                        </div>
                    </div>
                    {!!data.id && (
                        <div className="row mb-4">
                            <div className="col-12">
                                <CommentsContainer id={data.id} />
                            </div>
                        </div>
                    )}
                    <div className="row align-items-center">
                        <div className="col-12 col-md">
                            {!!data.id && (
                                <Watchers
                                    id={data.id}
                                    accessDeleteWatcher={accessDeleteWatcher}
                                />
                            )}
                        </div>
                        <div className="col-12 col-md-auto">
                            <div className="text-right">
                                <button
                                    type="submit"
                                    ref={btnRef}
                                    disabled={disabledSubmit}
                                    className={`btn btn-primary ${!desktop ? 'w-100' : ''}`}
                                >
                                    {/* eslint-disable-next-line no-nested-ternary */}
                                    {!disabledSubmit ? 'Сохранить и закрыть' : 'Выполнение...'}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </ModalCreateTaskContext.Provider>
        </>
    );
};
export default Content;
