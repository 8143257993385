/* eslint-disable import/no-cycle */
import {
    WEBSOCKET_BEGIN_RECONNECT,
    WEBSOCKET_BROKEN,
    WEBSOCKET_CLOSED,
    WEBSOCKET_CONNECT,
    WEBSOCKET_DISCONNECT,
    WEBSOCKET_ERROR,
    WEBSOCKET_MESSAGE,
    WEBSOCKET_OPEN,
    WEBSOCKET_RECONNECTED,
    WEBSOCKET_SEND,
} from '@giantmachines/redux-websocket/dist';
import {WEBSOCKET_PREFIX} from '../constants/defaultValues';

/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const CHANGE_LOADER = 'CHANGE_LOADER';
export const CHANGE_MODE_WATCH = 'CHANGE_MODE_WATCH';
export const CHANGE_MODE_WATCH_FETCH = 'CHANGE_MODE_WATCH_FETCH';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_TOKEN = 'SET_TOKEN';
export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_INFO_LOADING = 'GET_USER_INFO/LOADING';
export const GET_USER_INFO_RELOAD = 'GET_USER_INFO/RELOAD';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_SET_OPEN_LEVEL = 'MENU_SET_OPEN_LEVEL';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* TODOAPP */
export const TODO_GET_LIST = 'TODO_GET_LIST';
export const TODO_GET_LIST_SUCCESS = 'TODO_GET_LIST_SUCCESS';
export const TODO_GET_LIST_ERROR = 'TODO_GET_LIST_ERROR';
export const TODO_GET_LIST_WITH_FILTER = 'TODO_GET_LIST_WITH_FILTER';
export const TODO_GET_LIST_WITH_ORDER = 'TODO_GET_LIST_WITH_ORDER';
export const TODO_GET_LIST_SEARCH = 'TODO_GET_LIST_SEARCH';
export const TODO_ADD_ITEM = 'TODO_ADD_ITEM';
export const TODO_ADD_ITEM_SUCCESS = 'TODO_ADD_ITEM_SUCCESS';
export const TODO_ADD_ITEM_ERROR = 'TODO_ADD_ITEM_ERROR';
export const TODO_SELECTED_ITEMS_CHANGE = 'TODO_SELECTED_ITEMS_CHANGE';

/* CHAT APP */
export const CHAT_GET_CONTACTS = 'CHAT_GET_CONTACTS';
export const CHAT_GET_CONTACTS_SUCCESS = 'CHAT_GET_CONTACTS_SUCCESS';
export const CHAT_GET_CONTACTS_ERROR = 'CHAT_GET_CONTACTS_ERROR';
export const CHAT_GET_CONVERSATIONS = 'CHAT_GET_CONVERSATIONS';
export const CHAT_GET_CONVERSATIONS_SUCCESS = 'CHAT_GET_CONVERSATIONS_SUCCESS';
export const CHAT_GET_CONVERSATIONS_ERROR = 'CHAT_GET_CONVERSATIONS_ERROR';
export const CHAT_ADD_MESSAGE_TO_CONVERSATION =
  'CHAT_ADD_MESSAGE_TO_CONVERSATION';
export const CHAT_CREATE_CONVERSATION = 'CHAT_CREATE_CONVERSATION';
export const CHAT_SEARCH_CONTACT = 'CHAT_SEARCH_CONTACT';
export const CHAT_CHANGE_CONVERSATION = 'CHAT_CHANGE_CONVERSATION';

/* SURVEY LIST APP */
export const SURVEY_LIST_GET_LIST = 'SURVEY_LIST_GET_LIST';
export const SURVEY_LIST_GET_LIST_SUCCESS = 'SURVEY_LIST_GET_LIST_SUCCESS';
export const SURVEY_LIST_GET_LIST_ERROR = 'SURVEY_LIST_GET_LIST_ERROR';
export const SURVEY_LIST_GET_LIST_WITH_FILTER =
  'SURVEY_LIST_GET_LIST_WITH_FILTER';
export const SURVEY_LIST_GET_LIST_WITH_ORDER =
  'SURVEY_LIST_GET_LIST_WITH_ORDER';
export const SURVEY_LIST_GET_LIST_SEARCH = 'SURVEY_LIST_GET_LIST_SEARCH';
export const SURVEY_LIST_ADD_ITEM = 'SURVEY_LIST_ADD_ITEM';
export const SURVEY_LIST_ADD_ITEM_SUCCESS = 'SURVEY_LIST_ADD_ITEM_SUCCESS';
export const SURVEY_LIST_ADD_ITEM_ERROR = 'SURVEY_LIST_ADD_ITEM_ERROR';
export const SURVEY_LIST_SELECTED_ITEMS_CHANGE =
  'SURVEY_LIST_SELECTED_ITEMS_CHANGE';

/* SURVEY DETAIL APP */
export const SURVEY_GET_DETAILS = 'SURVEY_GET_DETAILS';
export const SURVEY_GET_DETAILS_SUCCESS = 'SURVEY_GET_DETAILS_SUCCESS';
export const SURVEY_GET_DETAILS_ERROR = 'SURVEY_GET_DETAILS_ERROR';
export const SURVEY_DELETE_QUESTION = 'SURVEY_DELETE_QUESTION';
export const SURVEY_SAVE = 'SURVEY_SAVE';

/* FINANCE */
export const DEAL_LOAD = 'DEAL/LOAD';
export const DEAL_LIST__ACTIVE_TAB = 'DEAL/LIST__ACTIVE_TAB';
export const DEAL_COMMENT_LOAD = 'DEAL/COMMENT_LOAD';
export const DEAL_COMMENTS = 'DEAL/COMMENTS';
export const DEAL_LOAD_FILTERS = 'DEAL/LOAD_FILTERS';
export const DEAL_FILTERS = 'DEAL/FILTERS';
export const FINANCE_CURRENCY_SET = 'FINANCE/CURRENCY/SET';
export const FINANCE_CURRENCY_GET = 'FINANCE/CURRENCY/GET';
export const DEAL_CARD_DATA_FETCH = 'DEAL/CARD/DATA/FETCH';
export const DEAL_CARD_DATA_RELOAD = 'DEAL/CARD/DATA/RELOAD';
export const DEAL_CARD_DATA_SET = 'DEAL/CARD/DATA/SET';
export const DEAL_CARD_DATA_ERROR = 'DEAL/CARD/DATA/ERROR';
export const DEAL_CARD_LOGISTICS_FETCH = 'DEAL/CARD/LOGISTICS/FETCH';
export const DEAL_CARD_LOGISTICS_RELOAD = 'DEAL/CARD/LOGISTICS/RELOAD';
export const DEAL_CARD_LOGISTICS_SET = 'DEAL/CARD/LOGISTICS/SET';
export const DEAL_CARD_LOGISTICS_LOADING = 'DEAL/CARD/LOGISTICS/LOADING';
export const DEAL_CARD_LOGISTICS_VIEW_SET = 'DEAL/CARD/LOGISTICS/VIEW/SET';
export const DEAL_CARD_PAYMENTS_FETCH = 'DEAL/CARD/PAYMENTS/FETCH';
export const DEAL_CARD_PAYMENTS_RELOAD = 'DEAL/CARD/PAYMENTS/RELOAD';
export const DEAL_CARD_PAYMENTS_SET = 'DEAL/CARD/PAYMENTS/SET';
export const DEAL_CARD_PAYMENTS_LOADING = 'DEAL/CARD/PAYMENTS/LOADING';
export const DEAL_CARD_DOCUMENTS_FETCH = 'DEAL/CARD/DOCUMENTS/FETCH';
export const DEAL_CARD_DOCUMENTS_RELOAD = 'DEAL/CARD/DOCUMENTS/RELOAD';
export const DEAL_CARD_DOCUMENTS_SELECTED_ADD = 'DEAL/CARD/DOCUMENTS/SELECTED/ADD';
export const DEAL_CARD_DOCUMENTS_SELECTED_REMOVE = 'DEAL/CARD/DOCUMENTS/SELECTED/REMOVE';
export const DEAL_CARD_DOCUMENTS_SELECTED_CLEAR = 'DEAL/CARD/DOCUMENTS/SELECTED/CLEAR';
export const DEAL_CARD_DOCUMENTS_SET = 'DEAL/CARD/DOCUMENTS/SET';
export const DEAL_CARD_DOCUMENTS_LOADING = 'DEAL/CARD/DOCUMENTS/LOADING';
export const DEAL_CARD_RECIPIENTS_FETCH = 'DEAL/CARD/RECIPIENTS/FETCH';
export const DEAL_CARD_RECIPIENTS_RELOAD = 'DEAL/CARD/RECIPIENTS/RELOAD';
export const DEAL_CARD_RECIPIENTS_SET = 'DEAL/CARD/RECIPIENTS/SET';
export const DEAL_CARD_RECIPIENTS_LOADING = 'DEAL/CARD/RECIPIENTS/LOADING';
export const DEAL_CARD_COMMENTS_FETCH = 'DEAL/CARD/COMMENTS/FETCH';
export const DEAL_CARD_COMMENTS_RELOAD = 'DEAL/CARD/COMMENTS/RELOAD';
export const DEAL_CARD_COMMENTS_SET = 'DEAL/CARD/COMMENTS/SET';
export const DEAL_CARD_COMMENTS_LOADING = 'DEAL/CARD/COMMENTS/LOADING';
export const DEAL_CARD_LOGISTICS_VIEW_COMMENTS_FETCH = 'DEAL/CARD/LOGISTICS/VIEW/COMMENTS/FETCH';
export const DEAL_CARD_LOGISTICS_VIEW_COMMENTS_RELOAD = 'DEAL/CARD/LOGISTICS/VIEW/COMMENTS/RELOAD';
export const DEAL_CARD_LOGISTICS_VIEW_COMMENTS_SET = 'DEAL/CARD/LOGISTICS/VIEW/COMMENTS/SET';
export const DEAL_CARD_LOGISTICS_VIEW_COMMENTS_COUNT_SET = 'DEAL/CARD/LOGISTICS/VIEW/COMMENTS/COUNT/SET';
export const DEAL_CARD_LOGISTICS_VIEW_COMMENTS_LOADING = 'DEAL/CARD/LOGISTICS/VIEW/COMMENTS/LOADING';
export const DEAL_LIST_FETCH_DATA = 'DEAL/LIST/DATA/FETCH';
export const DEAL_LIST_SET_DATA = 'DEAL/LIST/DATA/SET';
export const DEAL_LIST_LOADING = 'DEAL/LIST/DATA/LOADING';
export const DEAL_LIST_RELOAD = 'DEAL/LIST/DATA/RELOAD';
export const DEAL_CARD_PAYMENTS_MODAL_DELIVERY_CONFIRM = 'DEAL/CARD/PAYMENTS/MODAL/DELIVERY/CONFIRM';
export const DEAL_CARD_PAYMENTS_MODAL_DELIVERY_CANCEL = 'DEAL/CARD/PAYMENTS/MODAL/DELIVERY/CANCEL';
export const DEAL_CARD_PAYMENTS_MODAL_DOCUMENTS_CONFIRM = 'DEAL/CARD/PAYMENTS/MODAL/DOCUMENTS/CONFIRM';
export const DEAL_CARD_PAYMENTS_MODAL_DOCUMENTS_CANCEL = 'DEAL/CARD/PAYMENTS/MODAL/DOCUMENTS/CANCEL';
export const DEAL_CARD_PAYMENTS_MODAL_RECEIVED_CONFIRM = 'DEAL/CARD/PAYMENTS/MODAL/RECEIVED/CONFIRM';
export const DEAL_CARD_PAYMENTS_MODAL_RECEIVED_CANCEL = 'DEAL/CARD/PAYMENTS/MODAL/RECEIVED/CANCEL';
export const DEAL_CARD_PAYMENTS_MODAL_CONTRACT_CONFIRM = 'DEAL/CARD/PAYMENTS/MODAL/CONTRACT/CONFIRM';
export const DEAL_CARD_PAYMENTS_MODAL_CONTRACT_CANCEL = 'DEAL/CARD/PAYMENTS/MODAL/CONTRACT/CANCEL';
export const DEAL_CARD_PAYMENTS_MODAL_SCORE_CONFIRM = 'DEAL/CARD/PAYMENTS/MODAL/SCORE/CONFIRM';
export const DEAL_CARD_PAYMENTS_MODAL_SCORE_CANCEL = 'DEAL/CARD/PAYMENTS/MODAL/SCORE/CANCEL';
export const DEAL_CARD_MODAL_LOGISTICS_CREATE = 'DEAL/CARD/MODAL/LOGISTICS/CREATE';
export const DEAL_CARD_MODAL_LOGISTICS_BREAK_PRODUCT = 'DEAL/CARD/MODAL/LOGISTICS/BREAK_PRODUCT';
export const DEAL_CARD_FILES_FETCH = 'DEAL/CARD/FILES/FETCH';
export const DEAL_CARD_FILES_RELOAD = 'DEAL/CARD/FILES/RELOAD';
export const DEAL_CARD_FILES_SET = 'DEAL/CARD/FILES/SET';
export const DEAL_CARD_FILES_LOADING = 'DEAL/CARD/FILES/LOADING';
export const DEAL_CARD_PAYMENTS_MODAL_BREAK_PRODUCT = 'DEAL/CARD/PAYMENTS/MODAL/BREAK_PRODUCT';
export const DEAL_CARD_PAYMENTS_MODAL_UPDATE_PAYMENT = 'DEAL/CARD/PAYMENTS/MODAL/UPDATE_PAYMENT';

/* MANAGERS */
export const MANAGERS_PHOTO_LOAD = 'MANAGERS/PHOTO_LOAD'; // идет загрузка фото
export const MANAGERS_FETCH_PHOTO = 'MANAGERS/FETCH_PHOTO'; // загрузить фото
export const MANAGERS_CHECK_PHOTO = 'MANAGERS/CHECK_PHOTO'; // проверить, есть ли фото в state
export const MANAGERS_PHOTO = 'MANAGERS/PHOTO'; // массив фото
export const MANAGERS_LIST = 'MANAGERS/LIST'; // массив менеджеров

/* STATISTICS DEALS */
export const STATISTICS_DEAL = 'STATISTICS/DEAL';
export const STATISTICS_DEAL_LOADING = 'STATISTICS/DEAL/LOADING';
export const STATISTICS_DEAL_DATA = 'STATISTICS/DEAL/DATA';

/* ORDER */
export const CRM_ORDER_COUNT_NEW = 'CRM/ORDER/COUNT/NEW'; // кол-во новых заказов
export const CRM_ORDER_COUNT_YOU_RESPONSIBLE = 'CRM/ORDER/COUNT/YOU_RESPONSIBLE'; // кол-во новых заказов
export const CRM_ORDER_LOADING = 'CRM/ORDER/LOADING'; // загрузка данных
export const CRM_ORDER_FETCH_DATA = 'CRM/ORDER/FETCH_DATA'; // запрос на получение данныз заказа
export const CRM_ORDERS_FETCH_DATA = 'CRM/ORDERS/FETCH_DATA'; // запрос на получение заказов
export const CRM_ORDERS_RELOAD = 'CRM/ORDERS/RELOAD'; // запрос на получение заказов
export const CRM_ORDER_SET_DATA = 'CRM/ORDER/SET_DATA'; // сохранение данных заказа
export const CRM_ORDER_SET_ERROR = 'CRM/ORDER/SET_ERROR'; // ошибки при получении данных
export const CRM_ORDERS_SET_DATA = 'CRM/ORDERS/SET_DATA'; // сохранение заказов
export const CRM_ORDER_RELOAD = 'CRM/ORDER/RELOAD'; // обновить данные
export const CRM_ORDERS_SET_PARAMS = 'CRM/ORDERS/SET_PARAMS'; // обновить данные
export const CRM_ORDER_DISABLED = 'CRM/ORDER/DISABLED';
export const CRM_ORDER_CLIENT_FETCH = 'CRM/ORDER/CLIENT/FETCH';
export const CRM_ORDER_CLIENT_SET = 'CRM/ORDER/CLIENT/SET';
export const CRM_ORDER_CLIENT_LOADING = 'CRM/ORDER/CLIENT/LOADING';
export const CRM_ORDER_CLIENT_RELOAD = 'CRM/ORDER/CLIENT/RELOAD';
export const ORDER_CARD_COMMENTS_FETCH = 'ORDER/CARD/COMMENTS/FETCH';
export const ORDER_CARD_COMMENTS_RELOAD = 'ORDER/CARD/COMMENTS/RELOAD';
export const ORDER_CARD_COMMENTS_SET = 'ORDER/CARD/COMMENTS/SET';
export const ORDER_CARD_COMMENTS_LOADING = 'ORDER/CARD/COMMENTS/LOADING';


export const CRM_ORDER_SEARCH_PRODUCT = 'CRM/ORDERS/SEARCH_PRODUCT'; // поиск продуктов
export const CRM_ORDER_SEARCHING_PRODUCT = 'CRM/ORDERS/SEARCHING_PRODUCT'; // выполнение поиска
export const CRM_ORDER_FOUND_PRODUCTS = 'CRM/ORDERS/FOUND_PRODUCT'; // найденные продукты
export const CRM_ORDER_FETCH_PRODUCTS = 'CRM/ORDERS/FETCH_PRODUCT'; // запрос продуктов заказа
export const CRM_ORDER_FETCH_PRODUCTS_LOADING =
  'CRM/ORDERS/FETCH_PRODUCT_LOADING'; // выполнение запроса продуктов
export const CRM_ORDER_FETCH_PRODUCTS_RELOAD =
  'CRM/ORDERS/FETCH_PRODUCT/RELOAD';
export const CRM_ORDER_SET_PRODUCTS = 'CRM/ORDERS/SET_PRODUCT'; // сохранение продуктов заказа
export const CRM_ORDER_REMOVE_PRODUCT = 'CRM/ORDERS/REMOVE_PRODUCT'; // удаление продукта заказа
export const CRM_ORDER_ADD_EXIST_PRODUCT = 'CRM/ORDERS/ADD_EXIST_PRODUCT'; // добавление продукта в таблицу из существующих
export const CRM_ORDER_ADD_EXIST_PRODUCT_ERRORS =
  'CRM/ORDERS/ADD_EXIST_PRODUCT_ERRORS';
export const CRM_ORDER_EDITABLE_PRODUCT = 'CRM/ORDERS/EDITABLE_PRODUCT';
export const CRM_ORDER_SELECTED_PRODUCTS = 'CRM/ORDERS/SELECTED_PRODUCTS';
export const CRM_ORDER_SET_EDITABLE_PRODUCT = 'CRM/ORDERS/SET_EDITABLE_PRODUCT';

export const CRM_ORDER_FETCH_DOCUMENTS = 'CRM/ORDERS/FETCH_DOCUMENTS';
export const CRM_ORDER_SET_DOCUMENTS = 'CRM/ORDERS/SET_DOCUMENTS';
export const CRM_ORDER_LOADING_DOCUMENTS = 'CRM/ORDERS/LOADING_DOCUMENTS';
export const CRM_ORDER_RELOAD_DOCUMENTS = 'CRM/ORDERS/RELOAD_DOCUMENTS';
export const CRM_ORDER_SELECTED_DOCUMENT = 'CRM/ORDERS/SELECTED_DOCUMENT';
export const CRM_ORDER_CLIENT_UPDATE_CONTACT =
  'CRM/ORDERS/CLIENT/UPDATE_CONTACT';
export const CRM_ORDER_SELECTED_TASKS = 'CRM/ORDER/SELECTED_TASKS';
export const CRM_ORDER_FILE_REMOVE = 'CRM/ORDER/FILE/REMOVE';
export const CRM_ORDER_PRODUCTS_ADDED_ID = 'CRM/ORDER/PRODUCTS/ADDED_ID';
export const CRM_ORDER_DOCUMENT_FILES_FETCH = 'CRM/ORDER/DOCUMENT_FILES/FETCH';
export const CRM_ORDER_DOCUMENT_FILES_SET = 'CRM/ORDER/DOCUMENT_FILES/SET';
export const CRM_ORDER_DOCUMENT_FILES_LOADING = 'CRM/ORDER/DOCUMENT_FILES/LOADING';
export const CRM_ORDER_DOCUMENT_FILES_RELOAD = 'CRM/ORDER/DOCUMENT_FILES/RELOAD';
export const CRM_ORDER_DOCUMENT_FILES_REMOVE = 'CRM/ORDER/DOCUMENT_FILES/REMOVE';
export const CRM_ORDER_PRODUCT_SUPPLIER_SET = 'CRM/ORDER/PRODUCT/SUPPLIER/SET';
export const CRM_ORDER_MODAL_CANCEL_SHOW = 'CRM/ORDER/MODAL/CANCEL/SHOW';
export const CRM_ORDER_MODAL_EDIT_PRODUCT_SHOW = 'CRM/ORDER/MODAL/EDIT_PRODUCT/SHOW';

export const CRM_ORDER_MODAL_ABOUT_PRODUCT_SHOW = 'CRM/ORDER/MODAL/ABOUT_PRODUCT/SHOW';
export const CRM_ORDER_MODAL_LOGS_PRODUCT_SHOW = 'CRM/ORDER/MODAL/LOGS_PRODUCT/SHOW'
export const CRM_ORDER_MODAL_ANALOG_PRODUCT_SHOW = 'CRM/ORDER/MODAL/ANALOG_PRODUCT/SHOW'
export const CRM_ORDER_MODAL_SIMILARS_PRODUCT_SHOW = 'CRM/ORDER/MODAL/SIMILARS_PRODUCT/SHOW'
export const CRM_ORDER_MODAL_SCORE_PRODUCT_SHOW = 'CRM/ORDER/MODAL/SCORE_PRODUCT/SHOW'
export const CRM_ORDER_MODAL_ACTION_AUTO_PERSENT_SHOW = 'CRM/ORDER/MODAL/ACTION_AUTO_PERSENT/SHOW'
export const CRM_ORDER_MODAL_ADD_PRODUCT_SHOW = 'CRM/ORDER/MODAL/ADD_PRODUCT/SHOW'
export const CRM_ORDER_MODAL_SEARCH_PRODUCT_SHOW = 'CRM/ORDER/MODAL/SEARCH_PRODUCT/SHOW'
export const CRM_ORDER_MODAL_SORTING_LISTING_SHOW = 'CRM/ORDER/MODAL/SORTING_LISTING/SHOW'
export const CRM_ORDER_MODAL_FILTER_LISTING_SHOW = 'CRM/ORDER/MODAL/FILTER_LISTING/SHOW'
export const CRM_ORDER_MODAL_EDIT_PRODUCT_DATA = 'CRM/ORDER/MODAL/EDIT_PRODUCT/DATA';
export const CRM_ORDER_MODAL_MOVE_DOCUMENT_SHOW = 'CRM/ORDER/MODAL/MOVE_DOCUMENT/SHOW';
export const CRM_ORDER_MODAL_SEARCH_DOCUMENT_SHOW = 'CRM/ORDER/MODAL/SEARCH_DOCUMENT/SHOW';
export const CRM_ORDER_MODAL_CREATE_DOCUMENT_SHOW = 'CRM/ORDER/MODAL/CREATE_DOCUMENT/SHOW';
export const CRM_ORDER_MODAL_MOVE_DOCUMENT_DATA = 'CRM/ORDER/MODAL/MOVE_DOCUMENT/DATA';
export const CRM_ORDER_MODAL_MASS_EDIT_PRODUCT_SHOW = 'CRM/ORDER/MODAL/MASS_EDIT_PRODUCT/SHOW';
export const CRM_ORDER_MODAL_PRODUCT_RELATED_SHOW = 'CRM/ORDER/MODAL/PRODUCT_RELATED/SHOW';

export const CRM_ORDER_FEEDBACK_FETCH_DATA = 'CRM/ORDER/FEEDBACK/FETCH_DATA';
export const CRM_ORDER_FEEDBACK_LOADING = 'CRM/ORDER/FEEDBACK/LOADING';
export const CRM_ORDER_FEEDBACK_RELOAD = 'CRM/ORDER/FEEDBACK/RELOAD';
export const CRM_ORDER_FEEDBACK_SET = 'CRM/ORDER/FEEDBACK/SET';

export const ORDER_CARD_FEEDBACK_FETCH = 'ORDER/CARD/FEEDBACK/FETCH';
export const ORDER_CARD_FEEDBACK_RELOAD = 'ORDER/CARD/FEEDBACK/RELOAD';
export const ORDER_CARD_FEEDBACK_SET = 'ORDER/CARD/FEEDBACK/SET';
export const ORDER_CARD_FEEDBACK_LOADING = 'ORDER/CARD/FEEDBACK/LOADING';

/* CLIENT */
export const CRM_CLIENTS_FETCH_DATA = 'CRM/CLIENTS/FETCH_DATA'; // запрос на получение клиентов
export const CRM_CLIENTS_SET_DATA = 'CRM/CLIENTS/SET_DATA'; // сохранение клиентов
export const CRM_CLIENTS_CHANGE_LOADING = 'CRM/CLIENTS/CHANGE_LOADING'; // флаг загрузки данных
export const CRM_CLIENT_FETCH_DATA = 'CRM/CLIENT/FETCH_DATA';
export const CRM_CLIENT_SET_DATA = 'CRM/CLIENT/SET_DATA';
export const CRM_CLIENT_SET_DATA_FORM = 'CRM/CLIENT/SET_DATA/FORM';
export const CRM_CLIENT_LOAD_LOADING = 'CRM/CLIENT/LOAD_LOADING';
export const CRM_CLIENT_SET_ERRORS = 'CRM/CLIENT/SET_ERRORS';
export const CRM_CLIENT_RELOAD = 'CRM/CLIENT/RELOAD';
export const CRM_CLIENT_CHANGE = 'CRM/CLIENT/CHANGE';
export const CRM_CLIENT_CHANGE_SUCCESS = 'CRM/CLIENT/CHANGE/SUCCESS';
export const CRM_CLIENT_CHANGE_LOADING = 'CRM/CLIENT/CHANGE/LOADING';
export const CRM_CONTACT_CHANGE_CLIENT = 'CRM/CONTACT/CHANGE/CLIENT';
export const CRM_CONTACT_COPY_TO_CLIENT = 'CRM/CONTACT/COPY/TO_CLIENT';
export const CRM_CLIENT_ORDERS_FETCH = 'CRM/CLIENT/ORDERS/FETCH';
export const CRM_CLIENT_ORDERS_SET = 'CRM/CLIENT/ORDERS/SET';
export const CRM_CLIENT_ORDERS_LOADING = 'CRM/CLIENT/ORDERS/LOADING';
export const CRM_CLIENT_REMOVE_CONTACT = 'CRM/CLIENT/REMOVE_CONTACT';
export const CRM_CLIENT_REMOVE_CONTACT_SUCCESS = 'CRM/CLIENT/REMOVE_CONTACT/SUCCESS';
export const CRM_CLIENT_CARD_COUNT_ENTITY = 'CRM/CLIENT/CARD/COUNT_ENTITY';
export const CRM_CLIENT_MODAL_CREATE_CLIENT_STATUS = 'CRM/CLIENT/MODAL/CREATE_CLIENT/STATUS';
export const CRM_CLIENT_MODAL_CREATE_DEALER_STATUS = 'CRM/CLIENT/MODAL/CREATE_DEALER/STATUS';
export const CRM_CLIENT_SET_FILTER_VALUE = 'CRM/CLIENT/SET/FILTER/VALUE';
export const CRM_CLIENT_SET_FILTER_RANGE = 'CRM/CLIENT/SET/FILTER/RANGE';

/* TASK */
export const CRM_TASKS_FETCH_DATA = 'CRM/TASKS/FETCH_DATA';
export const CRM_TASKS_SET_DATA = 'CRM/TASKS/SET_DATA';
export const CRM_TASKS_LOADING = 'CRM/TASKS/LOADING';
export const CRM_TASKS_RELOAD = 'CRM/TASKS/RELOAD';
export const CRM_TASK_FETCH_DATA = 'CRM/TASK/FETCH_DATA';
export const CRM_TASK_SET_DATA = 'CRM/TASK/SET_DATA';
export const CRM_TASK_LOADING = 'CRM/TASK/LOADING';
export const CRM_TASK_SET_ERRORS = 'CRM/TASK/SET_ERRORS';
export const CRM_TASK_CHANGE = 'CRM/TASK/CHANGE';
export const CRM_TASK_CHANGE_SUCCESS = 'CRM/TASK/CHANGE/SUCCESS';
export const CRM_TASK_CHANGE_LOADING = 'CRM/TASK/CHANGE/LOADING';
export const CRM_TASK_CREATE = 'CRM/TASK/CREATE';
export const CRM_TASK_CREATE_SUCCESS = 'CRM/TASK/CREATE/SUCCESS';
export const CRM_TASK_CREATE_LOADING = 'CRM/TASK/CREATE/LOADING';

export const CRM_TASK_COMMENTS_SET = 'CRM/TASK/COMMENTS/SET';
export const CRM_TASK_COMMENTS_LOADING = 'CRM/TASK/COMMENTS/LOADING';
export const CRM_TASK_COMMENTS_RELOAD = 'CRM/TASK/COMMENTS/RELOAD';
export const CRM_TASKS_COUNT_NEW = 'CRM/TASKS/COUNT/NEW';
export const CRM_TASKS_COUNT_OPENED = 'CRM/TASKS/COUNT/OPENED';
export const CRM_TASKS_COUNT_TO_ENGINEER = 'CRM/TASKS/COUNT/ENGINEER';
export const CRM_TASKS_COUNT_NEW_COMMENT = 'CRM/TASKS/COUNT/NEW_COMMENT';

/* DIRECTORY */
export const DIRECTORY_COUNTRY = 'DIRECTORY_COUNTRY';
export const DIRECTORY_CITY = 'DIRECTORY_CITY';

/* Notification */
export const NOTIFICATION_FETCH_LIST = 'NOTIFICATION/FETCH_LIST';
export const NOTIFICATION_SET_LIST = 'NOTIFICATION/SET_LIST';
export const NOTIFICATION_LOADING = 'NOTIFICATION/LOADING';
export const NOTIFICATION_VIEWED = 'NOTIFICATION/VIEWED';

/* COMMENT */
export const COMMENTS_FETCH = 'COMMENTS/FETCH';
export const COMMENTS_SET = 'COMMENTS/SET';
export const COMMENT_CHANGE = 'COMMENT/CHANGE';
export const COMMENT_CHANGE_SUCCESS = 'COMMENT/CHANGE/SUCCESS';
export const COMMENT_CHANGE_LOADING = 'COMMENT/CHANGE/LOADING';
export const COMMENT_CREATE = 'COMMENT/CREATE';
export const COMMENT_VIEWING = 'COMMENT/VIEWING';

/* DOCUMENTS */
export const CRM_DOCUMENTS_FETCH_DATA = 'CRM/DOCUMENTS/FETCH_DATA';
export const CRM_DOCUMENTS_SET_DATA = 'CRM/DOCUMENTS/SET_DATA';
export const CRM_DOCUMENTS_LOADING = 'CRM/DOCUMENTS/LOADING';
export const CRM_DOCUMENTS_RELOAD = 'CRM/DOCUMENTS/RELOAD';
export const CRM_DOCUMENT_FETCH_DATA = 'CRM/DOCUMENT/FETCH_DATA';
export const CRM_DOCUMENT_SET_DATA = 'CRM/DOCUMENT/SET_DATA';
export const CRM_DOCUMENT_LOADING = 'CRM/DOCUMENT/LOADING';
export const CRM_DOCUMENT_SET_ERRORS = 'CRM/DOCUMENT/SET_ERRORS';
export const CRM_DOCUMENT_PRODUCTS_GET = 'CRM/DOCUMENT/PRODUCTS/GET';
export const CRM_DOCUMENT_PRODUCTS_SET = 'CRM/DOCUMENT/PRODUCTS/SET';

/* WEBSOCKET */
export const REDUX_WEBSOCKET_BROKEN = `${WEBSOCKET_PREFIX}::${WEBSOCKET_BROKEN}`;
export const REDUX_WEBSOCKET_OPEN = `${WEBSOCKET_PREFIX}::${WEBSOCKET_OPEN}`;
export const REDUX_WEBSOCKET_CLOSED = `${WEBSOCKET_PREFIX}::${WEBSOCKET_CLOSED}`;
export const REDUX_WEBSOCKET_MESSAGE = `${WEBSOCKET_PREFIX}::${WEBSOCKET_MESSAGE}`;
export const REDUX_WEBSOCKET_CONNECT = `${WEBSOCKET_PREFIX}::${WEBSOCKET_CONNECT}`;
export const REDUX_WEBSOCKET_DISCONNECT = `${WEBSOCKET_PREFIX}::${WEBSOCKET_DISCONNECT}`;
export const REDUX_WEBSOCKET_SEND = `${WEBSOCKET_PREFIX}::${WEBSOCKET_SEND}`;
export const REDUX_WEBSOCKET_BEGIN_RECONNECT = `${WEBSOCKET_PREFIX}::${WEBSOCKET_BEGIN_RECONNECT}`;
export const REDUX_WEBSOCKET_RECONNECTED = `${WEBSOCKET_PREFIX}::${WEBSOCKET_RECONNECTED}`;
export const REDUX_WEBSOCKET_ERROR = `${WEBSOCKET_PREFIX}::${WEBSOCKET_ERROR}`;
export const REDUX_WEBSOCKET_SET_EVENT = 'REDUX/WEBSOCKET/SET/EVENT';

/* UPDATES */
export const UPDATES_GET_ARTICLE = 'UPDATES/GET/ARTICLE'
export const UPDATES_GET_ARTICLES = 'UPDATES/GET/ARTICLES'
export const UPDATES_SET_ARTICLE = 'UPDATES/SET/ARTICLE'
export const UPDATES_SET_ARTICLES = 'UPDATES/SET/ARTICLES'


export const USER_CARD_SET = 'USER/CARD/SET';
export const USER_CARD_FETCH = 'USER/CARD/FETCH';
export const USER_CARD_LOADING = 'USER/CARD/LOADING';
export const USER_CARD_RELOAD = 'USER/CARD/RELOAD';
export const USER_CHANGE = 'USER/CHANGE';
export const USER_CHANGE_LOADING = 'USER/CHANGE/LOADING';
export const USER_CHANGE_SUCCESS = 'USER/CHANGE/SUCCESS';

export const MODAL_SELECT_DATE_STATUS = 'MODAL/SELECT_DATE/STATUS';
export const MODAL_SELECT_DATE_DATA = 'MODAL/SELECT_DATE/DATA';

export const MODAL_CONFIRM_STATUS = 'MODAL/CONFIRM/STATUS';
export const MODAL_CONFIRM_DATA = 'MODAL/CONFIRM/DATA';

export * from './menu/actions';
export * from './settings/actions';
export * from './auth/actions';
export * from './todo/actions';
export * from './chat/actions';
export * from './surveyList/actions';
export * from './surveyDetail/actions';
export * from './modal/actions';
