// eslint-disable-next-line import/named
import {
    CRM_CLIENT_CARD_COUNT_ENTITY,
    CRM_CLIENT_CHANGE_LOADING,
    CRM_CLIENT_CHANGE_SUCCESS,
    CRM_CLIENT_LOAD_LOADING,
    CRM_CLIENT_MODAL_CREATE_CLIENT_STATUS,
    CRM_CLIENT_MODAL_CREATE_DEALER_STATUS,
    CRM_CLIENT_ORDERS_LOADING,
    CRM_CLIENT_ORDERS_SET,
    CRM_CLIENT_RELOAD, CRM_CLIENT_REMOVE_CONTACT,
    CRM_CLIENT_REMOVE_CONTACT_SUCCESS,
    CRM_CLIENT_SET_DATA,
    CRM_CLIENT_SET_DATA_FORM,
    CRM_CLIENT_SET_ERRORS, CRM_CLIENT_SET_FILTER_RANGE, CRM_CLIENT_SET_FILTER_VALUE,
    CRM_CLIENTS_CHANGE_LOADING,
    CRM_CLIENTS_SET_DATA,
    CRM_CONTACT_CHANGE_CLIENT,
    CRM_CONTACT_COPY_TO_CLIENT,
} from '../../actions';

const INIT_STATE = {
    index: {
        loading: false,
        list: [],
        params: {},
    },
    card: {
        loading: false,
        data: null,
        reload: false,
        errors: null,
        orders: {
            list: {},
            loading: false,
        },
        contacts: {
            remove: {
                success: null,
            },
            // копирование контактного лица в другого контрагента
            copyTo: {
                loading: false,
                errors: [],
                data: null,
                success: false,
            },
        },
        countEntity: {
            order: 0,
            task: 0,
            document: 0,
            comment: 0,
            deals: 0,
            files: 0,
            addressShipping: 0,
            addressStorage: 0,
            processes: 0,
        }
    },
    // изменение организации у контактного лица
    change: {
        loading: false,
        errors: [],
        clientContact: null,
        success: false,
    },
    modal: {
        createClient: {
            status: false,
            data: null,
        },
        createDealer: {
            status: false,
            data: null,
        }
    },
    filter: {
        value: [0, 0],
        range: [0, 0]
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CRM_CLIENTS_SET_DATA:
            return {
                ...state,
                index: { ...state.index, list: action.payload },
                change: { ...state.change, success: false },
            };
        case CRM_CLIENT_CARD_COUNT_ENTITY: {
            const { entity, count } = action.payload;
            return {
                ...state,
                card: {
                    ...state.card,
                    countEntity: {
                        ...state.card.countEntity,
                        [entity]: count
                    }
                },
            };
        }
        case CRM_CLIENTS_CHANGE_LOADING:
            return {
                ...state,
                index: { ...state.index, loading: action.payload },
            };
        case CRM_CLIENT_SET_DATA:
            return {
                ...state,
                card: { ...state.card, data: action.payload, reload: false },
                change: { ...state.change, success: false },
            };
        case CRM_CLIENT_SET_DATA_FORM:
            return {
                ...state,
                card: {
                    ...state.card,
                    data: { ...state.card.data, form: action.payload },
                },
            };
        case CRM_CLIENT_LOAD_LOADING:
            return {
                ...state,
                card: { ...state.card, loading: action.payload },
            };
        case CRM_CLIENT_SET_ERRORS:
            return {
                ...state,
                card: { ...state.card, errors: action.payload },
            };
        case CRM_CLIENT_RELOAD:
            return {
                ...state,
                card: { ...state.card, reload: action.payload },
            };
        case CRM_CLIENT_CHANGE_LOADING:
            return {
                ...state,
                change: { ...state.change, loading: action.payload },
            };
        case CRM_CLIENT_CHANGE_SUCCESS:
            return {
                ...state,
                change: { ...state.change, errors: [], loading: false, success: action.payload, },
            };
        case CRM_CONTACT_CHANGE_CLIENT:
            return {
                ...state,
                change: { ...state.change, clientContact: action.payload },
            };
        case CRM_CONTACT_COPY_TO_CLIENT:
            return {
                ...state,
                card: {
                    ...state.card,
                    contacts: {
                        ...state.card.contacts,
                        copyTo: {
                            ...state.card.contacts.copyTo,
                            data: action.payload
                        }
                    }
                },
            };
        case CRM_CLIENT_ORDERS_SET:
            return {
                ...state,
                card: { ...state.card, orders: {...state.card.orders, list: action.payload} },
            };
        case CRM_CLIENT_ORDERS_LOADING:
            return {
                ...state,
                card: { ...state.card, orders: {...state.card.orders, loading: action.payload} },
            };
        case CRM_CLIENT_REMOVE_CONTACT:
            return {
                ...state,
                card: { ...state.card, contacts: {...state.card.contacts, remove: {...state.card.contacts.remove, success: null}} },
            };
        case CRM_CLIENT_REMOVE_CONTACT_SUCCESS:
            return {
                ...state,
                card: { ...state.card, contacts: {...state.card.contacts, remove: {...state.card.contacts.remove, success: action.payload}} },
            };
        case CRM_CLIENT_MODAL_CREATE_CLIENT_STATUS:
            return {
                ...state,
                modal: { ...state.modal,
                    createClient: {...state.modal.createClient,
                        status: action.payload.status ?? false,
                        data: action.payload.data ?? null,
                    }
                },
            };
        case CRM_CLIENT_MODAL_CREATE_DEALER_STATUS:
            return {
                ...state,
                modal: { ...state.modal,
                    createDealer: {...state.modal.createDealer,
                        status: action.payload.status ?? false,
                        data: action.payload.data ?? null,
                    }
                },
            };
        case CRM_CLIENT_SET_FILTER_VALUE:
            return {
                ...state,
                filter: {...state.filter, value: action.payload},
            };
        case CRM_CLIENT_SET_FILTER_RANGE:
            return {
                ...state,
                filter: {...state.filter, range: action.payload},
            };
        default:
            return { ...state };
    }
};
