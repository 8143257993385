import React from 'react';
import css from './nav.module.scss'
import SuperPageNav from "./SuperPageNav";
import {Chip, Tooltip} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {adminRoot} from "../../../constants/defaultValues";
import {useEffect, useMemo, useState} from "react";
import {encodeDataToUrl} from "../../../components/common/ModuleDataAndUrl";
import {send} from "@giantmachines/redux-websocket";
import {useHistory} from "react-router-dom";

const PageNavProcess = (props) => {
    const {navItem} = props;

    const currentUser = useSelector((state) => state.authUser.currentUser);
    const { connected } = useSelector((state) => state.websocket);
    const { process } = useSelector((state) => state.websocket.event);
    const dispatch = useDispatch();
    const history = useHistory();
    const linkProcess = `${adminRoot}/crm/process`;
    const [countData, setCountData] = useState({
        countProcessResponsible: 0,
    })

    const changeCountData = (name, value) => {
        setCountData((oldValue) => {
            return  {...oldValue, [name]: value};
        })
    }

    const hrefToYouResponsible = useMemo(() => {
        const managersID = [currentUser.id];
        const linkOrdersParams = {
            status_id: '1|2|3|6',
            manager_id: managersID.join('|'),
        };
        return `${linkProcess}?${encodeDataToUrl(linkOrdersParams)}`;
    }, [currentUser]);

    const goToHref = (e, href) => {
        e.preventDefault()
        return history.push(href)
    }

    useEffect(() => {
        if (connected) {
            dispatch(send({type: 'getCountProcessResponsible'}));
        }
    }, [connected]);

    useEffect(() => {
        if (process !== null) {
            const action = process?.action;
            if (['countProcessResponsible'].includes(action)) {
                changeCountData(action, Number(process.data ?? 0));
            }
        }
    }, [process]);

    return (
        <SuperPageNav href={navItem.to}>
            <div className={css.counterText}>
                <span className={css.headline}>{navItem.name}</span>
                <div className={css.counters}>
                    {countData.countProcessResponsible > 0 &&
                        <Tooltip title="Вы ответственный за процесс" placement="top">
                            <Chip
                                clickable={false}
                                onClick={(e) => goToHref(e, hrefToYouResponsible)}
                                className={`${css.counter  } ${  css.counterBlue}`}
                                label={countData.countProcessResponsible}/>
                        </Tooltip>
                    }
                </div>
            </div>
        </SuperPageNav>
    );
};
export default PageNavProcess;
