import NoAuthorization from './NoAuthorization'
import {error503, userLogin} from '../../constants/defaultValues'

const axiosResponseError = exception => {
    const status = Number.parseInt(exception?.response?.status, 10)
    switch (status) {
        case 403:
            NoAuthorization()
            document.location.href = error503
            break
        case 401:
            NoAuthorization()
            document.location.href = userLogin
            break
        default:
    }
}
export default axiosResponseError
