/* eslint-disable no-unused-vars,no-param-reassign */
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import React from "react";
import {fetchMetaDefault} from "../../../constants/defaultCrm";

const initialState = {
    dealers: {
        loading: false,
        data: {
            items: [],
            general: null,
            meta: fetchMetaDefault
        },
        reload: false,
    },
    dealersProducts: {
        loading: false,
        data: {
            items: [],
            meta: fetchMetaDefault
        },
        reload: false,
    },
    publishedProducts: {
        loading: false,
        data: {
            items: [],
            meta: fetchMetaDefault
        },
        reload: false,
    },
    managersTask: {
        loading: false,
        data: {
            items: [],
            meta: fetchMetaDefault
        },
        reload: false,
    },
    managersStorageModerator: {
        loading: false,
        data: {
            items: [],
            meta: fetchMetaDefault
        },
        reload: false,
    },
    managersStorage: {
        loading: false,
        data: {
            items: [],
            meta: fetchMetaDefault
        },
        reload: false,
    },

    managersStorageTime: {
        loading: false,
        data: {
            items: [],
            meta: fetchMetaDefault
        },
        reload: false,
    },

    managersStorageTimeModerator: {
        loading: false,
        data: {
            items: [],
            meta: fetchMetaDefault
        },
        reload: false,
    },

    suppliers: {
        loading: false,
        data: {
            items: [],
        },
        reload: false,
        prodCounts: {
            data: [],
            loading: false,
            error: false,
        }
    },

};

const slice = createSlice({
    name: "statistics",
    initialState,
    reducers: {
        setDataDealers: (state, { payload }) => {
            state.dealers = {
                ...state.dealers,
                data: {
                    items: !payload ? [] : payload.body,
                    general: !payload ? null : payload.general,
                    meta: !payload ? fetchMetaDefault : payload.meta
                },
                loading: false,
                reload: false,
            };
        },
        setDataDealersProducts: (state, { payload }) => {
            state.dealersProducts = {
                ...state.dealersProducts,
                data: {
                    items: !payload ? [] : payload.items,
                    // eslint-disable-next-line no-underscore-dangle
                    meta: !payload ? fetchMetaDefault : payload._meta
                },
                loading: false,
                reload: false,
            };
        },
        fetchDataDealers: (state, { payload }) => {
            state.dealers = {
                ...state.dealers,
                loading: true,
                reload: false,
            };
        },
        fetchDataDealersProducts: (state, { payload }) => {
            state.dealersProducts = {
                ...state.dealersProducts,
                loading: true,
                reload: false,
            };
        },
        reloadDataDealers: (state, { payload }) => {
            state.dealers.reload = payload;
        },
        reloadDataDealersProducts: (state, { payload }) => {
            state.dealersProducts.reload = payload;
        },
        fetchDataPublishedProducts: (state, { payload }) => {
            state.publishedProducts = {
                ...state.publishedProducts,
                reload: false,
                loading: true,
            };
        },
        setDataPublishedProducts: (state, { payload }) => {
            state.publishedProducts = {
                ...state.publishedProducts,
                data: {
                    items: !payload ? [] : payload.items,
                    // eslint-disable-next-line no-underscore-dangle
                    meta: !payload ? fetchMetaDefault : payload._meta
                },
                loading: false,
                reload: false,
            };
        },
        setDataManagersTask: (state, { payload }) => {
            state.managersTask = {
                ...state.managersTask,
                data: {
                    items: !payload ? [] : payload.data,
                    // eslint-disable-next-line no-underscore-dangle
                    meta: !payload ? fetchMetaDefault : payload._meta
                },
                loading: false,
                reload: false,
            };
        },
        fetchDataManagersTask: (state, { payload }) => {
            state.managersTask = {
                ...state.managersTask,
                loading: true,
                reload: false,
            };
        },


        setDataManagersStorage: (state, { payload }) => {
            state.managersStorage = {
                ...state.managersStorage,
                data: {
                    items: !payload ? [] : payload,
                    // eslint-disable-next-line no-underscore-dangle
                    meta: !payload ? fetchMetaDefault : payload._meta
                },
                loading: false,
                reload: false,
            };
        },
        fetchDataManagersStorage: (state, { payload }) => {
            state.managersStorage = {
                ...state.managersStorage,
                loading: true,
                reload: false,
            };
        },


        setDataManagersStorageModerator: (state, { payload }) => {
            state.managersStorageModerator = {
                ...state.managersStorageModerator,
                data: {
                    items: !payload ? [] : payload.items,
                    // eslint-disable-next-line no-underscore-dangle
                    meta: !payload ? fetchMetaDefault : payload._meta
                },
                loading: false,
                reload: false,
            };
        },
        fetchDataManagersStorageModerator: (state, { payload }) => {
            state.managersStorageModerator = {
                ...state.managersStorageModerator,
                loading: true,
                reload: false,
            };
        },


        setDataManagersStorageTime: (state, { payload }) => {
            state.managersStorageTime = {
                ...state.managersStorageTime,
                data: {
                    items: !payload ? [] : payload,
                    // eslint-disable-next-line no-underscore-dangle
                    meta: !payload ? fetchMetaDefault : payload._meta
                },
                loading: false,
                reload: false,
            };
        },
        fetchDataManagersStorageTime: (state, { payload }) => {
            state.managersStorageTime = {
                ...state.managersStorageTime,
                loading: true,
                reload: false,
            };
        },


        setDataManagersStorageTimeModerator: (state, { payload }) => {
            state.managersStorageTimeModerator = {
                ...state.managersStorageTimeModerator,
                data: {
                    items: !payload ? [] : payload,
                    // eslint-disable-next-line no-underscore-dangle
                    meta: !payload ? fetchMetaDefault : payload._meta
                },
                loading: false,
                reload: false,
            };
        },
        fetchDataManagersStorageTimeModerator: (state, { payload }) => {
            state.managersStorageTimeModerator = {
                ...state.managersStorageTimeModerator,
                loading: true,
                reload: false,
            };
        },

        setDataSuppliers: (state, { payload }) => {
            state.suppliers = {
                ...state.suppliers,
                data: {
                    items: !payload ? [] : payload.data,
                },
                loading: false,
                reload: false,
            };
        },
        fetchDataSuppliers: (state) => {
            state.suppliers = {
                ...state.suppliers,
                loading: true,
                reload: false,
            };
        },
        setSuppliersStorageProductCounts: (state, { payload }) => {
            state.suppliers.prodCounts = {
                data: !payload?.data ? [] : payload.data,
                loading: !!payload?.loading,
                error: !!payload?.error
            };
        },
        fetchSuppliersStorageProductCounts: (state) => {
            state.suppliers.prodCounts.loading = true;
        },

    }
})

export const statisticsReducer = slice.reducer;
export const statisticsActions = slice.actions;