import {put, call, takeEvery, select} from 'redux-saga/effects'

import {usersActions} from './slice'
import ErrorResponse from '../../../components/common/ErrorResponse'
import {fetchUsers} from '../../../api/query/directory'

function* fetchDataUsers({payload}) {
    try {
        const users = yield select(state => state.users.users)
        if (users.some(x => x.id === payload['user_id[0]'])) {
            yield put(usersActions.skip())
        } else {
            const data = yield call(fetchUsers, payload)
            yield put(usersActions.addUser(data.data[0]))
        }
    } catch (error) {
        ErrorResponse(error.response)
        yield put(usersActions.skip())
    }
}

// eslint-disable-next-line import/prefer-default-export
export function* usersSaga() {
    yield takeEvery(usersActions.getUsers.type, fetchDataUsers)
}
