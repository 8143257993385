import React from "react";
import moment from "moment";
import {Tooltip} from "@mui/material";

const DateFinish = ({date}) => {
    const dateMoment = moment(date);
    if (!dateMoment.isValid()) return <></>;
    return (
        <>
            <Tooltip title='Дата завершения' placement='top'>
                <span id="tooltip_modal_create_task_finish">
                    <i className="icon-stop mr-1 text-primary" />
                    {dateMoment.format('DD.MM.YYYY HH:mm')}
                </span>
            </Tooltip>
        </>
    )
}
export default DateFinish;