import {attachStorageUsers, detatchStorageUsers, fetchStorageUsers, findStorageUsers} from '../api/query/storage'

const expand = ['emails', 'phones', 'subscriptions', 'subscriptions.emails', 'position'].join(',')

export const getStorageUsers = async ({storage_id}) => {
    let result = {
        type: '',
        data: null,
    }
    await fetchStorageUsers({storage_id, expand})
        .then(response => {
            result = {
                type: 'success',
                data: response?.data?.items,
            }
        })
        .catch(error => {
            const message = error?.response?.data?.message ?? 'Что-то пошло не так'
            result = {
                type: 'error',
                data: message,
            }
        })
    return result
}

export const getStorageUsersSearch = async ({storage_id, s}) => {
    let result = {
        type: '',
        data: null,
    }
    await findStorageUsers({storage_id, s, expand})
        .then(response => {
            result = {
                type: 'success',
                data: response?.data?.items,
            }
        })
        .catch(error => {
            const message = error?.response?.data?.message ?? 'Что-то пошло не так'
            result = {
                type: 'error',
                data: message,
            }
        })
    return result
}

export const addStorageUsers = async ({storage_id, user_id}) => {
    let result = {
        type: '',
        data: null,
    }
    await attachStorageUsers({storage_id, user_id})
        .then(response => {
            result = {
                type: 'success',
                data: response,
            }
        })
        .catch(error => {
            const message = error?.response?.data?.message ?? 'Что-то пошло не так'
            result = {
                type: 'error',
                data: message,
            }
        })
    return result
}

export const removeStorageUsers = async ({storage_id, user_id}) => {
    let result = {
        type: '',
        data: null,
    }
    await detatchStorageUsers({storage_id, user_id})
        .then(response => {
            result = {
                type: 'success',
                data: response,
            }
        })
        .catch(error => {
            const message = error?.response?.data?.message ?? 'Что-то пошло не так'
            result = {
                type: 'error',
                data: message,
            }
        })
    return result
}
