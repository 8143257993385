import React, {useContext, useEffect, useRef, useState} from 'react';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {Scrollbars} from "react-custom-scrollbars";
import {useDispatch} from "react-redux";
import {fetchList} from '../../../../../api/query/organization';
import ModalChangeClientItem from './ModalChangeClientItem';
import ErrorResponse from "../../../../../components/common/ErrorResponse";
import useDebounce from "../../../../../helpers/hooks/useDebounce";
import {DesktopContext} from "../../../../../utils/hoc/withContext";
import {appActions} from "../../../../../redux/toolkit/app/slice";

const ModalChangeClient = ({isShow, changeShow, changeClient, orgType = [2], otherParams = {}}) => {
    const dispatch = useDispatch();
    const [searchClient, setSearchClient] = useState('');
    const [resultSearch, setResultSearch] = useState([]);
    const [runQuery, setRunQuery] = useState(false);
    const [selectedClient, setSelectedClient] = useState(0);
    const scrollbarRef = useRef();
    const perPage = 5;

    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    // eslint-disable-next-line no-unused-vars
    const [totalCount, setTotalCount] = useState(0);

    const desktop = useContext(DesktopContext)

    const onClickClient = (event) => {
        const item = event.currentTarget;
        const value = Number.parseInt(item.getAttribute('data-id'), 10);
        setSelectedClient(value);
    };
    const modelParamsDefault = () => {
        setSearchClient('');
        setResultSearch([]);
        setRunQuery(false);
        setSelectedClient(0);
    };
    const fetchData = (value, page = currentPage) => {
        return fetchList({
            expand: [
                'responsibleList.responsible.avatarData',
                'responsibleList.responsible.state',
                'legalAddress.city',
                'form',
                'requisites',
                'types',
                'orderData',
                'segment_income'
            ].join(','),
            'selection[name]': value,
            'selection[type]': orgType,
            'page': page,
            'per-page': perPage,
            fields: 'id, name, inn, responsible, form.*, types.*, orderData.*',
            ...otherParams
        });
    };

    const fetchDataHandler = (value, page) => {
        setRunQuery(true);
        fetchData(value, page)
            .then((response) => {
                // eslint-disable-next-line no-underscore-dangle
                const metaData = response.data._meta;
                setPageCount(metaData.pageCount);
                setTotalCount(metaData.totalCount);
                setCurrentPage(metaData.currentPage);

                setRunQuery(false);
                setResultSearch(page === 1 ? response.data.items : [...resultSearch, ...response.data.items]);
                scrollbarRef.current.scrollToBottom();
            })
            .catch((error) => {
                setRunQuery(false);
                setResultSearch([]);
                const errorResponse = ErrorResponse(error.response);
                dispatch(appActions.setNotification({type: 'error', message: errorResponse}));
            });
    };

    const searchValueChange = (event) => {
        const {value} = event.target;
        setSearchClient(value);
        setSelectedClient(0);
        if (value.length < 1) {
            setResultSearch([]);
            setPageCount(0);
            setTotalCount(0);
            setCurrentPage(1);
        }
    };

    useDebounce(() => {
        if (searchClient.length) {
            fetchDataHandler(searchClient, currentPage)
        }
    }, 1000, [searchClient.length]);

    const loadNextPage = () => {
        fetchDataHandler(searchClient, currentPage + 1);
    };

    const onClickChangeClient = () => {
        if (!selectedClient) {
            dispatch(appActions.setNotification({type: 'error', message: 'Не удалось изменить контрагента'}));
            return false;
        }
        const selectedValue = resultSearch.find((x) => Number(x.id) === selectedClient);
        changeClient(selectedValue);
        changeShow(false);
    };

    useEffect(() => {
        if (!isShow) {
            modelParamsDefault();
        }
    }, [isShow]);
    return (
        <>
            <Modal
                className="modal-style modal-lg"
                isOpen={isShow}
                toggle={() => changeShow(!isShow)}
                id="modal-change-client"
            >
                <ModalHeader toggle={() => changeShow(false)}>
                    <span>Поиск контрагентов</span>
                </ModalHeader>
                <ModalBody className="pt-0">
                    <div className="container-search_client">
                        <div className="search-input form-group">
                            <input
                                type="text"
                                className="form-control"
                                value={searchClient}
                                onChange={searchValueChange}
                                placeholder="Поиск"
                            />
                            <button className="btn" type="button">
                                <i className="simple-icon-magnifier"> </i>
                            </button>
                        </div>
                        <div className="search-result">
                            {resultSearch.length ? (
                                <p>Результаты поиска</p>
                            ) : ''}
                            <Scrollbars ref={scrollbarRef} autoHeight autoHide autoHeightMax={295}
                                        className={`scrollbar ${resultSearch.length ? 'active' : ''}`}>
                                {resultSearch.length ? (
                                    <>
                                        {desktop ?
                                            <table className="table align-middle table-hover mb-0">
                                                <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Наименование</th>
                                                    <th>ИНН</th>
                                                    <th>Ответственный</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {resultSearch.map((item) => {
                                                    return (
                                                        <ModalChangeClientItem
                                                            key={item.id}
                                                            {...item}
                                                            onClickClient={onClickClient}
                                                            selectedClient={selectedClient}
                                                            typeId={orgType}
                                                        />
                                                    );
                                                })}
                                                </tbody>
                                            </table>
                                            :
                                            <>
                                                {resultSearch.map((item) => {
                                                    return (
                                                        <ModalChangeClientItem
                                                            key={item.id}
                                                            {...item}
                                                            onClickClient={onClickClient}
                                                            selectedClient={selectedClient}
                                                            typeId={orgType}
                                                        />
                                                    );
                                                })}
                                            </>
                                        }
                                    </>
                                ) : ''}
                            </Scrollbars>
                            {pageCount > currentPage ? (
                                <div className="d-flex justify-content-center mt-3">
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary"
                                        onClick={loadNextPage}
                                        disabled={runQuery}
                                    >{runQuery ? 'Загрузка...' : 'Показать еще'}</button>
                                </div>
                            ) : ''}
                            {resultSearch.length ? (
                                <div className="text-right mt-3">
                                    <button
                                        type="submit"
                                        disabled={!selectedClient}
                                        className="btn btn-primary"
                                        onClick={onClickChangeClient}
                                    >
                                        Сменить контрагента
                                    </button>
                                </div>
                            ) : ''}
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};
export default ModalChangeClient;
