import {Tooltip} from "@mui/material";
import React from "react";
import PhotoManagerNew from "../../../../components/profile/photoManagerNew";

const Watcher = ({watcher, onDeleteWatcher, accessDeleteWatcher}) => {
    const fullName = `${watcher.last_name} ${watcher.first_name}`;
    const id = watcher.id ?? 0;
    const tooltipManagerId = `tooltip_task_watcher_${id}`;

    return (
        <>
            <Tooltip title={fullName} placement='bottom'>
                <div className="watcher" id={tooltipManagerId}>
                    <PhotoManagerNew
                        data={watcher}
                    />
                    {accessDeleteWatcher ? (
                        <button type="button" onClick={() => onDeleteWatcher(watcher.id)} className="btn detach">
                            <i className="icon-cross" />
                        </button>
                    ) : ''}
                </div>
            </Tooltip>
        </>
    )
}
export default Watcher;