import React from 'react';

const FormError = ({errors, name}) => {
    const existError = typeof errors[name] !== 'undefined' && errors[name];
    return existError ? (
        <div className="invalid-feedback d-block">{errors[name]}</div>
    ) : (
        ''
    );
};
export const ClearError = ({errors, name, changeErrors}) => {
    const existError = typeof errors[name] !== 'undefined' && errors[name];
    if (existError) {
        changeErrors((prevErrors) => {
            return {...prevErrors, [name]: undefined};
        });
    }
};
export default FormError;
