import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Typography} from '@mui/material';
import UniqID from "../../common/UniqID";
import PhotoManagerNew from "../../profile/photoManagerNew";
import {DesktopContext} from "../../../utils/hoc/withContext";
import {ChiefIcon} from "../../../assets/icons/chief-icon";
import {StyledTooltip} from "./helpers";
import {useDispatch, useSelector} from "react-redux";
import {usersActions} from "../../../redux/toolkit/users/slice";
import {usersSelectors} from "../../../redux/toolkit/users/selector";
import UserRank from "../../../helpers/userRank/userRank";
import css from './Manager.module.scss'

const queryExpand = [
    'fields',
    'phones',
    'emails',
    'crmGroups'
];

export const Manager = ({manager, managerGroup = null, field = ''}) => {
    return manager ? (
        <ViewManager data={manager} field={field}/>
    ) : (
        <ViewManagerGroup data={managerGroup} field={field}/>
    );
};

export const ViewManagerGroup = ({data, mobile = false}) => {

    if (!data) return <></>;
    const arr = data?.name.split(' ')
    const name = arr[arr.length - 1]

    return (
        <>
            <div className="d-flex align-items-center justify-content-start">
                <i
                    className="icomoon icon-tree"
                    style={{color: '#ed7117', fontSize: '16px'}}
                />
                <span className="ml-2">{mobile ? name : data.name}</span>
            </div>
        </>
    );
};

export const ViewManager = ({data, field, photoProps = {}, textRenderCall = null, reverse, showName = true, showLastName = true, showTooltip = true}) => {
    if (!data) return <></>;
    const dispatch = useDispatch()

    const usersData = useSelector(usersSelectors.getUsers)
    const userData = usersData?.find(x => x.id === data.id || x.id === data.user_id)
    const isFetching = useSelector(usersSelectors.isFetching)

    const [showToolTips, setShowToolTips] = useState(false);

    const onMouseEnterHandler = () => {
        if (showTooltip) setShowToolTips(true);
    };

    const onMouseLeaveHandler = () => {
        if (showTooltip) setShowToolTips(false);
    };

    const getCurrentField = (name) => {
        let currentValue
        userData?.fields.forEach(el => {
            if (el.field_name === name) currentValue = el.field_value
        })

        return currentValue
    }

    const getEmail = () => {
        let email = '';

        userData?.emails.forEach(el => {
            if (el.is_main === 1) email = el.email
        })

        return email
    }
    const getPhones = () => (userData?.phones[0]?.number)

    const getAddPhones = () => (userData?.phones[0]?.add_number)

    const dataTooltip = data ?
        <div>
            <div className={`${css.wrapImage} d-flex align-items-center justify-content-start`}>
                <PhotoManagerNew
                    size="lg"
                    data={data}
                    {...photoProps}
                />
                <p className={css.wrapImageText}>{`${data.last_name} ${data.first_name}`}</p>
            </div>
            {isFetching ? (
                <Typography textAlign='center' my={2}>Загрузка...</Typography>
            ) : (
                <>
                    <div className={css.textElem}>
                        <p className={css.textElemHeadline}>Отдел:</p>
                        <p className={css.textElemText}>
                            {userData?.crmGroups?.length > 0 && userData?.crmGroups[0]?.name}
                        </p>
                    </div>
                    <div className={css.textElem}>
                        <p className={css.textElemHeadline}>Должность:</p>
                        <p className={css.textElemText}>
                            {userData?.crmGroups?.length > 0 && userData?.crmGroups[0]?.role}
                        </p>
                    </div>
                    <div className={css.textElem}>
                        <p className={css.textElemHeadline}>Раб-й телефон:</p>
                        <p className={css.textElemText}>{getPhones()}</p>
                    </div>
                    <div className={css.textElem}>
                        <p className={css.textElemHeadline}>Доб-й телефон:</p>
                        <p className={css.textElemText}>{getAddPhones()}</p>
                    </div>
                    <div className={css.textElem}>
                        <p className={css.textElemHeadline}>E-mail:</p>
                        <p className={css.textElemText}>{getEmail()}</p>
                    </div>
                    <div className={`${css.textElem} ${css.textElemRank}`}>
                        <p className={css.textElemHeadline}>Ранг:</p>
                        <p className={css.textElemText}>
                            <UserRank id={getCurrentField('user_rank')} />
                        </p>
                    </div>
                    <div className={css.textElem}>
                        <p className={css.textElemHeadline}>О себе:</p>
                        <p className={css.textElemText}>{getCurrentField('user_about') || ''}</p>
                    </div>
                </>
            )}
        </div>
        : 'Не назначен';

    const desktop = useContext(DesktopContext)

    const uniqId = UniqID('manager');
    const tooltipManagerId = `tooltip_${uniqId}_${field}_${data ? data.id : 0}`;

    const textRender = useMemo(() => {
        if (textRenderCall) {
            const TextRenderCall = textRenderCall;
            return <TextRenderCall data={data} />
        }
        return data ? `${desktop && showLastName ? data.last_name : ''} ${showName ? data.first_name : ''}` : 'Не назначен';
    }, [data]);

    useEffect(() => {
        if (showToolTips && !isFetching) {
            dispatch(usersActions.getUsers({
                'user_id[0]': data.id || data.user_id,
                expand: queryExpand.join(','),
            }))
        }
    }, [showToolTips])

    return (
        <div>
            <StyledTooltip
                title={dataTooltip}
                placement="bottom"
                open={showToolTips}
                onMouseEnter={onMouseEnterHandler}
                onMouseLeave={onMouseLeaveHandler}>
                <div
                    id={tooltipManagerId}
                    className={`d-flex align-items-center justify-content-start ${reverse ? 'flex-row-reverse' : ''}`}>
                    <PhotoManagerNew size="sm" data={data} {...photoProps} />
                    <span className={reverse ? 'mr-2 text-left overflowEllipsis' : 'ml-2 text-left'}>
                        {textRender}
                        {data.is_main > 0 && <ChiefIcon />}
                    </span>
                </div>
            </StyledTooltip>
        </div>
    );
};
