import {
  CRM_DOCUMENT_FETCH_DATA,
  CRM_DOCUMENT_LOADING, CRM_DOCUMENT_PRODUCTS_GET, CRM_DOCUMENT_PRODUCTS_SET,
  CRM_DOCUMENT_SET_DATA,
  CRM_DOCUMENT_SET_ERRORS,
  CRM_DOCUMENTS_FETCH_DATA,
  CRM_DOCUMENTS_LOADING,
  CRM_DOCUMENTS_RELOAD,
  CRM_DOCUMENTS_SET_DATA,
} from '../../actions';

export const documentListFetchData = (params) => {
  return {
    type: CRM_DOCUMENTS_FETCH_DATA,
    payload: params,
  };
};
export const documentListSetData = (data) => {
  return {
    type: CRM_DOCUMENTS_SET_DATA,
    payload: data,
  };
};
export const documentListLoading = (loading) => {
  return {
    type: CRM_DOCUMENTS_LOADING,
    payload: loading,
  };
};
export const documentListReload = (reload) => {
  return {
    type: CRM_DOCUMENTS_RELOAD,
    payload: reload,
  };
};
export const documentOneFetchData = (params) => {
  return {
    type: CRM_DOCUMENT_FETCH_DATA,
    payload: params,
  };
};
export const documentOneSetData = (data) => {
  return {
    type: CRM_DOCUMENT_SET_DATA,
    payload: data,
  };
};
export const documentOneSetErrors = (errors) => {
  return {
    type: CRM_DOCUMENT_SET_ERRORS,
    payload: errors,
  };
};
export const documentOneLoading = (loading) => {
  return {
    type: CRM_DOCUMENT_LOADING,
    payload: loading,
  };
};
export const documentProductsGet = (params) => {
  return {
    type: CRM_DOCUMENT_PRODUCTS_GET,
    payload: params,
  };
};
export const documentProductsSet = (data) => {
  return {
    type: CRM_DOCUMENT_PRODUCTS_SET,
    payload: data,
  };
};