// eslint-disable-next-line import/prefer-default-export
export const arrayUniq = (arr) => {
    return Array.from(new Set(arr));
}
export const arraySum = (array) => {
    return array.reduce((previousValue, currentValue) => {
        return currentValue + previousValue;
    });
}

export const arrayChunk = (arr, chunkSize) => {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}