import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  GET_USER_INFO,
} from '../actions';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  // eslint-disable-next-line no-unused-vars
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  setUserInfo,
  getUserInfoLoading,
  setToken,
} from './actions';

import { adminRoot, userLogin } from '../../constants/defaultValues';
import { SendGet } from '../../api/handler/sendGet';
import { sendPost } from '../../api/handler/sendPost';
import { getMeInfo } from '../../api/query/auth';

// Аутентификация
export function* watchLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) => {
  // eslint-disable-next-line no-return-await,no-unused-vars
  return await sendPost('user/login', { email, password });
};

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    // eslint-disable-next-line radix
    if (Number.parseInt(loginUser.status) === 200) {
      const { token } = loginUser.data;
      if (!token || typeof token === 'undefined') {
        yield put(
          loginUserError('Не удалось выполнить подтверждение личности')
        );
      } else {
        yield put(setToken(token));
        yield put(loginUserSuccess());
        history.push(adminRoot);
      }
    } else {
      yield put(loginUserError(loginUser.data.message));
    }
  } catch (error) {
    yield put(loginUserError(error.response.data.message));
  }
}

// Регистрация пользователя
export function* watchRegisterUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (registerData) => {
  // eslint-disable-next-line no-return-await
  return await sendPost('users/register', registerData);
};

function* registerWithEmailPassword({ payload }) {
  const { history } = payload;

  try {
    yield call(registerWithEmailPasswordAsync, payload.user);

    yield put(registerUserSuccess(true));
    history.push(userLogin);
  } catch (error) {
    yield put(registerUserError(error.response.data.message));
  }
}

// Разлогинивание
export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async () => {
  await SendGet('user/logout')
    .then((user) => user)
    .catch((error) => error);
};

function* logout({ payload }) {
  const { history } = payload;
  yield call(logoutAsync);
  yield put(setUserInfo(null));
  yield put(setToken(''));
  history.push(userLogin);
}

// Запрос на восстановление пароля
export function* watchForgotPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email, url) => {
  // eslint-disable-next-line no-return-await
  return await sendPost('users/request-password-reset', { email, url });
};

function* forgotPassword({ payload }) {
  const { email, url } = payload.forgotUserMail;
  try {
    yield call(forgotPasswordAsync, email, url);

    yield put(forgotPasswordSuccess('success'));
  } catch (error) {
    yield put(forgotPasswordError(error.response.data.message));
  }
}

// Восстановление пароля
export function* watchResetPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (
  resetPasswordCode,
  password,
  confirmPassword
) => {
  // eslint-disable-next-line no-return-await
  return await sendPost(
    'users/reset-password',
    {
      password,
      confirm_password: confirmPassword,
    },
    { token: resetPasswordCode }
  );
};

function* resetPassword({ payload }) {
  const { password, confirmPassword, resetPasswordCode } = payload;
  try {
    yield call(
      resetPasswordAsync,
      resetPasswordCode,
      password,
      confirmPassword
    );
    yield put(resetPasswordSuccess(true));
  } catch (error) {
    yield put(resetPasswordError(error.response.data.message));
  }
}

function* loadUserInfo({ payload }) {
  try {
    const getData = yield call(getMeInfo, payload);
    const codeResponse = Number.parseInt(getData.status, 10);
    if (codeResponse === 200) {
      yield put(setUserInfo(getData.data));
    }
    yield put(getUserInfoLoading(false));
  } catch (error) {
    yield put(loginUserError(error.response.data.message));
    yield put(getUserInfoLoading(false));
  }
}
export function* watchUserInfo() {
  yield takeEvery(GET_USER_INFO, loadUserInfo);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchUserInfo),
  ]);
}
