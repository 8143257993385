import { SendGet } from '../handler/sendGet';

export const fetchCountry = (string) => {
    return SendGet(`directorys/country`, { s: string });
};
export const fetchCity = (string) => {
    return SendGet(`directorys/city`, { s: string, expand: 'parent' });
};
export const fetchOrgForm = (string) => {
    return SendGet(`directorys/org-form`, { s: string });
};
export const fetchIncome = () => {
    return SendGet(`organizations/directory?directory=income`);
};
export const fetchOrgType = (params) => {
    return SendGet(`directorys/org-type`, params);
};
export const fetchOrgCategory = (string) => {
    return SendGet(`directorys/org-category`, { s: string });
};
export const fetchClientStatus = (string) => {
    return SendGet(`directorys/client-status`, { s: string });
};
export const fetchLocation = (id) => {
    return SendGet(`directorys/location-id`, { id });
};
export const fetchOrderStatus = (params) => {
    return SendGet(`directorys/order-status`, params);
};
export const fetchOrderDoubleStatus = (params) => {
    return SendGet(`directorys/order-double-status`, params);
};
export const fetchLeadStatus = (string) => {
    return SendGet(`directorys/lead-status`, { s: string });
};
export const fetchManagers = (params) => {
    return SendGet(`directorys/managers`, params);
};
export const fetchUsers = (params) => {
    return SendGet(`directorys/users`, params);
};
export const fetchProductType = (params) => {
    return SendGet(`directorys/product-type`, params);
};
export const fetchProductBrand = (params) => {
    return SendGet(`brands`, params);
};
export const fetchPhoneType = (params) => {
    return SendGet(`directorys/phone-type`, params);
};
export const fetchDocumentType = (params) => {
    return SendGet(`directorys/document-type`, params);
};
export const fetchDocumentStatus = (params) => {
    return SendGet(`directorys/document-status`, params);
};
export const fetchUnits = (params) => {
    return SendGet(`directorys/product-unit`, params);
};
export const fetchCurrency = (params) => {
    return SendGet(`directorys/currency`, params);
};
export const fetchDegree = (params) => {
    return SendGet(`directorys/degree`, params);
};
export const fetchPaymentStatus = (params) => {
    return SendGet(`directorys/payment-status`, params);
};
export const fetchTaskStatus = (params) => {
    return SendGet(`directorys/order-task-status`, params);
};
export const fetchTaskDoubleStatus = (params) => {
    return SendGet(`directorys/order-task-double-status`, params);
};
export const fetchTaskType = (params) => {
    return SendGet(`directorys/order-task-type`, params);
};
export const fetchTaskTypeTemplate = (params) => {
    return SendGet(`directorys/order-task-type-template`, params);
};
export const fetchUserPosition = (params) => {
    return SendGet(`directorys/user-positions`, params);
};
export const fetchOrderSource = (params) => {
    return SendGet(`directorys/order-source`, params);
};
export const fetchFileType = (params) => {
    return SendGet(`directorys/file-type`, params);
};
export const fetchProductMatchType = (params) => {
    return SendGet(`directorys/product-match-type`, params);
};
export const fetchDealStatus = (params) => {
    return SendGet(`directorys/deal-status`, params);
};
export const fetchLogisticsPaymentStatus = (params) => {
    return SendGet(`directorys/logistics-payment-status`, params);
};
export const fetchLogisticsStatus = (params) => {
    return SendGet(`directorys/logistics-status`, params);
};
export const fetchSuggestAddress = (params) => {
    return SendGet(`directorys/suggest-address`, params);
};
export const fetchSuggestClient = (params) => {
    return SendGet(`directorys/suggest-client`, params);
};
export const fetchPaymentNumberScore = (params) => {
    return SendGet(`directorys/payment-number-score`, params);
};
export const fetchNumberScore = (params) => {
    return SendGet(`directorys/payment-number-score`, params);
};
export const fetchDealForm = (params) => {
    return SendGet(`directorys/deal-form`, params);
};
export const fetchLeadTypeFrom = (params) => {
    return SendGet(`directorys/lead-type-from`, params);
};
export const fetchPaymentUse = (params) => {
    return SendGet(`directorys/payment-use`, params);
};
export const fetchPaymentType = (params) => {
    return SendGet(`directorys/payment-type`, params);
};
export const fetchDealScore = (params) => {
    return SendGet(`directorys/deal-score`, params);
};
export const fetchTypeCompany = (params) => {
    return SendGet(`directorys/type-company`, params);
};
export const fetchOrderFeedbackStatus = (params) => {
    return SendGet(`directorys/order-feedback-status`, params);
};
export const fetchStates = (params) => {
    return SendGet(`directorys/user-states`, params);
};
export const fetchDelLinDeliveryTypes = (params) => {
    return SendGet(`directorys/del-lin-delivery-types`, params);
}
export const fetchDelLinTreminalIDs = (params) => {
    return SendGet(`directorys/del-lin-terminal-ids`, params);
}
export const fetchDelLinDispatchDates = (params) => {
    return SendGet(`directorys/del-lin-dispatch-dates`, params);
}
export const fetchDelLinCreateLtl = (params) => {
    return SendGet(`directorys/del-lin-create-ltl`, params);
}
export const fetchLogisticsList = (params) => {
    return SendGet(`directorys/logistics-list`, params)
}
export const fetchStorageStatus = (string) => {
    return SendGet(`directorys/storage-status`, { s: string});
};
export const fetchProductStatus = (string) => {
    return SendGet(`directorys/product-status`, { s: string});
};
export const fetchProductAvailability = (string) => {
    return SendGet(`directorys/product-availability`, { s: string});
};
export const fetchTargetStatus = (params) => {
    return SendGet(`directorys/target-status`, params);
};
export const fetchTaskPriority = (params) => {
    return SendGet(`directorys/task-priority`, params);
};
export const fetchProductTypeFromCategory = (params) => {
    return SendGet(`directorys/product-type-category`, params);
};
export const fetchDealerActivity = (params) => {
    return SendGet(`directorys/dealer-activity`, params);
};
export const fetchDealerActivityScore = (params) => {
    return SendGet(`directorys/dealer-activity-score`, params);
};
export const fetchEDOList = (params) => {
    return SendGet(`directorys/list-edo`, params);
};
export const fetchDeliveriesList= (params) => {
    return SendGet(`directorys/list-tk`, params);
};
export const fetchSegmentsPayment = (params) => {
    return SendGet(`directorys/segment-payment`, params);
};
export const fetchUserRank = (params) => {
    return SendGet(`directorys/user-rank`, params);
};