import {
  FINANCE_CURRENCY_SET,
} from '../actions';

const INIT_STATE = {
  currency: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FINANCE_CURRENCY_SET:
      return { ...state, currency: action.payload };
    default:
      return { ...state };
  }
};
