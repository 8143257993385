// eslint-disable-next-line import/named

import {
    REDUX_WEBSOCKET_BEGIN_RECONNECT,
    REDUX_WEBSOCKET_BROKEN,
    REDUX_WEBSOCKET_CLOSED,
    REDUX_WEBSOCKET_CONNECT,
    REDUX_WEBSOCKET_MESSAGE,
    REDUX_WEBSOCKET_OPEN, REDUX_WEBSOCKET_RECONNECTED,
    REDUX_WEBSOCKET_SET_EVENT,
} from '../actions';

const INIT_STATE = {
    beginReconnect: false,
    reconnected: false,
    connected: false,
    messages: [],
    url: '',
    event: {
        notification: null,
        task: null,
        order: null,
        document: null,
        comment: null,
        newOrder: null,
        youResponsibleOrder: null,
        newTasks: null,
        common__listCurrency: [],
        target: null,
        process: null,
    },
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case REDUX_WEBSOCKET_BEGIN_RECONNECT:
            return {
                ...state,
                beginReconnect: action.meta.timestamp,
            };
        case REDUX_WEBSOCKET_RECONNECTED:
            return {
                ...state,
                reconnected: action.meta.timestamp,
            };
        case REDUX_WEBSOCKET_OPEN:
            return {
                ...state,
                connected: true,
            };
        case REDUX_WEBSOCKET_CONNECT:
            return {
                ...state,
                url: action.payload.url,
            };
        case REDUX_WEBSOCKET_BROKEN:
        case REDUX_WEBSOCKET_CLOSED:
            return {
                ...state,
                connected: false,
            };

        case REDUX_WEBSOCKET_MESSAGE: {
            const message = JSON.parse(action.payload.message);
            const { type, eventId, params } = message;
            switch (type) {
                case 'event':
                    return { ...state,
                        event: {
                            ...state.event,
                            [eventId]: params
                        }
                    };
                default:
                    return { ...state };
            }
        }
        case REDUX_WEBSOCKET_SET_EVENT:
            return {
                ...state,
                event: {
                    ...state.event,
                    [action.payload.event]: action.payload.params,
                },
            };
        // case REDUX_WEBSOCKET_SEND:
        //   return {
        //     ...state,
        //     messages: [
        //       ...state.messages,
        //       {
        //         data: action.payload,
        //         origin: window.location.origin,
        //         timestamp: new Date().toISOString(),
        //         type: 'OUTGOING',
        //         id: UniqID('out'),
        //       },
        //     ],
        //   };
        default:
            return { ...state };
    }
};
