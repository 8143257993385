import React from 'react';
// eslint-disable-next-line import/prefer-default-export
export const Loader1 = () => {
  return (
    <>
      <div className="loader-1 loadingio-spinner-dual-ring-6ruguwiwehq">
        <div className="ldio-78xnj8tmbb">
          <div />
          <div>
            <div />
          </div>
        </div>
      </div>
    </>
  );
};
