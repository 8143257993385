import Select from "react-select";
import React, {useEffect} from "react";
import {Style6} from "../../constants/ReactSelect";

const SelectSync = ({
    initState,
    onChange,
    loadOptions,
    style = Style6(),
    placeholder = '',
    isClearable = false,
    disabled = false,
    getOptionValue = (option) => Number.parseInt(option.id, 10),
    getOptionLabel = (option) => option.name,
    callbackNotOptions = () => onChange(null)
}) => {

    useEffect(() => {
        if (!loadOptions.length) {
            callbackNotOptions();
        }
    }, [loadOptions]);

    return (
        <Select
            cacheOptions={false}
            defaultOptions
            value={initState}
            onChange={onChange}
            options={loadOptions}
            noOptionsMessage={() => 'ничего не найдено'}
            loadingMessage={() => 'поиск...'}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            styles={style}
            placeholder={placeholder}
            isClearable={isClearable}
            isDisabled={!loadOptions.length || disabled}
        />
    )
}
export default SelectSync;