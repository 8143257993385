export const UserRole = {
    Admin: 0,
    Editor: 1,
};
export const defaultPermissions = {
    crm_files_del: 'udale1685691633',
    crm_change_deal: 'izmen1612986660', // изменение сделок
    mass_upload_products: 'masso1689584784', // изменение сделок
    chat_with_provider: 'chats1694593101', // чат с поставщиком
    target_list: 'prosm1705403712', // список целей
    create_task_provider: 'sozda1709700587', // создание задач с типом "Поставщик"
    create_dealer: 'sozda1714978882', //создание поставщика
    process_view: 'prosm1712139337',
    process_create: 'sozda1712139326',
    audience_create: 'prosm1720527297',
    change_presence_of_complaints: 'izmen1723787880',
    change_missed_deadlines: 'izmen1723787927',
    change_no_feedback: 'izmen1723787955',
    change_not_following_the_profile: 'izmen1723787983',
};

export const defaultUserGroupsId = {
    moderator: 20
};

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default menu-sub-hidden';
export const WEBSOCKET_PREFIX = 'REDUX_WEBSOCKET';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
    {id: 'en', name: 'English - LTR', direction: 'ltr'},
    {id: 'es', name: 'Español', direction: 'ltr'},
    {id: 'enrtl', name: 'English - RTL', direction: 'rtl'},
];

export const firebaseConfig = {
    apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
    authDomain: 'index-react-login.firebaseapp.com',
    databaseURL: 'https://gogo-react-login.firebaseio.com',
    projectId: 'index-react-login',
    storageBucket: 'index-react-login.appspot.com',
    messagingSenderId: '216495999563',
};

export const adminRoot = '/app';
export const error503 = '/unauthorized';
export const userLogin = '/user/login';
export const userRegister = '/user/register';
export const pathResetPassword = '/user/reset-password';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/#`;
export const servicePath = 'https://api.coloredstrategies.com';
export const siteUrl = 'https://stoking.ru';
export const siteUrlImage = '//image.stoking.ru';
export const currentUser = {
    id: 1,
    title: 'Sarah Kortney',
    img: './assets/img/profiles/profile.svg',
    date: 'Last seen today 15:24',
    role: UserRole.Admin,
};

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.orangecarrot';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
    'bluenavy',
    'blueyale',
    'blueolympic',
    'greenmoss',
    'greenlime',
    'purplemonster',
    'orangecarrot',
    'redruby',
    'yellowgranola',
    'greysteel',
];

export function ResponseException(response) {
    return response;
}

ResponseException.prototype = Object.create(Error.prototype);


export const perPageOptions = [25, 50, 100];

export const usedBrands = {
    other: {id: 62, name: 'Прочее'},
    group: {id: 143, name: 'Сборная'},
    noBrand: {id: 394, name: 'Без бренда'},
    noBrandK: {id: 179, name: 'без бренда Калориферы'},
    noBrandA: {id: 178, name: 'без бренда Автоматика'},
    noBrandN: {id: 177, name: 'без бренда Насос'},
    noBrandP: {id: 176, name: 'без бренда ПТО'},
    noBrandPA: {id: 165, name: 'Прочее авт'},
}

export const switchSelect = [
    {
        id: 0,
        name: 'Нет'
    },
    {
        id: 1,
        name: 'Да'
    }
]

export const statusFinishTasks = [4,5];